import * as React from "react";
import styled from "styled-components/macro";

import { LoadingIndicator } from "../../../app/components/LoadingIndicator";
import { lazyLoad } from "../../../utils/Loadable";

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TiktokModelsPage = lazyLoad(
  () => import("./index"),
  (module) => module.TiktokModelsPage,
  {
    fallback: (
      <LoadingWrapper>
        <LoadingIndicator />
      </LoadingWrapper>
    ),
  },
);

export default TiktokModelsPage;
