import logo from "assets/logo_sm.svg";
import waves from "assets/partner/blue-waves.svg";
import img1 from "assets/partner/section-4-1.jpg";
import img2 from "assets/partner/section-4-2.jpg";
import { useEffect, useRef } from "react";

const Accelerate = ({ scrollToGetInTouch }) => {
  const divRef = useRef(null);
  const rectRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      rectRef.current.style.width = window.innerWidth - divRef?.current?.offsetWidth + "px";
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="relative flex flex-col gap-10 pt-8 text-white   lg:pt-12   xl:gap-20 ">
      <div
        className="relative z-10 mx-auto flex flex-col gap-10 px-4 xl:max-w-[1150px]  xl:px-8 2xl:max-w-[1400px] 2xl:py-32  3xl:max-w-screen-2xl"
        ref={divRef}
      >
        <div className="flex justify-end">
          <h1
            data-aos="zoom-out"
            data-aos-duration="1000"
            data-aos-delay="1000"
            className=" text-3xl font-bold lg:w-[75%] xl:text-4xl 2xl:w-[80%] 2xl:text-5xl 3xl:text-6xl"
          >
            Collaborate with like-minded individuals and elevate your business to the next level by
            joining Beladed's partnership program with exclusive benefits and dedicated support.
          </h1>
        </div>
        <div className=" flex flex-col gap-8 gap-y-32 lg:flex-row-reverse xl:mt-28 2xl:gap-10 3xl:gap-16">
          <div className="flex flex-col gap-4 xl:gap-6 2xl:gap-8 [&>*]:w-fit ">
            <div className="flex flex-col gap-4 xl:gap-6 [&>*]:w-fit">
              <div  data-aos="fade-down"
                data-aos-duration="1000"
                data-aos-delay="1000" className="rounded-full bg-neutral-2">
                <p className="text-gr whitespace-nowrap   py-2 px-5 text-center text-sm tracking-[2.5px] xl:text-lg 3xl:text-xl">
                  Empower Success
                </p>
              </div>
              <h2  data-aos="fade-down"
                data-aos-duration="1000"
                data-aos-delay="1000" className="text-3xl font-bold xl:text-4xl 2xl:text-5xl 3xl:text-6xl">
                Elevate Your Skills and Experience
              </h2>
            </div>
            <p  data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="1000" className="text-sm text-neutral-10 xl:text-base 2xl:text-lg 3xl:text-xl">
              Beladed is dedicated to helping you unlock your potential and achieve your goals. We
              offer exclusive training and development programs led by industry experts to enhance
              your skills and knowledge, giving you a competitive edge in today's landscape.
              Additionally, as a member of Beladed, you'll enjoy VIP treatment and exclusive
              experiences, personalized service and attention, and special perks and benefits. By
              choosing Beladed, you'll be able to elevate your skills and experience to the next
              level and unlock your full potential.
            </p>
            <button  data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="1000"
              onClick={scrollToGetInTouch}
              className="btn-gr btn rounded-full border-0 lg:py-3 lg:px-6 xl:py-4 xl:px-10"
            >
              Get In Touch
            </button>
          </div>
          <div className="relative  mx-auto  flex w-[90%] flex-shrink-0 justify-center lg:w-1/2">
            <img
              src={img1} data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="1000"
              alt="Strategize and Innovate with Beladed  "
              className="z-10  aspect-square w-[100%] max-w-[600px] object-cover lg:w-[90%] xl:w-[80%]"
            />
            <div
              ref={rectRef}
              className="h-gradient flex-c absolute top-0 left-0 z-0 h-[200px] min-w-[400px] max-w-[1000px] -translate-x-1/2  -translate-y-1/3 "
            >
              <p className=" vertical-text  -translate-x-4 text-sm uppercase tracking-[5px] md:translate-x-0 lg:text-base">
                Beladed
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="z-10  flex flex-col lg:flex-row">
        <div
          style={{ "--image-url": `url(${waves})` }}
          className="flex-c aspect-video flex-1 bg-[image:var(--image-url)] bg-cover bg-no-repeat"
        >
          <div className="mx-auto flex  max-w-2xl flex-col justify-center gap-4 px-4 py-8 md:px-8 lg:gap-6">
            <h2   data-aos="fade-down"
              data-aos-duration="1000"
              data-aos-delay="1000" className="text-3xl font-bold sm:text-3xl xl:text-4xl 2xl:text-5xl 3xl:text-6xl">
              Become A partner
            </h2>
            <p  data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="1000" className=" text-sm xl:text-base  2xl:text-lg 3xl:text-xl">
              By harnessing the expertise of Beladed's advisors, companies can gain access to
              cutting-edge technologies, strategies, and insights to help drive growth and
              innovation.
            </p>
            <button  data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="1000"
              onClick={scrollToGetInTouch}
              className="btn white-to-gr w-fit rounded-full border-0 px-10 py-4 2xl:px-12 2xl:py-5 3xl:px-20 3xl:py-5"
            >
              <span className="text-gr  text-sm font-semibold lg:text-base 2xl:text-lg 3xl:text-xl">
                Get In Touch
              </span>
            </button>
          </div>
        </div>
        <div
          style={{ "--image-url": `url(${img2})` }}
          className="aspect-video  flex-1  bg-[image:var(--image-url)] bg-cover bg-no-repeat"
        ></div>
      </div>
      <div className="absolute top-1/4 right-0 w-[400px]  lg:top-1/3 lg:-translate-y-1/2 lg:-translate-x-1/3    2xl:w-[500px]    3xl:w-[600px]">
        <img src={logo} alt="beladed" className="-z-10 w-full opacity-10 grayscale invert-[1]" />
      </div>
    </div>
  );
};

export default Accelerate;
