import SimpleInputForm from "app/components/Inputs/SimpleInputForm";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import logo from "assets/logo.svg";

import VerificationInput from "react-verification-input";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useCountdown from "app/hooks/useCountdown";
import { useAppContext } from "app/context/appContext";
export const Confirm = () => {
  const { user } = useAppContext();
  const [whichConfirm, setWhichConfirm] = useState("email");
  const [step, setStep] = useState(1);
  const [countdownEnded, setCountdownEnded] = useState(false);
  const handleSend = () => {
    setCountdownEnded(false);
    handleReset();
    setStep(2);
  };
  const timeReset = 1000 * 60;
  const [code, setCode] = useState();

  useEffect(() => {
    if (!countdownEnded) {
      setTimeout(() => {
        setCountdownEnded(true);
      }, timeReset);
    }
  }, [countdownEnded, timeReset]);
  const { values, handleReset } = useCountdown(timeReset);
  const handleSendAgain = () => {
    setCountdownEnded(false);
    handleReset();
  };
  const navigate = useNavigate();
  const handleConfirm = () => {
    // navigate("/");
  };

  return (
    <main className="gr-dark container-fluid lg:px-8    min-h-screen flex-c  mx-auto">
      <div className="row gx-sm-5 gap-y-8 max-w-screen-3xl mx-auto  text-white  h-full py-12 lg:py-0">
        <div className="col-12 col-lg-6 col-2xl-5 flex-c mx-auto">
          {step === 1 ? (
            <div className="row gap-y-6   justify-center text-center ">
              <div className="mb-4 w-fit lg:col-12  mx-auto ">
                <img src={logo} alt="logo" className="w-64 " />
              </div>
              <div className="col-12">
                <h1 className="text-2xl font-bold text-white">
                  {whichConfirm === "email"
                    ? "Confirm Your Email"
                    : "Confirm Your Phone"}
                </h1>
              </div>
              <div className="col-12 max-w-[500px]">
                {whichConfirm === "email" ? (
                  <SimpleInputForm
                    name="email"
                    label="Email"
                    placeholder={"Enter your email address"}
                    type="email"
                    theme="dark"
                  />
                ) : (
                  <SimpleInputForm
                    name="phone"
                    label="Phone Number"
                    placeholder={"Enter your phone number"}
                    type="tel"
                    theme="dark"
                  />
                )}
              </div>{" "}
              <div>
                <p className="leading-6 text-sm text-white underline-offset-4">
                  A code will be sent to the phone number you used to join the
                  Wait List
                </p>
              </div>
              <div className="mt-2 flex flex-col  gap-4 justify-center items-center   ">
                <div className="flex items-center gap-4 justify-center flex-wrap">
                  <button
                    className="btn btn-main text-base btn-large w-fit"
                    onClick={() => handleSend()}>
                    Send Code
                  </button>
                  <Link
                    className="btn btn-second-grey text-neutral-1 flex items-center gap-2 btn-large w-fit"
                    to={
                      user.type === "user"
                        ? "/waitlist/users"
                        : "/waitlist/models"
                    }>
                    <span>
                      <ArrowBackIcon />
                    </span>
                    Go Back
                  </Link>
                </div>

                {whichConfirm === "email" ? (
                  <button
                    className=" btn-link-color  text-sm font-semibold"
                    onClick={() => setWhichConfirm("phone")}>
                    Confirm with Phone Number
                  </button>
                ) : (
                  <button
                    className=" btn-link-color  text-sm font-semibold"
                    onClick={() => setWhichConfirm("email")}>
                    Confirm with Email Address
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div className="row gap-y-6   justify-center text-center ">
              <div className="mb-4 flex col-12  justify-center">
                <img src={logo} alt="logo" />
              </div>
              <div className="col-12">
                <div className="flex flex-col gap-4">
                  <h1 className="text-2xl font-bold text-white">
                    Security Code!
                  </h1>
                  <p className="text-neutral-10">
                    Type six digit security code that was sent to your email
                  </p>
                </div>
              </div>
              <div className="col-12 flex-c">
                <VerificationInput
                  onChange={(e) => setCode(e)}
                  placeholder="0"
                  validChars="/0-9/"
                  className=" w-full "
                  autoFocus={false}
                  classNames={{
                    container: "gap-1 w-full w-12 sm:h-16 flex-c",
                    character:
                      "bg-neutral-1 text-white  w-12 sm:w-16  flex-grow text-3xl sm:text-5xl aspect-square  rounded-lg font-medium flex items-center justify-center border-neutral-3  ",
                    characterInactive: "character--inactive ",
                    characterSelected: "text-neutral-1 dark:text-white",
                  }}
                />
              </div>{" "}
              <div>
                <p className="leading-6 text-sm text-white underline-offset-4">
                  A code will be sent to the phone number you used to join the
                  Wait List
                </p>
              </div>
              <div className="mt-2 flex flex-col  gap-4 justify-center items-center  ">
                <div className="flex items-center gap-4 justify-center flex-wrap">
                  <button
                    className="btn btn-main text-base btn-large w-fit"
                    onClick={() => handleConfirm()}>
                    Confirm
                  </button>
                  <button
                    className="btn btn-second-grey text-neutral-1 flex items-center gap-2 btn-large w-fit"
                    onClick={() => setStep(1)}>
                    <span>
                      <ArrowBackIcon />
                    </span>
                    Go Back
                  </button>
                </div>
                <div className="text-white text-sm  gap-2 flex items-center flex-wrap justify-center">
                  <span> Haven't received a code? </span>
                  <div className="gap-2 flex items-center flex-wrap justify-center">
                    <button
                      className=" btn-link-color   font-semibold"
                      onClick={() => handleSendAgain()}
                      disabled={!countdownEnded}>
                      Send again
                    </button>
                    <CountDown values={values} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default Confirm;
const CountDown = ({ values }) => {
  const [s, setS] = useState(values.seconds.toString());
  const [m, setM] = useState(values.minutes.toString());
  useEffect(() => {
    setS(values.seconds.toString());
    setM(values.minutes.toString());
    console.log(values);
  }, [values]);
  return (
    <div className=" countdown flex items-end justify-center gap-1">
      <p className="text-link text-sm">
        {m[0]}
        {m[1]}:{s[0]}
        {s[1]}
      </p>
    </div>
  );
};
