import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import logo from "assets/logo-d.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import grids from "assets/grids.jpg";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputLabel,
} from "@mui/material";
import styled from "@emotion/styled";
import { Label } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ScrollingPictures from "app/components/Big/ScrollingPictures";
import UserDropdown from "app/components/Structure/UserDropdown";
import useHandleAppModals from "app/hooks/useHandleAppModals";
import { useAppContext } from "app/context/appContext";
import MessagingModal from "app/components/Modals/Messaging";
const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 20,
  height: 20,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "white",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "inset 0 0 0 1px #8055D5, inset 0 -1px 0 #8055D5",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,

    backgroundImage: `url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 4.25L6.125 11.125L3 8' stroke='%238055D5' stroke-width='1.6666' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E ")`,
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#ebf1f5",
  },
});
const enteriesOptions = [
  {
    desc: "Add your referral link to your Linktree",
    link: "#link",
    action: "Update Linktree",
    isChecked: false,
  },
  {
    desc: "Add your link to your Instagram story",
    link: "#link",
    action: "Visit Instagram",
    isChecked: false,
  },
  {
    desc: "Add your link to your Tiktok Video reels",
    link: "#link",
    action: "Visit Tiktok",
    isChecked: false,
  },
  {
    desc: "Post a tweet with your link on Twitter",
    link: "#link",
    action: "Post Tweet",
    isChecked: false,
  },
  {
    desc: "Share your link on YouTube",
    link: "#link",
    action: "Visit YouTube",
    isChecked: false,
  },
  {
    desc: "Share your link on Twitch",
    link: "#link",
    action: "Visit Twitch",
    isChecked: false,
  },
  {
    desc: "Add your link on Patreon",
    link: "#link",
    action: "Visit Patreon",
    isChecked: false,
  },
  {
    desc: "Add your link on Snapchat ",
    link: "#link",
    action: "Visit Snapchat",
    isChecked: false,
  },

  {
    desc: "Add your link on Discord",
    link: "#link",
    action: "Visit Discord",
    isChecked: false,
  },
  {
    desc: "Add your link on Reddit",
    link: "#link",
    action: "Visit Reddit",
    isChecked: false,
  },
  {
    desc: "Text message your link to a friend",
    link: "#link",
    action: "Send SMS",
    isChecked: false,
  },
  {
    desc: "Email your link to a friend ",
    link: "#link",
    action: "Send Email",
    isChecked: false,
  },
];
const storedCount = localStorage.getItem("count");
export const Referral = () => {
  const [options, setOptions] = useState(enteriesOptions);
  const handleCheck = (index) => {
    const newOption = options[index];
    const newOptions = options;
    newOption.isChecked = true;
    newOptions.splice(index, 1, newOption);
    setOptions(newOptions);
    setIsChecked(!isChecked);
  };
  const [isCopied, setIsCopied] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };
  const [usersCount, setUsersCount] = useState(
    storedCount ? parseInt(storedCount) : Math.floor(Math.random() * 10000)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const randomIncrement = Math.floor(Math.random() * 100) + 1;
      setUsersCount((prev) => prev + randomIncrement);
    }, 3000);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    localStorage.setItem("count", usersCount);
  }, [usersCount]);
  const { user } = useAppContext();
  const { handleDynamicToggle, openModal, handleKeyClose } =
    useHandleAppModals();
  return (
    <main
      onKeyDown={handleKeyClose}
      className=" container-fluid sm:px-8    min-h-screen ">
      <MessagingModal
        state={openModal.messaging}
        toggle={handleDynamicToggle}
      />
      <div className="row gx-sm-5  mx-auto text-white  h-full min-h-screen ">
        <div className="col-12 hidden lg:flex col-lg-6 flex-c z-10 h-[max(100vh,1250px)]">
          <div className="h-full">
            <ScrollingPictures />
          </div>
        </div>
        <div className="col-12 col-lg-6 py-10 px-0 lg:px-8 2xl:px-12 3xl:px-20  flex-c">
          <div className="flex flex-col gap-8 ">
            <div className="mb-4  flex justify-between gap-4 items-center">
              <Link to="/waitlist/dashboard">
                <img src={logo} alt="logo" className="w-[180px] sm:w-auto" />
              </Link>
              <UserDropdown />
            </div>
            <div className="flex flex-col  gap-4 w-full  lg:items-start items-center">
              <h1 className="text-3xl font-semibold text-black ">
                Congratulations!{" "}
              </h1>
              <p className="text-xl text-neutral-4 text-center lg:text-start">
                Your Payout Details Has Been Submitted
              </p>
              <p className="text-neutral-4 text-center lg:text-start">
                Our team is working on creating your Beladed payout account.
                You’ll receive an email to login and view your earnings shortly.
              </p>
            </div>
            <div className="flex flex-col gap-6">
              <h1 className="text-3xl font-semibold text-black text-center lg:text-start">
                Your Referral Link
              </h1>
              <p className="text-neutral-4 text-center lg:text-start">
                To begin earning referral commissions, click the button below to
                copy your referral link. Make sure your let your friends know
                that you’ll be joining Beladed!
              </p>
              <div className="flex flex-wrap justify-center lg:justify-start gap-6 items-center">
                <div className="relative">
                  <button
                    className="btn btn-main   font-bold btn-large"
                    onClick={() => handleCopy()}>
                    Copy My Referral Link
                  </button>
                  {isCopied && (
                    <div className="flex items-center gap-1 text-sm absolute -bottom-8 left-1/2 -translate-x-1/2  text-secondary-5">
                      Copied!{" "}
                      <span className="text-secondary-5">
                        <CheckCircleIcon sx={{ fontSize: "1.5rem" }} />
                      </span>{" "}
                    </div>
                  )}
                </div>
                {user.type === "model" ? (
                  <button
                    name="messaging"
                    onClick={(e) => handleDynamicToggle(e)}
                    className="btn btn-second flex items-center gap-2 font-bold btn-large">
                    Reply To Messages
                  </button>
                ) : (
                  <Link
                    to="/waitlist/users/tokens"
                    className="btn btn-second flex items-center gap-2 font-bold btn-large">
                    Buy Tokens Now
                  </Link>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <h3 className="text-neutral-1 text-base font-bold">
                Maximize your commission payout by doing the below:
              </h3>
              <div className="text-neutral-1 hidden">
                <input type="checkbox" checked={isChecked} />
                <label>Checkbox</label>
              </div>
              <FormGroup className="flex flex-col gap-0 sm:gap-0">
                {options.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="flex flex-wrap items-center text-neutral-1">
                      <div className="flex items-center mr-2 sm:mr-4 py-1">
                        <Checkbox
                          sx={{
                            "&:hover": { bgcolor: "transparent" },
                          }}
                          disableRipple
                          color="default"
                          checkedIcon={<BpCheckedIcon />}
                          icon={<BpIcon />}
                          checked={item.isChecked}
                        />
                        <InputLabel className="text-neutral-1 !text-sm !sm:text-base">
                          {item.desc}
                        </InputLabel>
                      </div>

                      <button
                        className="btn-pill btn-second-grey sm:mr-2 !text-2xs sm:text-base"
                        onClick={() => handleCheck(index)}>
                        {item.action}
                      </button>
                    </div>
                  );
                })}
              </FormGroup>
            </div>
            <div>
              <Link
                to={`/waitlist/win-25k`}
                className="btn btn-second-grey text-neutral-1 flex items-center gap-2 btn-large w-fit">
                <span>
                  <ArrowBackIcon />
                </span>
                Win $25K
              </Link>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Referral;
