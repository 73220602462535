import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import React, { useEffect, useState } from "react";

const NumberInput = ({
  name,

  minAmount,
  onBlur,
  width = 32,
  classes,
  tokensAmount,
  setTokensAmount,
  placeholder = 100,
}) => {
  const handleTokensAmount = (e) => {
    let value = +e.target.value;
    if (value <= 0) {
      value = 0;
    }

    if (Number.isInteger(value)) {
      setTokensAmount({ ...tokensAmount, [e.target.name]: +e.target.value });
    } else {
      setTokensAmount({ ...tokensAmount, [e.target.name]: 0 });
    }
  };
  const handleIncrease = (e, name) => {
    if (!tokensAmount[name]) {
      setTokensAmount({ ...tokensAmount, [name]: 1 });
    } else {
      setTokensAmount({ ...tokensAmount, [name]: tokensAmount[name] + 1 });
    }
  };

  const handleDecrease = (e, name) => {
    if (!tokensAmount[name]) {
      setTokensAmount({ ...tokensAmount, [name]: 0 });
    } else {
      setTokensAmount({ ...tokensAmount, [name]: tokensAmount[name] - 1 });

      if (tokensAmount[name] <= 0) {
        setTokensAmount({ ...tokensAmount, [name]: 0 });
      }
    }
  };
  return (
    <div className="flex  items-center gap-3">
      <button
        name={name}
        className=" dark:bg-neutral-3 flex-c h-12 w-12 flex-shrink-0 bg-neutral-10"
        onClick={(e) => handleDecrease(e, name)}
        disabled={tokensAmount[name] <= minAmount}
      >
        <RemoveOutlinedIcon />
      </button>
      <div
        className={`h-12 w-${width} dark:border-neutral-3 flex-c  border border-neutral-10 bg-neutral-12 ${classes}  dark:bg-neutral-1`}
      >
        <input
          type="tel"
          className={`dark:bg-neutral-1 dark:text-neutral-11  h-full w-full bg-neutral-12 text-center text-2xl font-semibold text-neutral-3`}
          value={tokensAmount[name]}
          onChange={(e) => handleTokensAmount(e)}
          name={name}
          placeholder={placeholder}
          onBlur={onBlur}
          pattern="[0-9]*"
        />
      </div>
      <button
        name={name}
        className="dark:bg-secondary-5 flex-c h-12 w-12 flex-shrink-0 bg-secondary-6"
        onClick={(e) => handleIncrease(e, name)}
      >
        <AddOutlinedIcon sx={{ color: "white" }} />
      </button>
    </div>
  );
};

export default NumberInput;
