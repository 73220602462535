import Cryptocurrency from "assets/brand/Cryptocurrency.svg";
import GameTech from "assets/brand/Game-Tech.svg";
import Game from "assets/brand/Game.svg";
import Shows from "assets/brand/Shows.svg";
import adv from "assets/brand/adv.svg";
import ai from "assets/brand/ai.svg";
import ar from "assets/brand/ar.svg";
import fashion from "assets/brand/fashion.svg";
import fitness from "assets/brand/fitness.svg";
import marketing from "assets/brand/marketing.svg";
import media from "assets/brand/media.svg";
import socialMedia from "assets/brand/social-media.svg";
import logo from "assets/logo_sm.svg";

const Advantages = () => {
  return (
    <div className=" mx-auto flex flex-col gap-10 py-10 px-4 sm:px-10 md:py-16 lg:py-24 xl:max-w-[1150px] xl:gap-20 xl:px-8 2xl:max-w-[1400px] 2xl:py-32 3xl:max-w-screen-2xl">
      <div className="flex flex-col items-center justify-center gap-4 lg:gap-6">
        <div
          data-aos="fade-down"
          data-aos-duration="1000"
          data-aos-delay="1000"
          className="w-fit rounded-full bg-neutral-11"
        >
          <p className="text-gr    py-2 px-5 text-center text-sm tracking-[2.5px] xl:text-lg 3xl:text-xl">
            Write something for me
          </p>
        </div>
        <h1
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="1000"
          className="text-center text-3xl font-bold sm:text-4xl xl:text-5xl 2xl:text-6xl 3xl:text-7xl"
        >
          Advantage By Industry
        </h1>
      </div>
      <div className="lg:gap-x--8 relative z-0 flex  flex-wrap gap-y-6 gap-x-4 sm:gap-6 lg:gap-y-10 2xl:gap-8">
        {advantages.map((item, index) => {
          return (
            <div
              key={index}
              data-aos="zoom-in-left"
              data-aos-duration="1000"
              data-aos-delay={index <= 7 ? 700 + 200 * index : 200 + 200 * index}
              className=" relative z-10 sm:flex-[1_0_45%] md:flex-[1_0_30%] lg:flex-[1_0_22%]"
            >
              <SingleAdvantage {...item} />
            </div>
          );
        })}
        <div className=" abs-c w-[min(600px,100vw)] 2xl:w-[min(900px,100vw)]">
          <img src={logo} alt="beladed" className="-z-10 w-full opacity-5 grayscale" />
        </div>
      </div>
    </div>
  );
};

export default Advantages;

const advantages = [
  {
    title: "eSports",
    text: (
      <p>
        Collaborate with top-tier executives,{" "}
        <span>shape the future of gaming content, and influence the eSports industry.</span>
      </p>
    ),
    icon: Game,
  },
  {
    title: "Entertainment",
    text: (
      <p>
        Develop a content strategy{" "}
        <span>that contributes to talent acquisition and shapes the future of entertainment.</span>{" "}
      </p>
    ),
    icon: Shows,
  },
  {
    title: "Fashion",
    text: (
      <p>
        Collaborate on product placement,{" "}
        <span>content creation, and fashion to shape the future of fashion content.</span>{" "}
      </p>
    ),
    icon: fashion,
  },
  {
    title: "Fitness",
    text: (
      <p>
        Create a comprehensive fitness content strategy,{" "}
        <span>
          foster engagement through collaboration, and help shape the future of fitness content
        </span>{" "}
      </p>
    ),
    icon: fitness,
  },
  {
    title: "Marketing",
    text: (
      <p>
        <span>When it comes to influencer marketing,</span> focus on shaping brand partnerships{" "}
        <span>and leveraging them to drive platform growth.</span>{" "}
      </p>
    ),
    icon: marketing,
  },
  {
    title: "Advertising",
    text: (
      <p>
        Optimize ad performance and revenue{" "}
        <span>
          by strategically placing and creating engaging ad content, while continuously shaping and
          improving advertising on the platform.
        </span>{" "}
      </p>
    ),
    icon: adv,
  },
  {
    title: "Cryptocurrency",
    text: (
      <p>
        Integrate cryptocurrency{" "}
        <span>
          into your payment and reward structures to shape the future of digital currency.
        </span>{" "}
      </p>
    ),
    icon: Cryptocurrency,
  },
  {
    title: "Game Tech",
    text: (
      <p>
        Collaborate on user engagement{" "}
        <span>
          strategies to shape the future of gaming technology, with a focus on integrating augmented
          reality.
        </span>{" "}
      </p>
    ),
    icon: GameTech,
  },
  {
    title: "Augmented Reality (AR)",
    text: (
      <p>
        Collaborate on interactive experiences{" "}
        <span>and strategically influence the integration of AR content on the platform.</span>{" "}
      </p>
    ),
    icon: ar,
  },
  {
    title: "Artificial Intelligence (AI)",
    text: (
      <p>
        Incorporate AI-powered engagement{" "}
        <span>
          strategies by collaborating on content personalization and influencing the integration of
          chatbots on the platform.
        </span>{" "}
      </p>
    ),
    icon: ai,
  },
  {
    title: "Social Media",
    text: (
      <p>
        Collaborate with others on cross-promotion{" "}
        <span>
          opportunities to shape the future of social media engagement strategies on the platform.
        </span>{" "}
      </p>
    ),
    icon: socialMedia,
  },
  {
    title: "Media",
    text: (
      <p>
        <span>
          To shape the future of media consumption and influence user engagement on your platform,
        </span>{" "}
        focus on creating high-quality and relevant content,{" "}
        <span>and use targeted distribution strategies to reach your intended audience.</span>{" "}
      </p>
    ),
    icon: media,
  },
];

const SingleAdvantage = ({ title, text, icon }) => {
  return (
    <div className="flex flex-row items-center gap-4 sm:!flex-col  sm:items-start sm:gap-6">
      <div className="gradient-dark-2 flex-c h-16 w-16 flex-shrink-0 rounded-xl sm:h-20  sm:w-20">
        <img src={icon} alt={title} />
      </div>
      <div className="flex flex-col gap-1 sm:gap-3">
        <h3 className="text-base font-semibold sm:text-lg lg:text-xl 2xl:text-2xl">{title}</h3>
        <div className="text-xs sm:text-sm lg:text-base 2xl:text-lg  [&_p]:font-medium [&_span]:font-normal [&_span]:text-neutral-4">
          {text}
        </div>
      </div>
    </div>
  );
};
