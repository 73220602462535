import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, Input } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import Menu from "@mui/material/Menu";
import CircularLoading from "app/components/CustomComponents/Loading";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";

import { mockData } from "../../../data/mock-data";
import UserBox from "../CustomComponents/UserBox";

const Dropdown = ({ open, close, anchorState }) => {
  const fetchModels = async () => {
    // const { data } = await axios.get(
    //   `${process.env.REACT_APP_HOST_URL}/models`,
    // );
    const data = mockData["models"];
    return data;
  };
  const navigate = useNavigate();
  const handleViewAll = () => {
    navigate("/waitlist/search/streamers");
    close();
  };
  const [searchTerm, setSearchTerm] = useState("");
  const { isLoading, isError, data, error } = useQuery(["fetchStreamers"], fetchModels, {
    refetchOnWindowFocus: false,
  });

  // const [filteredData, setFilteredData] = useState(data);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
  const filteredStreamers = data
    ? data.filter((user) => user.username.toLowerCase().includes(searchTerm.toLowerCase()))
    : [];

  const inputRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      const inputElement = inputRef?.current?.querySelector("input");
      inputElement?.focus();
    }, 300);
  }, [open, searchTerm]);
  if (isLoading) {
    return (
      <div className="flex-c h-[800px] w-full ">
        <CircularLoading />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex-c h-[200px] w-full  text-lg font-medium">Error: {error.message}</div>
    );
  }
  return (
    <React.Fragment>
      <Menu
        anchorEl={anchorState}
        id="account-menu"
        open={open}
        onClose={close}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            boxShadow: "0px 12px 24px 0px #0000000D",
            mt: 0,
            px: 1,
            py: 3,
            minWidth: "420px",
            top: "0px !important",

            borderRadius: "8px",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        className="!top-0 min-w-[500px] !border !border-neutral-3"
      >
        <div className="flex w-full flex-col gap-4">
          <div className="flex items-center gap-1">
            <IconButton
              aria-label="close"
              onClick={() => close()}
              className="dark:text-neutral-8   text-neutral-5 "
            >
              {" "}
              <ArrowBackIcon />
            </IconButton>

            <div className="dark:border-neutral-4 dark:bg-neutral-1 flex w-full   rounded-lg border border-neutral-9 bg-white px-3 py-2  ">
              <FormControl
                className="flex w-full flex-row gap-2"
                sx={{ height: "100%", border: "none" }}
              >
                <div className="dark:text-neutral-9 flex-c text-neutral-5  ">
                  {" "}
                  <SearchIcon />
                </div>
                <div className="dark:text-neutral-9 w-full text-neutral-5 ">
                  <InputBase
                    ref={inputRef}
                    sx={{ flex: 1, fontSize: 16, width: "100%" }}
                    placeholder="Search.."
                    inputProps={{ "aria-label": "Search" }}
                    autoFocus
                    className="relative !w-full [&_input]:!w-full"
                    onChange={handleSearch}
                    value={searchTerm}
                  />
                  {searchTerm && (
                    <IconButton
                      aria-label="close"
                      onClick={() => setSearchTerm("")}
                      className="!absolute top-1/2 right-0 -translate-y-1/2 "
                    >
                      {" "}
                      <CloseIcon />
                    </IconButton>
                  )}
                </div>
              </FormControl>
            </div>
          </div>

          <div className="flex h-full flex-col gap-4">
            <ModelsSearch close={close} data={filteredStreamers} handleViewAll={handleViewAll} />
          </div>
        </div>
      </Menu>
    </React.Fragment>
  );
};

const SearchDropdown = ({ t }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const openDropDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };
  return (
    <div className="">
      <div
        onClick={handleClick}
        className="dark:border-neutral-4 dark:bg-neutral-1 flex w-[315px] items-center   rounded-lg border border-neutral-9 bg-white px-3 py-2  "
      >
        <FormControl className="flex w-full flex-row gap-2" sx={{ height: "100%", border: "none" }}>
          <div className="dark:text-neutral-8 flex-c   text-neutral-5">
            {" "}
            <SearchIcon />
          </div>
          <div className="dark:text-neutral-8   w-full text-neutral-5">
            <InputBase
              sx={{ flex: 1, fontSize: 16, width: "100%" }}
              placeholder="Search.."
              inputProps={{ "aria-label": "Search" }}
            />
          </div>
        </FormControl>
      </div>
      <Dropdown anchorState={anchorEl} open={openDropDown} close={handleCloseDropdown} />
    </div>
  );
};

const ModelsSearch = ({ handleViewAll, data }) => {
  return (
    <div className="flex w-full flex-col gap-4 px-4">
      {data.length ? (
        <div>
          <div className="flex flex-col gap-4  ">
            {data.slice(0, 4).map((item, index) => {
              const { id, name, image, username, followers_count } = item;
              return (
                <UserBox
                  key={index}
                  gridsCount={1}
                  link={`/${name.first}`}
                  image={image}
                  username={username}
                  followers_count={followers_count}
                  size={14}
                  mSize={14}
                  dynamicWidth={false}
                  classes="w-[240px]"
                />
              );
            })}
          </div>
          <div className="mt-4">
            <button onClick={handleViewAll} className="btn btn-main ">
              View All
            </button>
          </div>
        </div>
      ) : (
        <div className="flex-c mt-8">
          {" "}
          <p className="font-medium text-neutral-1">No result matched your search</p>
        </div>
      )}
    </div>
  );
};

export default SearchDropdown;
