import styled from "@emotion/styled";
import { Label } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import { Checkbox, FormControlLabel, FormGroup, InputLabel } from "@mui/material";
import ScrollingPictures from "app/components/Big/ScrollingPictures";
import MessagingModal from "app/components/Modals/Messaging";
import UserDropdown from "app/components/Structure/UserDropdown";
import { useAppContext } from "app/context/appContext";
import useHandleAppModals from "app/hooks/useHandleAppModals";
import grids from "assets/grids.jpg";
import logo from "assets/logo-d.svg";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 20,
  height: 20,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: theme.palette.mode === "dark" ? "rgba(57,75,89,.5)" : "rgba(206,217,224,.5)",
  },
}));
const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "white",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "inset 0 0 0 1px #8055D5, inset 0 -1px 0 #8055D5",
  backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,

    backgroundImage: `url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 4.25L6.125 11.125L3 8' stroke='%238055D5' stroke-width='1.6666' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E ")`,
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#ebf1f5",
  },
});
const enteriesOptions = [
  {
    desc: (
      <p>
        Invite Instagram Models To Join{" "}
        <a href="#link" className="text-link">
          Beladed
        </a>
      </p>
    ),
    link: "#link",
    action: "copy link",
    isChecked: true,
  },
  {
    desc: "Repost Beladed Video On Instagram",
    link: "#link",
    action: "copy link",
    isChecked: false,
  },
  {
    desc: (
      <p>
        Invite Tiktok Models To Join{" "}
        <a href="#link" className="text-link">
          Beladed
        </a>
      </p>
    ),
    link: "#link",
    action: "copy link",
    isChecked: false,
  },
  {
    desc: "Repost Beladed Video On Tiktok",
    link: "#link",
    action: "copy link",
    isChecked: false,
  },
  {
    desc: "Share with your friends for extra entries",
    link: "#link",
    action: "copy link",
    isChecked: false,
  },
  {
    desc: "Join The Beladed Signal Group",
    link: "#link",
    action: "copy link",
    isChecked: false,
  },
  {
    desc: "Follow @beladed_ on Twitter",
    link: "#link",
    action: "copy link",
    isChecked: false,
  },
  {
    desc: "Retweet Beladed Video On Twitter",
    link: "#link",
    action: "copy link",
    isChecked: false,
  },

  {
    desc: "Join @beladed_ on Telegram",
    link: "#link",
    action: "copy link",
    isChecked: false,
  },
  {
    desc: "Visit The Beladed website",
    link: "#link",
    action: "copy link",
    isChecked: false,
  },
];
const storedCount = localStorage.getItem("count");
export const WIN25K = () => {
  const [options, setOptions] = useState(enteriesOptions);
  const handleCheck = (index) => {
    const newOption = options[index];
    const newOptions = options;
    newOption.isChecked = true;
    newOptions.splice(index, 1, newOption);
    setOptions(newOptions);
    setIsChecked(!isChecked);
    handleCopy(options[index].link);
    setIsCopied(index);
    setTimeout(() => {
      setIsCopied(null);
    }, 1500);
  };
  const [isCopied, setIsCopied] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };
  const [usersCount, setUsersCount] = useState(
    storedCount ? parseInt(storedCount) : Math.floor(Math.random() * 10000),
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const randomIncrement = Math.floor(Math.random() * 100) + 1;
      setUsersCount((prev) => prev + randomIncrement);
    }, 3000);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    localStorage.setItem("count", usersCount);
  }, [usersCount]);
  const { user } = useAppContext();
  const { handleDynamicToggle, openModal, handleKeyClose } = useHandleAppModals();
  return (
    <main onKeyDown={handleKeyClose} className="container-fluid min-h-screen    lg:px-8 ">
      <MessagingModal state={openModal.messaging} toggle={handleDynamicToggle} />
      <div className="row  mx-auto h-full  min-h-screen text-white ">
        <div className="col-12 col-lg-6 col-xl-7 flex-c z-10 hidden h-[max(100vh,1100px)] lg:flex">
          <div className="h-full">
            <ScrollingPictures />
          </div>
        </div>
        <div className="col-12 col-lg-6 col-xl-5 flex-c py-10">
          <div className="flex flex-col gap-8 px-4 xl:px-0">
            <div className="mb-4 flex justify-between">
              <Link to="/waitlist/dashboard">
                <img src={logo} alt="logo" className=" w-44 md:w-auto" />
              </Link>
              <UserDropdown />
            </div>
            <div className="flex w-full  flex-col gap-6">
              <h1 className="text-4xl font-semibold text-black">WIN $25K</h1>
              <p className="text-neutral-4">
                To celebrate our pre-sale and rapidly growing community, we’re giving one lucky
                person the chance to win $25k worth of the Beladed token. All you have to do is
                follow the steps below to receive multiple entries.
              </p>
              <div className="flex flex-wrap items-center justify-center gap-6 lg:justify-start">
                {user.type === "model" ? (
                  <>
                    <Link
                      to="/waitlist/referral"
                      className="btn btn-large  bg-primary-5 font-bold hover:bg-primary-4"
                    >
                      REFER AND EARN
                    </Link>
                    <button
                      name="messaging"
                      onClick={(e) => handleDynamicToggle(e)}
                      className="btn btn-large btn-second flex items-center gap-2 font-bold"
                    >
                      Reply To Messages
                    </button>
                  </>
                ) : (
                  <>
                    <Link
                      to="/waitlist/users/tokens"
                      className="btn btn-large  bg-primary-5 font-bold hover:bg-primary-4"
                    >
                      BUY TOKENS NOW
                    </Link>
                    <button className="btn btn-large btn-second-grey flex items-center gap-2 font-bold">
                      <span>
                        <GroupsOutlinedIcon />
                      </span>
                      {usersCount.toLocaleString()}
                    </button>
                  </>
                )}
              </div>
            </div>

            <div className="flex flex-col gap-4 ">
              <h3 className="text-xl font-bold text-neutral-1">
                To gain multiple entries, complete the steps below:
              </h3>
              <div className="hidden text-neutral-1">
                <input type="checkbox" defaultChecked={isChecked} />
                <label>Checkbox</label>
              </div>
              <FormGroup className="flex flex-col gap-2 sm:gap-0">
                {options.map((item, index) => {
                  return (
                    <div key={index} className="flex flex-wrap items-center text-neutral-1 ">
                      <div className="mr-4 flex flex-wrap items-center py-1">
                        <Checkbox
                          sx={{
                            "&:hover": { bgcolor: "transparent" },
                          }}
                          disableRipple
                          color="default"
                          checkedIcon={<BpCheckedIcon />}
                          icon={<BpIcon />}
                          checked={item.isChecked}
                        />
                        <InputLabel className="!sm:text-base !text-sm text-neutral-1 ">
                          {item.desc}
                        </InputLabel>
                      </div>

                      <button
                        className="btn-second-grey btn-pill mr-2"
                        onClick={() => handleCheck(index)}
                      >
                        {item.action}
                      </button>
                      {isCopied === index && (
                        <div className="flex items-center gap-1 text-sm">
                          Copied!{" "}
                          <span className="text-primary-5">
                            <CheckCircleIcon sx={{ fontSize: "1.5rem" }} />
                          </span>{" "}
                        </div>
                      )}
                    </div>
                  );
                })}
              </FormGroup>
            </div>
            <div>
              <Link
                to="/waitlist/payout-details"
                className="btn btn-large btn-second-grey flex w-fit items-center gap-2 text-neutral-1"
              >
                <span>
                  <MonetizationOnOutlinedIcon />
                </span>
                Refer and Earn Money
              </Link>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default WIN25K;
