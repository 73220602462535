import React, { useEffect } from "react";
import logo from "assets/logo.svg";
import cover from "assets/home-cover.jpg";
import { Link, useNavigate } from "react-router-dom";
import Hero from "./sections/Hero";
import TargetIndustries from "./sections/TargetIndustries";
import TargetVerticals from "./sections/TargetVerticals";
import TargetEmergingMarkets from "./sections/TargetEmergingMarkets";
import TheMarket from "./sections/TheMarket";
import ThePlatform from "./sections/ThePlatform";
import TokenEconomy from "./sections/TokenEconomy";
import Contact from "./sections/Contact";
import Footer from "./sections/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import "./style.css";
const Home = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <main className=" overflow-x-hidden">
      <Hero />
      <TargetIndustries />
      <TargetVerticals />
      <TargetEmergingMarkets />
      <TheMarket />
      <ThePlatform />
      <div className="large-gr z-20">
        <TokenEconomy />
        <Contact />
        <Footer />
      </div>
    </main>
  );
};

export default Home;
