import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import CloseIcon from '@mui/icons-material/Close';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import SearchIcon from '@mui/icons-material/Search';
import TodayIcon from '@mui/icons-material/Today';
import { IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import axios from 'axios';
import { mockData } from 'data/mock-data.js';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';

import SimpleInputForm from '../Inputs/SimpleInputForm';
import SimpleSelectForm from '../Inputs/SimpleSelectForm';
import CircularLoading from '../Small/Loading';
import UserBoxSelect from '../Small/UserBoxSelect';
import Modal from '../Structure/Modal';

const initialValues = {
  first_name: '',
  last_name: '',
  username: '',
  email: '',
  phone: '',
  instagram: '',
  tiktok: '',
  twitter: '',
  ethnicity: '',
  birthday: '',
};
const EditProfile = ({
  state,
  toggle,
  staticToggle,
  doubleStaticToggle,
  setSelectedModal,
  selectedModal,
  handleSearchModal,
}) => {
  const [values, setValues] = useState({
    first_name: '',
    last_name: '',
    username: '',
    email: '',
    phone: '',
    instagram: '',
    tiktok: '',
    twitter: '',
    ethnicity: '',
    birthday: '',
  });
  const handleValuesChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('editProfileValues', values);
    staticToggle('editProfile');
  };

  return (
    <Modal state={state} close={toggle}>
      <div className="relative mx-auto my-auto flex w-[95vw] flex-col   gap-6 rounded-lg  bg-white px-6 py-4 sm:w-[600px]  md:px-8 md:py-8 lg:w-[780px]">
        <div className="flex items-start justify-between w-full">
          <div className="flex flex-col w-full gap-3 md:gap-6">
            <div className="flex items-center justify-between ">
              <h2 className="text-xl font-semibold text-neutral-1 md:text-2xl">
                Edit Profile Details
              </h2>
              <div>
                <IconButton aria-label="close" name="editProfile" onClick={(e) => toggle(e)}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={(e) => handleSubmit(e)} className="flex flex-col gap-4">
          <div className="row gap-y-4 gap-x-0">
            <div className="px-1 col-12 col-sm-4 col-md-4">
              <SimpleInputForm
                value={values.first_name}
                placeholder={'first name'}
                name="first_name"
                onChange={handleValuesChange}
                label={'First Name'}
              />
            </div>
            <div className="px-1 col-12 col-sm-4 col-md-4">
              <SimpleInputForm
                value={values.last_name}
                placeholder={'last name'}
                name="last_name"
                onChange={handleValuesChange}
                label={'Last Name'}
              />
            </div>
            <div className="px-1 col-12 col-sm-4 col-md-4">
              <SimpleInputForm
                value={values.username}
                placeholder={'username'}
                name="username"
                onChange={handleValuesChange}
                label={'Username'}
                hasIcon={true}
                icon={<AlternateEmailIcon sx={{ fontSize: '0.85rem', margin: '0' }} />}
              />
            </div>
            <div className="px-1 col-12 col-sm-6 col-md-6">
              <SimpleInputForm
                value={values.email}
                placeholder={'Email'}
                name="email"
                onChange={handleValuesChange}
                label={'Email'}
                type="email"
              />
            </div>
            <div className="px-1 col-12 col-sm-6 col-md-6">
              <SimpleInputForm
                value={values.phone}
                placeholder={'Phone Number'}
                name="phone"
                onChange={handleValuesChange}
                label={'Phone Number'}
                type="tel"
                hasPattern={true}
                pattern="[0-9]*"
              />
            </div>
            <div className="px-1 col-12 col-sm-4 col-md-4">
              <SimpleInputForm
                value={values.instagram}
                placeholder={'Instagram'}
                name="instagram"
                onChange={handleValuesChange}
                label={'Instagram'}
                hasIcon={true}
                icon={<AlternateEmailIcon sx={{ fontSize: '0.85rem', margin: '0' }} />}
              />
            </div>
            <div className="px-1 col-12 col-sm-4 col-md-4">
              <SimpleInputForm
                value={values.tiktok}
                placeholder={'Tiktok'}
                name="tiktok"
                onChange={handleValuesChange}
                label={'Tiktok'}
                hasIcon={true}
                icon={<AlternateEmailIcon sx={{ fontSize: '0.85rem', margin: '0' }} />}
              />
            </div>
            <div className="px-1 col-12 col-sm-4 col-md-4">
              <SimpleInputForm
                value={values.twitter}
                placeholder={'Twitter'}
                name="twitter"
                onChange={handleValuesChange}
                label={'Twitter'}
                hasIcon={true}
                icon={<AlternateEmailIcon sx={{ fontSize: '0.85rem', margin: '0' }} />}
              />
            </div>
            <div className="px-1 col-12 col-sm-6 col-md-6">
              <SimpleSelectForm
                value={values.ethnicity}
                placeholder={'Ethnicity'}
                name="ethnicity"
                onChange={handleValuesChange}
                label={'Ethnicity'}
                options={ethnicities}
              />
            </div>
            <div className="px-1 col-12 col-sm-6 col-md-6">
              <SimpleInputForm
                value={values.birthday}
                placeholder={'Birthday'}
                name="birthday"
                onChange={handleValuesChange}
                label={'Birthday'}
                type="date"
                hasIcon={true}
                icon={<TodayIcon sx={{ marginRight: '0.25rem' }} />}
              />
            </div>
          </div>

          <div className="flex items-center self-end gap-4 mt-3 justify-self-end">
            <button
              className="btn btn-tertiary-grey "
              onClick={(e) => toggle(e)}
              name="editProfile"
            >
              Close
            </button>
            <button type="submit" className="btn btn-main ">
              Update
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
export default EditProfile;
const ethnicities = [
  'African',
  'African American',
  'Alaska Native',
  'Arab',
  'Asian',
  'Biracial',
  'Black',
  'Caribbean',
  'Caucasian',
  'European',
  'Hispanic',
  'Indian',
  'Indigenous',
  'Latino',
  'Middle Eastern',
  'Multiracial',
  'Native American',
  'Pacific Islander',
  'White',
  'Other',
];
