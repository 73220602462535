import { Dialog, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
export const ModalComponent = ({ state, close, children }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <Dialog
      open={state}
      variant="standard"
      disableEscapeKeyDown={true}
      onClose={close}
      maxWidth={"lg"}
      fullScreen={fullScreen}
      fullWidth={true}
      PaperProps={{
        style: {
          borderRadius: "0px",
          background: "transparent",
          margin: 0,
          boxShadow: "none",
        },
      }}
      sx={{
        ".MuiPaper-root": {
          borderRadius: "20px",
        },
        ".MuiDialog-paperScrollPaper": {
          width: "unset",
        },
        ".MuiDialog-paper": {},
      }}>
      <div className=" flex h-full items-center justify-center lg:rounded-lg text-neutral-1 ">
        {" "}
        {children}
      </div>
    </Dialog>
  );
};

export default ModalComponent;
