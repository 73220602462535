import LinkedCameraOutlinedIcon from "@mui/icons-material/LinkedCameraOutlined";
import { useAppContext } from "app/context/appContext";
import useHandleUpload from "app/hooks/useHandleUpload";

const ProfilePhotoOwner = () => {
  const { uploadedImage, uploadPhoto } = useHandleUpload();
  const { user } = useAppContext();
  return (
    <div className="relative  w-24 flex-shrink-0 -translate-y-1/3 lg:-translate-y-1/3  ">
      <img
        src={uploadedImage ? uploadedImage : user.image}
        alt="profile"
        className=" aspect-square h-24 w-24 rounded-full object-cover "
      />
      <label className="  flex-c absolute right-0 bottom-0 aspect-square h-6 w-6 cursor-pointer rounded-full bg-primary-10 text-black ">
        <LinkedCameraOutlinedIcon sx={{ fontSize: "100%" }} />
        <input
          type="file"
          onChange={(e) => uploadPhoto(e)}
          className="hidden"
          accept=".jpg, .jpeg, .png"
        />
      </label>
    </div>
  );
};

export default ProfilePhotoOwner;
