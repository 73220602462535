import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import Modal from "../Structure/Modal";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import QR_venmo from "assets/QR_venmo.jpg";
import venmo from "assets/venmo_full.png";
import venmo2 from "assets/venmo.svg";
const VenmoModal = ({
  state,
  toggle,

  name,
}) => {
  const handleClose = (e) => {
    toggle(e);
  };
  return (
    <Modal state={state} close={toggle}>
      <div
        className=" hidden md:block custom-scrollbar w-[95vw]    overflow-y-auto bg-white  xl:w-[60vw]"
        style={{ maxHeight: "90vh" }}>
        <div className="h-gradient  relative h-40 w-full  ">
          <div className="relative left-1/2 top-full flex -translate-x-1/2 -translate-y-1/2 items-center justify-center gap-4">
            <img src={QR_venmo} alt="QR_venmo" className="" />
          </div>
          <button
            name={name}
            onClick={(e) => toggle(e)}
            className="absolute top-8 right-8 text-white">
            <HighlightOffIcon sx={{ fontSize: "2rem" }} />
          </button>
        </div>
        <div className="flex flex-col items-center gap-4 px-3 pb-4 pt-36 text-center  md:gap-4 md:pb-8 text-neutral-1">
          <div className="flex items-center gap-1">
            <img src={venmo} alt="venmo" />
          </div>

          <h2 className="text-3xl font-semibold">Scan QR Code</h2>
          <p className="text-neutral-5">
            We will confirm your payment and email you a receipt.{" "}
          </p>
        </div>
        <div className="flex  justify-center px-3 pb-8 ">
          <button
            name={name}
            onClick={(e) => handleClose(e)}
            className="btn btn-main  ">
            Close
          </button>
        </div>
      </div>
      <div className="flex flex-col gap-4 md:hidden bg-white w-[min(95vw,500px)] py-10 px-8 items-center justify-center">
        <div className="flex items-center gap-2">
          <img src={venmo} alt="venmo " />
        </div>
        <div>
          <button className="bg-neutral-1 py-4 px-8 btn-pill text-white flex items-center gap-2">
            <img src={venmo2} alt="" />
            Continue to Venmo Pay
          </button>
        </div>
        <p className="text-neutral-5 text-center">
          We will confirm your payment and email you a receipt.{" "}
        </p>
        <div className="flex  justify-center px-3 mt-2 ">
          <button
            name={name}
            onClick={(e) => handleClose(e)}
            className="btn btn-main ">
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default VenmoModal;
