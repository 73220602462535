import React, { useEffect, useState } from "react";

const initialModalStates = {
  announce: false,
  suggest: false,
  message: false,
  draft: false,
  findTeam: false,
  draftSuccess: false,
  findModal: false,
  search: false,
  report: false,
  invite: false,
  config: false,
  notify: false,
  team: false,
  share: false,
  send: false,
  removeTeam: false,
  removeUser: false,
  tip: false,
  tournament: false,
  score: false,
  chat: false,
  calendar: false,
  success: false,
  selectModel: false,
  sendToken: false,
  gallery: false,
  messaging: false,
  editProfile: false,
  uploadPhoto: false,
  gallerySingle: false,
  editGallery: false,
  editMessageSettings: false,
};
const useHandleAppModals = () => {
  const [openModal, setOpenModal] = useState({
    announce: false,
    suggest: false,
    message: false,
    draft: false,
    findTeam: false,
    draftSuccess: false,
    findModal: false,
    search: false,
    report: false,
    invite: false,
    config: false,
    notify: false,
    team: false,
    share: false,
    send: false,
    removeTeam: false,
    removeUser: false,
    tip: false,
    tournament: false,
    score: false,
    chat: false,
    calendar: false,
    success: false,
    selectModel: false,
    sendToken: false,
    gallery: false,
    messaging: false,
    editProfile: false,
    uploadPhoto: false,
    gallerySingle: false,
    editGallery: false,
    editMessageSettings: false,
  });

  const handleDynamicToggle = (e) => {
    setOpenModal({
      ...openModal,
      [e.target.name]: !openModal[e.target.name],
    });
  };
  const handleStaticToggle = (name) => {
    setOpenModal({
      ...openModal,
      [name]: !openModal[name],
    });
  };
  const handleDoubleStaticToggle = (name1, name2) => {
    setOpenModal({
      ...openModal,
      [name1]: !openModal[name1],
      [name2]: !openModal[name2],
    });
  };
  const handleKeyClose = (e) => {
    if (e.key === "Escape") {
      setOpenModal(initialModalStates);
      document.body.style.overflow = "auto";
    }
  };

  return {
    openModal,
    setOpenModal,
    handleDynamicToggle,
    handleStaticToggle,
    handleDoubleStaticToggle,
    handleKeyClose,
    initialModalStates,
  };
};

export default useHandleAppModals;
