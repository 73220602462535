import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const UserBoxSelect = ({
  gridsCount,
  textWidth,

  name,
  image,
  followers_count,
  size = 20,
  classes,
  dynamicWidth = true,
  t,
  onClick,
  toggle,
  isSelected,
  username,
}) => {
  const [isFollowing, setIsFollowing] = useState(false);
  const [isInvited, setIsInvited] = useState(false);

  const ref = useRef(null);
  const parent = useRef(null);

  useEffect(() => {
    ref.current.style.width = parent.current.offsetWidth - size * 4 - 40 + "px";
  }, [parent.current]);
  return (
    <div
      ref={parent}
      onClick={onClick}
      className={`user-box flex   items-center gap-2 p-2  ${classes} ${
        isSelected && " border-2 border-primary-5 "
      } `}>
      <div
        className={`w-14 h-14 aspect-square   flex-shrink-0 overflow-hidden rounded-full `}>
        <img
          src={image}
          alt={name}
          className={`rounded-full w-14 h-14 aspect-square scale-[1.02]`}
        />
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex flex-col">
          {" "}
          <p
            ref={ref}
            className="ellipsis text-sm font-semibold text-neutral-3 ">
            @{username ? username : name.first + name.last}
          </p>
          <p className="text-2xs  text-neutral-5  ">
            {followers_count} Followers
          </p>
        </div>
      </div>
    </div>
  );
};

export default UserBoxSelect;
