import React, { useEffect, useRef, useState } from "react";

import img1 from "assets/invest/section-6-1.png";
import img1a from "assets/invest/section-6-1-a.jpg";
import img1b from "assets/invest/section-6-1-b.png";
import img1c from "assets/invest/section-6-1-c.png";
import img1d from "assets/invest/section-6-1-d.png";
import { useAppContext } from "app/context/appContext";

const TokenEconomy = () => {
  const imgRef = useRef();
  const textRef = useRef();
  const { windowWidth } = useAppContext();
  const [height, setHeight] = useState(imgRef.current?.offsetHeight);

  // useEffect(() => {
  //   imgRef.current.addEventListener("load", () => {
  //     console.log("Image height:", imgRef.current.offsetHeight);
  //     textRef.current.style.marginBottom =
  //       -imgRef.current.offsetHeight / 2 + "px";
  //   });
  //   textRef.current.style.marginBottom =
  //     -imgRef.current.offsetHeight / 2 + "px";
  // }, [windowWidth, imgRef]);

  return (
    <div className=" text-white pt-16 lg:pt-32   flex flex-col gap-10  px-4 xl:px-8 ">
      <div className="max-w-[1400px] mx-auto ">
        <div className="flex flex-col gap-20 relative z-10">
          <div className=" z-10 ">
            <h1
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-delay="500"
              className="  text-5xl md:text-6xl lg:text-7xl  3xl:text-[150px] text-center  font-bold leading-tight px-4 mb-6 lg:mb-6">
              Token Economy
            </h1>
            <p
              ref={textRef}
              data-aos="fade-in"
              data-aos-duration="2000"
              data-aos-delay="1500"
              className=" text-base font-medium  lg:text-lg xl:text-xl 3xl:text-3xl text-start "
              // style={{ marginBottom: -height + "px" }}
            >
              Beladed's token-based livestream platform blends casino game
              addiction with unpredictable live-streaming and user
              participation. Users purchase tokens to engage with streamers
              through custom buttons, team drafts, stickers, unlocked cameras,
              and more. This taps into user engagement and reward psychology,
              creating a self-sustaining ecosystem. Beladed is leveraging this
              model to change the way we consume social media.
            </p>
          </div>

          <div className="z-10 ">
            <div
              ref={imgRef}
              className=" w-full grid grid-cols-[repeat(10, 1fr)] grid-rows-[repeat(8, 1fr)] py-6  rounded-xl">
              <div
                style={{ gridArea: "1 / 2 / 9 / 10" }}
                data-aos="fade-left"
                data-aos-duration="1000"
                data-aos-delay="1500">
                <img
                  src={img1a}
                  alt="Target Verticals"
                  className="rounded-lg border-4 lg:border-[12px] border-[#ffffff33] "
                />
              </div>
              <div
                style={{ gridArea: "1 / 8 / 3 / 11" }}
                className="flex justify-end"
                data-aos="fade-right"
                data-aos-duration="1000"
                data-aos-delay="2000">
                <img
                  src={img1b}
                  alt="Target Verticals"
                  className="rounded-lg "
                />
              </div>
              <div
                style={{ gridArea: "4 / 8 / 6 / 11" }}
                className="flex justify-start mr-20"
                data-aos="flip-right"
                data-aos-duration="1500"
                data-aos-delay="2500">
                <img
                  src={img1c}
                  alt="Target Verticals"
                  className="lg:rounded-xl"
                />
              </div>
              <div
                style={{ gridArea: "2 / 1 / 5 / 4" }}
                className="flex justify-end"
                data-aos="flip-left"
                data-aos-duration="1500"
                data-aos-delay="2500">
                <img
                  src={img1d}
                  alt="Target Verticals"
                  className="rounded-xl"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenEconomy;
