import container from "assets/agency/video-container.png";
import logo from "assets/logo-d.svg";
import React from "react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div className=" bg-neutral-11 pb-10 lg:pb-20 2xl:pb-32">
      <header className="max-screen mx-auto flex items-center justify-between gap-4 py-4 px-4 md:px-8 lg:py-12">
        <Link to="/agency-models">
          <img src={logo} alt="Beladed" className="w-32 lg:w-44" />
        </Link>
        <Link to="/waitlist/models" className="btn-gr lg:py-3 lg:px-8 lg:text-base">
          JOIN THE WAITLIST
        </Link>
      </header>
      <div className="hero-container max-screen mx-auto flex flex-col  gap-6 py-10 px-4  sm:gap-10 md:px-8 ">
        <h1
          className="archivo hero-title text-start text-5xl font-semibold  !leading-none sm:text-6xl lg:text-7xl lg:font-extrabold  xl:text-8xl 2xl:text-[120px] 3xl:text-[170px]"
          data-aos="fade-down"
          data-aos-duration="1000"
          data-aos-delay=""
        >
          Interactive <br /> <span className="text-gr-animation">Livestream</span> <br />{" "}
          <span className="hero-text-animation">Entertainment</span>
        </h1>
        <div data-aos="fade-in" data-aos-duration="2000" data-aos-delay="500">
          <Link to="/waitlist/models" className="btn-gr py-4 px-8">
            JOIN THE WAITLIST
          </Link>
        </div>
      </div>
      <div
        className=" max-screen mx-auto flex max-h-[920px]  justify-start bg-cover bg-center bg-no-repeat px-4 2xl:px-0"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-delay="2000"
        data-aos-offset="-200"
      >
        <div className=" z-10 h-full w-full rounded-lg  lg:flex">
          <div className="video-background-container flex-c z-0 aspect-video w-full">
            <img src={container} alt="" className="absolute inset-0 z-10 h-full w-full" />
            <div className="video-background  ">
              <ReactPlayer
                url="https://player.vimeo.com/video/809332867?h=01bb3a9b98&autoplay=1&title=0&byline=0&portrait=0"
                playsinline
                muted={true}
                width="100%"
                height="100%"
                controls={false}
                playing={true}
                loop={true}
                style={{
                  zIndex: "-1",
                  borderRadius: "8px",
                  overflow: "hidden",
                  position: "absolute",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
