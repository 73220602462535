import { useAppContext } from "app/context/appContext";
import logo from "assets/logo_sm.svg";

import Modal from "../Structure/Modal";

const LeavingModalUser = ({ state, toggle, leave, name, dynamicToggle }) => {
  const handleClose = (e) => {
    toggle(e);
    dynamicToggle("searchModel");
  };
  const { user } = useAppContext();
  return (
    <Modal state={state} close={toggle}>
      <div
        className="  custom-scrollbar w-[95vw]    overflow-y-auto bg-white  md:w-[min(750px)]"
        style={{ maxHeight: "90vh" }}
      >
        <div className="h-gradient  relative h-32 w-full  ">
          <div className="relative left-1/2 top-full flex -translate-x-1/2 -translate-y-1/2 items-center justify-center gap-4">
            <img src={logo} alt="Beladed" className=" w-24 sm:w-32" />
          </div>
        </div>
        <div className="flex flex-col items-center gap-2 px-3 pb-4 pt-16 text-center text-neutral-1 sm:gap-4  sm:pt-20 md:gap-4 md:pb-8">
          <div className="flex items-center gap-1">
            <p className="text-2xl font-semibold text-neutral-1 sm:text-3xl">
              {user.type === "user" ? "Message Models" : "Before You Go"}
            </p>
          </div>
          <div>
            {" "}
            {user.type === "user" ? (
              <p className="text-base font-medium text-neutral-4 sm:text-xl">
                Send a priority message and get a reply back!
              </p>
            ) : (
              <p className="flex flex-col gap-2 text-base font-medium text-neutral-4 sm:text-xl">
                Enter For Each Chance To
                <span className="text-2xl font-semibold text-primary-4">Win $25K</span>
              </p>
            )}
          </div>
          <div className="text-sm leading-normal text-neutral-4 sm:text-base lg:max-w-[80%]">
            {user.type === "user"
              ? "Get to know the Streamers that will be on Beladed Live, today! With your tokens, Streamers are incentivized to reply back. So don’t miss the chance to connect with your favorite Streamer today!"
              : "To celebrate our presale and rapidly growing community, we’re giving one lucky person the chance to win $25k worth of the Beladed token."}
          </div>
        </div>
        <div className="mt-2 flex flex-col items-center justify-center gap-3 pb-8 ">
          <button name={name} onClick={(e) => handleClose(e)} className="btn btn-main  ">
            {user.type === "user" ? " See All Models" : "Yes, Sign Me Up"}
          </button>
          <button onClick={(e) => leave(e)} className="text-xs font-semibold text-neutral-2">
            No Thanks
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default LeavingModalUser;
