import { AppProvider } from "app/context/appContext";
import React from "react";
import { hydrate, render } from "react-dom";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "react-query";

import App from "./app/index";
import "./css/output.css";
import "./css/styles/bootstrap-grid.css";
import "./css/styles/globals.css";

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AppProvider>
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </QueryClientProvider>
    </HelmetProvider>
  </AppProvider>,
);
