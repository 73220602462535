import { useAppContext } from "app/context/appContext";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const UserBox = ({
  gridsCount,
  textWidth,
  link = "/selena",
  name,
  username,
  image,
  followers_count,
  size = 20,
  mSize = 20,
  classes,
  dynamicWidth = true,

  buttons = [],
  toggle,
  indicator = false,
  value,
  expandLiveChat,
}) => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.stopPropagation();
    navigate(link);
  };

  const { remainingWidth } = useAppContext();
  const ref = useRef(null);
  const parent = useRef(null);

  const [parentWidth, setParentWidth] = useState();
  useEffect(() => {
    if (parent.current) {
      // ref.current.style.width = Math.min(parent.current.offsetWidth - 16) + "px";
    }
  }, [parent.current, remainingWidth, window.innerWidth, expandLiveChat]);
  return (
    <div
      onClick={handleClick}
      ref={parent}
      className={`user-box  flex flex-col items-center justify-center  gap-2 p-3  xs:flex-row 
        xs:justify-start md:flex-grow   md:justify-start md:p-2  ${classes} `}
      style={{
        width: dynamicWidth && `calc((100%/${gridsCount}) - 14px  - 0.01px)`,
      }}
    >
      <div
        className={` w-${mSize} md:w-${size} md:h-${size} relative aspect-square flex-shrink-0   rounded-full bg-secondary-9 `}
      >
        <img
          src={image}
          alt=""
          className={`rounded-full w-${mSize}  md:w-${size} md:h-${size}  aspect-square scale-[1.02] object-cover`}
        />
      </div>
      <div
        style={{
          maxWidth: parent?.current?.offsetWidth - size * 4 - 48 + "px",
        }}
        className="flex   flex-col items-center gap-2 overflow-hidden xs:items-start  md:w-auto md:gap-2 "
      >
        <div className="flex w-full  flex-col items-center gap-0.5 overflow-hidden xs:items-start    ">
          {" "}
          <p
            ref={ref}
            className="ellipsis dark:text-neutral-10  max-w-[20ch] text-center text-sm font-semibold
             text-neutral-3 xs:text-start md:text-start md:text-xs 3xl:text-base"
            style={{
              width: parent?.current?.offsetWidth - size * 4 - 48 + "px",
            }}
          >
            @{username}
          </p>
          <p className="dark:text-neutral-9 text-2xs  text-neutral-5 3xl:text-xs ">
            {followers_count} followers
          </p>
        </div>
      </div>
    </div>
  );
};

export default UserBox;
