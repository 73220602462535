import React, { useEffect, useRef, useState } from "react";

import img1 from "assets/invest/section-3-1.png";
import img1a from "assets/invest/section-3-1-a.jpg";
import img1b from "assets/invest/section-3-1-b.png";
import img1c from "assets/logo_sm.svg";
import { useAppContext } from "app/context/appContext";
const TargetEmergingMarkets = () => {
  return (
    <div className="bg-neutral-1 py-10 md:py-16 lg:py-24 2xl:py-32  px-4 xl:px-8 ">
      <div className="flex flex-col gap-4 xl:gap-20 max-w-[1400px] mx-auto">
        <div>
          <h1
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="500"
            data-aos-anchor-placement="top-center"
            className="text-white w-fit mx-auto text-4xl md:text-6xl lg:text-6xl xl:text-7xl 3xl:text-[150px] text-center font-bold !leading-tight px-4 mb-10 lg:mb-6">
            Target <span className="text-gr">Emerging</span> Markets
          </h1>
        </div>
        <div className="text-white flex flex-col-reverse items-center  lg:flex-row justify-between gap-8 lg:gap-12 flex-[0_0_55%] ">
          <div className="flex flex-col gap-6 lg:gap-12">
            <div
              className="flex flex-col gap-5"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-delay="1000">
              <span className="text-gr font-bold">PRIMARY:</span>
              <h1 className=" font-bold text-2xl lg:text-5xl 3xl:text-6xl !leading-[1.2]">
                Livestream Commerce
              </h1>
              <p className="text-[#CFCFCF] font-medium text-sm lg:text-xl 3xl:text-2xl">
                Beladed offers a unique blend of social media, gaming, and
                e-commerce. The platform enables users to participate in
                livestreams and interact with streamers through various features
                like token-based transactions.
              </p>
            </div>
            <div
              className="flex flex-col gap-5"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-delay="1500">
              <span className="text-gr font-bold">SECONDARY: </span>
              <h1 className="font-bold text-2xl lg:text-5xl 3xl:text-6xl !leading-[1.2]">
                Art Trading Platform
              </h1>
              <p className="text-[#CFCFCF] font-medium text-sm lg:text-xl 3xl:text-2xl">
                Beladed offers NFT’s from advertisers via rewards and streamers
                via private channels and streamer generated art. Users can
                purchase NFT art and rewards at a limited edition offering.
              </p>
            </div>
          </div>
          <div
            className="flex-[0_0_45%] w-full grid grid-cols-5 grid-rows-[repeat(9, 1fr)] gap-y-4 lg:gap-y-8 gap-x-4 py-6 lg:py-12 px-6 rounded-xl"
            style={{
              background:
                "linear-gradient(99.09deg, #6633cc2a 10.36%, #FF44B82a 98.61%)",
            }}>
            <div
              style={{ gridArea: " 1 / 1 / 5 / 5" }}
              data-aos="flip-right"
              data-aos-duration="1500"
              data-aos-delay="1000">
              <img src={img1a} alt="Target Verticals" className="rounded-lg" />
            </div>
            <div
              style={{ gridArea: "5 / 3 / 10 / 6" }}
              className="flex justify-end"
              data-aos="flip-left"
              data-aos-duration="1500"
              data-aos-delay="1500">
              <img src={img1b} alt="Target Verticals" className="rounded-lg" />
            </div>
            <div
              style={{ gridArea: "6 / 1 / 9 / 3" }}
              className="flex justify-center"
              data-aos="flip-right"
              data-aos-duration="1500"
              data-aos-delay="2000">
              <img
                src={img1c}
                alt="Target Verticals"
                className="rounded-lg w-[200px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TargetEmergingMarkets;
