import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Box, LinearProgress } from "@mui/material";
import SimpleInputForm from "app/components/Inputs/SimpleInputForm";
import SimpleSelectForm from "app/components/Inputs/SimpleSelectForm";
import LeavingModalUser from "app/components/Modals/LeavingModalUser";
import logoD from "assets/logo-d.svg";
import logo from "assets/logo.svg";
import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { Link, useLocation, useNavigate } from "react-router-dom";

const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  games: "",
  shows: "",
  how_referred: "",
  who_referred: "",
  username: "",
};

export const Join = () => {
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    games: "",
    shows: "",
    how_referred: "",
    who_referred: "",
    username: "",
  });
  const handleValuesChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const [isPlaying, setIsPlaying] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [leavingModal, setLeavingModal] = useState(false);

  const { pathname } = useLocation();

  const pushHistory = () => {
    window.history.pushState(null, document.title, window.location.href);
    setLeavingModal(true);

    window.location.hash = "1";

    window.location.hash = "1";
  };

  const popstate = () => {
    window.history.pushState(null, null, document.URL);
    setLeavingModal(true);
  };
  const navigate = useNavigate();

  const allow = localStorage.getItem("allowGoBack");

  useEffect(() => {
    if (allow === "0") {
      window.history.pushState(null, null, document.URL);
      window.addEventListener("popstate", popstate);
    } else {
      window.removeEventListener("popstate", popstate);
    }
  }, []);
  const handleBeforeUnload = (e) => {
    e.preventDefault();
    setLeavingModal(true);
    e.returnValue = "";
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleStay = () => {
    window.removeEventListener("popstate", popstate);
    setLeavingModal(false);
  };

  const handleLeave = () => {
    setLeavingModal(false);
    window.removeEventListener("beforeunload", handleBeforeUnload);
    navigate("/waitlist");
  };
  const [step, setStep] = useState(1);
  const handleSteps = () => {
    switch (step) {
      case 1:
        return (
          <SignUpStep1 values={values} handleValuesChange={handleValuesChange} setStep={setStep} />
        );
      case 2:
        return (
          <SignUpStep2 values={values} handleValuesChange={handleValuesChange} setStep={setStep} />
        );
      case 3:
        return (
          <SignUpStep3 values={values} handleValuesChange={handleValuesChange} setStep={setStep} />
        );
      case 4:
        return (
          <SignUpStep4
            values={values}
            handleValuesChange={handleValuesChange}
            setStep={setStep}
            handleContinue={handleContinue}
          />
        );
      default:
        break;
    }
  };
  const handleText = () => {
    switch (step) {
      case 1:
        return "Off to a great start - keep going!😍";
      case 2:
        return "Congratulations! 🥳 Keep up the momentum.";
      case 3:
        return "Awesome, one step closer.🏄🏼‍♀️";
      case 4:
        return "Success!💥 You finished the task.";
      default:
        break;
    }
  };
  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(0);
  useEffect(() => {
    switch (step) {
      case 1:
        setProgress(0);
        setBuffer(33);
        break;
      case 2:
        setProgress(33);
        setBuffer(66);
        break;
      case 3:
        setProgress(66);
        setBuffer(100);
        break;
      case 4:
        setProgress(100);
        setBuffer(100);
        break;
      default:
        break;
    }
  }, [step]);
  const handleContinue = () => {
    navigate("/waitlist/win-25k");
  };
  return (
    <main className=" container-fluid">
      <LeavingModalUser state={leavingModal} toggle={handleStay} leave={handleLeave} />

      <div className="row min-h-screen text-neutral-1">
        <div
          className="col-12 col-lg-6 col-2xl-6 flex-c gr-dark z-10 hidden flex-col px-4 py-12 
        lg:flex lg:min-h-screen lg:items-start lg:py-8 lg:px-8 xl:py-10"
        >
          <div className="flex-c  mb-4 w-full lg:mb-6">
            <Link to="/waitlist/dashboard">
              <img src={logo} alt="logo" className=" w-44 md:w-auto" />
            </Link>
          </div>
          <div className="z-10 h-full w-full rounded-lg lg:flex">
            <div
              className="video-background-container flex-c relative z-10 aspect-[43/75] max-h-[calc(100vh)]  w-full overflow-hidden xl:h-[calc(100vh-150px)]"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <div className="abs-c h-gradient aspect-[43/75]   h-full  rounded "></div>
              <div className="video-background z-10">
                <ReactPlayer
                  url="https://vimeo.com/846048602"
                  playing={isPlaying}
                  playsinline
                  muted={false}
                  width="99%"
                  height="99%"
                  controls={false}
                  style={{
                    zIndex: "1",
                    borderRadius: "4px",
                    overflow: "hidden",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translateY(-50%) translateX(-50%)",
                  }}
                  autoPlay={false}
                />
              </div>

              <button onClick={() => setIsPlaying(!isPlaying)} className="abs-c z-50">
                {isPlaying ? (
                  <div
                    className={`flex-c h-16 w-16 rounded-full bg-white transition-all duration-500  ${
                      isHovered ? "opacity-100" : "opacity-0"
                    } `}
                  >
                    <PauseIcon sx={{ color: "red", fontSize: "2rem" }} />
                  </div>
                ) : (
                  <div className="circle flex-c relative h-16 w-16 rounded-full bg-white !text-red-600">
                    <PlayArrowIcon sx={{ color: "red !important", fontSize: "2rem" }} />
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 col-2xl-6 flex h-full items-center bg-white px-4 py-8 lg:min-h-screen lg:py-10 lg:px-12 xl:px-20 2xl:py-10 ">
          <div className="row mx-auto max-w-lg gap-y-6 lg:mx-0 ">
            <div className="flex-c mb-4 lg:mb-6 lg:hidden ">
              <img src={logoD} alt="logo" className="w-64" />
            </div>
            <div className="flex flex-col gap-3">
              <div className="relative mb-4 flex w-full flex-col items-center gap-8 px-4">
                <Box sx={{ width: "100%" }} className="absolute">
                  <LinearProgress
                    value={progress}
                    variant="determinate"
                    className="!w-100 !bg-[#F7F6F9]"
                    color="progress"
                    sx={{ height: "23px", borderRadius: "22px" }}
                  />
                </Box>
                <Box sx={{ width: "100%" }} className="absolute !w-full">
                  <LinearProgress
                    value={buffer}
                    variant="determinate"
                    className="!w-100 !bg-[#F7F6F9]"
                    color="progress"
                    sx={{ height: "23px", borderRadius: "22px", opacity: 0.3 }}
                  />
                </Box>
              </div>
              <div>
                <p className="text-lg font-medium text-neutral-4">{handleText()}</p>
              </div>
            </div>
            {handleSteps()}
          </div>
        </div>
      </div>
    </main>
  );
};

export default Join;
const referOptions = [
  "Instagram",
  "Tiktok",
  "Twitter",
  "SnapChat",
  "OnlyFans",
  "Email",
  "Text Message",
  "Other Website",
];

const showsOptions = [
  "Party Games",
  "Fitness Workouts",
  "Outdoor Sports",
  "Cooking Shows",
  "Dress Up Shows",
  "Art Shows",
  "Music Shows",
  "Dancing Shows",
  "Talent Shows",
  "Fashion Shows",
  "Q&A Session",
  "Comedy shows",
];

const SignUpStep1 = ({ handleValuesChange, setStep, values }) => {
  return (
    <div className="flex w-full flex-col gap-8">
      <div className="flex flex-col gap-2">
        <h1 className="text-3xl font-bold text-neutral-1">Join The Waiting List</h1>
        <p className="text-lg font-medium">Priority access, exclusive opportunity.</p>
      </div>
      <form onSubmit={() => setStep(2)} className="flex flex-col gap-8">
        <div className="flex w-full flex-col gap-4 sm:gap-4">
          <h2 className="text-2xl font-semibold"> Personal Information</h2>
          <div className="col-12 col-lg-6 w-full">
            <SimpleInputForm
              value={values.first_name}
              name="first_name"
              label="First Name"
              placeholder={"Enter your first name"}
              onChange={handleValuesChange}
            />
          </div>
          <div className="col-12 col-lg-6 w-full">
            <SimpleInputForm
              value={values.last_name}
              name="last_name"
              label="Last Name"
              placeholder={"Enter your last name"}
              onChange={handleValuesChange}
            />
          </div>
          <div className="col-12 col-lg-6 w-full">
            <SimpleInputForm
              value={values.email}
              name="email"
              label="Email"
              placeholder={"Enter your email address"}
              type="email"
              onChange={handleValuesChange}
            />
          </div>{" "}
          <div className="col-12 col-lg-6 w-full">
            <SimpleInputForm
              value={values.phone}
              name="phone"
              label="Phone Number"
              placeholder={"Enter your phone number"}
              type="tel"
              hasPattern={true}
              pattern="[0-9]*"
              onChange={handleValuesChange}
            />
            <span className="text-2xs">Phone number is used to verify your device.</span>
          </div>
        </div>
        <div>
          <p className="text-sm leading-6 text-neutral-4 underline-offset-4">
            By submitting this form you agree to the{" "}
            <Link to="/about/terms" className=" text-neutral-1 hover:text-link">
              Terms and Conditions
            </Link>
            ,{" "}
            <Link to="/about/privacy" className=" text-neutral-1 hover:text-link">
              Privacy
            </Link>{" "}
            &{" "}
            <Link to="/about/cookies" className=" text-neutral-1 hover:text-link">
              Cookies Policy{" "}
            </Link>
            and certify that you are 18 years of age or older.
          </p>
        </div>
        <div className="mt-2 flex flex-col flex-wrap items-start justify-center gap-4 lg:justify-start">
          <button
            type="submit"
            // onClick={() => setStep(2)}
            className="btn btn-main btn-large text-base"
            to={"congrats"}
          >
            Next
          </button>
          <Link
            className="text-base font-semibold text-secondary-5 "
            to={"/waitlist/already-joined"}
          >
            Already Joined?
          </Link>
        </div>
      </form>
    </div>
  );
};

const SignUpStep2 = ({ handleValuesChange, setStep, values }) => {
  return (
    <form onSubmit={() => setStep(3)} className="flex w-full flex-col gap-8">
      <div className="flex w-full flex-col gap-4 sm:gap-4">
        <h2 className="text-2xl font-semibold"> Referral Information</h2>
        <div className="col-12 col-lg-6 w-full">
          <SimpleSelectForm
            value={values.how_referred}
            name="how_referred"
            label="How Were You Referred To Us?"
            placeholder={"Select an option"}
            options={referOptions}
            onChange={handleValuesChange}
          />
        </div>
        <div className="col-12 col-lg-6 w-full">
          <SimpleInputForm
            value={values.who_referred}
            name="who_referred"
            label="Who Referred You To Us?"
            required={false}
            placeholder={"Enter a username"}
            onChange={handleValuesChange}
          />
        </div>
      </div>
      <div>
        <p className="text-sm leading-6 text-neutral-4 underline-offset-4">
          By submitting this form you agree to the{" "}
          <Link to="/about/terms" className=" text-neutral-1 hover:text-link">
            Terms and Conditions
          </Link>
          ,{" "}
          <Link to="/about/privacy" className=" text-neutral-1 hover:text-link">
            Privacy
          </Link>{" "}
          &{" "}
          <Link to="/about/cookies" className=" text-neutral-1 hover:text-link">
            Cookies Policy{" "}
          </Link>{" "}
          and certify that you are 18 years of age or older.
        </p>
      </div>
      <div className="mt-2 flex flex-col flex-wrap items-start justify-center gap-4 lg:justify-start">
        <div className="flex flex-wrap gap-4 ">
          <button onClick={() => setStep(1)} className="btn btn-large btn-second-grey text-base ">
            Previous
          </button>
          <button
            // onClick={() => setStep(3)}
            type="submit"
            className="btn btn-main btn-large text-base"
          >
            Next
          </button>
        </div>
        <Link className="text-base font-semibold text-secondary-5 " to={"/waitlist/already-joined"}>
          Already Joined?
        </Link>
      </div>
    </form>
  );
};

const SignUpStep3 = ({ handleValuesChange, setStep, values }) => {
  const handleSubmit = () => {
    setStep(4);
    console.log("JoinUserValues", values);
  };

  return (
    <form onSubmit={handleSubmit} className="flex w-full flex-col gap-8">
      <div className="flex w-full flex-col gap-4 sm:gap-4">
        <h2 className="text-2xl font-semibold"> Create a username</h2>
        <div className="col-12 col-lg-6 w-full">
          <SimpleInputForm
            value={values.username}
            name="username"
            label="Username"
            placeholder={"Enter a username"}
            onChange={handleValuesChange}
            hasIcon={true}
            icon={<AlternateEmailIcon sx={{ fontSize: "16px" }} />}
          />
        </div>
      </div>
      <div className="flex w-full flex-col gap-4 sm:gap-4">
        <h2 className="text-2xl font-semibold"> Customize your entertainment pairings.</h2>
        <div className="col-12 col-lg-6 w-full">
          <SimpleSelectForm
            value={values.games}
            name="games"
            label="Games"
            placeholder={"Select a game"}
            options={showsOptions}
            onChange={handleValuesChange}
          />
        </div>
        <div className="col-12 col-lg-6 w-full">
          <SimpleSelectForm
            value={values.shows}
            name="shows"
            label="shows"
            placeholder={"Select a show"}
            options={showsOptions}
            onChange={handleValuesChange}
          />
        </div>
      </div>
      <div>
        <p className="text-sm leading-6 text-neutral-4 underline-offset-4">
          By submitting this form you agree to the{" "}
          <Link to="/about/terms" className=" text-neutral-1 hover:text-link">
            Terms and Conditions
          </Link>
          ,{" "}
          <Link to="/about/privacy" className=" text-neutral-1 hover:text-link">
            Privacy
          </Link>{" "}
          &{" "}
          <Link to="/about/cookies" className=" text-neutral-1 hover:text-link">
            Cookies Policy{" "}
          </Link>{" "}
          and certify that you are 18 years of age or older.
        </p>
      </div>
      <div className="mt-2 flex flex-col flex-wrap items-start justify-center gap-4 lg:justify-start">
        <div className="flex flex-wrap gap-4 ">
          <button
            onClick={() => setStep(2)}
            className="btn btn-large btn-second-grey text-base "
            to={"congrats"}
          >
            Previous
          </button>
          <button
            // onClick={() => setStep(4)}
            type="submit"
            className="btn btn-main btn-large text-base"
            to={"congrats"}
          >
            Join Wait List
          </button>
        </div>
        <Link className="text-base font-semibold text-secondary-5 " to={"/waitlist/already-joined"}>
          Already Joined?
        </Link>
      </div>
    </form>
  );
};

const SignUpStep4 = ({ handleValuesChange, setStep, handleContinue }) => {
  return (
    <div className="flex w-full flex-col gap-10">
      <div className="mt-2 flex flex-col flex-wrap items-start justify-center gap-8 lg:justify-start">
        <div className="flex flex-col gap-2">
          <h2 className="text-2xl font-semibold">Congratulations! 🥳</h2>
          <p>Something amazing is coming your way!</p>
        </div>

        <div className="flex flex-col gap-4">
          <button onClick={() => handleContinue()} className="btn btn-main btn-large text-base">
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};
