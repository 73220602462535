export const data = [
  {
    title: 'INTRODUCTION',
    content: (
      <div className="flex flex-col gap-4">
        <p>
          <span>
            Beladed (website url address:{' '}
            <a href="https://Beladed.com/" target="_blank" rel="noopener noreferrer">
              https://Beladed.com/
            </a>
            ) appreciates your business and trust
          </span>
          <span>
            . We are US based company, providing business services to elevate and support your
            business. Please read this Privacy Policy, providing consent to both documents in order
            to have permission to use our services.
          </span>
        </p>
      </div>
    ),
  },
  {
    title: 'CALIFORNIA RESIDENCE',
    content: (
      <div className="flex flex-col gap-4">
        <p>
          Beladed Inc (the “Company”) collects various types of personal information about you, both
          online and offline, during the course of our relationship. Under California law, if you
          are a resident of California, you may make a written request to the Company about how we
          have shared your personal information during the prior calendar year with third parties,
          and our affiliates, for their direct marketing purposes. In response to your written
          request, the Company is allowed to, instead, provide you with a cost-free means to opt-out
          of such sharing, or a “Customer Choice Notice”. For purposes of this California Privacy
          Notice, third parties may include companies within our “family” of companies, such as
          those companies affiliated with or owned directly or indirectly by Beladed Inc.
        </p>
        <p>
          The Company has chosen to provide you with a cost-free means to opt out of such sharing.
          If you would like to exercise your rights under California law, please send your written
          request to the postal address below. Please allow 30 days for the processing of your
          request.
        </p>
        <p>&nbsp;</p>
        <p>Beladed INC Attn: CALIFORNIA PRIVACY POLICY DEPARTMENT</p>

        <p>945 MCKINNEY ST, Suite 574 – HOUSTON, TX 77002</p>

        <p>Or email info@Beladed.com</p>

        <p>
          If you would like to know what information is gathered about you online when you visit the
          Site, and how it is used, you may review the Company{' '}
          <a href="https://Beladed.com/privacy-policy/">Privacy Policy</a> by clicking{' '}
          <a href="/privacy-policy">here</a>.
        </p>
      </div>
    ),
  },
  {
    title: 'EMBEDDED CONTENT',
    content: (
      <div className="flex flex-col gap-4">
        <p>
          <span>
            Pages on this site may include embedded content, like YouTube videos, for example.
            Embedded content from other websites behaves in the exact same way as if you visited the
            other website.
          </span>
        </p>
        <p>
          <span>
            These websites may collect data about you, use cookies, embed additional third-party
            tracking, and monitor your interaction with that embedded content, including tracking
            your interaction with the embedded content if you have an account and are logged-in to
            that website. Below you can find a list of the services we use:
          </span>
        </p>
        <h4>
          <span>FACEBOOK</span>
        </h4>
        <p>
          <span>
            The Facebook page plugin is used to display our Facebook timeline on our site. Facebook
            has its own cookie and privacy policies over which we have no control. There is no
            installation of cookies from Facebook and your IP is not sent to a Facebook server until
            you consent to it. See their privacy policy here:{' '}
          </span>
          <a href="https://www.facebook.com/privacy/" target="_blank" rel="noopener noreferrer">
            <span>Facebook Privacy Policy </span>
          </a>
          <span>.</span>
        </p>
        <h4>
          <span>TWITTER</span>
        </h4>
        <p>
          <span>
            We use the Twitter API to display our tweets timeline on our site. Twitter has its own
            cookie and privacy policies over which we have no control. Your IP is not sent to a
            Twitter server until you consent to it. See their privacy policy here:{' '}
          </span>
          <a href="https://twitter.com/privacy" target="_blank" rel="noopener noreferrer">
            <span>Twitter Privacy Policy </span>
          </a>
          <span>.</span>
        </p>
        <h4>
          <span>YOUTUBE</span>
        </h4>
        <p>
          <span>
            We use YouTube videos embedded on our site. YouTube has its own cookie and privacy
            policies over which we have no control. There is no installation of cookies from YouTube
            and your IP is not sent to a YouTube server until you consent to it. See their privacy
            policy here:{' '}
          </span>
          <a
            href="https://www.youtube.com/static?template=privacy_guidelines"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>YouTube Privacy Policy</span>
          </a>
          <span>.</span>
        </p>
      </div>
    ),
  },
  {
    title: 'COOKIES',
    content: (
      <div className="flex flex-col gap-4 [&_li]:ml-6 [&_li]:list-disc">
        <p>
          <span>
            This site uses cookies – small text files that are placed on your machine to help the
            site provide a better user experience. In general, cookies are used to retain user
            preferences, store information for things like shopping carts, and provide anonymized
            tracking data to third party applications like Google Analytics. Cookies generally exist
            to make your browsing experience better. However, you may prefer to disable cookies on
            this site and on others. The most effective way to do this is to disable cookies in your
            browser. We suggest consulting the help section of your browser.
          </span>
        </p>
        <h4>
          <span>NECESSARY COOKIES (ALL SITE VISITORS)</span>
        </h4>
        <ul>
          <li>
            <b>cfduid:</b>
            <span>
              {' '}
              Is used for our CDN CloudFlare to identify individual clients behind a shared IP
              address and apply security settings on a per-client basis. See more information on
              privacy here:{' '}
            </span>
            <a
              href="https://www.cloudflare.com/privacypolicy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>CloudFlare Privacy Policy</span>
            </a>
            <span>.</span>
          </li>
          <li>
            <b>PHPSESSID:</b>
            <span> To identify your unique session on the website.</span>
          </li>
        </ul>
        <h4>
          <span>NECESSARY COOKIES (ADDITIONAL FOR LOGGED IN CUSTOMERS)</span>
        </h4>
        <ul>
          <li>
            <b>wp-auth:</b>
            <span>
              {' '}
              Used by WordPress to authenticate logged-in visitors, password authentication and user
              verification.
            </span>
          </li>
          <li>
            <b>wordpress_logged_in_:</b>
            <span>
              {' '}
              Used by WordPress to authenticate logged-in visitors, password authentication and user
              verification.
            </span>
          </li>
          <li>
            <b>wordpress_test_cookie</b>
            <span> Used by WordPress to ensure cookies are working correctly.</span>
          </li>
          <li>
            <b>wp-settings-[UID]:</b>
            <span>
              {' '}
              WordPress sets a few wp-settings-[UID] cookies. The number on the end is your
              individual user ID from the users database table. This is used to customize your view
              of admin interface, and possibly also the main site interface.
            </span>
          </li>
          <li>
            <b>wp-settings-[UID]:</b>
            <span>
              WordPress also sets a few wp-settings--[UID] cookies. The number on the end is your
              individual user ID from the users database table. This is used to customize your view
              of admin interface, and possibly also the main site interface.
            </span>
          </li>
        </ul>
      </div>
    ),
  },
  {
    title: 'WHO HAS ACCESS TO YOUR DATA',
    content: (
      <div className="flex flex-col gap-4">
        <p>
          <span>
            If you are not a registered client for our site, there is no personal information we can
            retain or view regarding yourself.
          </span>
        </p>
        <p>
          <span>
            If you are a client with a registered account, your personal information can be accessed
            by:
          </span>
        </p>
        <ul>
          <li>
            <span>Our system administrators.</span>
          </li>
          <li>
            <span>
              Our supporters when they (in order to provide support) need to get the information
              about the client accounts and access.
            </span>
          </li>
        </ul>
      </div>
    ),
  },
  {
    title: 'THIRD PARTY ACCESS TO YOUR DATA',
    content: (
      <div className="flex flex-col gap-4">
        <p>
          <span>
            We don’t share your data with third-parties in a way as to reveal any of your personal
            information like email, name, etc. The only exceptions to that rule are for partners we
            have to share limited data with in order to provide the services you expect from us.
            Please see below:
          </span>
        </p>
        <h4>
          <span>ICARUS FUNDING LLC</span>
        </h4>
        <p>
          <span>
            For the purpose of processing and filing your information which include information you
            send to us via our web forms, email, phone or other communication mediums, we send your
            provided information to Icarus Funding LLC and use the response from their systems to
            update your on your application status. Contact the Icarus Funding LLC for me
            information here:{' '}
          </span>
          <a href="https://icarus-fund.com" target="_blank" rel="noopener noreferrer">
            <span>Icarus Funding LLC</span>
          </a>
          <span>.</span>
        </p>
      </div>
    ),
  },
  {
    title: 'HOW LONG WE RETAIN YOUR DATA',
    content: (
      <div className="flex flex-col gap-4">
        <p>
          <span>
            When you submit a support ticket or a comment, its metadata is retained until (if) you
            tell us to remove it. We use this data so that we can recognize you and approve your
            comments automatically instead of holding them for moderation.
          </span>
        </p>
        <h4>
          <span>ICARUS FUNDING LLC</span>
        </h4>
        <p>
          <span>
            If you register on our website, we also store the personal information you provide in
            your user profile. You can see, edit, or delete your personal information at any time
            (except changing your username). Website administrators can also see and edit that
            information.
          </span>
        </p>
      </div>
    ),
  },
  {
    title: 'SECURITY MEASURES',
    content: (
      <div className="flex flex-col gap-4">
        <p>
          <span>
            We use the SSL/HTTPS protocol throughout our site. This encrypts our user communications
            with the servers so that personal identifiable information is not captured/hijacked by
            third parties without authorization.
          </span>
        </p>
        <h4>
          <span>ICARUS FUNDING LLC</span>
        </h4>
        <p>
          <span>
            In case of a data breach, system administrators will immediately take all needed steps
            to ensure system integrity, will contact affected users and will attempt to reset
            passwords if needed.
          </span>
        </p>
      </div>
    ),
  },
  {
    title: 'YOUR DATA RIGHTS',
    content: (
      <div className="flex flex-col gap-4">
        <h4>GENERAL RIGHTS</h4>
        <p>
          <span>
            If you have a registered account on this website or have left comments, you can request
            an exported file of the personal data we retain, including any additional data you have
            provided to us.
          </span>
        </p>
        <p>
          <span>
            You can also request that we erase any of the personal data we have stored. This does
            not include any data we are obliged to keep for administrative, legal, or security
            purposes. In short, we cannot erase data that is vital to you being an active customer
            (i.e. basic account information like an email address).
          </span>
        </p>
        <p>
          <span>
            If you wish that all of your data is erased, we will no longer be able to offer any
            support or other product-related services to you.
          </span>
        </p>
        <h4>GDPR RIGHTS</h4>
        <p>
          <span>
            Your privacy is critically important to us. Going forward with the GDPR we aim to
            support the GDPR standard. Gredger permits residents of the European Union to use its
            Service. Therefore, it is the intent of Gredger to comply with the European General Data
            Protection Regulation. For more details please see here:{' '}
            <a href="https://www.eugdpr.org/" target="_blank" rel="noopener noreferrer">
              EU GDPR Information Portal.
            </a>
          </span>
        </p>
      </div>
    ),
  },
  {
    title: 'THIRD PARTY WEBSITES',
    content: (
      <div className="flex flex-col gap-4">
        <p>
          <span>
            Gredger may post links to third party websites on this website. These third party
            websites are not screened for privacy or security compliance by Gredger, and you release
            us from any liability for the conduct of these third party websites.
          </span>
        </p>
        <p>
          <span>
            All social media sharing links, either displayed as text links or social media icons do
            not connect you to any of the associated third parties, unless you explicitly click on
            them.
          </span>
        </p>
        <p>
          <span>
            Please be aware that this Privacy Policy, and any other policies in place, in addition
            to any amendments, does not create rights enforceable by third parties or require
            disclosure of any personal information relating to members of the Service or Site.
            Gredger bears no responsibility for the information collected or used by any advertiser
            or third party website. Please review the privacy policy and terms of service for each
            site you visit through third party links.
          </span>
        </p>
      </div>
    ),
  },
  {
    title: 'RELEASE OF YOUR DATA FOR LEGAL PURPOSES',
    content: (
      <div className="flex flex-col gap-4">
        <p>
          <span>
            At times it may become necessary or desirable to Gredger, for legal purposes, to release
            your information in response to a request from a government agency or a private
            litigant. You agree that we may disclose your information to a third party where we
            believe, in good faith, that it is desirable to do so for the purposes of a civil
            action, criminal investigation, or other legal matter. In the event that we receive a
            subpoena affecting your privacy, we may elect to notify you to give you an opportunity
            to file a motion to quash the subpoena, or we may attempt to quash it ourselves, but we
            are not obligated to do either. We may also proactively report you, and release your
            information to, third parties where we believe that it is prudent to do so for legal
            reasons, such as our belief that you have engaged in fraudulent activities. You release
            us from any damages that may arise from or relate to the release of your information to
            a request from law enforcement agencies or private litigants.
          </span>
        </p>
        <p>
          <span>
            Any passing on of personal data for legal purposes will only be done in compliance with
            laws of the country you reside in.
          </span>
        </p>
      </div>
    ),
  },
  {
    title: 'AMENDMENTS',
    content: (
      <div className="flex flex-col gap-4">
        <p>
          <span>
            We may amend this Privacy Policy from time to time. When we amend this Privacy Policy,
            we will update this page accordingly and require you to accept the amendments in order
            to be permitted to continue using our services.
          </span>
        </p>
      </div>
    ),
  },
];

export const terms = [
  {
    title: 'Adults only',
    content: (
      <div>
        <p>
          We have placed this detail over and above all of our other terms because it is the most
          important (although it is also referenced below). The beladed.com platform is only open to
          consenting adults looking to express themselves in the form of streaming video and
          messaging. We have zero tolerance for any minors on the beladed.com platform, whether such
          individuals are actually minors or pretending to be minors perhaps in a misguided attempt
          at role-play. Moreover, we have zero tolerance for any image of any minor being uploaded
          to our platform for any reason. Please be advised that we reserve the right to and do
          report each and every suspected minor to the National Center for Missing and Exploited
          Children and any and all other law enforcement agencies we feel appropriate. If you
          believe that a minor is using the site, we strongly request and encourage you to report
          each and every user you encounter which you believe or suspect to be under the age of 18.
          All reports are reviewed as quickly as we can. If you are a minor, you must immediately
          leave this site now. You are not legally permitted on beladed for any reason, and if we
          find you on this site, we will report you to law enforcement as noted above. We will not
          reactivate you for any reason ever.
        </p>
        <p>
          With respect to all other matters, as we operate an online platform and we are not in the
          business of producing or presenting cam broadcasts, we only adopt what we feel are
          necessary to comply with law, community standards and for the safety of our users.
        </p>
      </div>
    ),
  },
  {
    title: 'The Platform',
    content: (
      <div>
        <p>
          The Platform (as defined below) is an online platform providing social networking
          capabilities including both a text and video chat service whereby individuals accessing
          the Platform (each a "Community Member") may create and share with other Community Members
          online audio, video, interactive, and live content which may include, at the providing
          Community Member’s election, content of an adult nature. Community Members, whether or not
          their account is capable of receiving tokens, are free to broadcast their live streaming
          video through the Platform, subject to the minimal restrictions set forth in these Terms,
          which restrictions have been put in place to comply with applicable law, community
          standards, and for the safety of the Community Members. Subject to safety and legality
          required rules, Community Members may produce and broadcast (or not broadcast) as and when
          they determine and set their own rules for other Community Members viewing their
          broadcasts. Community Members are also free to use other video streaming platforms either
          at different times or at the same time as such Community Members use our Platform, subject
          to the provision below that Community Members may not, through the Platform, promote or
          advertise any entity, product, service, or website that delivers live-streaming content.
        </p>
      </div>
    ),
  },
  {
    title: 'INTRODUCTION AND DESCRIPTION OF SERVICE',
    content: (
      <div>
        <p>
          When you access the Platform, you understand that you may see graphic depictions of
          nudity, exposed genitals and persons engaged in explicit sexual activity consisting of
          people of different genders, people of the same gender, as well as sexual acts involving
          more than 2 individuals. By accessing and/or using the Platform, you expressly acknowledge
          that you wish to see such materials. By accessing the interactive video chat social
          networking platform located at beladed.com (collectively the "Platform") these terms and
          conditions (these "Terms") form the binding agreement between you ("you") and Multi Media,
          LLC (the "Company") regarding your use of the Platform. If you do not agree to be bound by
          these Terms, you must immediately exit the Platform and not access the Platform again for
          any reason. There is no fee to access the Platform and view content to access some
          functions of the Platform, but Community Members will be required to create an Account
          (defined below). Community Members who are eligible to receive tokens in their account,
          from other Community Members are referred to in these Terms as an "Independent
          Broadcaster(s)". In these Terms, the use of phrases such as "we" "our" "us" or other
          appropriate first-person terms refer to the Company. The use of the terms "you" or "your"
          refers, as the context may require, to you the Community Member or Independent Broadcaster
          accessing or utilizing the Platform. By accessing and/or using the Platform, you agree to
          be bound by the Terms.
        </p>
      </div>
    ),
  },
  {
    title: 'NO ACCESS BY MINORS AND NO EXPLOITATION OF CHILDREN',
    content: (
      <div>
        <p>
          This Platform is for Adults Only. In order to access and use the Platform, you must be at
          least 18 years old or the age of majority in your jurisdiction, whichever is older (the
          "Age of Majority"). IF YOU ARE NOT OF THE AGE OF MAJORITY, YOU MUST IMMEDIATELY LEAVE THE
          PLATFORM. By accessing and/or using the Platform you agree that you have reached the Age
          of Majority and acknowledge that we have the sole and absolute right to terminate your
          Account if we believe you are in violation of this requirement. If we believe you are in
          violation of this Age of Majority requirement, we will report you to law enforcement.
        </p>
        <p>
          Law Enforcement Reporting. We reserve the right to and do report suspected violations of
          the Platform's minimum age requirement to the National Center for Missing and Exploited
          Children ("NCMEC") and any and all law enforcement and other organizations we may feel
          appropriate, in our sole discretion. If you believe that a minor has accessed the
          Platform, you should immediately report it to us at legal@beladed.com. For more
          information regarding this policy and our zero-tolerance stance on minors using or in any
          way appearing on the Platform, please contact us at legal@beladed.com.
        </p>
        <p>
          No Child Pornography. For the purpose of these Terms, "Child Pornography" includes, but is
          not limited to (i) any materials showing a person under the Age of Majority in a state of
          undress, or engaged in any suggestive or sexual acts of any kind; (ii) any materials that
          simulate the foregoing, such as dolls, animated films or shorts, adults made to look like
          children or suggest that they are below the Age of Majority; and, (iii) discussion of
          child pornography or child exploitation. Child Pornography and the exploitation of
          children is a serious crime. All Community Members are strictly prohibited from using the
          Platform to distribute, access, or solicit Child Pornography or engage in any conduct or
          discussion exploitative of a person below the Age of Majority, including by way of role
          play. If you see anything on the Platform that violates this provision or seems
          questionable, report it immediately to us at legal@beladed.com. We will immediately
          investigate any claim and take the appropriate action. IF YOU ARE SEEKING OR HAVE AN
          INTEREST IN ANY TYPE OF PEDOPHILIC OR PEDERASTIC CONTENT, YOU MUST IMMEDIATELY LEAVE THIS
          SITE. YOU WILL BE BANNED FOR EVEN DISCUSSING SUCH ACTIVITY OR ENGAGING IN INAPPROPRIATE
          ROLE PLAY. FURTHER YOU WILL ALSO BE BANNED FROM THE PLATFORM AND REPORTED TO NCMEC AND/OR
          LAW ENFORCEMENT FOR DISCUSSING OR ARRANGING TO DISCUSS MINORS ON ANOTHER PLATFORM. SIMPLY
          PUT, PEOPLE WITH INTERESTS IN CHILDREN, INCLUDING EVEN JUST ROLE PLAY, ARE NOT WELCOME TO
          USE THE PLATFORM AND WILL BE DEALT WITH IN THE HARSHEST MANNER POSSIBLE.
        </p>
        <p>
          Section 230 of the Communications Decency Act. The Platform is a Community Member-driven
          interactive platform with content provided by Independent Broadcasters, and pursuant to
          Section 230 of the Communications Decency Act, we are immune from suit for materials
          published through the Platform by Community Members. As such, we are not liable for
          content published by Community Members. As we operate an online platform and we are not in
          the business of producing or presenting cam broadcasts, we have endeavored to keep our
          rules to a minimum imposing only those we feel are necessary for the safety of Community
          Members, to comply with applicable law, and the continued operation of the Platform.
        </p>
      </div>
    ),
  },
  {
    title: 'YOUR ACCOUNT AND GENERAL TERMS APPLICABLE TO ALL USERS',
    content: (
      <div>
        <h4>Member Account and Password</h4>
        <p>
          In order to access the non-public portions of the Platform, you must create an account
          (your "Account"). In creating an Account, you will be prompted to create a username and
          password and, if you wish to become an Independent Broadcaster, you will be required to
          enter certain personally identifiable information. In order to take advantage of some of
          the pay features available through the Platform, you will be prompted to input a payment
          method. As noted in our Privacy Policy, this information is received and held by a
          third-party payment processor who, subject to their terms, will store your payment
          information for future use through the Platform. You may not choose a username that may
          falsely represent you as someone else or a name that may otherwise be in violation of the
          rights of any other individual or entity. We reserve the right to disallow the use of
          usernames or cancel, at any time, the membership of any Community Member who uses their
          selected username in violation of these Terms or in any other way we deem inappropriate in
          our sole discretion. You are solely responsible for any and all activities conducted and
          purchases made through your Account. It is important to note that, while we do not require
          that you provide an email address to create an Account, choosing not to provide an email
          address limits the support we can provide in connection with your Account as we are unable
          to verify you are the Account holder.
        </p>
        <h4>Shared Accounts</h4>
        <p>
          With respect to Accounts which have more than one age-verified user, the person in control
          of the Account is deemed to be the person whose email address is associated with the
          Account, unless there is payee information associated with the Account, in which case the
          payee is deemed to be the person in control of the Account. Material changes to Accounts
          may only be made at the request of the person deemed to be in control of the account.
          Material changes include changes to the email address associated with the Account, changes
          to payee information, and changes to two-factor authentication settings. You understand
          and agree that the person deemed to be in control of any such Account will have full
          access to and control over all information associated with the Account, including all
          information collected on such Account. For more information about information we collect,
          please see our Privacy Policy.
        </p>
        <h4>Studio Accounts</h4>
        <p>
          Individuals who wish to create a studio account (a "Studio Account") through the Platform
          must comply with our rules for setting up a Studio Account. If these rules are complied
          with, such Account will be considered a "Studio" on the Platform. For all Accounts under a
          Studio, the Studio is deemed the owner of all Accounts in such Studio Account ("Studio
          Sub-Accounts"). The followers, images, and data associated with all Studio Sub-Accounts
          may not be transferred away from or shared with another Account without the applicable
          Studio's consent, absent what we may deem, in our sole and exclusive determination, to be
          extraordinary circumstances.
        </p>
        <h4>Security of your Account</h4>
        <p>
          You are solely responsible for maintaining the confidentiality of your username and
          password and are fully responsible for all activities that occur under your username and
          password, including without limitation any unauthorized access to the Platform caused by
          you, including access which may violate applicable laws and/or subject you to criminal
          prosecution. This will result in your immediate ban from the Platform and deactivation of
          your Account. We will not release your password for security reasons. You agree to (i)
          immediately notify us of any unauthorized use of your username or password or any other
          breach or suspected breach of security, and (ii) ensure that you log out of your Account
          each time you stop interacting through the Platform. We highly encourage all Community
          Members to turn on two-factor authentication to ensure the security of their Account.
        </p>
        <h4>License to Access the Platform</h4>
        <p>
          You are granted a limited, non-exclusive license to access and use the Platform for your
          own personal enjoyment. You may not, download, reproduce, sell, rent, perform, or link to
          any content made available through the Platform, except as expressly permitted by the
          Community Member and/or Independent Broadcaster, as appropriate, responsible for such
          content or otherwise as permitted by the rules of the Platform.
        </p>
        <h4>Solicitations</h4>
        <p>
          Although we do not control Independent Broadcasters and other users' use of the Platform,
          you may not use the Platform to promote or advertise any third-party products, sites, or
          services that deliver live-streaming content. Although, of course, Independent
          Broadcasters and other Community Members are free to cam on other sites, you may not use
          the Platform to solicit any Community Member to utilize another product, site, or service
          that delivers live-streaming content.
        </p>
        <h4>Billing</h4>
        <p>
          Community Members may elect to put money on deposit with the Platform which will be
          converted to tokens which tokens may only be used through the Platform as the applicable
          Community Member elects, including for use to tip Independent Broadcasters. By tipping
          tokens through the Platform, you agree that all token tips are intended as a gratuity and
          all tips are final when sent. In the event that we receive a complaint about a tip after
          it has been sent, we may, at our election and with no obligation to do so, send the
          complaint to the other party for the other party's response regarding the complaint. You
          are prohibited from providing "tips" for the performance of specific acts. Requesting or
          demanding specific acts for tips may result in a ban from the Platform for all parties
          involved. Independent Broadcasters are prohibited from requesting any type of off-Platform
          payments; provided, however, the Platform may, from time to time, permit Independent
          Broadcasters to post links to wish lists. Community Members who determine to stream and/or
          upload video or photo content but have not provided age verification documentation
          (referred to herein as "Exhibitionists") are not Independent Broadcasters and are not
          eligible to receive tips. Your chosen payment method will only be billed as you
          specifically request. Unless otherwise specifically provided in writing, you will not be
          billed for the same purchase on a recurring basis. By providing your payment information,
          you expressly consent to the use of third-party payment processors to facilitate any and
          all transactions you may elect to make through the Platform. It is your sole
          responsibility to make sure that your billing information is up to date. If you believe
          that you have been erroneously billed, please notify us immediately of such error. If we
          do not hear from you within 30 days after such billing error first appears on any account
          statement, such fee will be deemed accepted by you for all purposes, including resolution
          of inquiries made by your credit card issuer. You release us from all liabilities and
          claims of loss resulting from any error or discrepancy that is not reported to us within
          30 days of its publication. We reserve the right to impose transaction limits on Community
          Members based upon a variety of factors including, without limitation, length of
          membership to the Platform, location, change in access information, and amount of refund
          requests. Please note that abuse of special offers, including creating multiple accounts
          to take advantage of such offers, is a violation of these Terms. Community Members may
          withdraw unused moneys on deposit with the Platform by contacting customer support, less
          amounts charged by third parties in connection with the initial and refund transactions.
        </p>
        <h4>Videos and Images Offered by Community Members</h4>
        <p>
          Community Members, including Independent Broadcasters, may create or otherwise make
          available video, photo, or other content containing content and intellectual property that
          they own or have a license to use ("Member Content"). In making available Member Content,
          you agree that once a Community Member acquires that content in the Community Member's
          Account on the Platform you have granted to that Community Member, and represent that you
          have the right to grant, a perpetual, irrevocable license to access and view such content
          through the Platform. With respect to any Member Content that you elect to acquire through
          the Platform you acknowledge and agree as follows: (i) that we are neither the creator nor
          source of the Member Content; (ii) that we simply offer a platform for Community Members
          to create and share Member Content with other Community Members; (iii) the Community
          Member who posted the applicable Member Content is solely responsible for any claims or
          liabilities associated with, arising from, or in any way relating to such Member Content;
          (iv) your purchase or use of any Member Content is solely at your own risk; and (v) you
          forever release the Company, its affiliates, successors, assigns, officers, employees,
          agents, directors, shareholders and attorneys from any and all claims and liabilities
          associated with, arising from, or in any way relating to Member Content. We have the
          absolute right to remove any Member Content that we believe, in our sole discretion, may
          violate any law or these Terms. In the event that you provide tokens to an Independent
          Broadcaster in connection with obtaining Member Content, you agree that these tokens are
          provided as a gratuity notwithstanding any request from the applicable Independent
          Broadcaster; and, you further understand that such tokens will not be refunded for any
          reason.
        </p>
        <h4>Private Shows</h4>
        <p>
          All Independent Broadcasters acknowledge and agree they are aware that all private shows
          are recorded by default and such recordings are provided to the applicable Community
          Member(s) who purchased such private shows; provided, however, that Independent
          Broadcasters can disable this recording option at any time in account settings.
          Independent Broadcasters acknowledge and agree that all recordings of private shows are
          considered Member Content and that upon delivery of the recording to the purchasing
          Community Member(s), such Community Member(s) will have acquired from the applicable
          Independent Broadcaster a perpetual, irrevocable license to view and access such recording
          through the Platform.
        </p>
        <h4>Subscriptions</h4>
        <p>
          Independent Broadcasters have the ability to create and administer a subscription through
          the Platform provided that Independent Broadcasters' account is in good standing and
          Company has not limited or removed the subscription functionality. Should an Independent
          Broadcaster elect to start a subscription, the Independent Broadcaster will choose a
          monthly fee that other Community Members must pay to be members of the Independent
          Broadcaster's subscription. You understand and agree that in purchasing a membership to an
          Independent Broadcaster's subscription, such purchase will automatically renew, and you
          will be automatically rebilled for such purchase, on a monthly basis until such time as
          you cancel such subscription membership. Updates and what, if anything, is included in any
          such membership will be in the sole control and determination of the applicable
          Independent Broadcaster. For the avoidance of doubt, in the event that you are an
          Independent Broadcaster and offer a subscription, you agree that you have the unrestricted
          right to offer and/or provide any content you may post to Community Members who join your
          subscription is subject in all respects to these Terms and our policies. In the event that
          one or more members of an Independent Broadcaster's subscription request a refund from us,
          or institute a chargeback with our payment processor, we reserve the right to assess a
          chargeback fee to such Independent Broadcaster's Account and/or suspend the Independent
          Broadcaster's ability to maintain a subscription through the Platform. We reserve the
          right to rescind any Independent Broadcaster's permission to maintain a subscription for
          any or no reason at all.
        </p>
        <h4>Warning Regarding Streaming Content</h4>
        <p>
          The Platform provides functionality allowing Community Members to stream/broadcast using
          their webcams. It is possible that other Community Members might, without your permission,
          unlawfully record, make copies of, store, re-broadcast, distribute, publish or otherwise
          share your broadcast online or through other media forms. You assume all risk for your
          broadcasts and hereby release and agree to indemnify and hold us harmless us for all
          actions arising out of such activities, including without limitation invasion of privacy,
          defamation, and/or intellectual property infringement. As noted in our Privacy Policy, all
          information and content you determine to share or stream through the Platform, including
          in "private" and/or password protected situations, is considered public information.
        </p>
        <h4>Use of Information on the Platform</h4>
        <p>
          As noted in our Privacy Policy, we cannot ensure the security or privacy of information
          (including, without limitation, text, images, and videos) you provide or share through the
          Platform. We are not responsible for, and cannot control, the use of any information, by
          anyone, that you provide or make available to other parties through the Platform. Use
          caution in deciding what personal information you share with others through the Platform.
          We cannot assume any responsibility for the content of any message sent by any Community
          Member on the Platform. You release us from any and all liability in connection with the
          content(s) of any communication(s) you may receive from other users.
        </p>
        <h4>Public Information/Caution in Sharing Information</h4>
        <p>
          As noted in our Privacy Policy, all information and/or content you choose to post and/or
          share through your profile on the Platform, through chat (including private chat or
          "direct message"), and all content you stream or otherwise share through the Platform is
          considered public information. You agree to limit the information you share through the
          Platform keeping in mind we cannot control the use of such information by those with whom
          you share your information.
        </p>
        <h4>On- or Off-Platform Interactions/Meetings</h4>
        <p>
          We do not recommend or condone any form of interaction between Community Members outside
          of the Platform and, as disclosed elsewhere in these Terms, your use of and interactions
          through the Platform are done at your own risk. Use of the Platform to arrange
          face-to-face meetings for the purpose of engaging in illegal activity is strictly
          prohibited and will subject your Account to immediate termination. If you elect to legally
          interact with any Community Member outside of the Platform, you do so at your own risk,
          and you acknowledge and agree that we are not responsible for any consequences of your
          election to interact with anyone, whether in person or otherwise, outside of the Platform.
          We cannot and will not intervene in any matters or disputes which take place outside of
          the Platform including with respect to situations where we are provided third party screen
          captures or records as we cannot verify such screen captures or records. In the event that
          you determine to communicate with another Community Member outside of the Platform despite
          these cautions, you should, at a minimum, consider the following precautions:
        </p>
        <p>Anyone who is able to commit identity theft can also falsify a user profile.</p>
        <p>
          There is no substitute for acting with caution when communicating with any stranger who
          wants to meet you.
        </p>
        <p>
          Never include your last name, email address, home address, phone number, place of work, or
          any other identifying information in your user profile or initial email messages.
          Immediately stop communicating with anyone who pressures you for personal or financial
          information or attempts in any way to trick you into revealing it.
        </p>
        <p>
          If you choose to have a face-to-face meeting with another user, always tell someone in
          your family or a friend where you are going and when you will return. Never agree to be
          picked up at your home. Always provide your own transportation to and from the meeting,
          and meet in a public place with many people around.
        </p>
        <p>
          All the money and gifts you send to other users, whether directly or indirectly, through
          the Platform or outside of the Platform, is done at your own risk. We will not intervene
          or become involved in any dispute between Community Members.
        </p>
        <h4>Contests</h4>
        <p>
          From time to time, we may permit certain contests for participation by Community Members
          and Independent Broadcasters. The general rules for our contests are located in our
          general contest rules in Appendix A to these Terms which appendix is incorporated by
          reference herein.
        </p>
        <h4>Your Privacy Rights</h4>
        <p>
          When you use the Platform, we collect and process certain personally identifiable and
          other data about you. Our use of this information is governed by our Privacy &amp; Cookies
          Policy, which is incorporated by reference herein. You are encouraged to read this policy
          as it contains important information on how we collect and use this information and your
          rights regarding the same. Additionally, the Platform utilizes cookies and certain
          technology that tracks usage, performance and your geographic location which are more
          fully described in our Privacy &amp; Cookies Policy.
        </p>
        <h4>Termination</h4>
        <p>
          By You. You may terminate your Account and/or any of your memberships at any time by
          visiting our cancellation page or contacting our customer support. You agree to be
          personally liable for any and all charges incurred by your Account, username, and password
          until terminated as provided herein. If you are an Independent Broadcaster and determine
          to terminate your Account, any tokens remaining in your Account at the time of termination
          will be disbursed to the payment information on file for your Account on our next payout
          date. If you are not an Independent Broadcaster and determine to terminate your Account
          with money on deposit in your Account for use as tokens, such amounts can be withdrawn by
          you subject to any third-party costs associated with processing your deposit and
          withdrawal. Upon our processing of your request to terminate your Account, you will no
          longer have access to the non-public areas of the Platform nor will you have access to any
          Member Content in your Account.
        </p>
        <p>
          By Us. We may, in our sole discretion, terminate or suspend your access to all or part of
          the Platform at any time, with or without notice, for any reason or no reason at all,
          including, without limitation, breach of this Agreement. Without limiting the generality
          of the foregoing, any activity which we believe, in our sole discretion, to possibly be
          illegal, fraudulent, abusive, or in violation of our community standards, or our rules,
          may be grounds for termination of your access to all or part of the Platform at our sole
          discretion, and we reserve the right to refer any such activity to any appropriate law
          enforcement agencies.
        </p>
        <h4>Effect of Termination</h4>
        <p>
          You accept that when you cancel your Account you will be automatically locked out of the
          Platform, and will no longer be able to access your Account, including any and all Member
          Content. You also agree and accept that upon termination of your Account, we have no
          obligation to maintain or store any content, mail or other materials connected to or in
          your Account and that such information may be irretrievable.
        </p>
        <h4>Representations by Community Members</h4>
        <p>You represent and warrant the following:</p>
        <p>
          You are familiar with the laws in your area that may affect your legal right to access or
          transmit erotica or adult-oriented material and, by your accessing the site, you are
          representing and warranting that you are not prohibited by law from accessing or using the
          Platform or transmitting in any way any adult-oriented material.
        </p>
        <p>
          You are not a registered sex offender in any jurisdiction and have never been convicted of
          any type of sexual crime against any person or animal.
        </p>
        <p>
          You are voluntarily choosing to proceed with your use of the Platform because you want to
          view, read, or hear various content, including, without limitation, content of an explicit
          adult nature for your own personal enjoyment, information, and/or education.
        </p>
        <p>
          You are familiar with the standards in your community regarding acceptance of
          sexually-oriented materials, and the materials you expect to encounter through use of the
          Platform are within your community standards. Should the content you encounter through the
          Platform not meet your community standards, you will immediately cease use of the
          Platform.
        </p>
        <p>
          You have not notified any governmental agency, including the U.S. Postal Service, that you
          do not wish to receive sexually oriented material
        </p>
        <p>
          You will not violate any civil or other rights of any other Community Member or any third
          party.
        </p>
        <p>
          Any content that you upload or stream will be your original work and not infringe the
          intellectual property rights of any third-party.
        </p>
        <p>You agree that:</p>
        <p>You will follow all applicable laws governing your use of the Platform; and</p>
        <p>You will not violate any provision of these Terms.</p>
        <h4>Community Member Indemnities</h4>
        <p>
          An indemnity is your obligation to cover us for certain losses, whether monetary or
          otherwise relating to or arising out of certain actions by you. This section creates an
          obligation on your part to protect us in these instances and is a material inducement on
          our part to provide the Platform. You hereby agree to indemnify us for any and all claims
          and losses, whether actual or threated, including without limitation, our reasonable
          attorneys' fees and costs, with respect to any claim relating to or arising out of your
          use('of') the Platform that violates these Terms or any applicable law. Again, if you do
          not agree to these Terms, you may not access or use the Platform and should immediately
          exit the Platform.
        </p>
      </div>
    ),
  },
  {
    title: 'CONTENT',
    content: (
      <div>
        <h4>Obligations Under 18 U.S.C. §2257</h4>
        <p>
          You should be aware that, pursuant to United States federal law, any visual depictions
          that you post, share or perform on or through the Platform which portray "actual sexually
          explicit conduct", "depictions of the genitals or pubic area", or "simulated sexually
          explicit activity", as those terms are defined in 18 U.S.C. §2256(2)(A)(i)-(iv) and
          §2257A, require that you maintain the records listed under 18 U.S.C. §2257, and any such
          postings must contain a "18 U.S.C. §2257 Record-Keeping Requirements Compliance
          Statement." Your failure to comply with the provisions of 18 U.S.C. §2257 may make you
          subject to criminal and civil prosecution for the violation of federal law.
        </p>
        <h4>Content Submitted to the Platform</h4>
        <p>
          All materials submitted and/or streamed by Community Members through the Platform,
          including Member Content, and created by such Community Member ("Community Member
          Content") is and shall remain the property of the Community Member or Independent
          Broadcaster who created it. Community Member Content shall also include any chats, or
          other materials that are transmitted through the Platform when you use the Platform. When
          you broadcast or upload Community Member Content to the Platform, you hereby grant us a
          worldwide, non-exclusive, royalty-free, sublicensable and transferable license to use,
          reproduce, distribute, prepare derivative works of, and perform the Community Member
          Content in connection with delivering the Platform and for marketing and advertising the
          availability of the Platform in any media format we choose. You also expressly provide
          that each Community Member shall have a license to use the Community Member Content under
          the terms of Section III(c) of these Terms. You will not submit content that you did not
          create, own, or to which you do not have the legal right to submit. This restriction
          includes the submission of any third-party content. Community Member Content will comply
          with these Terms and the Code of Conduct. All Community Member Content must also comply
          with our "Content Submission Guidelines" in our Code of Conduct located at Appendix C and
          which are incorporated by reference herein. You represent and warrant that all Community
          Member Content is your content and does not infringe upon the intellectual property rights
          of any third-party. You will indemnify and hold us harmless for any and all losses,
          damages or costs relating to or arising out of a claim that your Community Member Content
          infringes the intellectual property rights of any third-party.
        </p>
        <h4>Deleting Community Member Content</h4>
        <p>
          You may request that we delete Community Member Content submitted by you by contacting us
          at support@beladed.com. We may retain copies of the Community Member Content but will not
          make them available through the Platform after we have processed your request. Your
          license to us in any comments, texts, chats or other Community Member Content (used in
          published advertising) is not revocable. As noted elsewhere in these Terms, your Community
          Member Content, if acquired through the Platform by another Community Member, will be
          deleted from your Account per your request; however, any Community Member Content acquired
          by other Community Members prior to the date of your request for deletion of such
          Community Member Content will remain in the Accounts of such Community Members. Moreover,
          you understand that, although we may delete Community Member Content, one or more other
          Community Members may have copies of or notes regarding such content and we are unable to
          delete such copies or notes.
        </p>
        <h4>No Endorsement for Community Member Content</h4>
        <p>
          We do not endorse or recommend, nor do we have or assume any obligation to monitor any
          Community Member Content streamed or otherwise shared through the Platform by any
          Community Member or Independent Broadcaster; and, we hereby disclaim any and all liability
          with respect to Community Member Content. We do not permit any copyright infringing
          activities or any Community Member Content that infringes on our intellectual property
          rights or those of any party or third party. We will remove any Community Member Content
          where we are properly notified of such infringement as set forth below. We may remove any
          such Community Member Content without any notice. Our means of identifying Community
          Member Content that may infringe upon a third party's rights or which is illegal or
          violates our code of conduct, is dependent on properly presented notifications from third
          parties claiming that their rights have been violated. For Community Member Content which
          is offensive or which you believe is illegal, notify us at support@beladed.com. For
          infringing content, please follow the procedures in paragraphs "f" and "g" below.
        </p>
        <h4>Repeat Infringer</h4>
        <p>
          We have and enforce a repeat infringer policy. We will terminate the Accounts of any
          Community Member and/or Independent Broadcaster who is determined to be a repeat
          infringer.
        </p>
        <h4>Infringement and the DMCA</h4>
        <p>
          We respect the creative efforts of Community Members and non-community members and have no
          tolerance for anyone's use of a third party's intellectual property without such third
          party's express permission. If you are a copyright owner and believe that your work has
          been copied or in any way distributed or shared through someone's use of the Platform in a
          way that constitutes copyright infringement, or your intellectual property rights have
          been otherwise violated, please provide the Platform's Designated Copyright Agent the
          following information:
        </p>
        <p>
          an electronic or physical signature of the person authorized to act on behalf of the owner
          of the copyright or other intellectual property interest;
        </p>
        <p>
          description of the copyrighted work or other intellectual property that you claim has been
          infringed;
        </p>
        <p>
          a description of where on the Platform the material that you claim is infringing is
          located;
        </p>
        <p>your address, telephone number, and email address;</p>
        <p>
          a statement by you that you have a good faith belief that the disputed use is not
          authorized by the copyright owner, its agent, or the law; and
        </p>
        <p>
          a statement by you made under penalty of perjury that the information in your Notice is
          accurate and that you are the copyright or intellectual property owner or authorized to
          act on the copyright or intellectual property owner's behalf.
        </p>
        <p>Send your Notice of Claimed Infringement to:</p>
        <p>
          Lawrence G. Walters, Esq. 195 W. Pine Avenue Longwood, FL 32750 Fax: (407) 774-6151 Email:
          Notice@DMCANotice.com
        </p>
        <p>
          Do not send other inquires or information (other than Notices of Infringement) to our
          Designated Agent. Any other inquiries should be sent to support@beladed.com.
        </p>
        <h4>Infringement Counter-Notice</h4>
        <p>Your physical or electronic signature;</p>
        <p>
          Identification of the Content that has been removed or to which access has been disabled
          and the location at which the Content appeared before it was removed or disabled;
        </p>
        <p>
          A statement that you have a good faith belief that the Content was removed or disabled as
          a result of mistake or a misidentification of the Content; and
        </p>
        <p>
          Your name, address, telephone number, and e-mail address, a statement that you consent to
          the jurisdiction of the federal court in Los Angeles, California, and a statement that you
          will accept service of process from the person who provided notification of the alleged
          infringement.
        </p>
        <p>
          If a counter-notice is received by the Copyright Agent, we may send a copy of the
          counter-notice to the original complaining party informing that person that it may replace
          the removed Content or cease disabling it in 10 business days. Unless the copyright owner
          files an action seeking a court order against the Content provider, member or user, the
          removed Content may be replaced, or access to it restored, in 10 to 14 business days or
          more after receipt of the counter-notice, at our sole discretion.
        </p>
      </div>
    ),
  },
  {
    title: 'CODE OF CONDUCT',
    content: (
      <div>
        <p>
          You understand that we do not create or publish content. Instead, we provide a platform to
          provide others with the ability to share their own content which they have all rights and
          permissions to share. We work to implement only minimal rules intended to respect the
          rights of others and comply with applicable law, and so that the Platform remains a safe
          location for Community Members to express themselves. These community rules are guidelines
          for what is permissible through the Platform and are subject to change. Please review our
          "Code of Conduct" located at Appendix C which is hereby incorporated by reference and made
          a part of these Terms.
        </p>
      </div>
    ),
  },
  {
    title: 'PROMOTION OF THE PLATFORM',
    content: (
      <div>
        <p>
          Registered users of the Platform may be eligible to participate in our affiliate
          advertising program and potentially earn commissions based on the number and quality of
          registered user referred to the Platform. For more information, please see Appendix B,
          which is hereby incorporated by reference and made a part of these Terms.
        </p>
      </div>
    ),
  },
  {
    title: 'MISCELLANEOUS',
    content: (
      <div>
        <h4>Links to Other Sites</h4>
        <p>
          The Platform may contain links, posted by us or by one or more Community Members, to other
          websites operated by independent third parties. These websites are not operated by us and
          we are not responsible for any content or links they provide. Our linking to any
          third-party sites is not an endorsement or certification as to the content, opinions
          expressed thereon or safety or suitability of such site. When you access a link to another
          site, you are leaving the Platform and accessing the third-party site at your own risk.
          Other sites are will have their own terms, privacy policies and procedures and may contain
          malicious or destructive code, viruses, malware and other tracking cookies. You should
          ensure that your device is adequately protected. WE SHALL NOT BE LIABLE TO YOU IN ANY WAY
          FOR YOUR ACCESSING ANY THIRD PARTY LINKED SITES. Community members may not post links (or
          references to links or third party sites generally) in their room subject or as their
          location in their bio pages. We reserve the right to further restrict where links may be
          shared and to which third party websites you may link or reference.
        </p>
        <h4>Proprietary Information</h4>
        <p>
          Elements of the Platform contain proprietary or confidential information that belongs to
          us. We assert full copyright protection in the Platform, including all of the design and
          code embodied therein. Any information shared or posted by us or by Community Members may
          be protected whether or not it is identified as proprietary to us or to the sharing
          Community Member. You will not modify, copy, or distribute any information on the Platform
          without the express written permission of the owner of such information and may not use
          any automated means to scrape, download or otherwise collect any data or content from the
          Platform including, without limitation, robots/bots, crawlers, or data mining tools.
        </p>
        <h4>Disclaimer of Warranties</h4>
        <p>
          The Platform is provided on an "as is" and "as available" basis. We do not warrant that
          the Platform will be uninterrupted or error-free. There may be delays, omissions, and
          interruptions in the availability of the Platform. WHERE PERMITTED BY LAW, YOU ACKNOWLEDGE
          THAT THE SERVICE IS PROVIDED WITHOUT ANY WARRANTIES OF ANY KIND WHATSOEVER, EITHER EXPRESS
          OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY OR
          FITNESS FOR A PARTICULAR PURPOSE; NOR DOES THE SITE MAKE ANY WARRANTY AS TO THE RESULTS
          THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES OR AS TO THE ACCURACY OR RELIABILITY OF
          ANY INFORMATION OBTAINED THROUGH THE SERVICES OR THAT DEFECTS IN ANY SOFTWARE, HARDWARE OR
          THE SERVICES WILL BE CORRECTED. YOU UNDERSTAND AND AGREE THAT ANY USE YOU MAKE OF ANY
          MATERIAL AND/OR DATA DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS AT
          YOUR OWN DISCRETION AND RISK, AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO
          YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF SUCH MATERIAL
          AND/OR DATA. We do not represent or endorse the accuracy or reliability of any advice,
          opinion, statement or other information displayed, uploaded or distributed through the
          Platform by us or by any Community Member or any other person or entity. You acknowledge
          that any reliance upon any such opinion, advice, statement or information shall be at your
          sole risk. The site may contain errors, omissions or other outdated information. We
          reserve the right to correct these errors. If you have any questions, or believe you have
          encountered any type of error, please contact us at support@beladed.com.
        </p>
      </div>
    ),
  },
  {
    title: 'LIMITATION OF LIABILITY',
    content: (
      <div>
        <p>
          When permitted by law, we and our affiliated companies will not be responsible for any
          lost profits, revenues, loss of data, financial losses, special, indirect consequential or
          punitive damages, except where caused by our gross negligence or willful misconduct. to
          the fullest extent permitted by law, our liability to you will not exceed, in the
          aggregate, the less of any unused portion of the virtual money you purchased (but only if
          we discontinue the use of virtual currency, or if you voluntarily cancelled your account),
          or the sum of $250 usd. in all instances, we will not be liable for any loss or damage
          that is not reasonably foreseeable.
        </p>
        <h4>Complaints</h4>
        <p>
          To resolve or report a complaint regarding the Platform or other Community Members, send
          an email detailing your complaint to support@beladed.com. In appropriate circumstances, we
          will take immediate action in order to help resolve the problem. You agree that in
          attempting to resolve any complaint you send to us we may, in our sole and exclusive
          determination, share your complaint in part or in whole with other individuals involved
          and/or otherwise implicated in the complaint.
        </p>
        <h4>Choice of Law</h4>
        <p>
          You agree that these Terms are governed by the laws of the State of California, without
          regard to its choice of law provisions.
        </p>
        <h4>Arbitration</h4>
        <p>
          You hereby agree that, if we become involved in any dispute relating to or arising out of
          your use of the Platform, any such claims, including any private attorney-general
          representative claims, will be resolved by binding individual arbitration and not in
          court. There is no judge or jury in arbitration proceedings, and awards made by an
          arbitrator are not generally appealable in court except in rare circumstances. Arbitrators
          can, however, award damages on an individual basis identical to what can be awarded by a
          judge. An arbitrator hearing a claim is obligated to follow these Terms as a judge in a
          regular court proceeding would be. The United States Federal Arbitration Act and United
          States federal arbitration law apply to these Terms. To commence an arbitration
          proceeding, you must send a notice to us at legal@beladed.com. The arbitration will be
          conducted by the American Arbitration Association (AAA) under its rules, including the
          Supplementary Procedures for Consumer-Related Disputes, and the Federal Arbitration Act.
          The AAA rules are available at www.adr.org or by calling 1-800-778-7879. Payment and fees
          for the arbitration are governed by the AAA rules. We will reimburse the arbitration
          filing fees for claims less than $1,000 except where the claims are ruled to be frivolous.
          The arbitration will be held in Los Angeles County, California. Neither party will be
          entitled to an award of its attorneys' fees or costs incurred in arbitration, except where
          the court has ruled that the other party's claim is frivolous.
        </p>
        <p>
          Regardless of where you reside, to the fullest extent permitted by law, you expressly
          agree that class action lawsuits, class-wide arbitrations, private attorney-general
          actions, and any other proceeding where someone acts in a representative capacity are not
          allowed, nor is combining individual proceedings without the consent of all parties.
        </p>
        <h4>Entire Agreement</h4>
        <p>
          These terms plus any policies referenced herein or on the site represent the entire
          agreement between you and us. To the extent that there is a conflict between these Terms
          and any other policy, these Terms will control except where expressly stated to the
          contrary.
        </p>
        <h4>Severability</h4>
        <p>
          These Terms are severable. If any provision or portion of these Terms is held to be
          invalid or otherwise unenforceable, such provision or portion shall be interpreted to
          fulfill its intended purpose to the maximum extent permitted by applicable law, or if
          legally impossible, such provision or portion shall be ineffective only to the extent of
          such invalidity, and the remainder of these Terms will continue in full force and effect.
          If any provision or portion of these Terms may be construed in two or more ways, one of
          which would render the provision invalid or otherwise voidable or unenforceable and
          another of which would render the provision valid and enforceable, such provision will
          have the meaning that renders it valid and enforceable.
        </p>
      </div>
    ),
  },
];

export const privacy = [
  {
    title: 'About us',
    content: (
      <div>
        <p>Beladed, Inc operates the software platform located on Beladed (the "Platform").</p>
      </div>
    ),
  },
  {
    title: 'Information we may collect and process about you',
    content: (
      <div>
        <p>
          Information you provide to us, including by way of example only, your username, email
          address, and date of birth
        </p>
        <h4>Your IP address</h4>
        <p>
          Information about accounts you've visited, products and services you've clicked on or
          shown an interest
        </p>
        <p>
          Information provided by other companies who have obtained your permission through their
          own privacy policies to share information about you
        </p>
        <p>Information about your interaction with the Platform including any advertisements</p>
        <p>
          Information we collect using cookies stored on your device about your use of the Platform
          and/or selected third party websites.
        </p>
        <p>
          Information we collect or remember using technologies similar to cookies, such as device
          identifiers on your mobile device.
        </p>
        <p>Technical information from your device relating to the service you receive</p>
        <p>Information relating to the location of your device.</p>
      </div>
    ),
  },
  {
    title: 'How we may use your information',
    content: (
      <div>
        <p>
          By accessing the Platform, you agree that we may collect, hold, use or otherwise process
          your information (including personal information) for the purpose of providing you with
          those products and services you request or access, and developing our business. By
          providing us with your email address, you agree to receive emails from us or sent on our
          behalf providing you with messages regarding your account including, but not limited to,
          purchase confirmations, renewal confirmations and warnings, alerts you may have requested
          to receive including alerts regarding other user’s streaming broadcasts or content
          submissions), and newsletters. Should you determine to provide an email address, you may
          change your email address at any time.
        </p>
        <h4>Payment Processing</h4>
        <p>
          Please note that we contract with various third parties to carry out payment processing in
          connection with any purchases you may make through the Platform. The processing and
          storage of any and all information you may provide to any such third-party payment
          processor, including information they may collect from you, is not governed by this
          Policy. You will need to review the Privacy and Cookie Policy for such third party on
          their website.
        </p>
        <h4>Two Factor Authentication</h4>
        <p>
          When you enable two factor authentication to protect your account, you are providing your
          phone number directly to a third party called Twilio. Their use of this information is
          outside of this Policy; however they do not receive any information regarding your account
          beyond the phone number you provide directly to them.
        </p>
        <h4>Fraud Prevention Services</h4>
        <p>
          We use third-party services in connection with preventing fraud and other damage to our
          system. As such we may provide to such services with your IP and device data should your
          account be terminated for fraud or behavior which is damaging to our Platform. This
          information will be retained indefinitely for the protection of our Platform and users.
        </p>
      </div>
    ),
  },
  {
    title: 'How we may use your information in specific products or services',
    content: (
      <div>
        <h4>Registration</h4>
        <h5>General account registration:</h5>
        <p>
          You are not required to provide any information to use the Platform. You may determine to
          create an account with the Platform. In such event you will be required to provide a user
          name, birthdate to demonstrate you meet our minimum age requirements, and your gender.
          This information, together with any information you determine to provide in your account
          profile, will be publicly posted in connection with your username.
        </p>
        <h5>Contributor registration:</h5>
        <p>
          Should you determine to create a "Contributor" account (which is an account that is
          permitted to receive digital currency from other users of the Platform), you will be
          required to provide a color copy of your current government issued photo identification.
          This information is stored by us in compliance with United States Federal law. You will
          also be required to provide "pay to" information such as a bank account or home address.
          You may be required to provide certain taxpayer information. We report to the United
          States Internal Revenue Service all moneys paid by us to Independent Broadcasters as
          required by law.
        </p>
        <h5>Webmaster registration:</h5>
        <p>
          Should you determine to create a webmaster account with the Platform, you will be required
          to provide "pay to" information such as a bank account or home address. You may be
          required to provide certain taxpayer information. We report to the United States Internal
          Revenue Service moneys paid by us to webmasters as required by law.
        </p>
        <h5>Message boards, blogs and other public forums</h5>
        <p>
          The Platform may provide message boards, blogs and other user generated content
          facilities, in addition to user profiles. Anything that you share through any of these
          means is deemed public information. You should always be careful when deciding to disclose
          your personal information.
        </p>
        <h4>Location based services</h4>
        <p>
          Where we provide services that utilize your device's location, we may permit other users
          of the service to block individuals in certain locations, based upon their IP, from
          accessing such users' content.
        </p>
      </div>
    ),
  },
  {
    title: 'Certain Information Use Out of Our Control',
    content: (
      <div>
        <p>
          Should you determine to stream or upload any content to our Platform, you understand that
          we cannot control the use of such content by any user/observer of our Platform.
          Furthermore, we cannot control the use of any personal information you may determine to
          share with other users/observers of the Platform. You should exercise caution in
          discussing your location, name, account information, and other identifying information.
          For privacy, we recommend using a unique username not in any way tied to any of your
          social media accounts or real name.
        </p>
      </div>
    ),
  },
  {
    title: 'How we may share your information',
    content: (
      <div>
        <p>
          Your username will be associated with all activities you undertake on the Platform
          including, without limitation, messages sent, content shared, and streamed content. Your
          gender information will be used to categorize your account and the content that you share
          so it is easier for site users to find. Your IP addresses, device data, and other use
          information are used by us in order to optimize the Platform for use. Your usage history
          may also be used to help our internal algorithm to recommend other Platform users to you.
          Your information may also be shared with third parties with whom we contract to provide
          certain services on our website such as third-party hosting services and site optimization
          services. Your "pay to" information, if provided to us, will be shared with third parties
          (such as, by way of example only, our bank) only as necessary to process payments to you
          using the "pay to" information you have provided to us. Your government identification, if
          provided to us, will be shared as required to comply with applicable law and as may be
          requested by you in writing. Notwithstanding the forgoing, we reserve the right to share
          any and all account information as we deem appropriate - in response to written request(s)
          from law enforcement or other third parties; should we believe, in our sole discretion,
          that you or your account may be involved with illegal activity; or, if we determine, in
          our sole discretion, that such disclosure is necessary to protect the rights or property
          of Beladed, our users, or third parties.
        </p>
      </div>
    ),
  },
  {
    title: 'Safeguards and security for your information',
    content: (
      <div>
        <p>
          We have measures in place to protect the security of your personal information from
          unauthorized access or use, such as by using encryption technology.
        </p>
      </div>
    ),
  },
  {
    title: 'Information Deletion',
    content: (
      <div>
        <p>
          Should you request your personal data be deleted, it will be deleted in compliance with
          our data retention policy and applicable law and rules. In order to avoid abuse of our
          system, we will retain device and IP data relating to your account for a period of time to
          prevent you from creating a new account. In the event that you have tokens in your account
          at the time you request deletion, by requesting deletion you are agreeing that these
          tokens are being forfeited by you to us and that you shall not be entitled to a transfer
          of or refund for such tokens.
        </p>
        <h3>
          You can request permanent deletion using our deletion tool{' '}
          <a href="https://beladed.com/contact">here</a>.
        </h3>
      </div>
    ),
  },
];

export const cookies = [
  {
    title: 'Cookies Policy',
    content: (
      <div>
        <p>
          You should be aware that when you use the Site we may collect information using cookies or
          similar technologies.
        </p>
      </div>
    ),
  },
  {
    title: 'What are cookies and how do they work?',
    content: (
      <div>
        <p>
          Cookies are small files that are downloaded to your computer or mobile device when you
          visit a website. Your browser sends these cookies back to the website every time you visit
          the site again, so it can recognize you. This allows websites to tailor what you see on
          the screen.
        </p>
      </div>
    ),
  },
  {
    title: 'Do we use other technologies which are similar to cookies?',
    content: (
      <div>
        <p>
          Our websites, apps and emails often contain small invisible images known as 'web beacons'
          or 'tracking pixels'. These are used in a way similar to cookies, to understand when a
          particular part of a webpage is viewed. Our apps often use device identifiers in the same
          way as cookies. A device identifier is a unique number on your device which allow us to
          remember your device.
        </p>
      </div>
    ),
  },
  {
    title: 'What do we use cookies for?',
    content: (
      <div>
        <p>
          Cookies make using websites much smoother and affect lots of the useful features of
          websites. There are many different uses for cookies, but they fall into four main groups:
        </p>
        <p>
          Cookies that are needed to provide the service you have asked for. Some cookies are
          essential so you can move around the website and use its features. Without these cookies,
          services you've asked for can't be provided. These cookies gather information about you
          that can be used for remembering your browsing history or allowing you to stay logged in
          during your visit.
        </p>
        <p>
          Cookies used to improve your browsing experience. These cookies allow the website to
          remember choices you make, such as your language or region and they provide improved
          features such as content provider recommendations and limiting the number of times a
          particular advertisement is shown to you.
        </p>
        <p>
          Cookies used to understand how people use our products and services. This allows us to
          keep track of what pages are popular to help other users find such pages.
        </p>
        <p>
          Cookies used to show advertising that is relevant to you based upon your viewing history.
        </p>
        <p>
          We also use cookies in order to know how many advertisements we serve, how many times
          these are clicked or hovered over with a mouse cursor, how many advertisements we show to
          a given user and how many customer actions these generate.
        </p>
        <p>
          We may also use cookies on the Site and advertisers' sites to understand which customers
          reach a sale or other action page on an advertiser's site. This allows us to monitor how
          effective our advertising is.
        </p>
        <p>
          Some of our web pages will contain promotional links to other companies' sites. If you
          follow one of these links and then register with or buy something from that other site, a
          cookie is sometimes used to tell that other site that you came from one of our sites. Such
          other site may then pay us a small amount for the successful referral. You will need to
          review the privacy policy of any website whose advertisement you click on as this Policy
          only applies to pages on our domain.
        </p>
      </div>
    ),
  },
  {
    title: 'Contacting us and accessing your personal information',
    content: (
      <div>
        <p>
          If you wish to review or receive a copy of the personal information we hold about you, we
          offer a self-serve tool <a href="contact.html">here</a>
        </p>
        <p>
          If you have any queries or comments about this Privacy &amp; Cookies Policy OR if you wish
          to review or receive a copy of the personal information we hold about you, please write to
          us at:
        </p>
        <p>Attention: Data Protection Officer</p>
        <p>Data Admins Ltd</p>
        <p>Unit 32 Studios</p>
        <p>100 Bradford Road</p>
        <p>Dewsbury, United Kingdom, WF13 2EF</p>
        <p>privacyquestions@beladed.com</p>
        <p>
          We reserve the right to charge an administration fee in relation to fulfilling a request
          for access to personal information which fee shall not exceed the maximum fee allowed by
          law.
        </p>
      </div>
    ),
  },
  {
    title: 'Changes to this Privacy & Cookies Policy',
    content: (
      <div>
        <p>
          We reserve the right to make changes to our Privacy &amp; Cookies Policy. Changes to this
          policy will be posted here and will become effective as of the updated effective date.
          Your continued use of our website or platform will signify your acceptance of these
          changes.
        </p>
      </div>
    ),
  },
  {
    title: 'Residents of the State of California',
    content: (
      <div>
        <p>
          Under the California Consumer Privacy Act, you have certain rights with respect to the
          personally identifying information you provide to us.
        </p>
      </div>
    ),
  },
  {
    title: 'Copies of Your Personally Identifying Information',
    content: (
      <div>
        <p>
          For free, up to two times in any twelve-month period, you are entitled to obtain the
          following information for the immediately preceding twelve month period:
        </p>
        <p>The categories of information we have collected about you;</p>
        <p>The categories of sources of the information we have collected about you;</p>
        <p>The purpose for which we have collected such information about you;</p>
        <p>A copy of the personally identifying information we have collected about you; and,</p>
        <p>
          To be informed if we have sold or disclosed your personally identifying information to a
          third party and for what purpose.
        </p>
        <p>
          In order to obtain a copy of this information with respect to your account, you may email
          or call using the following information:
        </p>
        <p></p>
        <p>US phone number: (916) 214-0011</p>
        <p>Email address: support@beladed.com</p>
        <p>
          For your privacy and protection, we will require certain information to confirm that it is
          you, the accountholder, requesting the information. If there is no information associated
          with your account that we can verify, such as your email address or other information, we
          may be unable to complete your request. For your protection, responsive information will
          only be delivered to the email address assigned to the subject account. For an email
          address to be "assigned to an account" as used herein, it means the email address to which
          you receive account security notifications such as account recovery instructions; it does
          not mean the email address you may have provided in completing your Independent
          Broadcaster Agreement, if you completed an Independent Broadcaster Agreement.
        </p>
      </div>
    ),
  },
];
