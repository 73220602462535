import img1a from "assets/invest/section-4-1-a.jpg";
import img1b from "assets/invest/section-4-1-b.jpg";
import img1c from "assets/invest/section-4-1-c.png";
import img1 from "assets/invest/section-4-1.jpg";
import React, { useEffect, useRef, useState } from "react";

const TheMarket = () => {
  return (
    <div className=" mx-auto flex flex-col gap-10 py-10 px-4 md:py-16 lg:py-24 xl:max-w-screen-lg xl:gap-20 xl:px-8 2xl:max-w-screen-xl 2xl:py-32 3xl:max-w-screen-2xl">
      <div>
        <h1
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="500"
          className="mx-auto mb-6 w-fit px-4 text-center text-5xl font-bold leading-tight text-neutral-1 md:text-6xl lg:mb-6 lg:text-7xl xl:text-8xl 3xl:text-[150px]"
        >
          The <span className="text-gr">Market</span>
        </h1>
        <p
          data-aos="fade-in"
          data-aos-duration="2000"
          data-aos-delay="1500"
          className="mx-auto text-center text-base  font-medium text-neutral-1 lg:text-xl xl:w-3/4 3xl:text-3xl"
        >
          Beladed taps into a rapidly growing market, with the global interactive livestreaming
          industry projected to reach $247 billion by 2028, targeting a wide age group ranging from
          tech-savvy Gen Z gamers to millennial content creators and beyond.
        </p>
      </div>

      <div className=" grid-cols-[repeat(8, 1fr)] grid-rows-[repeat(8, 1fr)] grid w-full rounded-xl py-6 px-6 lg:py-12">
        <div
          style={{ gridArea: " 1 / 1 / 8 / 8" }}
          data-aos="fade-right"
          data-aos-duration="1500"
          data-aos-delay="1000"
        >
          <img
            src={img1a}
            alt="Target Verticals"
            className="w-full rounded-lg object-cover md:h-full"
          />
        </div>
        <div
          style={{ gridArea: "2 / 6 / 9 / 9" }}
          className="flex justify-end"
          data-aos="fade-left"
          data-aos-duration="2000"
          data-aos-delay="1500"
        >
          <img src={img1b} alt="Target Verticals" className="rounded-lg" />
        </div>
        <div
          style={{ gridArea: "5 / 4 / 7 / 7" }}
          className="flex justify-end"
          data-aos="flip-right"
          data-aos-duration="1500"
          data-aos-delay="1500"
        >
          <img src={img1c} alt="Target Verticals" className="rounded-lg" />
        </div>
      </div>
    </div>
  );
};

export default TheMarket;
