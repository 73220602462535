import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useEffect, useState } from "react";

const SelectWithRadio = ({
  areOpen,
  setAreOpen,
  configs,
  handleClose,
  handleChange,
  initialState,
  name,
  options,
  icon,
  label,
  classNames,
}) => {
  const [isMouseIn, setIsMouseIn] = useState(false);
  const handleBlur = () => {
    if (!isMouseIn) {
      handleClose();
    }
  };

  return (
    <div className="relative w-full ">
      <button
        onBlur={handleBlur}
        id={name + "-button"}
        aria-haspopup="true"
        name={name}
        onClick={e =>
          setAreOpen({
            ...initialState,
            [e.target.name]: !areOpen[e.target.name],
          })
        }
        className={`relative z-20 flex w-full items-center justify-between rounded-lg bg-neutral-11 py-2 pl-2  pr-1 text-sm focus:border-secondary-5 dark:border dark:border-neutral-3  dark:bg-neutral-1 sm:py-3 sm:pl-4 sm:pr-3   sm:text-base ${classNames}`}
      >
        <div className="pointer-events-none flex items-center gap-2 capitalize">
          {icon}
          {configs[name]}
        </div>
        <div className="pointer-events-none">
          <ArrowDropDownIcon sx={{ color: "#5A5A5A", fontSize: { xs: "16px", sm: "24px" } }} />
        </div>
      </button>
      {areOpen[name] && (
        <div
          onMouseEnter={() => setIsMouseIn(true)}
          onMouseLeave={() => setIsMouseIn(false)}
          id={name + "-menu"}
          tabindex="0"
          onClose={handleClose}
          className="absolute top-full z-30 mt-1 flex w-full flex-col  gap-1 rounded-lg border   border-neutral-10 bg-white p-0 dark:border-neutral-3 dark:bg-neutral-1 sm:p-2"
          MenuListProps={{
            "aria-labelledby": name + "-button",
          }}
        >
          <FormControl>
            <RadioGroup
              aria-labelledby={name}
              name={name}
              value={configs[name]}
              className="flex-col gap-0 "
              onChange={handleChange}
            >
              {options.map((item, index) => {
                const { value, label } = item;
                return (
                  <FormControlLabel
                    key={index}
                    className="m-0 rounded-lg  px-1 py-1  !text-[2px] hover:bg-neutral-11 dark:hover:bg-neutral-2"
                    value={value}
                    checked={configs[name] === value}
                    sx={{
                      marginLeft: 0,
                      marginRight: 0,

                      ".MuiFormControlLabel-label": {
                        fontSize: { xs: "12px", sm: "14px" },
                      },
                    }}
                    control={
                      <Radio
                        sx={{
                          color: "#CCCCCC",
                        }}
                        color="secondary"
                      />
                    }
                    label={label}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        </div>
      )}
    </div>
  );
};

export default SelectWithRadio;
