import img1a from "assets/onlyfans/section-2-1.png";
import img1b from "assets/onlyfans/section-2-2.png";
import img1c from "assets/onlyfans/section-2-3.png";
import img1d from "assets/onlyfans/section-2-4.png";
import img1e from "assets/onlyfans/section-2-5.png";
import img1f from "assets/onlyfans/section-2-6.png";
import img2 from "assets/onlyfans/section-2-model.webp";
import vector from "assets/onlyfans/section-2-vector.svg";
import { useEffect, useRef } from "react";

const Section2 = () => {
  const observer = useRef(null);
  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setTimeout(() => {
            if (
              entry.target.id === "observe1" ||
              entry.target.id === "observe3" ||
              entry.target.id === "observe5"
            ) {
              entry.target.classList.add("floating-animation-2");
            } else {
              entry.target.classList.add("floating-animation");
            }
          }, 2500);
        } else {
          if (
            entry.target.id === "observe1" ||
            entry.target.id === "observe3" ||
            entry.target.id === "observe5"
          ) {
            entry.target.classList.remove("floating-animation-2");
          } else {
            entry.target.classList.remove("floating-animation");
          }
        }
      });
    });

    observer.current.observe(document.querySelector("#observe1"));
    observer.current.observe(document.querySelector("#observe2"));
    observer.current.observe(document.querySelector("#observe3"));
    observer.current.observe(document.querySelector("#observe4"));
    observer.current.observe(document.querySelector("#observe5"));
    observer.current.observe(document.querySelector("#observe6"));

    return () => {
      observer.current.disconnect();
    };
  }, []);
  return (
    <div className="gr-dark relative flex flex-col gap-10 pt-10 md:pt-16 lg:gap-20 lg:pt-24 2xl:pt-32">
      <div className="max-screen mx-auto flex flex-col  gap-6  ">
        <div className="px-4 lg:px-8">
          <h1
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="500"
            className="archivo mb-6 w-fit  text-start text-4xl font-bold !leading-none text-[#DDD2F2] md:text-6xl lg:mb-10 lg:text-6xl  xl:text-7xl 2xl:text-8xl 3xl:text-[130px]"
          >
            More Ways to <span className="text-gr">Earn</span>
          </h1>
          <div className="lg:flex-8 flex flex-col gap-4">
            <h2
              data-aos="fade-in"
              data-aos-duration="2000"
              data-aos-delay="1500"
              className="text-start text-lg font-bold  text-neutral-10 lg:text-3xl 3xl:text-4xl     "
            >
              Diversify earnings, maximize income, and secure success
            </h2>
            <p
              data-aos="fade-in"
              data-aos-duration="2000"
              data-aos-delay="1500"
              className="text-start text-sm font-normal  text-neutral-10 lg:text-xl 3xl:text-3xl "
            >
              Monetize your content in multiple ways, from custom buttons to post-live video ads,
              ensuring a steady income stream and financial stability as a streamer.
            </p>
          </div>
        </div>
        <div className="flex flex-col py-6 lg:py-12 ">
          {" "}
          <div
            style={{
              gridTemplateColumns: "repeat(2, 1fr) 75px repeat(2, 1fr) 75px repeat(2, 1fr)",
              gridTemplateRows: "repeat(2, 1fr) 75px repeat(2, 1fr)",
            }}
            className=" grid w-full   rounded-xl "
          >
            <div
              id="observe1"
              style={{ gridArea: " 1 / 1 / 4 / 4" }}
              className="  h-full w-full"
              data-aos="flip-left"
              data-aos-duration="1500"
              data-aos-delay="1000"
              data-aos-once="true"
            >
              <img
                src={img1a}
                alt="Target Verticals"
                className="h-full w-full rounded-lg  object-cover"
              />
            </div>
            <div
              id="observe2"
              style={{ gridArea: "1 / 3 / 4 / 7" }}
              className=" flex justify-end"
              data-aos="flip-down"
              data-aos-duration="2000"
              data-aos-delay="1500"
              data-aos-once="true"
            >
              <img
                src={img1b}
                alt="Target Verticals"
                className="h-full w-full rounded-lg object-cover"
              />
            </div>
            <div
              id="observe3"
              style={{ gridArea: " 1 / 6 / 4 / 9" }}
              className="flex justify-end"
              data-aos="flip-right"
              data-aos-duration="1500"
              data-aos-delay="1500"
            >
              <img
                src={img1c}
                alt="Target Verticals"
                className="h-full w-full rounded-lg object-cover"
              />
            </div>
            <div
              id="observe4"
              style={{ gridArea: "3 / 1 / 6 / 4" }}
              className=" flex justify-end"
              data-aos="flip-right"
              data-aos-duration="1500"
              data-aos-delay="1500"
            >
              <img
                src={img1d}
                alt="Target Verticals"
                className="h-full w-full rounded-lg object-cover"
              />
            </div>
            <div
              id="observe5"
              style={{ gridArea: "3 / 3 / 6 / 7" }}
              className=" flex justify-end"
              data-aos="flip-up"
              data-aos-duration="1500"
              data-aos-delay="1500"
            >
              <img
                src={img1e}
                alt="Target Verticals"
                className="h-full w-full rounded-lg object-cover"
              />
            </div>
            <div
              id="observe6"
              style={{ gridArea: "3 / 6 / 6 / 9" }}
              className=" flex justify-end"
              data-aos="flip-left"
              data-aos-duration="1500"
              data-aos-delay="1500"
            >
              <img
                src={img1f}
                alt="Target Verticals"
                className="h-full w-full rounded-lg object-cover"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="relative pb-32 pt-10 lg:pt-20 lg:pb-20">
        <div className=" max-screen relative mx-auto flex flex-col items-end gap-4 lg:flex-row  ">
          <div className="flex flex-col gap-4   px-4 pb-6 pt-10  text-white lg:w-1/2 lg:gap-10 lg:px-8  lg:pb-20  2xl:pb-40">
            <h1
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="500"
              className="archivo z-10 text-start text-4xl  font-bold !leading-none text-[#DDD2F2] drop-shadow-lg md:text-6xl lg:text-6xl xl:text-7xl 2xl:text-8xl 3xl:text-[130px]  "
            >
              Unleash <span className="text-gr">Creative</span> Freedom
            </h1>
            <div>
              <p
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="1000"
                className="relative z-10  p-1 text-start text-sm font-normal text-neutral-10  drop-shadow-md lg:text-xl 3xl:text-3xl "
              >
                Be bold, express yourself, and redefine your image. Break free from conventional
                boundaries, experiment with innovative content ideas, and redefine your personal
                brand in the ever-evolving world of streaming.
              </p>
            </div>
          </div>
        </div>
        <div
          className="absolute  bottom-0 right-0 h-full w-[1/2] "
          data-aos="zoom-out"
          data-aos-duration="1500"
          data-aos-delay="1000"
        >
          <img src={img2} alt="streaming" className=" h-full" />
        </div>
      </div>
      <div className=" absolute bottom-0 left-0  z-10 w-full">
        <img src={vector} alt="" className=" fire w-full " />
      </div>
    </div>
  );
};

export default Section2;
const shadow = { filter: "drop-shadow(0px -79px 88px #281C5B)" };
