import AOS from "aos";
import "aos/dist/aos.css";
import useIsDesktop from "app/hooks/useIsDesktop";
import logo from "assets/logo_sm.svg";
import { useEffect, useRef, useState } from "react";

import Contact from "./sections/Contact";
import Footer from "./sections/Footer";
import Hero from "./sections/Hero";
import Section1 from "./sections/Section1";
import Section2 from "./sections/Section2";
import Section3 from "./sections/Section3";
import Section4 from "./sections/Section4";
import Section5 from "./sections/Section5";
import SliderSection from "./sections/SliderSection";
import "./style.css";

const Advisor = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const [isScrolling, setIsScrolling] = useState(false);
  const { isDesktopScreen } = useIsDesktop();
  useEffect(() => {
    AOS.refresh();
  }, [isDesktopScreen]);
  function endScroll() {
    setIsScrolling(false);
  }
  const scrollToGetInTouch = () => {
    setIsScrolling(true);
    const a = contactRef.current;
    a.scrollIntoView({ behavior: "smooth" });
  };
  const contactRef = useRef();
  return (
    <main className=" overflow-x-hidden">
      <Hero scrollToGetInTouch={scrollToGetInTouch} />
      <SliderSection isScrolling={isScrolling} />
      <Section1 scrollToGetInTouch={scrollToGetInTouch} />
      <Section2 scrollToGetInTouch={scrollToGetInTouch} />
      <Section3 scrollToGetInTouch={scrollToGetInTouch} />
      <div className="relative">
        <Section4 scrollToGetInTouch={scrollToGetInTouch} />
        <div className="absolute top-2/3 right-0 hidden w-[200px] -translate-y-1/2 translate-x-1/3   lg:block    3xl:w-[300px]">
          <img src={logo} alt="Beladed" className="-z-10 w-full opacity-10 grayscale" />
        </div>
      </div>

      <div className="bg-black py-4">
        <Section5 scrollToGetInTouch={scrollToGetInTouch} />
        <div ref={contactRef} id="GetInTouch" onScroll={endScroll}>
          <Contact scrollToGetInTouch={scrollToGetInTouch} />
        </div>
        <Footer scrollToGetInTouch={scrollToGetInTouch} />
      </div>
    </main>
  );
};

export default Advisor;
