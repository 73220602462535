import useIsDesktop from "app/hooks/useIsDesktop";
import img1 from "assets/partner/section-3-1.jpg";
import img2 from "assets/partner/section-3-2.jpg";
import img3 from "assets/partner/section-3-3.jpg";

import "../style.css";

const BuildLastingConnections = ({ scrollToGetInTouch }) => {
  const { isDesktopScreen } = useIsDesktop();
  return (
    <div
      className="relative flex flex-col gap-10 gap-y-20 py-10 lg:gap-20 lg:py-20  xl:gap-20   [&>div]:mx-auto
    [&>div]:px-4 lg:[&>div]:px-8 xl:[&>div]:max-w-[1150px] 2xl:[&>div]:max-w-[1400px] 3xl:[&>div]:max-w-screen-2xl "
    >
      {/* 1 */}
      <div className=" flex flex-col gap-8  lg:flex-row xl:mt-28 2xl:gap-10 3xl:gap-16">
        <div className="flex flex-col gap-4 xl:gap-6 2xl:gap-8 [&>*]:w-fit ">
          <div className="flex flex-col gap-4 xl:gap-6 [&>*]:w-fit">
            <div
              data-aos="fade-down"
              data-aos-duration="1000"
              data-aos-delay="1000"
              className="rounded-full bg-neutral-11 py-2 px-5"
            >
              <p className="text-gr     text-center text-sm tracking-[2.5px] xl:text-lg 3xl:text-xl">
                Global Fan Connection
              </p>
            </div>
            <h2
              data-aos="fade-down"
              data-aos-duration="1500"
              data-aos-delay="1000"
              className="text-3xl font-bold xl:text-4xl 2xl:text-5xl 3xl:text-6xl"
            >
              Build Lasting Connections and Make a Meaningful Impact
            </h2>
          </div>
          <p
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="1000"
            className="text-sm text-neutral-4 xl:text-base 2xl:text-lg 3xl:text-xl"
          >
            Expand your reach and connect with a diverse audience through Beladed's Fan Connection.
            Collaborate with global partners and build lasting connections with fans worldwide,
            making a meaningful impact on their lives. With advanced technology and expertise,
            Beladed's Fan Connection helps you connect with fans like never before, expanding your
            reach and unlocking new opportunities for growth. Sign up today to start building your
            global network of supporters.
          </p>
          <button
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="1000"
            onClick={scrollToGetInTouch}
            className="btn-gr btn rounded-full lg:py-3 lg:px-6 xl:py-4 xl:px-10"
          >
            Get In Touch
          </button>
        </div>
        <div className="relative mx-auto flex  w-[90%] flex-shrink-0 justify-center lg:w-1/2">
          <img
            src={img1}
            data-aos="fade-right"
            data-aos-duration="1500"
            data-aos-delay={isDesktopScreen ? "2500" : "1500"}
            alt="Strategize and Innovate with Beladed  "
            className="aspect-square h-fit w-[100%] max-w-[600px] object-cover lg:w-[90%] xl:w-[80%]"
          />
          <div className="gradient-1 absolute inset-0 top-4 left-4 -z-10 mx-auto aspect-square max-w-[600px]  translate-x-2 translate-y-2  lg:w-[90%] xl:w-[80%] ">
            <p
              data-aos="fade-left"
              data-aos-duration="1500"
              data-aos-delay={isDesktopScreen ? "2500" : "1500"}
              className=" vertical-text absolute -right-6 bottom-2 text-sm uppercase tracking-[5px] lg:text-base"
            >
              Impact
            </p>
          </div>
        </div>
      </div>
      {/* 2 */}
      <div
        className="half-grey flex !w-full !max-w-full flex-col gap-10 pt-10 md:gap-16  lg:pt-20  [&>div]:mx-auto
     lg:[&>div]:px-8 xl:[&>div]:max-w-[1150px] 2xl:[&>div]:max-w-[1400px] 3xl:[&>div]:max-w-screen-2xl"
      >
        <div className="">
          {" "}
          <div className="relative mx-auto flex flex-col gap-8 lg:left-4  lg:flex-row-reverse  xl:max-w-[1150px]  xl:gap-20 2xl:max-w-[1400px] 2xl:gap-10   3xl:max-w-screen-2xl 3xl:gap-16">
            <div className="flex flex-col gap-4 xl:gap-6 2xl:gap-8 [&>*]:w-fit">
              <div className="flex  flex-col gap-4 xl:gap-6 [&>*]:w-fit">
                <div
                  data-aos="fade-down"
                  data-aos-duration="1000"
                  data-aos-delay="1000"
                  className="rounded-full bg-neutral-12 py-2 px-5"
                >
                  <p className="text-gr     text-center text-sm tracking-[2.5px] xl:text-lg 3xl:text-xl">
                    Customized Advertising Impact
                  </p>
                </div>
                <h2
                  data-aos="fade-down"
                  data-aos-duration="1500"
                  data-aos-delay="1000"
                  className="text-3xl font-bold xl:text-4xl 2xl:text-5xl 3xl:text-6xl"
                >
                  Create Personalized Ad Campaigns and Boost Your Earning Potential
                </h2>
              </div>
              <p
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="1000"
                className=" text-sm text-neutral-4 xl:text-base  2xl:text-lg 3xl:text-xl"
              >
                Beladed is a powerful platform that helps businesses to boost their earning
                potential by creating personalized ad campaigns that captivate audience attention
                and integrate seamlessly with their content.
              </p>
              <button
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="1000"
                onClick={scrollToGetInTouch}
                className="btn-gr btn rounded-full lg:py-3 lg:px-6 xl:py-4 xl:px-10"
              >
                Get In Touch
              </button>
            </div>
            <div className="relative mx-auto flex    w-[90%] flex-shrink-0 justify-center lg:w-1/2 lg:justify-start">
              <img
                src={img2}
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-delay={isDesktopScreen ? "3000" : "1500"}
                alt="Strategize and Innovate with Beladed"
                className="z-20 aspect-square w-[100%] max-w-[600px] object-cover lg:h-fit lg:w-[90%] xl:w-[80%]"
              />
              <div className="gradient-1 absolute inset-0 top-3 -right-0 -z-0 mx-auto aspect-square max-w-[600px] -translate-x-3 object-cover lg:-right-4 lg:top-4 lg:mx-0 lg:w-[90%] lg:-translate-x-5 xl:w-[80%] ">
                <p
                  data-aos="fade-right"
                  data-aos-duration="1500"
                  data-aos-delay={isDesktopScreen ? "3000" : "1500"}
                  className="vertical-text absolute -left-5 bottom-2 text-sm uppercase tracking-[5px] sm:-left-6 lg:text-base"
                >
                  Potential
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col   gap-6 2xl:gap-10">
          <div className="relative w-full">
            <img
              src={img3}
              alt="Strategize and Innovate with Beladed  "
              className="w-full object-cover"
            />
          </div>
          <div className="flex flex-col gap-4 lg:flex-row lg:items-end xl:gap-6 2xl:gap-8 [&>*]:w-fit">
            <div className="flex flex-col gap-4 xl:gap-6 [&>*]:w-fit">
              <div
                data-aos="fade-down"
                data-aos-duration="1000"
                data-aos-delay="1000"
                className="rounded-full bg-neutral-11 py-2 px-5"
              >
                <p className="text-gr     text-center text-sm tracking-[2.5px] xl:text-lg 3xl:text-xl">
                  Collaborate, Expand, and Grow
                </p>
              </div>
              <h2
                data-aos="fade-down"
                data-aos-duration="1000"
                data-aos-delay="1000"
                className="text-3xl font-bold xl:text-4xl 2xl:text-5xl 3xl:text-6xl"
              >
                Beladed's Exclusive Partner Perks
              </h2>
            </div>
            <p
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="1000"
              className="text-sm text-neutral-4 xl:text-base 2xl:text-lg 3xl:text-xl"
            >
              Join Beladed's Partnership Program and gain access to exclusive benefits including
              priority access to features, dedicated support, and promo opportunities. Collaborate
              with like-minded influencers and work on exciting projects to expand your network,
              grow your influence, and build your brand presence. Together with Beladed, you'll have
              the opportunity to take your influence to the next level.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildLastingConnections;
