import bush from "assets/bush-right.png";
import clouds from "assets/clouds.png";
import bg from "assets/footer-bg2.png";
import logo from "assets/logo.svg";
import palm from "assets/palm-tree-right.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer
      className=" relative z-10 flex aspect-[3/2] items-end justify-center overflow-hidden bg-cover bg-no-repeat   p-4 
      text-white sm:justify-start xl:gap-20 "
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "",
      }}
    >
      {/* <img src={bg} alt="" className="inset-0 object-cover w-full h-full" /> */}
      <div className="bush absolute -right-[2%] -bottom-[2%]">
        <img src={bush} alt="bush" className="w-[20vw]" />
      </div>
      <div className="palm-tree absolute -left-[5%]  top-[10%]  ">
        <img src={palm} alt="palm-tree" className="w-[30vw] " />
      </div>
      <div className="clouds absolute left-1/2  top-[10%] -z-10 ">
        <img src={clouds} alt="clouds" className="w-[80vw] " />
      </div>
      <div className="flex flex-col items-center ">
        <div className="hidden">
          <img src={logo} alt="Beladed" />
        </div>
        <div className="reality mt-4 select-none">
          <div className="perspective-text">
            <div className="perspective-line">
              <p></p>
              <p>Beladed</p>
            </div>
            <div className="perspective-line">
              <p>Beladed</p>
              <p>Interactive</p>
            </div>
            <div className="perspective-line">
              <p>Interactive</p>
              <p>Livestream</p>
            </div>
            <div className="perspective-line">
              <p>Livestream</p>
              <p>Entertainment</p>
            </div>
            <div className="perspective-line">
              <p>Entertainment</p>
              <p></p>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div>
            <p className="text-center text-xs font-semibold text-neutral-1 sm:text-base">
              © 2023 Beladed Inc. All rights reserved.
            </p>
          </div>
          <div className="flex w-full justify-center gap-3 text-2xs font-semibold text-neutral-1 underline sm:gap-6 sm:text-sm lg:gap-8 lg:text-base">
            <Link to="/about/terms" className="hover:text-secondary-4">
              Terms of Use
            </Link>
            <Link to="/about/privacy" className="hover:text-secondary-4">
              Privacy Policy
            </Link>
            <Link to="/about/california-privacy-policy" className="hover:text-secondary-4">
              California Consumer
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
