import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import SimpleInputForm from "app/components/Inputs/SimpleInputForm";
import SimpleSelectForm from "app/components/Inputs/SimpleSelectForm";
import LeavingModal from "app/components/Modals/LeavingModal";
import LeavingModalUser from "app/components/Modals/LeavingModalUser";
import logoD from "assets/logo-d.svg";
import logo from "assets/logo.svg";
import { useEffect, useRef, useState } from "react";
import { CountryRegionData } from "react-country-region-selector";
import ReactPlayer from "react-player";
import { Link, useLocation, useNavigate } from "react-router-dom";

const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  games: "",
  shows: "",
  how_referred: "",
  who_referred: "",
  username: "",
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};
export const Join = () => {
  const [countryIndex, setCountryIndex] = useState(null);
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [activities, setActivities] = useState([]);
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    country: country,
    region: region,
    city: "",
    instagram: "",
    tiktok: "",
    agency: "",
    experience_years: "",
    travel: "",
    second_job: "",
    earning: "",
    desired_activities: activities,
    livestream_schedule: "",
    how_referred: "",
  });
  const handleValuesChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const [isPlaying, setIsPlaying] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [leavingModal, setLeavingModal] = useState(false);

  const { pathname } = useLocation();

  const pushHistory = () => {
    window.history.pushState(null, document.title, window.location.href);
    setLeavingModal(true);

    window.location.hash = "1";

    window.location.hash = "1";
  };

  const popstate = () => {
    window.history.pushState(null, null, document.URL);
    setLeavingModal(true);
  };
  const navigate = useNavigate();

  const allow = localStorage.getItem("allowGoBack");

  useEffect(() => {
    if (allow === "0") {
      window.history.pushState(null, null, document.URL);
      window.addEventListener("popstate", popstate);
    } else {
      window.removeEventListener("popstate", popstate);
    }
  }, []);
  const handleBeforeUnload = (e) => {
    e.preventDefault();
    setLeavingModal(true);
    e.returnValue = "";
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleStay = () => {
    window.removeEventListener("popstate", popstate);
    setLeavingModal(false);
  };

  const handleLeave = () => {
    setLeavingModal(false);
    window.removeEventListener("beforeunload", handleBeforeUnload);
    navigate("/waitlist");
  };
  const [step, setStep] = useState(1);
  const handleSteps = () => {
    switch (step) {
      case 1:
        return (
          <SignUpStep1 values={values} handleValuesChange={handleValuesChange} setStep={setStep} />
        );
      case 2:
        return (
          <SignUpStep2
            values={values}
            handleValuesChange={handleValuesChange}
            setStep={setStep}
            handleCountryChange={handleCountryChange}
            setCountryIndex={setCountryIndex}
            country={country}
            regions={regions}
            handleRegionChange={handleRegionChange}
          />
        );
      case 3:
        return (
          <SignUpStep3 values={values} handleValuesChange={handleValuesChange} setStep={setStep} />
        );
      case 4:
        return (
          <SignUpStep4
            values={values}
            handleValuesChange={handleValuesChange}
            setStep={setStep}
            activities={activities}
            handleMultipleSelectChange={handleMultipleSelectChange}
          />
        );
      case 5:
        return (
          <SignUpStep5
            values={values}
            handleValuesChange={handleValuesChange}
            setStep={setStep}
            handleContinue={handleContinue}
          />
        );
      default:
        break;
    }
  };
  const handleText = () => {
    switch (step) {
      case 1:
        return "Off to a great start - keep going!😍";
      case 2:
        return "Congratulations! 🥳 Keep up the momentum.";
      case 3:
        return "High-five! 👋🏻 On to the next step.";
      case 4:
        return "Awesome, one step closer. 🏄🏼‍♀️";
      case 5:
        return "Success!💥 You finished the task.";
      default:
        break;
    }
  };
  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(0);
  useEffect(() => {
    switch (step) {
      case 1:
        setProgress(0);
        setBuffer(25);
        break;
      case 2:
        setProgress(25);
        setBuffer(50);
        break;
      case 3:
        setProgress(50);
        setBuffer(75);
        break;
      case 4:
        setProgress(75);
        setBuffer(100);
        break;
      case 5:
        setProgress(100);
        setBuffer(100);
        break;
      default:
        break;
    }
  }, [step]);

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    setValues({ ...values, country: e.target.value[0] });
  };
  const handleRegionChange = (e) => {
    setValues({ ...values, region: e.target.value });
  };

  const [regions, setRegions] = useState([]);
  useEffect(() => {
    if (countryIndex !== null) {
      setRegions(CountryRegionData[countryIndex][2].split("|"));
      // console.log(CountryRegionData[1]);
    }
  }, [countryIndex]);

  useEffect(() => {
    setValues({ ...values, region: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);
  useEffect(() => {
    setValues({ ...values, desired_activities: activities });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activities]);
  const handleMultipleSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    setActivities(typeof value === "string" ? value.split(",") : value);
  };

  const handleContinue = () => {
    navigate("/waitlist/win-25k");
  };

  return (
    <main className=" container-fluid">
      <LeavingModalUser state={leavingModal} toggle={handleStay} leave={handleLeave} />

      <div className="row min-h-screen text-neutral-1">
        <div className="col-12 col-lg-6 col-2xl-6 flex-c gr-dark z-10 hidden flex-col px-4 py-12 lg:flex lg:min-h-screen lg:items-start lg:py-2 lg:px-8 xl:py-0">
          <div className="mb-4 lg:mb-6 ">
            <Link to="/waitlist/dashboard">
              <img src={logo} alt="logo" className=" w-44 md:w-auto" />
            </Link>
          </div>
          <div className="z-10 w-full rounded-lg lg:flex">
            <div
              className="video-background-container flex-c z-10 aspect-video w-full"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <div className="video-background ">
                <ReactPlayer
                  url="https://player.vimeo.com/video/667528642?h=01bb3a9b98&autoplay=1&title=0&byline=0&portrait=0"
                  playing={isPlaying}
                  playsinline
                  muted={false}
                  width="100%"
                  height="100%"
                  controls={false}
                  style={{
                    zIndex: "-1",
                    borderRadius: "8px",
                    overflow: "hidden",
                    position: "absolute",
                  }}
                  autoPlay={false}
                />
              </div>

              <button onClick={() => setIsPlaying(!isPlaying)} className="abs-c z-50">
                {isPlaying ? (
                  <div
                    className={`flex-c h-16 w-16 rounded-full bg-white transition-all duration-500  ${
                      isHovered ? "opacity-100" : "opacity-0"
                    } `}
                  >
                    <PauseIcon sx={{ color: "red", fontSize: "2rem" }} />
                  </div>
                ) : (
                  <div className="circle flex-c relative h-16 w-16 rounded-full bg-white !text-red-600">
                    <PlayArrowIcon sx={{ color: "red !important", fontSize: "2rem" }} />
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 col-2xl-6 flex h-full items-center bg-white px-4 py-8 lg:min-h-screen lg:py-10 lg:px-12 xl:px-20 2xl:py-10 ">
          <div className="row mx-auto max-w-lg gap-y-6 lg:mx-0">
            <div className="flex-c mb-4 lg:mb-6 lg:hidden ">
              <img src={logoD} alt="logo" className="w-64" />
            </div>
            <div className="flex flex-col gap-3">
              <div className="relative mb-4 flex w-full flex-col items-center gap-8 px-4">
                <Box sx={{ width: "100%" }} className="absolute">
                  <LinearProgress
                    value={progress}
                    variant="determinate"
                    className="!w-100 !bg-[#F7F6F9]"
                    color="progress"
                    sx={{ height: "23px", borderRadius: "22px" }}
                  />
                </Box>
                <Box sx={{ width: "100%" }} className="absolute !w-full">
                  <LinearProgress
                    value={buffer}
                    variant="determinate"
                    className="!w-100 !bg-[#F7F6F9]"
                    color="progress"
                    sx={{ height: "23px", borderRadius: "22px", opacity: 0.3 }}
                  />
                </Box>
              </div>
              <div>
                <p className="text-lg font-medium text-neutral-4">{handleText()}</p>
              </div>
            </div>
            {handleSteps()}
          </div>
        </div>
      </div>
    </main>
  );
};

export default Join;
const referOptions = [
  "Instagram",
  "Tiktok",
  "Twitter",
  "SnapChat",
  "OnlyFans",
  "Email",
  "Text Message",
  "Other Website",
];

const showsOptions = [
  "Party Games",
  "Fitness Workouts",
  "Outdoor Sports",
  "Cooking Shows",
  "Dress Up Shows",
  "Art Shows",
  "Music Shows",
  "Dancing Shows",
  "Talent Shows",
  "Fashion Shows",
  "Q&A Session",
  "Comedy shows",
];

const SignUpStep1 = ({ handleValuesChange, setStep, values }) => {
  return (
    <div className="flex w-full flex-col gap-8">
      <div className="flex flex-col gap-2">
        <h1 className="text-3xl font-bold text-neutral-1">Join The Waiting List</h1>
        <p className="text-lg font-medium">Don't miss out on exclusive earning potential.</p>
      </div>
      <form onSubmit={() => setStep(2)} className="flex flex-col gap-8">
        <div className="flex w-full flex-col gap-4 sm:gap-4">
          <h2 className="text-2xl font-semibold"> Personal Information</h2>
          <div className="col-12 col-lg-6 w-full">
            <SimpleInputForm
              value={values.first_name}
              name="first_name"
              label="First Name"
              placeholder={"Enter your first name"}
              onChange={handleValuesChange}
            />
          </div>
          <div className="col-12 col-lg-6 w-full">
            <SimpleInputForm
              value={values.last_name}
              name="last_name"
              label="Last Name"
              placeholder={"Enter your last name"}
              onChange={handleValuesChange}
            />
          </div>
          <div className="col-12 col-lg-6 w-full">
            <SimpleInputForm
              value={values.email}
              name="email"
              label="Email"
              placeholder={"Enter your email address"}
              type="email"
              onChange={handleValuesChange}
            />
          </div>{" "}
          <div className="col-12 col-lg-6 w-full">
            <SimpleInputForm
              value={values.phone}
              name="phone"
              label="Phone Number"
              placeholder={"Enter your phone number"}
              type="tel"
              onChange={handleValuesChange}
              hasPattern={true}
              pattern="[0-9]*"
            />
            <span className="text-2xs">Phone number is used to verify your device.</span>
          </div>
        </div>
        <div>
          <p className="text-sm leading-6 text-neutral-4 underline-offset-4">
            By submitting this form you agree to the{" "}
            <Link to="/about/terms" className=" text-neutral-1 hover:text-link">
              Terms and Conditions
            </Link>
            ,{" "}
            <Link to="/about/privacy" className=" text-neutral-1 hover:text-link">
              Privacy
            </Link>{" "}
            &{" "}
            <Link to="/about/cookies" className=" text-neutral-1 hover:text-link">
              Cookies Policy{" "}
            </Link>{" "}
            and certify that you are 18 years of age or older.
          </p>
        </div>
        <div className="mt-2 flex flex-col flex-wrap items-start justify-center gap-4 lg:justify-start">
          <button
            // onClick={() => setStep(2)}
            type="submit"
            className="btn btn-main btn-large text-base"
            to={"congrats"}
          >
            Next
          </button>
          <Link
            className="text-base font-semibold text-secondary-5 "
            to={"/waitlist/already-joined"}
          >
            Already Joined?
          </Link>
        </div>
      </form>
    </div>
  );
};

const SignUpStep2 = ({
  handleValuesChange,
  setStep,
  handleCountryChange,
  setCountryIndex,
  country,
  regions,
  values,
  handleRegionChange,
}) => {
  return (
    <form onSubmit={() => setStep(3)} className="flex w-full flex-col gap-8">
      <div className="flex w-full flex-col gap-4 sm:gap-4">
        <h2 className="text-2xl font-semibold"> Extended Personal Information</h2>
        <div className="col-12 col-lg-6 w-full">
          <FormControl className="w-full">
            <InputLabel id="select-category">Country</InputLabel>
            <Select
              sx={{ width: "100%" }}
              id="country"
              label="Country"
              value={country}
              select="true"
              required
              onChange={(e) => handleCountryChange(e)}
            >
              {CountryRegionData.map((option, index) => (
                <MenuItem key={index} value={option} onClick={() => setCountryIndex(index)}>
                  {option[0]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="col-12 col-lg-6 w-full">
          <FormControl className="w-full">
            <InputLabel id="select-category">State</InputLabel>
            <Select
              sx={{ width: "100%" }}
              id="state"
              label="State"
              value={values.region}
              select="true"
              required
              onChange={(e) => handleRegionChange(e)}
            >
              {regions.map((option, index) => (
                <MenuItem key={index} value={option.split("~")[0]}>
                  {option.split("~")[0]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="col-12 col-lg-6 w-full">
          <SimpleInputForm
            value={values.city}
            name="city"
            label="City"
            placeholder={"Enter your city"}
            onChange={handleValuesChange}
          />
        </div>
      </div>
      <div className="flex w-full flex-col gap-4 sm:gap-4">
        <h2 className="text-2xl font-semibold"> Social Media Information</h2>
        <div className="col-12 col-lg-6 w-full">
          <SimpleInputForm
            value={values.instagram}
            placeholder={"username"}
            name="instagram"
            onChange={handleValuesChange}
            label={"Instagram"}
            hasIcon={true}
            icon={<AlternateEmailIcon sx={{ fontSize: "0.85rem", margin: "0" }} />}
          />
        </div>
        <div className="col-12 col-lg-6 w-full">
          <SimpleInputForm
            value={values.tiktok}
            placeholder={"username"}
            name="tiktok"
            onChange={handleValuesChange}
            label={"Tiktok"}
            hasIcon={true}
            icon={<AlternateEmailIcon sx={{ fontSize: "0.85rem", margin: "0" }} />}
          />
        </div>
      </div>
      <div>
        <p className="text-sm leading-6 text-neutral-4 underline-offset-4">
          By submitting this form you agree to the{" "}
          <Link to="/about/terms" className=" text-neutral-1 hover:text-link">
            Terms and Conditions
          </Link>
          ,{" "}
          <Link to="/about/privacy" className=" text-neutral-1 hover:text-link">
            Privacy
          </Link>{" "}
          &{" "}
          <Link to="/about/cookies" className=" text-neutral-1 hover:text-link">
            Cookies Policy{" "}
          </Link>{" "}
          and certify that you are 18 years of age or older.
        </p>
      </div>
      <div className="mt-2 flex flex-col flex-wrap items-start justify-center gap-4 lg:justify-start">
        <div className="flex flex-wrap gap-4 ">
          <button onClick={() => setStep(1)} className="btn btn-large btn-second-grey text-base ">
            Previous
          </button>
          <button
            // onClick={() => setStep(3)}
            type="submit"
            className="btn btn-main btn-large text-base"
          >
            Next
          </button>
        </div>
        <Link className="text-base font-semibold text-secondary-5 " to={"/waitlist/already-joined"}>
          Already Joined?
        </Link>
      </div>
    </form>
  );
};

const SignUpStep3 = ({ handleValuesChange, setStep, values }) => {
  return (
    <form onSubmit={() => setStep(4)} className="flex w-full flex-col gap-8">
      <div className="flex w-full flex-col gap-4 sm:gap-4">
        <h2 className="text-2xl font-semibold"> Previous Modelling Experience.</h2>
        <div className="flex gap-3">
          <div className="col-8 flex-grow">
            <SimpleInputForm
              value={values.agency}
              name="agency"
              label="Name Of Agency You Work For"
              placeholder={"No Agency"}
              onChange={handleValuesChange}
            />
          </div>
          <div className="col-4 flex-grow">
            <SimpleInputForm
              value={values.experience_years}
              name="experience_years"
              label="Years of Experience"
              placeholder={"12"}
              onChange={handleValuesChange}
              type="number"
            />
          </div>
        </div>
      </div>
      <div className="flex w-full flex-col gap-4 sm:gap-4">
        <h2 className="text-2xl font-semibold"> Personal Lifestyle.</h2>
        <div className="col-12 col-lg-6 w-full">
          <SimpleSelectForm
            value={values.travel}
            name="travel"
            label="How Often Do You Travel?"
            options={travelingOptions}
            placeholder={"Select an option"}
            onChange={handleValuesChange}
          />
        </div>
        <div className="col-12 col-lg-6 w-full">
          <SimpleInputForm
            value={values.second_job}
            name="second_job"
            label="What Is Your Second Job?"
            placeholder={"Enter Your Second Job"}
            type="text"
            onChange={handleValuesChange}
          />
        </div>
      </div>
      <div>
        <p className="text-sm leading-6 text-neutral-4 underline-offset-4">
          By submitting this form you agree to the{" "}
          <Link to="/about/terms" className=" text-neutral-1 hover:text-link">
            Terms and Conditions
          </Link>
          ,{" "}
          <Link to="/about/privacy" className=" text-neutral-1 hover:text-link">
            Privacy
          </Link>{" "}
          &{" "}
          <Link to="/about/cookies" className=" text-neutral-1 hover:text-link">
            Cookies Policy{" "}
          </Link>{" "}
          and certify that you are 18 years of age or older.
        </p>
      </div>
      <div className="mt-2 flex flex-col flex-wrap items-start justify-center gap-4 lg:justify-start">
        <div className="flex flex-wrap gap-4 ">
          <button onClick={() => setStep(2)} className="btn btn-large btn-second-grey text-base ">
            Previous
          </button>
          <button type="submit" className="btn btn-main btn-large text-base">
            Next
          </button>
        </div>
        <Link className="text-base font-semibold text-secondary-5 " to={"/waitlist/already-joined"}>
          Already Joined?
        </Link>
      </div>
    </form>
  );
};
const SignUpStep4 = ({
  handleValuesChange,
  setStep,
  activities,
  handleMultipleSelectChange,
  values,
}) => {
  const handleSubmit = () => {
    setStep(5);
    console.log("JoinModelValues", values);
  };
  return (
    <form onSubmit={handleSubmit} className="flex w-full flex-col gap-8">
      <div className="flex w-full flex-col gap-4 sm:gap-4">
        <h2 className="text-2xl font-semibold"> Model Live-streaming Question</h2>
        <div className="col-12 col-lg-6 w-full">
          <SimpleSelectForm
            value={values.earning}
            name="earning"
            label="How Much Do You Want To Earn?"
            options={earningOptions}
            placeholder={"Select an option"}
            onChange={handleValuesChange}
          />
        </div>
        <div className="col-12 col-lg-6 custom-scrollbar w-full">
          <FormControl className="custom-scrollbar w-full">
            <InputLabel>What Activities Would You Like To Do?</InputLabel>
            <Select
              multiple
              value={activities}
              onChange={handleMultipleSelectChange}
              required
              className="custom-scrollbar"
              input={
                <OutlinedInput
                  label="What Activities Would You Like To Do?
          "
                  className="custom-scrollbar"
                />
              }
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {activitiesOptions.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={activities.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="col-12 col-lg-6 w-full">
          <SimpleSelectForm
            value={values.livestream_schedule}
            name="livestream_schedule"
            label="How Often Do You Livestream?"
            options={liveStreamOptions}
            placeholder={"Select an option"}
            onChange={handleValuesChange}
          />
        </div>{" "}
        <div className="col-12 col-lg-6 w-full">
          <SimpleSelectForm
            value={values.how_referred}
            name="how_referred"
            label="How Were You Referred To Us?"
            options={referOptions}
            placeholder={"Select an option"}
            onChange={handleValuesChange}
          />
        </div>
      </div>
      <div>
        <p className="text-sm leading-6 text-neutral-4 underline-offset-4">
          By submitting this form you agree to the{" "}
          <Link to="/about/terms" className=" text-neutral-1 hover:text-link">
            Terms and Conditions
          </Link>
          ,{" "}
          <Link to="/about/privacy" className=" text-neutral-1 hover:text-link">
            Privacy
          </Link>{" "}
          &{" "}
          <Link to="/about/cookies" className=" text-neutral-1 hover:text-link">
            Cookies Policy{" "}
          </Link>{" "}
          and certify that you are 18 years of age or older.
        </p>
      </div>
      <div className="mt-2 flex flex-col flex-wrap items-start justify-center gap-4 lg:justify-start">
        <div className="flex flex-wrap gap-4 ">
          <button onClick={() => setStep(3)} className="btn btn-large btn-second-grey text-base ">
            Previous
          </button>
          <button type="submit" className="btn btn-main btn-large text-base">
            Join Wait List
          </button>
        </div>
        <Link className="text-base font-semibold text-secondary-5 " to={"/waitlist/already-joined"}>
          Already Joined?
        </Link>
      </div>
    </form>
  );
};
const SignUpStep5 = ({ handleValuesChange, setStep, handleContinue }) => {
  return (
    <div className="flex w-full flex-col gap-10">
      <div className="mt-2 flex flex-col flex-wrap items-start justify-center gap-8 lg:justify-start">
        <div className="flex flex-col gap-2">
          <h2 className="text-2xl font-semibold">Congratulations! 🥳</h2>
          <p>Something amazing is coming your way!</p>
        </div>

        <div className="flex flex-col gap-4">
          <button onClick={() => handleContinue()} className="btn btn-main btn-large text-base">
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};
const activitiesOptions = [
  "Party Games",
  "Fitness Workouts",
  "Outdoor Sports",
  "Cooking Shows",
  "Dress Up Shows",
  "Art Shows",
  "Music Shows",
  "Dancing Shows",
  "Talent Shows",
  "Fashion Shows",
  "Q&A Session",
  "Comedy Shows",
  "Podcast Shows",
  "Product Reviews",
];
const earningOptions = [
  "$1,000 Daily",
  "$500 Daily",
  "$100 Daily",
  "$2,000 Monthly",
  "$5,000 Monthly",
  "$10,000 Monthly",
  "I Don’t Know Yet",
];

const travelingOptions = [
  "Every Week",
  "Twice A Week",
  "Every Month",
  "Twice A Month",
  "Every Other Month",
  "When Ever Needed",
  "Not Traveling Now",
];
const liveStreamOptions = [
  "Once A Day",
  "Twice A Day",
  "Once A Week",
  "Twice A Week",
  "Once A Month",
  "Twice A Month",
  "Have Not Streamed",
];
