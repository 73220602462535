import img1 from "assets/brand/slider-1.jpg";
import img2a from "assets/brand/slider-2-1.jpg";
import img2b from "assets/brand/slider-2-2.jpg";
import img2c from "assets/brand/slider-2-3.jpg";
import img3 from "assets/brand/slider-3.jpg";
import img4a from "assets/brand/slider-4-1.jpg";
import img4b from "assets/brand/slider-4-2.jpg";
import img4c from "assets/brand/slider-4-3.jpg";
import img5 from "assets/brand/slider-5.jpg";
import img6a from "assets/brand/slider-6-1.jpg";
import img6b from "assets/brand/slider-6-2.jpg";
import { useEffect, useRef, useState } from "react";

import "../style.css";

const SliderSection = ({ isScrolling }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const containerRef = useRef(null);

  const handleMouseOver = () => {
    containerRef.current.style.animationPlayState = "paused";
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    e.preventDefault();
    if (!isDragging) return;
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    containerRef.current.scrollLeft = scrollLeft - walk;
  };
  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartX(e.touches[0].pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  const handleTouchMove = (e) => {
    e.preventDefault();
    if (!isDragging) return;
    const x = e.touches[0].pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  useEffect(() => {
    const container = containerRef.current;

    let intervalId;

    const moveSlider = () => {
      if (Math.round(container.scrollLeft) < container.scrollWidth - container.offsetWidth) {
        container.scroll({
          left: container.scrollLeft + 300,
          behavior: "smooth",
        });
        // console.log(container.scrollLeft);
        // console.log(container.scrollWidth - container.offsetWidth);
      } else {
        container.scrollTo({
          left: 0,
          behavior: "smooth",
        });
      }
    };

    const startSlider = () => {
      intervalId = setInterval(moveSlider, 2000);
    };

    const stopSlider = () => {
      clearInterval(intervalId);
    };

    startSlider();
    if (!isScrolling) {
      container.addEventListener("mouseenter", stopSlider);
      container.addEventListener("mouseleave", startSlider);
    }

    return () => {
      container.removeEventListener("mouseenter", stopSlider);
      container.removeEventListener("mouseleave", startSlider);
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div
      onMouseOver={handleMouseOver}
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      ref={containerRef}
      className="custom-scrollbar slider-animate-infinite-hr-parent cursor-grab scroll-smooth"
    >
      {" "}
      <div className=" slider-animate-infinite-hr custom-scrollbar flex justify-between  gap-2 pb-4 sm:gap-4 [&>div]:flex-shrink-0">
        <SliderSimpleCard img={img1} text="Captivate Audiences Across Industries" />
        <SliderComplexCard
          img={[img2a, img2b, img2c]}
          text={[
            "Build Lasting Connections with Exclusive Branded Teams on Beladed",
            "Target Your Ideal Audience with Customizable Ads on Beladed",
            "Leverage NFTs to Enhance Your Brand's Value on Beladed",
          ]}
        />
        <SliderSimpleCard
          img={img3}
          text="Expand Your Brand's Presence with Metaverse Integration on Beladed"
        />
        <SliderComplexCard
          img={[img4a, img4b, img4c]}
          text={[
            "Optimize Your Campaigns Strategically with AI-Powered Insights on Beladed",
            "Tap into Crypto Benefits on Beladed for Tech-Savvy Users.",
            "Connect with Fans Worldwide on Beladed's Global Platform",
          ]}
        />
        <SliderSimpleCard
          img={img5}
          text="Unleash Your Organization's Potential with Beladed's Innovative Suite of Features"
        />
        <SliderComplexCard
          hidden={true}
          img={[img6a, img6b]}
          text={[
            "Nurture and Expand Your Audience with Beladed's Community Growth Tools",
            "Harness Analytics for Strategic Growth with Beladed's Data-Driven Insights.",
          ]}
        />
      </div>
    </div>
  );
};

export default SliderSection;

const SliderSimpleCard = ({ img, text }) => {
  return (
    <div
      style={{ "--image-url": `url(${img})` }}
      className={`relative z-0 flex aspect-square w-[min(450px,100%)] items-end justify-start bg-[image:var(--image-url)]
       bg-cover bg-no-repeat md:aspect-[7/8] lg:w-[min(500px,100%)]  2xl:w-[min(600px,100%)] 3xl:w-[min(700px,100%)] `}
    >
      <p className="z-10 p-4 text-[2em]   font-bold text-white sm:p-6 md:text-[3em]">{text}</p>
      <div className="bg-shadow absolute bottom-0 h-1/2 w-full"></div>
    </div>
  );
};

const SliderComplexCard = ({ img, text, hidden = false }) => {
  return (
    <div className="flex aspect-square w-[min(450px,100%)] flex-col gap-2 sm:gap-4 md:aspect-[7/8] lg:w-[min(500px,100%)]  2xl:w-[min(600px,100%)] 3xl:w-[min(700px,100%)]">
      <div
        style={{ "--image-url": `url(${img[0]})` }}
        className={`relative flex w-full  flex-grow items-end justify-start
       bg-[image:var(--image-url)] bg-cover bg-no-repeat  `}
      >
        <p className="z-10 p-4 text-[1.2em] font-bold text-white sm:p-6 md:text-[1.5em] ">
          {text[0]}
        </p>
        <div className="bg-shadow absolute bottom-0 h-1/2 w-full"></div>
      </div>
      <div className="flex h-1/2 w-full gap-4">
        <div
          style={{ "--image-url": `url(${img[1]})` }}
          className={`relative flex flex-grow items-end justify-start
       bg-[image:var(--image-url)] bg-cover bg-no-repeat  `}
        >
          <p className="z-10  p-4 text-[1.2em]  font-bold text-white sm:p-6 md:text-[1.5em] ">
            {text[1]}
          </p>
          <div className="bg-shadow absolute bottom-0 h-full w-full md:h-1/2"></div>
        </div>
        {!hidden && (
          <div
            style={{ "--image-url": `url(${img[2]})` }}
            className={`relative flex  flex-grow items-end justify-start
       bg-[image:var(--image-url)] bg-cover bg-no-repeat `}
          >
            <p className="z-10 p-4 text-[1.2em]  font-bold text-white sm:p-6 md:text-[1.5em]">
              {text[2]}
            </p>
            <div className="bg-shadow absolute bottom-0 h-full w-full md:h-1/2"></div>
          </div>
        )}
      </div>
    </div>
  );
};
