import useIsDesktop from "app/hooks/useIsDesktop";
import img1 from "assets/advisor/section-3-1.jpg";
import img2 from "assets/advisor/section-3-2.jpg";
import img3 from "assets/advisor/section-3-3.jpg";
import img4 from "assets/advisor/section-3-4.jpg";
import img5 from "assets/advisor/section-3-5.jpg";

import "../style.css";

const IndustryPresence = ({ scrollToGetInTouch }) => {
  const { isDesktopScreen } = useIsDesktop();
  return (
    <div
      className="relative flex flex-col gap-10 gap-y-20 py-10 lg:gap-20 lg:py-20 xl:gap-20 2xl:py-32  [&>div]:mx-auto
    [&>div]:px-4 lg:[&>div]:px-8 xl:[&>div]:max-w-[1150px] 2xl:[&>div]:max-w-[1400px] 3xl:[&>div]:max-w-screen-2xl "
    >
      {/* 1 */}
      <div className=" flex flex-col gap-8 lg:mt-20 lg:flex-row xl:mt-28 2xl:gap-10 3xl:gap-16">
        <div className="flex flex-col gap-4 xl:gap-6 2xl:gap-8 [&>*]:w-fit ">
          <div className="flex flex-col gap-4 xl:gap-6 [&>*]:w-fit">
            <div
              data-aos="fade-down"
              data-aos-duration="1000"
              data-aos-delay="1000"
              className="rounded-full bg-neutral-11"
            >
              <p className="text-gr    py-2 px-5 text-center text-sm tracking-[2.5px] xl:text-lg 3xl:text-xl">
                Amplify Your Brand
              </p>
            </div>
            <h2
              data-aos="fade-down"
              data-aos-duration="1500"
              data-aos-delay="1000"
              className="text-3xl font-bold xl:text-4xl 2xl:text-5xl 3xl:text-6xl"
            >
              Industry Presence Amplification
            </h2>
          </div>
          <p
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="1000"
            className="text-sm text-neutral-4 xl:text-base 2xl:text-lg 3xl:text-xl"
          >
            Partnering with Beladed Advisors offers unique opportunities for your brand to reach new
            audiences and markets. By contributing to the development and growth of our platform,
            you'll enhance your brand's visibility and credibility in various industries, including
            eSports, entertainment, marketing, and technology. Leverage our innovative streaming
            solutions to showcase your expertise, drive engagement, and reinforce your position as
            an industry leader. Amplify your brand's impact with Beladed Advisors today.
          </p>
          <button
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="1000"
            onClick={scrollToGetInTouch}
            className="btn-gr btn rounded-full lg:py-3 lg:px-6 xl:py-4 xl:px-10"
          >
            Get In Touch
          </button>
        </div>
        <div className="relative mx-auto flex  w-[90%] flex-shrink-0 justify-center lg:w-1/2">
          <img
            src={img1}
            data-aos="fade-right"
            data-aos-duration="1500"
            data-aos-delay={isDesktopScreen ? "2500" : "1500"}
            alt="Strategize and Innovate with Beladed  "
            className="aspect-square h-fit w-[100%] max-w-[600px] object-cover lg:w-[90%] xl:w-[80%]"
          />
          <div className="gradient-1 absolute inset-0 top-4 left-4 -z-10 mx-auto aspect-square max-w-[600px]  translate-x-2 translate-y-2  lg:w-[90%] xl:w-[80%] ">
            <p
              data-aos="fade-left"
              data-aos-duration="1500"
              data-aos-delay={isDesktopScreen ? "2500" : "1500"}
              className=" vertical-text absolute -right-6 bottom-2 text-sm uppercase tracking-[5px] lg:text-base"
            >
              Amplification
            </p>
          </div>
        </div>
      </div>
      {/* 2 */}
      <div
        className="half-grey flex !w-full !max-w-full flex-col gap-10 pt-10 md:gap-16  lg:pt-20  [&>div]:mx-auto
     lg:[&>div]:px-8 xl:[&>div]:max-w-[1150px] 2xl:[&>div]:max-w-[1400px] 3xl:[&>div]:max-w-screen-2xl"
      >
        <div className="">
          {" "}
          <div className="relative mx-auto flex flex-col gap-8 lg:left-4  lg:flex-row-reverse  xl:max-w-[1150px]  xl:gap-20 2xl:max-w-[1400px] 2xl:gap-10   3xl:max-w-screen-2xl 3xl:gap-16">
            <div className="flex flex-col gap-4 xl:gap-6 2xl:gap-8 [&>*]:w-fit">
              <div className="flex  flex-col gap-4 xl:gap-6 [&>*]:w-fit">
                <div
                  data-aos="fade-down"
                  data-aos-duration="1000"
                  data-aos-delay="1000"
                  className="rounded-full bg-neutral-12"
                >
                  <p className="text-gr    py-2 px-5 text-center text-sm tracking-[2.5px] xl:text-lg 3xl:text-xl">
                    Discover New Opportunities
                  </p>
                </div>
                <h2
                  data-aos="fade-down"
                  data-aos-duration="1500"
                  data-aos-delay="1000"
                  className="text-3xl font-bold xl:text-4xl 2xl:text-5xl 3xl:text-6xl"
                >
                  Stay Ahead of Industry Trends
                </h2>
              </div>
              <p
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="1000"
                className=" text-sm text-neutral-4 xl:text-base  2xl:text-lg 3xl:text-xl"
              >
                As a Beladed Advisor, you'll gain exclusive access to cutting-edge developments in
                live-streaming, gaming, and technology. Your insights will play a crucial role in
                shaping the future of Beladed's platform, influencing the way brands and consumers
                interact in the digital world. Join our advisory board and seize the chance to
                explore untapped potential in the fast-paced world of technology and streaming.
              </p>
              <button
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="1000"
                onClick={scrollToGetInTouch}
                className="btn-gr btn rounded-full lg:py-3 lg:px-6 xl:py-4 xl:px-10"
              >
                Get In Touch
              </button>
            </div>
            <div className="relative mx-auto flex    w-[90%] flex-shrink-0 justify-center lg:w-1/2 lg:justify-start">
              <img
                src={img2}
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-delay={isDesktopScreen ? "3000" : "1500"}
                alt="Strategize and Innovate with Beladed"
                className="z-20 aspect-square w-[100%] max-w-[600px] object-cover lg:h-fit lg:w-[90%] xl:w-[80%]"
              />
              <div className="gradient-1 absolute inset-0 top-3 -right-0 -z-0 mx-auto aspect-square max-w-[600px] -translate-x-3 object-cover lg:-right-4 lg:top-4 lg:mx-0 lg:w-[90%] lg:-translate-x-5 xl:w-[80%] ">
                <p
                  data-aos="fade-right"
                  data-aos-duration="1500"
                  data-aos-delay={isDesktopScreen ? "3000" : "1500"}
                  className="vertical-text absolute -left-5 bottom-2 text-sm uppercase tracking-[5px] sm:-left-6 lg:text-base"
                >
                  Stay Ahead
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-6  2xl:gap-10">
          <div className="relative w-full">
            <img
              src={img3}
              alt="Strategize and Innovate with Beladed  "
              className="w-full object-cover"
            />
          </div>
          <div className="flex flex-col gap-4 lg:flex-row xl:gap-6 2xl:gap-8 [&>*]:w-fit ">
            <div className="flex flex-col gap-4 xl:gap-6 [&>*]:w-fit">
              <div
                data-aos="fade-down"
                data-aos-duration="1000"
                data-aos-delay="1000"
                className="rounded-full bg-neutral-11"
              >
                <p className="text-gr    py-2 px-5 text-center text-sm tracking-[2.5px] xl:text-lg 3xl:text-xl">
                  Drive Lasting Impact
                </p>
              </div>
              <h2
                data-aos="fade-down"
                data-aos-duration="1000"
                data-aos-delay="1000"
                className="text-3xl font-bold xl:text-4xl 2xl:text-5xl 3xl:text-6xl"
              >
                Revolutionize the Digital Landscape
              </h2>
            </div>
            <p
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="1000"
              className="text-sm text-neutral-4 xl:text-base 2xl:text-lg 3xl:text-xl"
            >
              Beladed Advisors presents a rare opportunity for industry experts to make a lasting
              impact on the digital landscape. By sharing your knowledge and expertise, you'll help
              mold the future of live-streaming, gaming, and technology, creating a platform that
              revolutionizes the way brands and consumers engage with one another. Collaborate with
              fellow thought leaders to develop groundbreaking solutions that redefine user
              experiences across a variety of industries. Make your mark on the future with Beladed
              Advisors.
            </p>
          </div>
        </div>
      </div>

      {/* 3 */}

      <div className="relative flex flex-col gap-8 lg:flex-row">
        <div className="bg-neutral-11 ">
          <div className="flex flex-col gap-10 2xl:gap-12">
            <div className="flex flex-col gap-4  px-4 pt-12 lg:px-6 xl:gap-6 2xl:gap-8 2xl:px-10 [&>*]:w-fit">
              <div className="flex flex-col gap-4 xl:gap-6 [&>*]:w-fit">
                <div
                  data-aos="fade-down"
                  data-aos-duration="1000"
                  data-aos-delay="1000"
                  className="rounded-full bg-white"
                >
                  <p className="text-gr    py-2 px-5 text-center text-sm tracking-[2.5px] xl:text-lg 3xl:text-xl">
                    Unlock Exclusive Benefits
                  </p>
                </div>
                <h2
                  data-aos="fade-down"
                  data-aos-duration="1000"
                  data-aos-delay="1500"
                  className="text-3xl font-bold xl:text-4xl 2xl:text-5xl 3xl:text-6xl"
                >
                  Maximize Your Beladed Experience
                </h2>
              </div>
              <p
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="1500"
                className="text-sm text-neutral-4 xl:text-base 2xl:text-lg 3xl:text-xl"
              >
                Beladed Advisors offers a range of exclusive benefits to enhance your experience and
                maximize your impact. As a valued member of our advisory board, you'll gain access
                to advanced analytics, unique networking opportunities, and priority access to new
                features and developments. Leverage these resources to fuel your brand's growth,
                optimize your strategies, and ensure you stay at the forefront of industry
                innovation. Experience unparalleled benefits and support as you collaborate with
                Beladed Advisors to shape the future of streaming and technology.
              </p>
            </div>
            <div className="relative w-full">
              <img
                src={img5}
                alt="Strategize and Innovate with Beladed  "
                className="w-full object-cover"
              />
            </div>
          </div>
        </div>
        <div className="bg-neutral-11 ">
          <div className="flex flex-col-reverse gap-10 2xl:gap-12">
            <div className="flex flex-col gap-4  px-4 pb-12 lg:px-6 xl:gap-6 2xl:gap-8 2xl:px-10 [&>*]:w-fit">
              <div className="flex flex-col gap-4 xl:gap-6 [&>*]:w-fit">
                <div
                  data-aos="fade-down"
                  data-aos-duration="1000"
                  data-aos-delay="1000"
                  className="rounded-full bg-white"
                >
                  <p className="text-gr    py-2 px-5 text-center text-sm tracking-[2.5px] xl:text-lg 3xl:text-xl">
                    Influence and Inspire
                  </p>
                </div>
                <h2
                  data-aos="fade-down"
                  data-aos-duration="1000"
                  data-aos-delay="1000"
                  className="text-3xl font-bold xl:text-4xl 2xl:text-5xl 3xl:text-6xl"
                >
                  Lead the Livestream Revolution
                </h2>
              </div>
              <p
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="1000"
                className="text-sm text-neutral-4 xl:text-base 2xl:text-lg 3xl:text-xl"
              >
                Beladed Advisors provides a platform for visionaries like you to make a lasting
                impact on the livestreaming industry. As a member of our prestigious advisory board,
                your innovative ideas and unique insights will inspire change and drive progress
                across the digital landscape. Collaborate with high-caliber professionals from
                eSports, entertainment, marketing, AI, and more to create new possibilities in the
                realm of interactive content. Seize the opportunity to lead the livestream
                revolution and empower the next generation of digital experiences with Beladed
                Advisors.
              </p>
            </div>
            <div className="relative w-full">
              <img
                src={img4}
                alt="Strategize and Innovate with Beladed  "
                className="w-full object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndustryPresence;
