import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AddIcon from "@mui/icons-material/Add";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import PermMediaOutlinedIcon from "@mui/icons-material/PermMediaOutlined";
import SentimentSatisfiedOutlinedIcon from "@mui/icons-material/SentimentSatisfiedOutlined";
import WestIcon from "@mui/icons-material/West";
import {
  Divider,
  FormControlLabel,
  IconButton,
  InputLabel,
  Menu,
  OutlinedInput,
  Radio,
  RadioGroup,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useAppContext } from "app/context/appContext";
import useHandleUpload from "app/hooks/useHandleUpload";
import sendIcon from "assets/send.svg";
import token from "assets/token.svg";
import axios from "axios";
import { mockData } from "data/mock-data.js";
import EmojiPicker from "emoji-picker-react";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

import CircularLoading from "../Small/Loading";
import Modal from "../Structure/Modal";

const MessagingModal = ({
  state,
  toggle,
  staticToggle,
  resetAllModals,
  setSelectedModal,
  selectedModal,
  handleSearchModal,
}) => {
  const { user } = useAppContext();
  const fetchModels = async () => {
    // const { data } = await axios.get(`http://localhost:5000/models`);
    const data = mockData["models"];
    return data;
  };
  const { isLoading, isError, data, error } = useQuery("ProfileFollowing2", fetchModels, {
    refetchOnWindowFocus: false,
  });

  const [selectedModel, setSelectedModel] = useState("");
  const handleSelectModel = (id) => {
    setSelectedModel(id);
  };
  const checkIfSelected = (id) => {
    const isSelected = data.indexOf(id);

    return id === selectedModel.id ? true : false;
  };
  const handleSelect = () => {
    toggle(selectedModal);

    handleSearchModal();
    toggle("gallery");
  };

  const [toggleProfile, setToggleProfile] = useState(false);
  const [toggleMessagedModels, setToggleMessagedModels] = useState(true);
  const [toggleMessagedUsers, setToggleMessagedUsers] = useState(true);
  const [toggleOtherModels, setToggleOtherModels] = useState(true);

  const [username, setUsername] = useState("username12727");
  const [isAvailable, setIsAvailable] = useState(null);
  const handleUpdate = async () => {
    setIsAvailable(false);
  };

  useEffect(() => {
    const t = setTimeout(() => {
      setIsAvailable(null);
    }, 2000);
    return () => clearTimeout(t);
  }, [isAvailable]);
  const [step, setStep] = useState(1);
  const [currentChatUser, setCurrentChatUser] = useState();
  const [messages, setMessages] = useState([
    { type: "received", content: randomText },
    { type: "received", content: randomText },
    { type: "sent", content: randomText },
    { type: "sent", content: randomText },
    { type: "received", content: randomText },
  ]);
  const [inputValue, setInputValue] = useState("");
  const handleNewLine = (e) => {
    if (e.key === "Enter") {
      handleSend();
    }
  };
  const handleSend = () => {
    const newMessages = messages;
    if (inputValue) {
      newMessages.push({ type: "sent", content: inputValue });
      setMessages(newMessages);
      setInputValue("");
    }
  };

  const chatBoxRef = useRef();
  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages.length]);
  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [state]);
  if (isLoading) {
    return (
      <div className="flex-c h-[200px] w-full ">
        <CircularLoading />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex-c h-[200px] w-full  text-lg font-medium">Error: {error.message}</div>
    );
  }
  return (
    <Modal state={state} close={toggle}>
      <div
        onKeyDown={handleNewLine}
        className="relative mx-auto my-auto hidden h-full w-[100vw] bg-white  md:flex    lg:w-[min(900px,90vw)] lg:rounded-lg   xl:w-[900px]"
      >
        <div className="custom-scrollbar relative flex h-[100vh] flex-[35%] flex-col overflow-auto border-r border-neutral-9 lg:h-[min(100vh,700px)]">
          <div className="sticky top-0  z-50 flex flex-col bg-neutral-11 px-6 pt-5">
            <div className="flex items-center justify-between pb-5">
              <button
                className="flex items-center gap-0"
                onClick={() => setToggleProfile(!toggleProfile)}
              >
                <Tooltip title="Account settings">
                  <img
                    src={user.image}
                    className="aspect-square w-8 rounded-full object-cover"
                    alt=""
                  />
                </Tooltip>
                {toggleProfile ? (
                  <KeyboardArrowUp sx={{ color: "#969696" }} />
                ) : (
                  <KeyboardArrowDown sx={{ color: "#969696" }} />
                )}
              </button>
              {user.type === "model" ? (
                <div className="flex items-center gap-2 rounded-full bg-neutral-10 py-2 px-3">
                  <div className="flex items-center gap-1">
                    <span className="text-xs font-semibold">$56,374</span>
                  </div>
                  <Link
                    to="/waitlist/payout-details"
                    className="rounded-full bg-secondary-5 px-3 py-1 text-2xs font-medium text-white"
                  >
                    Edit Payout Details
                  </Link>
                </div>
              ) : (
                <div className="flex items-center gap-2 rounded-full bg-neutral-10 py-2 px-3">
                  <div className="flex items-center gap-1">
                    <img src={token} alt="" /> <span className="text-xs font-semibold">56950</span>
                  </div>
                  <Link
                    to="/waitlist/users/tokens"
                    className="rounded-full bg-secondary-5 px-3 py-1 text-2xs font-medium text-white"
                  >
                    Buy Tokens
                  </Link>
                </div>
              )}{" "}
            </div>
            {toggleProfile && (
              <div className="flex flex-col gap-6 border-t border-neutral-9 pt-4 pb-5">
                <ProfilePhoto />
                <div className="relative flex items-center gap-2">
                  <FormControl
                    variant="outlined"
                    className="w-full !text-xs"
                    sx={{ fontSize: "12px" }}
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                  >
                    <InputLabel htmlFor={"Update User Name"}>{"Update User Name"}</InputLabel>
                    <OutlinedInput
                      sx={{
                        ".MuiInputBase-input": {
                          padding: "16px 12px",
                          fontSize: "14px",
                        },
                      }}
                      type={"text"}
                      label={"Update User Name"}
                      placeholder={"Update User Name"}
                      name={username}
                      value={username}
                    />
                  </FormControl>
                  <button
                    className="btn btn-main h-full px-5 text-sm"
                    onClick={() => handleUpdate()}
                  >
                    Update
                  </button>
                  <div className="absolute -bottom-4 left-0">
                    {isAvailable === null ? null : isAvailable ? (
                      <p className="text-2xs text-feedback-success-5">Saved!</p>
                    ) : (
                      <p className="text-2xs text-feedback-failure-5">Not Available!</p>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          {user.type === "model" ? (
            <div className="flex flex-col items-start gap-4 px-6 pt-6">
              <button
                className="font-semibold"
                onClick={() => setToggleMessagedUsers(!toggleMessagedUsers)}
              >
                Messaged Users
                {toggleMessagedUsers ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </button>
              {toggleMessagedUsers && <MessagedUsers setCurrentChatUser={setCurrentChatUser} />}
            </div>
          ) : (
            <>
              <div className="flex flex-col items-start gap-4 px-6 pt-6">
                <button
                  className="font-semibold"
                  onClick={() => setToggleMessagedModels(!toggleMessagedModels)}
                >
                  Messaged Models
                  {toggleMessagedModels ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </button>
                {toggleMessagedModels && (
                  <MessagedModels setStep={setStep} setCurrentChatUser={setCurrentChatUser} />
                )}
              </div>
              <div className="flex flex-col items-start gap-4 px-6 py-6 ">
                <button
                  className="font-semibold"
                  onClick={() => setToggleOtherModels(!toggleOtherModels)}
                >
                  Other Available Models
                  {toggleOtherModels ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </button>
                {toggleOtherModels && (
                  <AvailableModels setStep={setStep} setCurrentChatUser={setCurrentChatUser} />
                )}
              </div>
            </>
          )}
        </div>

        <div className="relative flex   h-[100vh]    flex-[45%] flex-col justify-between lg:h-[min(100vh,700px)]  ">
          <div className="flex items-center justify-between  bg-neutral-11 px-6 py-3.5">
            <div className="flex items-center gap-2">
              <div>
                <img
                  src={currentChatUser?.image}
                  className="aspect-square w-12 rounded-full"
                  alt={""}
                />
              </div>
              <div className="flex flex-col gap-2">
                <p className="font-semibold">{currentChatUser?.name}</p>
                <div className="flex items-center gap-1">
                  <div
                    className={`aspect-square w-2 rounded-full ${
                      currentChatUser?.status === "Online"
                        ? "bg-[#12B76A]"
                        : currentChatUser?.status === "Absent"
                        ? "bg-orange-500"
                        : "bg-red-500"
                    } bg-[#12B76A]`}
                  ></div>
                  <p className="dark:text-neutral-9  text-2xs text-neutral-5">
                    {currentChatUser?.status === "Online"
                      ? "Online now"
                      : currentChatUser?.status === "Absent"
                      ? "Absent"
                      : "Offline"}
                  </p>
                </div>
              </div>
            </div>
            <div className="">
              <IconButton aria-label="close" name="messaging" onClick={(e) => toggle(e)}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>{" "}
          <ChatBox currentChatUser={currentChatUser} messages={messages} chatBoxRef={chatBoxRef} />
          <ChatInput
            inputValue={inputValue}
            setInputValue={setInputValue}
            handleNewLine={handleNewLine}
            handleSend={handleSend}
            userType={user?.type}
          />
        </div>
      </div>
      <div className="relative  mx-auto my-auto w-[100vw] bg-white md:hidden">
        {step === 1 ? (
          <div className="custom-scrollbar relative flex h-[100vh] flex-[35%] flex-col overflow-auto border-r border-neutral-9 lg:h-[min(100vh,700px)]">
            <div className="flex items-center gap-2 px-4 py-4">
              <div className="">
                <IconButton aria-label="close" name="messaging" onClick={(e) => toggle(e)}>
                  <WestIcon />
                </IconButton>
              </div>
              <h3 className="text-xl font-semibold">Messages</h3>
            </div>
            <div className="sticky top-0  z-50 flex flex-col bg-neutral-11 px-6 pt-5">
              <div className="flex items-center justify-between pb-5">
                <button
                  className="flex items-center gap-0"
                  onClick={() => setToggleProfile(!toggleProfile)}
                >
                  <Tooltip title="Account settings">
                    <img
                      src={user.image}
                      className="aspect-square w-8 rounded-full object-cover"
                      alt=""
                    />
                  </Tooltip>
                  {toggleProfile ? (
                    <KeyboardArrowUp sx={{ color: "#969696" }} />
                  ) : (
                    <KeyboardArrowDown sx={{ color: "#969696" }} />
                  )}
                </button>
                {user.type === "model" ? (
                  <div className="flex items-center gap-2 rounded-full bg-neutral-10 py-2 px-3">
                    <div className="flex items-center gap-1">
                      <span className="text-xs font-semibold">$56,374</span>
                    </div>
                    <Link
                      to="/waitlist/payout-details"
                      className="rounded-full bg-secondary-5 px-3 py-1 text-2xs font-medium text-white"
                    >
                      Edit Payout Details
                    </Link>
                  </div>
                ) : (
                  <div className="flex items-center gap-2 rounded-full bg-neutral-10 py-2 px-3">
                    <div className="flex items-center gap-1">
                      <img src={token} alt="" />{" "}
                      <span className="text-xs font-semibold">56950</span>
                    </div>
                    <Link
                      to="/waitlist/users/tokens"
                      className="rounded-full bg-secondary-5 px-3 py-1 text-2xs font-medium text-white"
                    >
                      Buy Tokens
                    </Link>
                  </div>
                )}
              </div>
              {toggleProfile && (
                <div className="flex flex-col gap-6 border-t border-neutral-9 pt-4 pb-5">
                  <ProfilePhoto />
                  <div className="relative flex items-center gap-2">
                    <FormControl
                      variant="outlined"
                      className="w-full !text-xs"
                      sx={{ fontSize: "12px" }}
                      onChange={(e) => setUsername(e.target.value)}
                      value={username}
                    >
                      <InputLabel htmlFor={"Update User Name"}>{"Update User Name"}</InputLabel>
                      <OutlinedInput
                        sx={{
                          ".MuiInputBase-input": {
                            padding: "16px 12px",
                            fontSize: "14px",
                          },
                        }}
                        type={"text"}
                        label={"Update User Name"}
                        placeholder={"Update User Name"}
                        name={username}
                        value={username}
                      />
                    </FormControl>
                    <button
                      className="btn btn-main h-full px-5 text-sm"
                      onClick={() => handleUpdate()}
                    >
                      Update
                    </button>
                    <div className="absolute -bottom-4 left-0">
                      {isAvailable === null ? null : isAvailable ? (
                        <p className="text-2xs text-feedback-success-5">Saved!</p>
                      ) : (
                        <p className="text-2xs text-feedback-failure-5">Not Available!</p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {user.type === "model" ? (
              <div className="flex flex-col items-start gap-4 px-6 pt-6">
                <button
                  className="font-semibold"
                  onClick={() => setToggleMessagedUsers(!toggleMessagedUsers)}
                >
                  Messaged Users
                  {toggleMessagedUsers ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </button>
                {toggleMessagedUsers && (
                  <MessagedUsers setStep={setStep} setCurrentChatUser={setCurrentChatUser} />
                )}
              </div>
            ) : (
              <>
                <div className="flex flex-col items-start gap-4 px-6 pt-6">
                  <button
                    className="font-semibold"
                    onClick={() => setToggleMessagedModels(!toggleMessagedModels)}
                  >
                    Messaged Models
                    {toggleMessagedModels ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                  </button>
                  {toggleMessagedModels && (
                    <MessagedModels setStep={setStep} setCurrentChatUser={setCurrentChatUser} />
                  )}
                </div>
                <div className="flex flex-col items-start gap-4 px-6 py-6 ">
                  <button
                    className="font-semibold"
                    onClick={() => setToggleOtherModels(!toggleOtherModels)}
                  >
                    Other Available Models
                    {toggleOtherModels ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                  </button>
                  {toggleOtherModels && (
                    <AvailableModels setStep={setStep} setCurrentChatUser={setCurrentChatUser} />
                  )}
                </div>
              </>
            )}
          </div>
        ) : (
          <div className=" relative    flex h-[100vh] flex-col justify-between  ">
            <div className="flex items-center justify-between  bg-neutral-11 px-6 py-3.5">
              <div className="flex items-center gap-2">
                <div>
                  <img
                    src={currentChatUser?.image}
                    className="aspect-square w-12 rounded-full"
                    alt={""}
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <p className="font-semibold">{currentChatUser?.name}</p>
                  <div className="flex items-center gap-1">
                    <div
                      className={`aspect-square w-2 rounded-full ${
                        currentChatUser?.status === "Online"
                          ? "bg-[#12B76A]"
                          : currentChatUser?.status === "Absent"
                          ? "bg-orange-500"
                          : "bg-red-500"
                      } bg-[#12B76A]`}
                    ></div>
                    <p className="dark:text-neutral-9  text-2xs text-neutral-5">
                      {currentChatUser?.status === "Online"
                        ? "Online now"
                        : currentChatUser?.status === "Absent"
                        ? "Absent"
                        : "Offline"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="">
                <IconButton aria-label="go back" onClick={() => setStep(1)}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>{" "}
            <ChatBox
              currentChatUser={currentChatUser}
              messages={messages}
              chatBoxRef={chatBoxRef}
            />
            <ChatInput
              inputValue={inputValue}
              setInputValue={setInputValue}
              handleNewLine={handleNewLine}
              handleSend={handleSend}
              userType={user?.type}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};
export default MessagingModal;

const MessagedModels = ({ setStep, setCurrentChatUser }) => {
  const fetchData = async () => {
    // const { data } = await axios.get(`http://localhost:5000/messages`);
    const data = mockData["messages"];
    setCurrentChatUser(data.find((obj) => obj.id === 1));
    return data;
  };
  const { isLoading, isError, data, error } = useQuery("messages", fetchData);
  const handleOpenChat = (id) => {
    if (window.innerWidth <= 768) {
      setStep(2);
    }
    setCurrentChatUser(data.find((obj) => obj.id === id));
  };
  if (isLoading) {
    return (
      <div className="flex-c h-[200px] w-full ">
        <CircularLoading />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex-c h-[200px] w-full  text-lg font-medium">Error: {error.message}</div>
    );
  }
  return (
    <div className="custom-scrollbar flex h-[30vh]  flex-col overflow-y-auto md:h-[max(260px)]   ">
      <div className="">
        <div className="flex flex-col  gap-2 ">
          {data.slice(0, 10).map((item, index) => {
            const { id, image, name, message, unread_messages, date, status } = item;
            const month = new Date(date).toLocaleString("default", {
              month: "short",
            });
            const day = new Date(date).getDate();
            const random = Math.floor(Math.random() * 100).toString();
            const img = image;
            return (
              <button onClick={() => handleOpenChat(id)} key={index}>
                <MessagePreviewContainer
                  img={img}
                  name={name}
                  status={status}
                  message={message}
                  month={month}
                  day={day}
                  unread_messages={unread_messages}
                  setStep={setStep}
                />
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};
const MessagedUsers = ({ setStep, setCurrentChatUser }) => {
  const fetchData = async () => {
    //// const { data } = await axios.get(`http://localhost:5000/messages`);
    const data = mockData["messagesModel"];
    setCurrentChatUser(data.find((obj) => obj.id === 1));
    return data;
  };
  const { isLoading, isError, data, error } = useQuery("messages", fetchData);
  const handleOpenChat = (id) => {
    if (window.innerWidth <= 768) {
      setStep(2);
    }
    setCurrentChatUser(data.find((obj) => obj.id === id));
  };

  if (isLoading) {
    return (
      <div className="flex-c h-[200px] w-full ">
        <CircularLoading />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex-c h-[200px] w-full  text-lg font-medium">Error: {error.message}</div>
    );
  }
  return (
    <div
      className="custom-scrollbar h-[calc(100vh - 200px))] flex  flex-col overflow-y-auto lg:!h-[max(560px)]   "
      style={{ height: "calc(100vh - 220px)" }}
    >
      <div className="">
        <div className="flex flex-col  gap-2 ">
          {data.slice(0, 10).map((item, index) => {
            const { id, image, name, message, unread_messages, date, status } = item;
            const month = new Date(date).toLocaleString("default", {
              month: "short",
            });
            const day = new Date(date).getDate();
            const random = Math.floor(Math.random() * 100).toString();
            const img = image;
            return (
              <button onClick={() => handleOpenChat(id)} key={index}>
                <MessagePreviewContainer
                  id={id}
                  img={img}
                  name={name}
                  status={status}
                  message={message}
                  month={month}
                  day={day}
                  unread_messages={unread_messages}
                  setStep={setStep}
                />
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};
const AvailableModels = ({ setStep, setCurrentChatUser }) => {
  const fetchData = async () => {
    // const { data } = await axios.get(`http://localhost:5000/messages`);
    const data = mockData["messages"];
    setCurrentChatUser(data.find((obj) => obj.id === 1));
    return data;
  };
  const { isLoading, isError, data, error } = useQuery("messages", fetchData);
  const handleOpenChat = (id) => {
    if (window.innerWidth <= 768) {
      setStep(2);
    }
    setCurrentChatUser(data.find((obj) => obj.id === id));
  };
  if (isLoading) {
    return (
      <div className="flex-c h-[200px] w-full ">
        <CircularLoading />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex-c h-[200px] w-full  text-lg font-medium">Error: {error.message}</div>
    );
  }
  return (
    <div className="custom-scrollbar flex h-[30vh] w-full   flex-col overflow-y-auto md:h-[max(260px)]   ">
      <div className="w-full">
        <div className="flex flex-col  gap-2 ">
          {data.slice(0, 10).map((item, index) => {
            const { id, image, name, message, unread_messages, date, status } = item;
            const month = new Date(date).toLocaleString("default", {
              month: "short",
            });
            const day = new Date(date).getDate();
            const random = Math.floor(Math.random() * 100).toString();
            const img = image;
            return (
              <div onClick={() => handleOpenChat(id)} key={index}>
                <SingleAvailableModel
                  img={img}
                  name={name}
                  status={status}
                  message={message}
                  month={month}
                  day={day}
                  unread_messages={unread_messages}
                  setStep={setStep}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const SingleAvailableModel = ({
  img,
  name,
  status,
  message,
  month,
  day,
  unread_messages,
  setStep,
}) => {
  return (
    <button
      onClick={() => setStep(2)}
      className="dark:text-neutral-8 hover:dark:bg-neutral-2 flex w-full  items-center justify-between gap-3 rounded-lg p-3 text-neutral-5 hover:bg-neutral-11"
    >
      <div className="flex items-start gap-4">
        {" "}
        <div className=" relative w-12  flex-shrink-0 ">
          <img src={img} className=" aspect-square h-12 w-12 rounded-full" alt={name} />
        </div>
        <div className="flex flex-col items-start gap-1 pr-1">
          <p className="dark:text-white text-sm font-medium text-neutral-3">{name}</p>

          <div className="flex flex-col items-start gap-1 text-xs text-neutral-1">
            <p>
              Tokens per message: <span className="font-bold text-neutral-5">200 tokens</span>
            </p>
            <p>
              Avg. Response Time: <span className="font-bold text-neutral-5">3 days</span>
            </p>
          </div>
        </div>
      </div>
    </button>
  );
};
const MessagePreviewContainer = ({
  img,
  name,
  status,
  message,
  month,
  day,
  unread_messages,
  setStep,
}) => {
  return (
    <div className="dark:text-neutral-8 hover:dark:bg-neutral-2 flex  items-center justify-between gap-3 rounded-lg p-3 text-neutral-5 hover:bg-neutral-11">
      <div className="flex items-start gap-4">
        {" "}
        <div className=" relative w-12  flex-shrink-0 ">
          <img src={img} className=" aspect-square h-12 w-12 rounded-full" alt={name} />
          <span
            className={`
                      absolute right-0 bottom-0 h-3 w-3 rounded-full border border-white
                  ${
                    status === "Online"
                      ? "bg-[#12B76A]"
                      : status === "Absent"
                      ? "bg-orange-500"
                      : "bg-red-500"
                  }`}
          ></span>
        </div>
        <div className="flex flex-col items-start gap-1 pr-1">
          <p className="dark:text-white text-sm font-medium text-neutral-3">{name}</p>

          <p
            className="two-line dark:text-neutral-9  text-start   text-2xs  leading-normal     text-neutral-5   "
            style={{ lineClamp: 2 }}
          >
            {message}
          </p>
        </div>
      </div>

      <div className="flex flex-col items-end justify-between gap-5">
        <div className="flex gap-1 text-2xs  font-medium">
          <span>{month}</span>
          <span>{day}</span>
        </div>
        <div className="flex-c h-4 w-4 rounded-full bg-secondary-5 text-2xs  text-white ">
          <span>{unread_messages}</span>
        </div>
      </div>
    </div>
  );
};

const ProfilePhoto = ({ t }) => {
  const { uploadedImage, uploadPhoto } = useHandleUpload();
  const inputRef = useRef(null);
  return (
    <div className="flex items-center gap-2">
      <div>
        <div className="relative aspect-square h-16 w-16 rounded-full bg-secondary-9">
          {uploadedImage ? (
            <img
              src={uploadedImage}
              alt="profile"
              className="aspect-square h-16  w-16 rounded-full object-cover"
            />
          ) : (
            <div className="flex-c aspect-square h-16 w-16 rounded-full">
              <PermMediaOutlinedIcon />
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-3">
          <label className={`btn btn-main cursor-pointer px-3 py-1  text-2xs `}>
            Upload Profile Picture
            <input
              type="file"
              onChange={(e) => uploadPhoto(e, false)}
              className="hidden"
              accept=".jpg, .jpeg, .png"
              ref={inputRef}
            />
          </label>
        </div>
        <div>
          <p className="dark:text-neutral-9  text-3xs text-neutral-4 ">
            Image should be at lest 300px✖300px as a png or jpeg file
          </p>
        </div>
      </div>
    </div>
  );
};

const ChatBox = ({ currentChatUser, messages, chatBoxRef }) => {
  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [chatBoxRef?.current?.scrollHeight]);
  return (
    <div
      ref={chatBoxRef}
      className="custom-scrollbar my-3 flex h-full  max-h-[calc(100%-162px)]  flex-col gap-3 overflow-y-auto px-4"
    >
      {messages.map((item, index) => {
        return item.type === "sent" ? (
          <div key={index} className="flex justify-end">
            <MessageSent content={item.content} />
          </div>
        ) : (
          <div key={index} className="flex justify-start">
            <MessageReceived currentChatUser={currentChatUser} />
          </div>
        );
      })}
    </div>
  );
};

const MessageReceived = ({ currentChatUser }) => {
  return (
    <div className="flex-col gap-1">
      <div className="flex items-end gap-2">
        <div className="flex-shrink-0">
          <img
            src={currentChatUser?.image}
            alt={currentChatUser?.name}
            className="aspect-square w-10 rounded-full  object-cover"
          />
        </div>
        <div className="dark:bg-neutral-2 w-fit max-w-full rounded-lg bg-primary-5  py-2 px-3 text-white sm:max-w-[80%] md:max-w-full lg:max-w-[80%] ">
          <p className="text-sm leading-normal">{currentChatUser?.message}</p>
        </div>
      </div>
      <div className="ml-14">
        <span className="text-2xs text-neutral-5 ">3 min ago</span>
      </div>
    </div>
  );
};

const MessageSent = ({ content }) => {
  const { user } = useAppContext();
  return (
    <div className="flex w-fit flex-col gap-1 self-end">
      <div className="flex flex-row-reverse items-end gap-2">
        <div className="flex-shrink-0">
          <img
            src={user.image}
            alt={user.first_name}
            className="aspect-square w-10 rounded-full object-cover "
          />
        </div>
        <div className=" w-fit max-w-full rounded-lg border border-neutral-9 bg-white py-2 px-3 text-neutral-4 sm:max-w-[80%] md:max-w-full lg:max-w-[80%] ">
          <p className="break-all text-sm leading-normal">{content ? content : randomText}</p>
        </div>
      </div>
      <div className="mr-14 flex justify-end">
        <span className="text-2xs text-neutral-5 ">3 min ago</span>
      </div>
    </div>
  );
};

const randomText =
  "I'm sharing an unrealistic budget. So, what about 100$ for 5days? Let me know if this suites for you because I'm open to negotiate based on your preference.";

const ChatInput = ({ inputValue, setInputValue, handleSend, userType }) => {
  const [showEmojiPanel, setShowEmojiPanel] = useState(false);

  function toggleEmojiPanel() {
    setShowEmojiPanel(!showEmojiPanel);
  }
  const handleEmojiClick = (obj) => {
    setInputValue(inputValue + obj.emoji);
    setShowEmojiPanel(!showEmojiPanel);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [currentPriority, setCurrentPriority] = useState("0");
  const [priorityOrder, setPriorityOrder] = useState("");
  const handlePriority = (e) => {
    setCurrentPriority(e.target.value);
  };
  useEffect(() => {
    const a = priorities.find((item) => currentPriority === item.value);
    setPriorityOrder(a.order);
    handleClose();
  }, [currentPriority]);
  return (
    <div className="dark:border-neutral-3 dark:bg-neutral-1 relative flex items-center   border-t border-neutral-10 bg-white px-1 py-4 ">
      {showEmojiPanel && (
        <div className="fixed bottom-28 z-50 w-[300px] sm:w-[350px]">
          <EmojiPicker emojiStyle={"native"} width={"100%"} onEmojiClick={handleEmojiClick} />
          <div className="absolute top-0 -right-12 z-10 hidden rounded-full bg-neutral-1 text-white">
            <IconButton aria-label="close" name="editGallery" onClick={() => toggleEmojiPanel()}>
              <CloseIcon sx={{ color: "white" }} />
            </IconButton>
          </div>
        </div>
      )}
      <div className="flex w-full items-center py-0.5 px-1">
        <IconButton
          onClick={toggleEmojiPanel}
          sx={{ p: "10px" }}
          aria-label="menu"
          className="h-8 w-8 lg:h-12 lg:w-12"
        >
          <SentimentSatisfiedOutlinedIcon sx={{ color: showEmojiPanel && "#FF1FAA" }} />
        </IconButton>

        {userType === "user" && (
          <>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton
              // sx={{ p: "10px" }}
              aria-label="menu"
              className="!relative h-8 w-8 lg:h-12 lg:w-12"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <AccessTimeIcon />
              {priorityOrder && (
                <span className="absolute bottom-1 right-1 h-3  w-3 rounded-full bg-primary-5 text-2xs text-white lg:right-3 lg:bottom-3">
                  {priorityOrder}
                </span>
              )}
            </IconButton>
          </>
        )}

        <TextareaAutosize
          className="custom-scrollbar custom-textarea  dark:border-neutral-3 w-full rounded-lg border  border-neutral-10 bg-transparent py-4 px-3 focus:outline-secondary-5"
          sx={{ ml: 1, flex: 1 }}
          placeholder="Type your message here"
          inputprops={{ "aria-label": "Type your message here" }}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
          maxRows={4}
          minRows={1}
        />

        <button
          className="btn lg:ln-3 btn-main flex-c ml-3 aspect-square h-full  flex-shrink-0 rounded-lg p-2  md:ml-1 lg:h-12 lg:w-12 lg:p-1"
          onClick={handleSend}
        >
          <img src={sendIcon} alt="send" className="" />
        </button>
      </div>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          ".MuiList-root": {
            padding: 0,
          },
        }}
        className="z-50 "
      >
        <div className="flex flex-col gap-4 p-6">
          <div className="flex flex-col gap-1">
            <h3 className="text-base font-semibold lg:text-xl">Select Your Message Priority</h3>
            <p className="dark:text-neutral-7 text-2xs text-neutral-5 lg:text-xs">
              Prioritize your messages for a faster response.{" "}
            </p>
          </div>
          <RadioGroup
            aria-labelledby={"prio"}
            name={"prio"}
            value={currentPriority}
            className="flex-col gap-0 "
            onChange={handlePriority}
            sx={{
              ".MuiButtonBase-root": {
                padding: 0,
                margin: "0.25rem 0.5rem 0.25rem 0",
              },
            }}
          >
            {priorities.map((item, index) => {
              const { value, label } = item;
              return (
                <FormControlLabel
                  key={index}
                  className="dark:hover:bg-neutral-2 m-0  rounded-lg px-1  py-1 !text-[2px] hover:bg-neutral-11"
                  value={value}
                  checked={currentPriority === value}
                  sx={{
                    marginLeft: 0,
                    marginRight: 0,

                    ".MuiFormControlLabel-label": {
                      fontSize: { xs: "12px", sm: "14px" },
                    },
                  }}
                  control={
                    <Radio
                      sx={{
                        color: "#CCCCCC",
                      }}
                      color="secondary"
                    />
                  }
                  label={label}
                />
              );
            })}
          </RadioGroup>
        </div>
      </Menu>
    </div>
  );
};

const priorities = [
  {
    label: (
      <span className="text-xs lg:text-base">
        1st Priority - <span className="font-semibold">200 tokens</span>
      </span>
    ),
    value: "200",
    order: 1,
  },
  {
    label: (
      <span className="text-xs lg:text-base">
        2nd Priority - <span className="font-semibold">150 tokens</span>
      </span>
    ),
    value: "150",
    order: 2,
  },
  {
    label: (
      <span className="text-xs lg:text-base">
        3rd Priority - <span className="font-semibold">100 tokens</span>
      </span>
    ),
    value: "100",
    order: 3,
  },
  {
    label: (
      <span className="text-xs lg:text-base">
        No Priority - <span className="font-semibold">0 tokens</span>
      </span>
    ),
    value: "0",
    order: "",
  },
];
