
import { mockData } from "data/mock-data.js";

import { useCallback, useEffect, useRef, useState } from "react";

import { useQuery } from "react-query";
import CircularLoading from "../Small/Loading";
import Modal from "../Structure/Modal";
import {  IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAppContext } from "app/context/appContext";

import DeleteForeverIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrag, useDrop } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import { isMobile } from "react-device-detect";

import { useDragLayer } from "react-dnd";
const EditGallery = ({ state, toggle, staticToggle, doubleStaticToggle }) => {
  const { user } = useAppContext();
  const fetchModels = async () => {
    // // const { data } = await axios.get(`http://localhost:5000/models`);
    const data = mockData["models"];

    return data;
  };
  const { isLoading, isError, data, error } = useQuery(
    "ProfileFollowing",
    fetchModels,
    {
      refetchOnWindowFocus: false,
    }
  );

  const [selectedModel, setSelectedModel] = useState("");
  const handleSelectModel = (id) => {
    setSelectedModel(id);
  };
  const checkIfSelected = (id) => {
    const isSelected = data.indexOf(id);

    return id === selectedModel.id ? true : false;
  };
  const handleSelect = () => {
    doubleStaticToggle("messaging", "gallery");

    // handleSearchModal();
  };

  const handleGoBack = () => {
    doubleStaticToggle("selectModel", "gallery");
  };
  const handleClose = () => {
    toggle("gallery");
  };

  const [modalState, setModalState] = useState("gallery");
  const [enoughTokens, setEnoughTokens] = useState(true);
  useEffect(() => {
    if (!enoughTokens) {
      setModalState("buy");
    }
  }, []);
  const [imagesList, setImagesList] = useState(gallery);

  const moveImage = useCallback((dragIndex, hoverIndex) => {
    setImagesList((prevCards) => {
      const clonedCards = [...prevCards];
      const removedItem = clonedCards.splice(dragIndex, 1)[0];

      clonedCards.splice(hoverIndex, 0, removedItem);
      return clonedCards;
    });
  }, []);

  const [dragIndex, setDragIndex] = useState(null);

  const handleDragStart = (index) => {
    setDragIndex(index);
  };
  const [images, setImages] = useState(gallery);
  const handleDrop = (index) => {
    const draggedImage = images[dragIndex];
    const newImages = [...images];
    newImages.splice(dragIndex, 1);
    newImages.splice(index, 0, draggedImage);
    setImages(newImages);
    setDragIndex(null);
  };
  const handleDeleteImage = (index) => {
    const newImageList = [...imagesList];
    newImageList.splice(index, 1);
    setImagesList(newImageList);
  };
  const handleSaveChanges = () => {
    staticToggle("editGallery");
  };

  if (isLoading) {
    return (
      <div className="flex-c h-[200px] w-full ">
        <CircularLoading />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex-c h-[200px] w-full  text-lg font-medium">
        Error: {error.message}
      </div>
    );
  }
  return (
    <Modal state={state} close={toggle}>
      <DndProvider backend={HTML5Backend}>
        <div className="relative mx-auto my-auto flex w-[95vw] sm:w-[600px]   flex-col gap-6  rounded-lg bg-white px-3 py-4  md:px-8 md:py-8 lg:w-[780px]">
          <div className="flex  w-full items-start justify-between">
            <div className="flex w-full  flex-col gap-3 md:gap-6">
              <div className="flex items-center justify-between ">
                {" "}
                <div className="flex items-center gap-5">
                  <div
                    className={`w-20 h-20 aspect-square   flex-shrink-0 overflow-hidden rounded-full `}>
                    <img
                      src={user.image}
                      alt={"name"}
                      className={`rounded-full w-20 h-20 aspect-square scale-[1.02]`}
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <h2 className="font-semibold text-neutral-1 text-lg">
                      {user.first_name} {user.last_name}
                    </h2>
                    <span className="text-neutral-5 text-2xs font-medium  ">
                      These are the public photos we found on your instagram
                    </span>
                    <div className="flex flex-wrap items-center gap-2 text-neutral-1 text-xs">
                      <p className="text-xs font-medium">
                        Re-arrange or remove photos in your gallery
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <IconButton
                    aria-label="close"
                    name="editGallery"
                    onClick={(e) => toggle(e)}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
            </div>
            <div></div>
          </div>
          <div className="flex  flex-col gap-6">
            <div
              className="custom-scrollbar grid max-h-[400px] grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-2 
           overflow-y-auto pr-2  pb-4 md:max-h-[450px]  md:gap-4 ">
              {imagesList.map((item, index) => {
                return (
                  <div key={index} className="relative">
                    <Card
                      src={item.src}
                      id={item.id}
                      index={index}
                      moveImage={moveImage}
                      alt=""
                    />
                    <button
                      onClick={() => handleDeleteImage(index)}
                      className="w-7 h-7 flex-c rounded-full absolute bottom-4 right-4 bg-[#ffffffc4] hover:scale-[1.15] transition-all duration-500">
                      <DeleteForeverIcon color="primary" />
                    </button>
                  </div>
                );
              })}
            </div>
            {/* <ImageList images={gallery} /> */}
          </div>

          <div className="mt-3 flex  items-center gap-4 self-end justify-self-end">
            <button
              name="draftSuccess"
              className="btn btn-main "
              onClick={handleSaveChanges}>
              Save Changes
            </button>
          </div>
        </div>
      </DndProvider>
    </Modal>
  );
};
export default EditGallery;

const Card = ({ src, title, id, index, moveImage }) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: "image",
    hover: (item, monitor) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveImage(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "image",
    item: () => {
      return { id, index };
    },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  const handleTouchStart = (event) => {
    if (event.touches.length > 1) return; // ignore multi-touch
    event.preventDefault(); // prevent the context menu from showing
  };
  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);
  return (
    <div ref={ref}>
      <img
        src={src}
        alt=""
        className={`aspect-video object-cover transition-all duration-500 ${
          isDragging && "scale-75"
        }`}
      />
    </div>
  );
};

// ImageItem.js

const ImageItem = ({ id, src, index, moveImage }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "image",
    item: { id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));
  const ref = useRef(null);
  const [, drop] = useDrop(() => ({
    accept: "image",
    hover(item, monitor) {
      const draggedIndex = item.index;
      const hoverIndex = index;

      if (draggedIndex === hoverIndex) {
        return;
      }

      moveImage(draggedIndex, hoverIndex);
      item.index = hoverIndex;
    },
  }));

  return (
    <img
      ref={(node) => drag(drop(node))}
      src={src}
      alt={`img-${index}`}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      className="w-[200px] object-cover"
    />
  );
};

// ImageList.js

const ImageList = ({ images }) => {
  const [imageList, setImageList] = useState(images);

  const moveImage = (dragIndex, hoverIndex) => {
    const dragImage = imageList[dragIndex];
    setImageList((prevState) => {
      const newState = [...prevState];
      newState.splice(dragIndex, 1);
      newState.splice(hoverIndex, 0, dragImage);
      return newState;
    });
  };

  return (
    <>
      <div className="image-list">
        {imageList.map((image, index) => (
          <ImageItem
            key={image.id}
            id={image.id}
            src={image.src}
            index={index}
            moveImage={moveImage}
          />
        ))}
      </div>
      <ImageDragLayer />
    </>
  );
};
// ImageDragLayer.js

const layerStyles = {
  position: "fixed",
  pointerEvents: "none",
  zIndex: 100,
  left: 0,
  top: 0,
};

function getItemStyles(currentOffset) {
  if (!currentOffset) {
    return {
      display: "none",
    };
  }

  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;

  return {
    transform,
    WebkitTransform: transform,
  };
}

const ImageDragLayer = () => {
  const { itemType, isDragging, item, initialOffset, currentOffset } =
    useDragLayer((monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }));

  function renderItem() {
    return <img src={item.src} alt={`img-${item.index}`} />;
  }

  if (!isDragging) {
    return null;
  }

  return (
    <div style={layerStyles}>
      <div style={getItemStyles(currentOffset)}>{renderItem()}</div>
    </div>
  );
};

const gallery = [
  {
    id: 1,
    src: "https://images.unsplash.com/photo-1568819317551-31051b37f69f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
  },
  {
    id: 2,
    src: "https://images.unsplash.com/photo-1582639590011-f5a8416d1101?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1349&q=80",
  },
  {
    id: 3,
    src: "https://images.unsplash.com/photo-1611145434336-2324aa4079cd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1365&q=80",
  },
  {
    id: 4,
    src: "https://images.unsplash.com/photo-1518489913881-199b7c7a081d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2374&q=80",
  },
  {
    id: 5,
    src: "https://images.unsplash.com/photo-1617271077111-8976e8c717c6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80",
  },
  {
    id: 6,
    src: "https://images.unsplash.com/photo-1577284875515-ff9b464813c2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
  },
  {
    id: 6,
    src: "https://images.unsplash.com/photo-1591238484654-c42ce1cd83f3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1364&q=80",
  },
  {
    id: 6,
    src: "https://images.unsplash.com/photo-1571348635303-dabc89cff3be?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1315&q=80",
  },
];
