import img1 from "assets/agency/section-4-1.jpg";

const GetPaidToMessage = () => {
  return (
    <div className="relative -mt-[40vw] pt-[40vw] 4xl:-mt-[25vw] 4xl:pt-[25vw]" style={bg}>
      <div className="absolute top-0 h-[20vw] w-full bg-white 3xl:h-[15vw] " style={path}></div>
      <div className="mx-auto flex flex-col gap-4 lg:gap-0">
        <div className="max-screen mx-auto px-4">
          <h1
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="500"
            data-aos-anchor-placement="top-center"
            className="archivo mb-6 w-fit text-center text-3xl font-bold !leading-none 
            text-white lg:mb-10 lg:text-6xl  xl:text-7xl 2xl:text-8xl 3xl:text-[130px]"
          >
            Get Paid To <span className="text-gr">Message</span>
          </h1>
          <div className="lg:flex-8 flex flex-col gap-4">
            <h2
              data-aos="fade-up"
              data-aos-duration="2000"
              data-aos-delay="1000"
              className="text-start text-lg font-bold  text-neutral-10 lg:text-3xl 3xl:text-4xl    "
            >
              Connect, communicate, and cash in on every chat
            </h2>
            <p
              data-aos="fade-up"
              data-aos-duration="2000"
              data-aos-delay="1000"
              className="text-start text-sm font-normal  text-neutral-10 lg:text-xl 3xl:text-3xl "
            >
              Earn tokens as you engage with viewers through direct messaging, building personal
              connections while simultaneously increasing your income.
            </p>
          </div>
        </div>
        <div
          className="relative -z-10 mx-auto aspect-video w-full bg-cover "
          style={{ backgroundImage: `url(${img1})` }}
        ></div>
      </div>
    </div>
  );
};

export default GetPaidToMessage;

const bg = {
  background:
    "linear-gradient(179.35deg, #150A55 7.4%, #4D0C80 41.75%, rgba(77, 12, 128, 0.95) 55.05%, rgba(77, 12, 128, 0.28) 80.43%, rgba(77, 12, 128, 0) 98.43%)",
};

const path = { clipPath: "polygon(0% 0%, 100% 0%, 0% 100%)" };
