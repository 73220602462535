import SimpleInputForm from "app/components/Inputs/SimpleInputForm";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import { Divider } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { CountryRegionData } from "react-country-region-selector";

import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UserDropdown from "app/components/Structure/UserDropdown";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SimpleSelectForm from "app/components/Inputs/SimpleSelectForm";
import TodayIcon from "@mui/icons-material/Today";
function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}
export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const clearValue = clearNumber(value);
  let nextValue;

  nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
    4,
    8
  )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 16)}`;

  return nextValue.trim();
}
export function formatCVC(value) {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  return clearValue.slice(0, maxLength);
}

function getMonthsInNumbers() {
  return Array.from({ length: 12 }, (_, i) =>
    (i + 1).toString().padStart(2, "0")
  );
}
function getNextTenYears() {
  var currentYear = new Date().getFullYear();
  return Array.from({ length: 10 }, (_, i) => (currentYear + i).toString());
}
export const Payout = () => {
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    company: "",
    address: "",
    apt: "",
    country: country,
    state: region,
    city: "",

    tax_ID: "",
    business_type: "",
    business_structure: "",
    gender: "",
    ethnicity: "",
    birthday: "",
    paypal: "",
    venmo: "",
    cash_app: "",
  });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const [countryIndex, setCountryIndex] = useState(null);
  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    setValues({ ...values, country: e.target.value[0] });
  };
  const handleRegionChange = (e) => {
    setValues({ ...values, state: e.target.value });
  };

  const [regions, setRegions] = useState([]);
  useEffect(() => {
    if (countryIndex !== null) {
      setRegions(CountryRegionData[countryIndex][2].split("|"));
    }
  }, [countryIndex]);

  useEffect(() => {
    setValues({ ...values, state: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);
  const [showTables, setShowTables] = useState(true);
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("payoutDetailsValues", values);
  };

  return (
    <main className=" container-fluid lg:px-4 xl:px-8 py-12  min-h-screen text-neutral-1">
      <div className="row">
        <div className="col-12 flex justify-between px-4">
          <div className=" flex flex-col gap-1 sm:gap-4 mb-4">
            <h2 className="text-lg sm:text-3xl font-semibold ">
              Add Payout Details
            </h2>
            <p className="text-neutral-4 text-xs sm:text-base">
              Enter the details below to complete registration
            </p>
          </div>
          <UserDropdown />
        </div>
      </div>
      <div className="row gap-y-4 sm:gap-y-8  mx-auto text-white  h-full min-h-screen flex-col-reverse lg:flex-row mt-4 sm:mt-8">
        <div className="col-12 col-lg-6 col-xl-6 px-0 lg:px-4 ">
          <form
            onSubmit={(e) => handleSubmit(e)}
            className="row  gap-y-4 sm:gap-y-8 text-neutral-1">
            <div className="col-12 col-sm-6">
              <SimpleInputForm
                value={values.first_name}
                placeholder={"First Name"}
                name="first_name"
                onChange={handleChange}
                label={"First Name"}
              />
            </div>

            <div className="col-12 col-sm-6">
              <SimpleInputForm
                value={values.last_name}
                placeholder={"Last Name"}
                name="last_name"
                onChange={handleChange}
                label={"Last Name"}
              />
            </div>

            <div className="col-12 col-sm-6">
              {" "}
              <SimpleInputForm
                value={values.email}
                placeholder={"Email"}
                name="email"
                onChange={handleChange}
                label={"Email"}
                type="email"
              />
            </div>

            <div className="col-12 col-sm-6">
              {" "}
              <SimpleInputForm
                value={values.phone}
                placeholder={"Phone Number"}
                name="phone"
                onChange={handleChange}
                label={"Phone Number"}
                type="tel"
                hasPattern={true}
                pattern="[0-9]*"
              />
            </div>

            <div className="col-12 col-sm-8 ">
              <SimpleInputForm
                value={values.address}
                placeholder={"Enter your address"}
                name="address"
                onChange={handleChange}
                label={"Address"}
              />
            </div>

            <div className="col-12 col-sm-4 col-xl-4">
              {" "}
              <SimpleInputForm
                value={values.apt}
                placeholder={"Apt/Unit"}
                name="apt"
                onChange={handleChange}
                label={"Apt/Unit"}
              />
            </div>

            <div className="col-12 col-sm-6 col-xl-4">
              <FormControl className="w-full">
                <InputLabel id="select-category">Country</InputLabel>
                <Select
                  sx={{ width: "100%" }}
                  id="country"
                  label="Country"
                  value={country}
                  select="true"
                  onChange={(e) => handleCountryChange(e)}>
                  {CountryRegionData.map((option, index) => (
                    <MenuItem
                      key={index}
                      value={option}
                      onClick={() => setCountryIndex(index)}>
                      {option[0]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-sm-6 col-xl-4">
              <FormControl className="w-full">
                <InputLabel id="select-category">State</InputLabel>
                <Select
                  sx={{ width: "100%" }}
                  id="state"
                  label="State"
                  value={values.state}
                  select="true"
                  onChange={(e) => handleRegionChange(e)}>
                  {regions.map((option, index) => (
                    <MenuItem key={index} value={option.split("~")[0]}>
                      {option.split("~")[0]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-sm-6 col-xl-4">
              {" "}
              <SimpleInputForm
                value={values.city}
                placeholder={"City"}
                name="city"
                onChange={handleChange}
                label={"City"}
              />
            </div>
            <div className="col-12 col-sm-6">
              <SimpleInputForm
                value={values.company}
                placeholder={"Enter company name"}
                name="company"
                onChange={handleChange}
                label={"Company(optional)"}
                required={false}
              />
            </div>
            <div className="col-12 col-sm-6 col-xl-6">
              {" "}
              <SimpleInputForm
                value={values.tax_ID}
                placeholder={"Tax ID Number"}
                name="tax_ID"
                onChange={handleChange}
                label={"Tax ID Number"}
                type="number"
              />
            </div>
            <div className="col-12 col-sm-6 col-xl-6">
              {" "}
              <SimpleSelectForm
                options={businessTypes}
                placeholder={"Business Type"}
                name="business_type"
                onChange={handleChange}
                label={"Business Type"}
              />
            </div>
            <div className="col-12 col-sm-6 col-xl-6">
              {" "}
              <SimpleSelectForm
                options={businessStructures}
                placeholder={"Business Structure"}
                name="business_structure"
                onChange={handleChange}
                label={"Business Structure"}
              />
            </div>
            <div className="col-12 col-sm-6 col-xl-4">
              {" "}
              <SimpleSelectForm
                options={genderList}
                placeholder={"Gender"}
                name="gender"
                onChange={handleChange}
                label={"Gender"}
              />
            </div>
            <div className="col-12 col-sm-6 col-xl-4">
              {" "}
              <SimpleSelectForm
                options={ethnicities}
                placeholder={"Ethnicity"}
                name="ethnicity"
                onChange={handleChange}
                label={"Ethnicity"}
              />
            </div>
            <div className="col-12 col-sm-6 col-xl-4">
              {" "}
              <SimpleInputForm
                placeholder={"Birthday"}
                name="birthday"
                onChange={handleChange}
                label={"Birthday"}
                type="date"
                hasIcon={true}
                icon={<TodayIcon sx={{ marginRight: "0.5rem" }} />}
              />
            </div>

            <Divider />
            <div>
              <p className="text-sm sm:text-base">
                Add your PayPal, Venmo and CashApp details for instant payouts.
                If you do not have any of these accounts, a 14 day wait time
                will be applied before funds are transferred to you. Instant
                payouts are determined by the type of payment customers use to
                purchase tokens.
              </p>
            </div>
            <div className="col-12 col-xl-4">
              <SimpleInputForm
                placeholder={"user@email.com"}
                name="paypal"
                onChange={handleChange}
                label={"PayPal Email"}
                type="email"
                required={false}
              />
            </div>
            <div className="col-12  col-xl-4">
              <SimpleInputForm
                placeholder={"username"}
                name="venmo"
                onChange={handleChange}
                label={"Venmo Handle"}
                hasIcon={true}
                required={false}
                icon={
                  <AlternateEmailIcon sx={{ fontSize: "1rem", margin: "0" }} />
                }
              />
            </div>
            <div className="col-12 col-xl-4">
              <SimpleInputForm
                placeholder={"username"}
                name="cash_app"
                onChange={handleChange}
                label={"Cash App Handle"}
                hasIcon={true}
                required={false}
                icon={
                  <AttachMoneyIcon sx={{ fontSize: "1rem", margin: "0" }} />
                }
              />
            </div>
            <Divider />
            <div className="flex flex-col gap-3 sm:gap-6">
              <p className="text-sm sm:text-base">
                By submitting this form, you agree to the{" "}
                <Link to="/about/terms  " className="text-link">
                  Beladed Payouts Terms of Service.
                </Link>{" "}
              </p>
              <button type="submit" className="btn btn-main  w-fit btn-large">
                Submit
              </button>
            </div>

            <div>
              <Link
                to={`/waitlist/win-25k`}
                className="btn btn-second-grey text-neutral-1 flex items-center gap-2 btn-large w-fit">
                <span>
                  <ArrowBackIcon />
                </span>
                Go Back
              </Link>
            </div>
          </form>
        </div>
        <div className="col-12 col-lg-6 col-xl-6 px-0  lg:px-4">
          <div className="bg-neutral-11 p-4 lg:p-6 flex flex-col ">
            <div className="flex items-center justify-between text-neutral-1 gap-1 sm:gap-4 flex-wrap">
              <h2 className="font-bold text-lg sm:text-3xl flex gap-1">
                Commission Table
                <button
                  className="block lg:hidden"
                  onClick={() => setShowTables(!showTables)}>
                  <ArrowDropDownIcon />
                </button>
              </h2>
              <p className="font-semibold text-xs sm:text-base">
                By Token Package
              </p>
            </div>
            <div
              className={`grid lg:mt-8  sm:grid-cols-[repeat(auto-fit,minmax(300px,1fr))] xl:grid-cols-2 h-full max-h-0 lg:max-h-[1000px] transition-all duration-500  gap-2 overflow-hidden ${
                showTables && "!max-h-[1000px] mt-4 sm:mt-8"
              } `}>
              {offers.map((item, index) => {
                return <OfferBox key={index} {...item} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Payout;

const offers = [
  {
    title: "Boss",
    earning: 499.99,
    discount: 5,
    description: "$7,999.01",
    isBestOffer: false,
    options: [
      "25000 Tokens",
      "Send 500 Priority Messages",
      "Draft 250 Models To Your Team",
    ],
    endpoint: "Tiny",
  },
  {
    title: "Captain",
    earning: 249.99,
    discount: 5,
    description: "$2996.01",
    isBestOffer: false,
    options: [
      "10000 Tokens",
      "Send 200 Priority Messages",
      "Draft 100 Models To Your Team",
    ],
    endpoint: "Tiny",
  },
  {
    title: "Large",
    earning: 24.99,
    discount: 5,
    description: "$139.76",
    isBestOffer: false,
    options: [
      "600 Tokens",
      "Send 12 Priority Messages",
      "Draft 6 Models To Your Team",
    ],
    endpoint: "Tiny",
  },
  {
    title: "Small",
    earning: 7.99,
    discount: 5,
    description: "$14.97",
    isBestOffer: false,
    options: [
      "200 Tokens",
      "Send 4 Priority Messages ",
      "Draft 2 Models To Your Team",
    ],
    endpoint: "Tiny",
  },
];
const OfferBox = ({
  title,

  options,
  endpoint,

  earning,
}) => {
  const goTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <div
      className={`relative w-full tracking-normal bg-white flex  flex-col justify-between gap-4 rounded-lg border border-neutral-10 p-6 
    
 
    `}>
      <div className="flex flex-col gap-2 border-b border-neutral-10 ">
        <h3 className={`text-2xl font-semibold text-primary-6  `}>{title}</h3>{" "}
        <div className="mb-2 flex gap-1 items-center">
          <span className={`text-sm text-neutral-6  `}>You earn:</span>
          <span className="text-base font-medium text-neutral-1">
            ${earning}
          </span>
        </div>
      </div>
      <div>
        {" "}
        <ul className=" flex flex-col gap-2 sm:gap-4">
          {options.map((item, index) => {
            return (
              <li
                className="flex items-center gap-2 text-neutral-1"
                key={index}>
                <span className="text-primary-5">
                  {" "}
                  <CheckCircleOutlineIcon />
                </span>
                <span className="text-xs">{item}</span>{" "}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

const gradientText = {
  background: "linear-gradient(99.09deg, #6633CC 10.36%, #FF44B8 98.61%)",
  WebkitBackgroundClip: "text",
  webkitTextFillColor: "transparent",
  backgroundClip: "text",
  textFillColor: "transparent",
};
const businessTypes = [
  "Accounting",
  "Advertising",
  "Agriculture",
  "Architecture",
  "Automotive",
  "Banking",
  "Beauty",
  "Construction",
  "Consulting",
  "Design",
  "Education",
  "Energy",
  "Engineering",
  "Entertainment",
  "Fashion",
  "Finance",
  "Food and Beverage",
  "Healthcare",
  "Hospitality",
  "Insurance",
  "IT",
  "Legal",
  "Manufacturing",
  "Marketing",
  "Media",
  "Nonprofit",
  "Real Estate",
  "Retail",
  "Sales",
  "Science",
  "Sports",
  "Transportation",
  "Travel",
  "Utilities",
  "Wholesale",
];
const businessStructures = [
  "Sole Proprietorship",
  "Partnership",
  "Limited Partnership",
  "Limited Liability Partnership (LLP)",
  "Limited Liability Company (LLC)",
  "C Corporation",
  "S Corporation",
  "B Corporation",
  "Cooperative",
  "Nonprofit Corporation",
  "Benefit Corporation",
  "Professional Corporation (PC)",
  "Joint Venture",
  "Franchise",
  "Merger & Acquisition (M&A)",
];

const genderList = ["Male", "Female", "Prefer not to say"];
const ethnicities = [
  "African",
  "African American",
  "Alaska Native",
  "Arab",
  "Asian",
  "Biracial",
  "Black",
  "Caribbean",
  "Caucasian",
  "European",
  "Hispanic",
  "Indian",
  "Indigenous",
  "Latino",
  "Middle Eastern",
  "Multiracial",
  "Native American",
  "Pacific Islander",
  "White",
  "Other",
];
