import logo from "assets/logo.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="flex flex-col gap-10 bg-neutral-1 py-10 px-4 text-white lg:py-20 xl:gap-20 xl:px-8 ">
      <div className="mx-auto flex max-w-[1400px] flex-col items-center gap-4">
        <Link to="/agency-models" className="mb-4">
          <img src={logo} alt="Beladed" />
        </Link>
        <div>
          <p className="text-xs sm:text-base">© 2023 Beladed Inc. All rights reserved. </p>
        </div>
        <div className="flex w-full gap-3 text-2xs font-semibold text-secondary-6 sm:gap-6 sm:text-sm lg:gap-8 lg:text-base">
          <Link to="/about/terms" className="hover:text-secondary-4">
            Terms of Use
          </Link>
          <Link to="/about/privacy" className="hover:text-secondary-4">
            Privacy Policy
          </Link>
          <Link to="/about/california-privacy-policy" className="hover:text-secondary-4">
            California Consumer
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
