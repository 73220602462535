import useStaticCountdown from "app/hooks/useStaticCountdown";
import Sidebar from "assets/invest/Sidebar.jpg";
import TopbarSm from "assets/invest/Topbar-sm.jpg";
import Topbar from "assets/invest/Topbar.jpg";
import logo from "assets/logo-d.svg";
import logoSm from "assets/logo_sm.svg";
import twodots from "assets/twodots.svg";
import waves from "assets/waves-2.svg";
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";

import "../style.css";

const videosUrl = [
  "https://youtu.be/Kq5vTntrPtY",
  "https://youtu.be/F0NAoalpmOI",
  "https://youtu.be/4AILK9BzCjo",
  "https://youtu.be/hDwVq7xJe48",
];
const sortActions = (actions) => {
  return Object.entries(actions)
    .sort(([, a], [, b]) => b - a)
    .reduce(
      (r, [k, v]) => ({
        ...r,
        [k]: v,
      }),
      {},
    );
};
const Hero = () => {
  const [action, setAction] = useState(["Intro", "Team", "Market", "Summary"]);
  const [countActions, setCountActions] = useState({
    Intro: 0,
    Team: 0,
    Market: 0,
    Summary: 0,
  });

  const [sortedActions, setSortedActions] = useState(
    Object.entries(sortActions(countActions)).slice(0, 2),
  );
  const [currentVideo, setCurrentVideo] = useState("");
  useEffect(() => {
    setSortedActions(Object.entries(sortActions(countActions)).slice(0, 2));
  }, [countActions]);

  const handleClick = (e, index) => {
    setTimeout(() => {
      setCountActions({
        ...countActions,
        [e.target.name]: countActions[e.target.name] + 1,
      });
      setCurrentVideo(videosUrl[index]);
      playerRef.current.play();
    }, 500);
  };
  const [onProgress, setOnProgress] = useState(0);
  const [onDuration, setOnDuration] = useState(0);

  const playerRef = useRef(null);

  const [timerCalculate, setTimerCalculate] = useState();
  const { values: timer } = useStaticCountdown(timerCalculate ? timerCalculate * 1000 : 0);
  useEffect(() => {
    setTimerCalculate(Math.round(Math.round(onDuration) - onProgress.playedSeconds));
    console.log(timerCalculate);
  }, [onProgress]);
  const scrollToPitchDeck = () => {
    const a = document.querySelector("#PitchDeck");
    a.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="mb-10 lg:mb-20">
      <header className="mx-auto flex max-w-screen-xl items-center justify-between gap-4 py-4 px-4 md:px-8 lg:py-12">
        <Link to="/invest">
          <img src={logo} alt="Beladed" className="w-32 lg:w-64" />
        </Link>
        <button onClick={scrollToPitchDeck} className="btn-gr lg:py-4 lg:px-6">
          Download Pitch Deck
        </button>
      </header>
      <div className="hero-container mx-auto flex max-w-screen-xl flex-col items-center gap-6 py-10 px-4 sm:gap-10 md:py-20 md:px-8">
        <h1
          className="hero-title text-center text-[44px]  font-extrabold !leading-tight md:text-6xl  lg:text-7xl xl:text-8xl"
          data-aos="fade-down"
          data-aos-duration="1000"
          data-aos-delay=""
        >
          Interactive <span className=" text-gr-animation">Livestream</span>{" "}
          <span className="hero-text-animation">Entertainment</span>
        </h1>
        <p
          className="mx-auto text-center text-sm font-medium !leading-normal !tracking-[-1.2px] text-black sm:text-base md:text-2xl lg:w-[95%] 2xl:text-3xl"
          data-aos="fade-in"
          data-aos-duration="2000"
          data-aos-delay="1000"
        >
          Beladed is an interactive livestream platform for streamers and their audiences.
        </p>
        <div data-aos="fade-in" data-aos-duration="2000" data-aos-delay="1000">
          <button onClick={scrollToPitchDeck} className="btn-gr py-4 px-8">
            Download Pitch Deck
          </button>
        </div>
      </div>
      <div
        className="h-full w-full bg-cover bg-center px-1 md:px-4"
        // style={{
        //   backgroundImage: `url(${heroBg})`,
        //   backgroundSize: "100% 80%",
        // }}
      >
        <div
          className=" relative mx-auto flex h-[500px] w-full  max-w-screen-sm rounded-lg
          border-[8px] border-t-[26px] border-black bg-no-repeat  xs:h-[123vw]
           sm:h-[520px] sm:min-h-[650px] sm:border-[12px] sm:border-t-[36px] md:min-h-[720px] md:max-w-screen-md lg:min-h-[790px] lg:max-w-screen-lg xl:aspect-video   
            xl:min-h-[935px] xl:max-w-[1200px] 2xl:min-h-[970px] 2xl:max-w-screen-xl "
          data-aos="fade-in"
          data-aos-duration="2000"
          data-aos-delay="1000"
        >
          <div className="flex h-full w-full ">
            <div
              className="hidden h-full  w-[5.5%]   bg-primary-3 bg-no-repeat md:block"
              style={{
                backgroundImage: `url(${Sidebar})`,
                backgroundSize: "100% auto",
                backgroundPositionX: "center",
              }}
            ></div>
            <div className="flex   w-full  flex-col ">
              <div
                className="hidden aspect-[1360/97] w-full  flex-shrink-0  md:block "
                style={{
                  backgroundImage: `url(${Topbar})`,
                  backgroundSize: "100% 100%",
                }}
              ></div>
              <div
                className="aspect-[800/94] w-full    flex-shrink-0 md:hidden"
                style={{
                  backgroundImage: `url(${TopbarSm})`,
                  backgroundSize: "100% 100%",
                }}
              ></div>
              <div className="flex h-full  flex-col justify-between gap-4 bg-white p-2 md:p-4">
                <div className="  video-background-container h-full min-h-[50vw] sm:min-h-[320px] md:min-h-[380px] lg:h-[100%]">
                  <div className="video-background relative z-0 h-full w-full rounded-lg  bg-black ">
                    <ReactPlayer
                      url={currentVideo}
                      playsinline
                      muted={false}
                      width="100%"
                      height="100%"
                      controls={false}
                      playing={true}
                      loop={false}
                      onProgress={(obj) => setOnProgress(obj)}
                      onDuration={(value) => console.log(value)}
                      onPlay={() => setOnDuration(playerRef.current.getDuration())}
                      ref={playerRef}
                    />
                    {countActions.Intro === 0 && (
                      <div
                        className="abs-c flex-c absolute h-full w-full bg-cover "
                        style={{
                          backgroundImage: `url(${waves})`,
                          backgroundSize: "100% 100%",
                        }}
                      >
                        {" "}
                        <img src={waves} alt="" className="absolute  z-10 w-full object-cover" />
                        <img
                          src={logoSm}
                          alt=""
                          className="z-20 w-[100px] shadow-2xl sm:w-[20vw] 3xl:w-[10vw]"
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="     w-full  xl:h-[25%] 2xl:h-[20%]">
                  <div className=" flex h-full justify-end bg-neutral-11 px-2  py-3 sm:px-6">
                    <div className="flex w-full flex-col   items-center justify-center gap-y-4 gap-x-8 lg:flex-row lg:justify-between lg:gap-x-4 lg:gap-y-10 xl:gap-x-10">
                      <div className="flex w-full items-center justify-between gap-4 sm:gap-20 lg:w-auto lg:gap-10 xl:gap-20">
                        <div className="">
                          <VideoCountDown values={timer} />
                        </div>
                        <div className="flex flex-col items-center gap-2">
                          <h6 className="text-2xs font-semibold text-neutral-1 sm:text-xs ">
                            Voting Results
                          </h6>
                          <div className="flex items-center gap-4">
                            {sortedActions.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="flex flex-col items-center gap-1 transition-all duration-500"
                                >
                                  <div
                                    className={`1 min-w-[40px] bg-white px-2 py-2 text-center text-xl font-semibold text-neutral-3  sm:min-w-[88px] sm:px-5 sm:text-2xl ${
                                      index === 0 && "dark:text-secondary-6 text-secondary-6"
                                    }`}
                                  >
                                    {item[1]}
                                  </div>
                                  <p className="text-3xs font-medium capitalize text-neutral-4 sm:text-xs ">
                                    {item[0]}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-wrap items-center justify-center gap-2 sm:gap-3 xl:gap-4 ">
                        {action.map((item, index) => {
                          return (
                            <button
                              name={item}
                              key={index}
                              onClick={(e) => handleClick(e, index)}
                              disabled={index !== 0 && countActions.Intro === 0}
                              className="flex-c relative w-[100px] gap-2 rounded-md bg-primary-5 py-2 px-3 text-2xs font-medium text-white hover:bg-primary-4 disabled:bg-primary-3 xs:px-4 xs:py-2 xs:text-xs sm:rounded-lg sm:px-6 sm:py-3 sm:text-sm lg:w-[85px] xl:w-[120px]"
                            >
                              <span className=" pointer-events-none"> {item}</span>
                              {index === 0 && countActions.Intro === 0 && (
                                <div className=" pointer-events-none absolute -top-1/2 left-1/2 -mt-4 w-32 -translate-x-1/2 -translate-y-1/2 sm:w-44">
                                  <div
                                    style={{
                                      boxShadow: "0px 3px 7px rgba(180, 5, 255, 0.3)",
                                    }}
                                    className="discover-mission animate__animated animate__infinite  animate__slideInDown relative flex animate-bounce  flex-col items-center justify-center gap-2 rounded-full bg-white p-1 text-2xs font-semibold text-primary-5 sm:p-2 sm:text-xs"
                                  >
                                    <p className="flex flex-wrap items-center justify-center">
                                      {" "}
                                      Discover our mission!
                                      <span className="text-xs sm:text-base">👇🏻</span>
                                    </p>

                                    {/* <img src={arrow} alt="" width={32} /> */}
                                  </div>
                                </div>
                              )}
                            </button>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute -top-5 left-2 flex gap-2 sm:-top-6 sm:left-4">
            <div className="h-3 w-3 rounded-full bg-red-600 sm:h-4 sm:w-4"></div>
            <div className="hh-3 w-3 rounded-full bg-yellow-600 sm:h-4 sm:w-4"></div>
            <div className="h-3 w-3 rounded-full bg-green-600 sm:h-4 sm:w-4"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;

const VideoCountDown = ({ values }) => {
  const [s, setS] = useState(values.seconds.toString());
  const [m, setM] = useState(values.minutes.toString());
  useEffect(() => {
    setS(values.seconds.toString());
    setM(values.minutes.toString());
    // console.log(values, "values");
  }, [values]);

  return (
    <div className={`countdown flex justify-center gap-2 ${0 ? " countdown-end " : ""}`}>
      <span className="flex-c h-[1.5em] rounded-lg bg-neutral-12 px-2 text-xl text-neutral-2 sm:text-3xl">
        {m[0]}
      </span>
      <span className="flex-c h-[1.5em] rounded-lg bg-neutral-12 px-2 text-xl text-neutral-2 sm:text-3xl">
        {m[1]}
      </span>
      <span className="flex-c h-[1.5em]  text-xl text-white sm:text-3xl ">
        <img src={twodots} alt="" height={"100%"} className="h-1/2" />
      </span>
      <span className="flex-c h-[1.5em] rounded-lg bg-neutral-12 px-2 text-xl text-neutral-2 sm:text-3xl">
        {s[0]}
      </span>
      <span className="flex-c h-[1.5em] rounded-lg bg-neutral-12 px-2 text-xl text-neutral-2 sm:text-3xl">
        {s[1]}
      </span>
    </div>
  );
};
