import React, { useEffect, useRef, useState } from "react";
import img1 from "assets/invest/section-1-1.png";
import img1a from "assets/invest/section-1-1-a.jpg";
import img1b from "assets/invest/section-1-1-b.png";
import img2a from "assets/invest/section-1-2-a.jpg";
import img2b from "assets/invest/section-1-2-b.png";
import img2 from "assets/invest/section-1-2.png";
import { useAppContext } from "app/context/appContext";
const TargetIndustries = () => {
  const titleRef = useRef();
  const { windowWidth } = useAppContext();
  const [width, setWidth] = useState(windowWidth);

  useEffect(() => {
    setWidth(
      titleRef.current.offsetWidth +
        (windowWidth - titleRef.current.offsetWidth) / 2
    );
  }, [windowWidth]);
  return (
    <div className="gr-dark py-10 md:py-16 lg:py-24 2xl:py-32 flex flex-col gap-4">
      <div>
        <h1
          ref={titleRef}
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="500"
          className="text-[#DDD2F2] w-fit mx-auto text-4xl md:text-6xl lg:text-7xl xl:text-7xl 3xl:text-[150px] text-center font-bold leading-tight px-4 mb-10 lg:mb-6">
          Target Industries
        </h1>
      </div>
      <div className=" flex flex-col gap-10 ">
        <div className="flex justify-end 4xl:justify-center  w-full">
          <div
            className="text-white flex flex-col-reverse xl:flex-row  self-end gap-10 items-center"
            style={{ maxWidth: width + "px" }}>
            <div
              className="flex flex-col gap-4 lg:gap-6 px-4 flex-1 w-full item justify-end"
              data-aos="fade-up"
              data-aos-duration="2000"
              data-aos-delay="1000">
              <h2 className="text-bold text-2xl lg:text-3xl 3xl:text-[64px]  font-bold">
                Social/Platform Software
              </h2>
              <p className="text-[#F8F8F8] font-medium text-sm lg:text-lg 3xl:text-2xl">
                Beladed is a social and interactive livestream entertainment
                platform that connects streamers with their audiences in
                real-time. It falls under the platform software category as it
                provides a digital platform for social interaction and
                entertainment
              </p>
            </div>
            <div className="px-4 xl:px-0 flex-1w-full justify-end  relative  grid  grid-cols-5 grid-rows-6">
              <div
                className="flex justify-end"
                style={{ gridArea: "1 / 2 / 6 / 6" }}
                data-aos="flip-left"
                data-aos-duration="1000"
                data-aos-delay="1500">
                <img
                  src={img1a}
                  alt="streaming"
                  className="object-cover  rounded-xl"
                />
              </div>
              <div
                className="flex justify-end"
                style={{ gridArea: "3 / 1 / 7 / 4" }}
                data-aos="fade-right"
                data-aos-duration="1000"
                data-aos-delay="2500">
                <img src={img1b} alt="streaming" className=" object-contain" />
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-start 4xl:justify-center  w-full">
          <div
            className="text-white flex flex-col-reverse xl:flex-row-reverse  self-end gap-10 items-center"
            style={{ maxWidth: width + "px" }}>
            <div
              className="flex flex-col gap-4 lg:gap-6 px-4 flex-1 w-full item"
              data-aos="fade-up"
              data-aos-duration="2000"
              data-aos-delay="1000">
              <h2 className="font-bold text-2xl lg:text-3xl 3xl:text-6xl  ">
                Entertainment Software
              </h2>
              <p className="text-[#F8F8F8] font-medium text-sm lg:text-lg 3xl:text-2xl">
                Beladed is a platform that offers interactive live streaming
                entertainment to users. It falls under the entertainment
                software industry due to its focus on providing engaging
                experiences through online gaming, live streaming, and social
                interactions.
              </p>
            </div>
            <div
              className="px-4 xl:px-0 flex-1  justify-start w-full  grid  grid-cols-5 grid-rows-6"
              data-aos="flip-right"
              data-aos-duration="2000"
              data-aos-delay="1500">
              <div
                className="flex justify-start"
                style={{ gridArea: "1 / 1 / 6 / 5" }}
                data-aos="fade-left"
                data-aos-duration="1000"
                data-aos-delay="1500">
                <img
                  src={img2a}
                  alt="streaming"
                  className="object-cover  rounded-xl"
                />
              </div>
              <div
                className="flex justify-start"
                style={{ gridArea: "3 / 4 / 7 / 7" }}
                data-aos="fade-left"
                data-aos-duration="1000"
                data-aos-delay="2500">
                <img src={img2b} alt="streaming" className="  " />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TargetIndustries;
