import React, { useEffect } from "react";
import logo from "assets/logo.svg";
import cover from "assets/home-cover.jpg";
import { Link, useNavigate } from "react-router-dom";
const Root = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/waitlist");
  }, []);
  return (
    <main className="relative flex-c min-h-screen  overflow-hidden"></main>
  );
};

export default Root;
