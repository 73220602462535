import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Divider } from "@mui/material";
import EditGallery from "app/components/Modals/EditGallery";
import MessagingModal from "app/components/Modals/Messaging";
import ModelGallery from "app/components/Modals/ModelGallery";
import SelectModel from "app/components/Modals/SelectModel";
import UploadPhotoModal from "app/components/Modals/UploadPhotoModal";
import ProfilePhotoOwner from "app/components/Small/ProfilePhotoOwner";
import SearchDropdown from "app/components/Structure/SearchDropdown";
import UserDropdown from "app/components/Structure/UserDropdown";
import { useAppContext } from "app/context/appContext";
import useHandleAppModals from "app/hooks/useHandleAppModals";
import useHandleUpload from "app/hooks/useHandleUpload";
import logo from "assets/logo-d.svg";
import waves from "assets/waves.svg";
import axios from "axios";
import { mockData } from "data/mock-data.js";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Dashboard = () => {
  const { user } = useAppContext();
  const {
    openModal,
    setOpenModal,
    handleDynamicToggle,
    handleStaticToggle,
    handleDoubleStaticToggle,
    initialModalStates,
  } = useHandleAppModals();
  const handleKeyClose = (e) => {
    if (e.key === "Escape") {
      setOpenModal(initialModalStates);
      setSearchModal(false);
    }
  };
  const resetAllModals = () => {
    setOpenModal(initialModalStates);
    setSelectedModal("");
    setSelectedModel("");
  };
  const [selectedModal, setSelectedModal] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [searchModal, setSearchModal] = useState(false);
  const handleSearchModal = () => {
    handleStaticToggle("searchModel");
  };
  const [imagesList, setImagesList] = useState(gallery);
  const handleDeleteImage = (index) => {
    const newImageList = [...imagesList];
    newImageList.splice(index, 1);
    setImagesList(newImageList);
  };
  const fileInputRef = useRef(null);
  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const newImageList = [{ src: reader.result, alt: file.name }, ...imagesList];
      setImagesList(newImageList);
      fileInputRef.current.value = null;
    };
    reader.readAsDataURL(file);
  };
  return (
    <div className="mt-16 flex flex-col gap-10" onKeyDown={handleKeyClose}>
      <SelectModel
        state={openModal.selectModel}
        toggle={handleDynamicToggle}
        staticToggle={handleStaticToggle}
        doubleStaticToggle={handleDoubleStaticToggle}
        resetAllModals={resetAllModals}
        setSelectedModal={setSelectedModal}
        setSelectedModel={setSelectedModel}
        selectedModel={selectedModel}
        selectedModal={selectedModal}
        handleSearchModal={handleSearchModal}
      />
      <ModelGallery
        state={openModal.gallery}
        toggle={handleDynamicToggle}
        staticToggle={handleStaticToggle}
        doubleStaticToggle={handleDoubleStaticToggle}
        resetAllModals={resetAllModals}
      />
      <MessagingModal
        state={openModal.messaging}
        toggle={handleDynamicToggle}
        staticToggle={handleStaticToggle}
        doubleStaticToggle={handleDoubleStaticToggle}
        resetAllModals={resetAllModals}
      />
      <EditGallery
        state={openModal.editGallery}
        toggle={handleDynamicToggle}
        staticToggle={handleStaticToggle}
        doubleStaticToggle={handleDoubleStaticToggle}
      />
      <UploadPhotoModal
        state={openModal.uploadPhoto}
        toggle={handleDynamicToggle}
        staticToggle={handleStaticToggle}
      />
      <div
        className="flex flex-col items-center gap-5 bg-cover"
        style={{
          backgroundImage: `url(${waves})`,
          backgroundSize: "100% 100%",
        }}
      >
        <div className="-mb-8">
          <ProfilePhotoOwner />
        </div>
        <p className="text-3xl font-semibold text-neutral-1">
          {user.first_name} {user.last_name}
        </p>
        <div className="flex items-center gap-5 rounded-full bg-primary-10 py-3 px-4">
          <div className="flex items-center gap-1">
            {user.type === "model" ? (
              <span className="text-sm font-semibold text-primary-5 md:text-xl ">
                {" "}
                Earnings: $56,374
              </span>
            ) : (
              <span className="text-sm font-semibold text-primary-5 md:text-xl ">
                {" "}
                56374 tokens
              </span>
            )}
          </div>
          {user.type === "model" ? (
            <button
              onClick={(e) => handleDynamicToggle(e)}
              name="editGallery"
              className="rounded-full bg-primary-5 px-6 py-4 text-sm font-medium text-white transition-all hover:bg-primary-6"
            >
              Edit Photo Gallery
            </button>
          ) : (
            <Link
              to="/waitlist/users/tokens"
              className="rounded-full bg-primary-5 px-6 py-4 text-sm font-medium text-white transition-all hover:bg-primary-6"
            >
              Buy Tokens
            </Link>
          )}
        </div>
      </div>
      <div
        className="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] 
        gap-4  border-b pb-8  xl:grid-cols-[repeat(auto-fit,minmax(350px,1fr))] xl:gap-10"
      >
        {user.type === "model"
          ? BoxesDataModel.map((item, index) => {
              return (
                <Boxes
                  key={index}
                  index0={index}
                  {...item}
                  handleDynamicToggle={handleDynamicToggle}
                />
              );
            })
          : BoxesData.map((item, index) => {
              return (
                <Boxes
                  key={index}
                  index0={index}
                  {...item}
                  handleDynamicToggle={handleDynamicToggle}
                />
              );
            })}
      </div>

      <div>
        {/* <ImageUploader />
          <ImageList />
          <Products /> */}
        {user.type === "model" && (
          <GalleryUploads
            toggle={handleDynamicToggle}
            staticToggle={handleStaticToggle}
            imagesList={imagesList}
            handleDeleteImage={handleDeleteImage}
            handleUploadImage={handleUploadImage}
            fileInputRef={fileInputRef}
          />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
const BoxesData = [
  {
    title: "Enter to win $25K",
    description: "Show your support by sharing",
    options: ["Complete your entrees", "Share Beladed with friends", "Let people know you’re here"],
    link: ["Win $25K", "/waitlist/win-25k"],
  },
  {
    title: "Message Models",
    description: "Use tokens to send messages",
    options: [
      "Send streamers priority messages",
      "Get actual replies from streamers",
      "Start talking to streamers today!",
    ],
    link: ["Message Models", "selectModel"],
  },
  {
    title: "Refer & Earn Money",
    description: "Earn a commission for referring",
    options: [
      "Unlimited earning potential",
      "Get paid when people buy tokens ",
      "Join our Referral program today!",
    ],
    link: ["Refer People", "/waitlist/referral"],
  },
];
const BoxesDataModel = [
  {
    title: "Enter to win $25K",
    description: "Show your support by sharing",
    options: ["Complete your entrees", "Share Beladed with friends", "Let people know you’re here"],
    link: ["Win $25K", "/waitlist/win-25k"],
  },
  {
    title: "Reply To Messages",
    description: "Earn for each reply you send",
    options: [
      "Get priority messages from users",
      "Connect with your loyal followers",
      "Get paid on each and every reply",
    ],
    link: ["Reply To messages", "messaging"],
  },
  {
    title: "Refer & Earn Money",
    description: "Earn a commission for referring",
    options: [
      "Unlimited earning potential",
      "Get paid when people buy tokens ",
      "Join our Referral program today!",
    ],
    link: ["Refer People", "/waitlist/referral"],
  },
];
const Boxes = ({ title, link, handleDynamicToggle, description, index0, options }) => {
  return (
    <div
      className={`relative   flex w-full flex-col justify-between  gap-4 rounded-lg border border-neutral-10 bg-neutral-11 p-6 tracking-normal 
      
   
      `}
    >
      <div className="flex flex-col gap-2 border-b border-neutral-10  pb-4">
        <h3 className={`text-2xl font-semibold text-neutral-1  `}>{title}</h3>{" "}
        <p className={`font-medium text-neutral-5  `}>{description}</p>
      </div>
      <div>
        {" "}
        <ul className=" flex flex-col gap-4">
          {options.map((item, index) => {
            return (
              <li className="flex items-center gap-2 text-neutral-1" key={index}>
                <span className="text-primary-5">
                  {" "}
                  <CheckCircleOutlineIcon />
                </span>
                <span className="text-xs">{item}</span>{" "}
              </li>
            );
          })}
        </ul>
      </div>

      <div className="mt-4 flex w-full">
        {index0 === 1 ? (
          <button
            onClick={(e) => handleDynamicToggle(e)}
            name={link[1]}
            className="btn btn-main w-full"
          >
            {link[0]}{" "}
          </button>
        ) : (
          <Link className="btn btn-main flex-c w-full" to={link[1]}>
            {link[0]}{" "}
          </Link>
        )}
      </div>
    </div>
  );
};

const GalleryUploads = ({
  toggle,
  imagesList,
  handleDeleteImage,
  handleUploadImage,
  fileInputRef,
}) => {
  return (
    <div className="flex flex-col gap-8">
      <div className="flex items-center justify-between">
        <h2 className="text-3xl font-semibold">Image Gallery </h2>
        {/* <button
          className="btn btn-main"
          name="uploadPhoto"
          onClick={(e) => toggle(e)}>
          Upload Image
        </button> */}
        <label className="btn btn-main cursor-pointer">
          <input className="hidden" type="file" onChange={handleUploadImage} ref={fileInputRef} />
          Upload Image
        </label>
      </div>
      <div>
        <div
          className="custom-scrollbar grid  grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-2 pr-2 
            pb-4  md:gap-4   xl:grid-cols-[repeat(auto-fit,minmax(350px,1fr))] "
        >
          {imagesList.map((item, index) => {
            return (
              <button
                onClick={(e) => toggle(e)}
                name="editGallery"
                key={index}
                className="relative"
              >
                <img
                  src={item.src}
                  alt=""
                  className="pointer-events-none aspect-video w-full  object-cover"
                />
                <button
                  onClick={() => handleDeleteImage(index)}
                  className="flex-c absolute bottom-4 right-4 h-7 w-7 rounded-full bg-[#ffffffc4] transition-all duration-500 hover:scale-[1.15]"
                >
                  <DeleteIcon color="primary" />
                </button>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};
const gallery = [
  {
    id: 1,
    src: "https://images.unsplash.com/photo-1568819317551-31051b37f69f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
  },
  {
    id: 2,
    src: "https://images.unsplash.com/photo-1582639590011-f5a8416d1101?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1349&q=80",
  },
  {
    id: 3,
    src: "https://images.unsplash.com/photo-1611145434336-2324aa4079cd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1365&q=80",
  },
  {
    id: 4,
    src: "https://images.unsplash.com/photo-1518489913881-199b7c7a081d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2374&q=80",
  },
  {
    id: 5,
    src: "https://images.unsplash.com/photo-1617271077111-8976e8c717c6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80",
  },
  {
    id: 6,
    src: "https://images.unsplash.com/photo-1577284875515-ff9b464813c2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
  },
  {
    id: 6,
    src: "https://images.unsplash.com/photo-1591238484654-c42ce1cd83f3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1364&q=80",
  },
  {
    id: 6,
    src: "https://images.unsplash.com/photo-1571348635303-dabc89cff3be?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1315&q=80",
  },
];
const ImageUploader = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", selectedFile);
    try {
      const response = await axios.post("http://localhost:5000/upload", formData);
      console.log(formData);
      setSelectedFile(null);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileInputChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  return (
    <form onSubmit={handleSubmit} encType="multipart/form-data">
      <input type="file" name="file" onChange={handleFileInputChange} />
      <button type="submit">Upload</button>
    </form>
  );
};

const ImageList = () => {
  const [imageList, setImageList] = useState([]);

  useEffect(() => {
    const getImages = async () => {
      try {
        const response = await axios.get("http://localhost:5000/images");
        setImageList(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getImages();
  }, []);

  return (
    <div>
      {imageList.map((image) => (
        <div key={image}>
          <img src={`http://localhost:3001/uploads/${image}`} alt={image} />
        </div>
      ))}
    </div>
  );
};

const Products = () => {
  const url = "/api/v1/products";
  const [nameValue, setNameValue] = useState("");
  const [priceValue, setPriceValue] = useState("");
  const [imageValue, setImageValue] = useState(null);
  const [products, setProducts] = useState([]);

  const handleImageChange = async (e) => {
    const imageFile = e.target.files[0];
    const formData = new FormData();
    formData.append("image", imageFile);
    try {
      const {
        data: {
          image: { src },
        },
      } = await axios.post(`${url}/uploads`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setImageValue(src);
    } catch (error) {
      setImageValue(null);
      console.log(error);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const product = { name: nameValue, price: priceValue, image: imageValue };
      await axios.post(url, product);
      fetchProducts();
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProducts = async () => {
    try {
      const {
        data: { products },
      } = await axios.get(url);
      setProducts(products);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <div className="container">
      <form onSubmit={handleFormSubmit} className="file-form">
        <div>
          <label htmlFor="image">Image:</label>
          <input type="file" id="image" accept="image/*" onChange={handleImageChange} required />
        </div>
        <button type="submit">Add Product</button>
      </form>
      <div className="products">
        {products.map((product) => (
          <article key={product.id} className="product">
            <img src={product.image} alt={product.name} className="img" />
            <footer>
              <p>{product.name}</p>
              <span>${product.price}</span>
            </footer>
          </article>
        ))}
      </div>
    </div>
  );
};
