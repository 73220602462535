import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";


import Footer from "./sections/Footer";

import GetStarted from "./sections/GetStarted";
import Hero from "./sections/Hero";
import Section1 from "./sections/Section1";
import Section2 from "./sections/Section2";
import Section3 from "./sections/Section3";
import Section4 from "./sections/Section4";
import Section5 from "./sections/Section5";

const Home = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <main className=" overflow-x-hidden">
      <Hero />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <GetStarted />
      <Footer />
    </main>
  );
};

export default Home;
