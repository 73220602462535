import img1 from "assets/tiktok/section-5-1-1.webp";
import img2 from "assets/tiktok/section-5-2.jpg";

const JoinTournament = () => {
  return (
    <div className=" max-screen mx-auto flex flex-col gap-10 py-10 px-4 md:py-16 lg:py-24 xl:gap-20 xl:px-8 2xl:py-32">
      <div>
        <h1
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="500"
          className=" hero-title archivo mb-6  text-start text-5xl font-bold leading-tight text-neutral-1 lg:mb-6 lg:text-6xl  xl:text-7xl 2xl:text-8xl 3xl:text-[150px]"
        >
          Join Team <span className="text-gr">Tournaments</span>
        </h1>
        <div className="flex flex-col gap-4 lg:gap-8">
          <h2
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="1000"
            className="text-start text-lg font-bold  text-neutral-2 lg:text-3xl 3xl:text-4xl    "
          >
            Compete, collaborate, and conquer with fellow streamers.
          </h2>
          <p
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="1000"
            className="text-start text-sm font-normal  text-neutral-2 lg:text-xl 3xl:text-3xl "
          >
            Team up with other streamers for exciting tournaments and challenges, expanding your
            network, fostering collaboration, and sharing in the glory of victory.
          </p>
        </div>
      </div>

      <div className=" grid-rows-[1rem repeat(2,1fr) 1rem] grid w-full grid-cols-9  rounded-xl">
        <div
          className=" self-center"
          style={{ gridArea: "1 / 2 / 5 / 10" }}
          data-aos="zoom-in"
          data-aos-duration="1500"
          data-aos-delay="1000"
        >
          <img src={img1} alt="Target Verticals" className="w-full rounded-lg" />
        </div>
        <div
          style={{ gridArea: "2 / 1 / 4 / 4" }}
          className=""
          data-aos="fade-right"
          data-aos-duration="2000"
          data-aos-delay="1500"
        >
          <img src={img2} alt="Target Verticals" className="rounded-lg " />
        </div>
      </div>
    </div>
  );
};

export default JoinTournament;
