import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import SearchIcon from "@mui/icons-material/Search";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import axios from "axios";
import { mockData } from "data/mock-data.js";
import { useEffect, useRef, useState } from "react";

import { useQuery } from "react-query";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CircularLoading from "../Small/Loading";
import UserBoxSelect from "../Small/UserBoxSelect";
import Modal from "../Structure/Modal";
import { Divider } from "@mui/material";

const ModelGallerySingle = ({
  state,
  toggle,
  staticToggle,
  doubleStaticToggle,
}) => {
  const fetchModels = async () => {
    // const { data } = await axios.get(`http://localhost:5000/models`);
    const data = mockData["models"];
    return data;
  };
  const { isLoading, isError, data, error } = useQuery(
    "ProfileFollowing",
    fetchModels,
    {
      refetchOnWindowFocus: false,
    }
  );

  const [selectedModel, setSelectedModel] = useState("");
  const handleSelectModel = (id) => {
    setSelectedModel(id);
  };
  const checkIfSelected = (id) => {
    const isSelected = data.indexOf(id);

    return id === selectedModel.id ? true : false;
  };
  const handleSelect = () => {
    doubleStaticToggle("messaging", "gallerySingle");

    // handleSearchModal();
  };
  useEffect(() => {
    console.log(selectedModel);
  }, [selectedModel]);

  const [modalState, setModalState] = useState("gallery");
  const [enoughTokens, setEnoughTokens] = useState(true);
  useEffect(() => {
    if (!enoughTokens) {
      setModalState("buy");
    }
  }, []);
  if (isLoading) {
    return (
      <div className="flex-c h-[200px] w-full ">
        <CircularLoading />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex-c h-[200px] w-full  text-lg font-medium">
        Error: {error.message}
      </div>
    );
  }
  return (
    <Modal state={state} close={toggle}>
      <div className="relative mx-auto my-auto flex w-[95vw] sm:w-[600px]   flex-col gap-6  rounded-lg bg-white px-3 py-4  md:px-8 md:py-8 lg:w-[780px]">
        <div className="flex  w-full items-start justify-between">
          <div className="flex w-full  flex-col gap-3 md:gap-6">
            <div className="flex items-center justify-between ">
              {" "}
              <div className="flex items-center gap-5">
                <div
                  className={`w-20 h-20 aspect-square   flex-shrink-0 overflow-hidden rounded-full `}>
                  <img
                    src={
                      "https://i.scdn.co/image/ab6761670000ecd4faf63cac20a454f861478fc0"
                    }
                    alt={"name"}
                    className={`rounded-full w-20 h-20 aspect-square scale-[1.02]`}
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <h2 className="font-semibold text-neutral-1">Selena Gomez</h2>
                  <span className="text-neutral-5 text-2xs">@SelenaGomez</span>
                  <div className="flex flex-wrap items-center gap-2 text-neutral-1 text-xs">
                    <p>
                      Tokens Required:{" "}
                      <span className="font-bold">200 tokens</span>{" "}
                    </p>
                    <Divider
                      sx={{
                        backgroundColor: "#DDDDDD",
                        width: "1px",
                        height: 8,
                      }}
                    />
                    <p>
                      Avg. Response Time:{" "}
                      <span className="font-bold">3 days</span>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
        <div className="flex  flex-col gap-6">
          <div
            className="custom-scrollbar grid max-h-[400px] grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-2 
           overflow-y-auto pr-2  pb-4 md:max-h-[450px]  md:gap-4 ">
            {gallery.map((item, index) => {
              return (
                <img
                  src={item}
                  key={index}
                  alt=""
                  className="aspect-video object-cover"
                />
              );
            })}
          </div>
        </div>

        <div className="mt-3 flex  items-center gap-4 self-end justify-self-end">
          <button
            name="gallerySingle"
            className="btn btn-tertiary-grey"
            onClick={(e) => toggle(e)}>
            Close
          </button>
          <button
            name="draftSuccess"
            className="btn btn-main "
            onClick={() => handleSelect()}>
            Send Priority Message
            <ArrowRightAltIcon />
          </button>
        </div>
      </div>
    </Modal>
  );
};
export default ModelGallerySingle;

const gallery = [
  "https://images.unsplash.com/photo-1568819317551-31051b37f69f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
  "https://images.unsplash.com/photo-1582639590011-f5a8416d1101?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1349&q=80",
  "https://images.unsplash.com/photo-1611145434336-2324aa4079cd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1365&q=80",
  "https://images.unsplash.com/photo-1518489913881-199b7c7a081d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2374&q=80",
  "https://images.unsplash.com/photo-1617271077111-8976e8c717c6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80",
  "https://images.unsplash.com/photo-1577284875515-ff9b464813c2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
  "https://images.unsplash.com/photo-1591238484654-c42ce1cd83f3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1364&q=80",
  "https://images.unsplash.com/photo-1571348635303-dabc89cff3be?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1315&q=80",
];
