import img1 from "assets/instagram/section-4-1.webp";
import img2 from "assets/instagram/section-4-2.jpg";
import img3 from "assets/instagram/section-4-3.jpg";

const Section4 = () => {
  return (
    <div className="gr-dark relative -mt-[40vw] pt-[40vw] pb-10 lg:pt-[35vw] xl:pt-[35vw] 4xl:-mt-[25vw] 4xl:pt-[25vw]">
      <div className="absolute top-0 h-[20vw] w-full bg-white 3xl:h-[15vw] " style={path}></div>
      <div className="mx-auto flex flex-col gap-4 lg:gap-10">
        <div className="max-screen mx-auto px-4">
          <h1
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="500"
            data-aos-anchor-placement="top-center"
            className="archivo mb-6 w-fit  text-5xl font-bold !leading-none 
            text-white lg:mb-10 lg:text-6xl  xl:text-7xl 2xl:text-8xl 3xl:text-[130px]"
          >
            Expand Your <span className="text-gr">Reach</span>
          </h1>
          <div className="lg:flex-8 flex flex-col gap-4">
            <h2
              data-aos="fade-up"
              data-aos-duration="2000"
              data-aos-delay="1000"
              className=" text-start text-lg  font-bold text-neutral-10 lg:text-3xl 3xl:text-4xl   "
            >
              Stream your livestream across multiple platforms
            </h2>
            <p
              data-aos="fade-up"
              data-aos-duration="2000"
              data-aos-delay="1000"
              className="text-start text-sm font-normal  text-neutral-10 lg:text-xl 3xl:text-3xl "
            >
              Broadcast your live video on multiple online platforms simultaneously to reach a wider
              audience. Reach your audience wherever they may be on sites like YouTube, Twitch,
              TikTok, and others.
            </p>
          </div>
        </div>
        <div className="max-screen  relative px-4">
          <div className=" grid w-full grid-cols-[50px_repeat(8,1fr)] grid-rows-4  rounded-xl">
            <div
              className=" l self-center"
              style={{ gridArea: "1 / 1 / 5 / 9" }}
              data-aos="zoom-in"
              data-aos-duration="1500"
              data-aos-delay="1000"
            >
              <img src={img1} alt="Target Verticals" className="rounded-lg" />
            </div>
            <div
              style={{ gridArea: "3 / 7 / 5 / 10" }}
              className="h-full w-full"
              data-aos="fade-right"
              data-aos-duration="2000"
              data-aos-delay="1500"
            >
              <img src={img2} alt="Target Verticals" className=" rounded-lg object-cover" />
            </div>
            <div
              style={{ gridArea: "3 / 3 / 5 / 5" }}
              className="hidden"
              data-aos="fade-up"
              data-aos-duration="2000"
              data-aos-delay="2000"
            >
              <img src={img3} alt="Target Verticals" className="rounded-lg " />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;

const bg = {
  background:
    "linear-gradient(179.35deg, #150A55 7.4%, #4D0C80 41.75%, rgba(77, 12, 128, 0.95) 55.05%, rgba(77, 12, 128, 0.28) 80.43%, rgba(77, 12, 128, 0) 98.43%)",
};

const path = { clipPath: "polygon(0% 0%, 100% 0%, 0% 100%)" };
