import { Link } from "react-router-dom";

const Avatar = ({
  gridsCount,
  image,
  name,
  showName,
  dynamicWidth,
  username,
  size = 20,
  staticToggle,
}) => {
  return (
    <div
      onClick={() => staticToggle("gallerySingle")}
      className="flex flex-col items-center gap-2
       text-neutral-3 hover:text-neutral-1 dark:text-neutral-9 dark:hover:text-neutral-12
      cursor-pointer
       "
      style={{
        width: dynamicWidth && `calc((100%/${gridsCount}) - 32px  - 0.01px)`,
      }}>
      <div className=" relative">
        <div
          className={` h-16 w-16 md:h-20 md:w-20 aspect-square rounded-full image-zoom-in overflow-hidden `}>
          <img
            src={image}
            alt={username}
            className={`rounded-full h-16 w-16 md:w-20 md:h-20 aspect-square object-cover transition-all duration-500 `}
          />
        </div>
        <AvatarStatus />
      </div>
      {showName && (
        <div>
          <p
            className="ellipsis text-xs font-medium"
            style={{ maxWidth: size * 4 + "px" }}>
            @{username}
          </p>
        </div>
      )}
    </div>
  );
};

export default Avatar;
const AvatarStatus = () => {
  return (
    <div className="h-4 w-4 rounded-full bg-[#12B76A]  border border-white absolute right-0 bottom-0 "></div>
  );
};
