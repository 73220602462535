import { countries } from "data/countries";
import React, { useEffect, useRef, useState } from "react";

import "../style.css";

const initialState = {
  name: "",
  email: "",
  phone: "",
  country: "",
  job_title: "",
  company: "",
  message: "",
};

const Contact = () => {
  const [values, setValues] = useState(initialState);
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!values.name || !values.email || !values.phone || !values.country) {
      setErrorMessage("please enter all required fields.");
    } else {
      console.log(values);
      setSuccessMessage("Your message has been sent!");
      setValues(initialState);
    }
  };
  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
    }
  }, [errorMessage]);
  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage("");
      }, 2000);
    }
  }, [successMessage]);
  return (
    <div className="flex flex-col gap-10 px-2 py-10 text-white lg:py-16 xl:gap-20 xl:px-8">
      <div className="mx-auto flex max-w-[1400px] flex-col gap-10 ">
        <div className="z-10 pt-0 sm:pt-10 md:pt-32 2xl:pt-48 3xl:pt-64" id="PitchDeck">
          <h1
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="500"
            className="  mb-6 px-4 text-center text-5xl font-bold leading-tight lg:mb-6 lg:text-start lg:text-9xl 3xl:text-[140px]"
          >
            Get Our PitchDeck
          </h1>
        </div>

        <div
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="500"
          className="mx-auto flex w-full max-w-[1050px] flex-col gap-6 rounded-lg bg-[rgba(0,0,0,0.3)] px-6 py-8 md:px-10 lg:gap-10 lg:px-20 lg:py-10"
        >
          <h2 className="text-center text-4xl font-bold leading-tight md:text-6xl lg:text-start lg:text-6xl">
            Are you an investor?
          </h2>
          <p className="text-sm lg:text-lg xl:text-xl">
            Please complete this short form and our team will reach out to you via email.
          </p>
          <form onSubmit={handleSubmit} className="flex flex-col gap-4 sm:gap-6">
            <Input
              label={"full name*"}
              placeholder="Enter your Full Name"
              onChange={handleChange}
              name="name"
              minLength={4}
              value={values.name}
            />
            <Input
              label={"Email Address*"}
              placeholder="Enter your Email Address"
              type="email"
              onChange={handleChange}
              name="email"
              value={values.email}
            />
            <div className="flex w-full flex-col gap-6 sm:flex-row sm:gap-4 [&>*]:flex-1">
              <Input
                label={"phone number*"}
                placeholder="Enter your Phone Number"
                type="tel"
                onChange={handleChange}
                name="phone"
                minLength={8}
                value={values.phone}
              />

              <Select
                label={"country/region*"}
                placeholder="Select your Country/Region"
                onChange={handleChange}
                name="country"
                minLength={3}
                value={values.country}
                options={countries}
              />
            </div>
            <div className="flex w-full flex-col gap-6 sm:flex-row sm:gap-4 [&>*]:flex-1">
              <Input
                label={"Company"}
                placeholder="Enter your company's name"
                isRequired={false}
                onChange={handleChange}
                name="company"
                minLength={2}
                value={values.twitter}
              />
              <Input
                label={"Job title"}
                placeholder="Job title"
                isRequired={false}
                onChange={handleChange}
                name="job_title"
                minLength={2}
                value={values.instagram}
              />
            </div>
            <div className="relative flex flex-col gap-1">
              <label className="text-xs uppercase text-[#FEFEFE] lg:text-sm">Message</label>
              <textarea
                onChange={handleChange}
                name="message"
                rows="4"
                value={values.message}
                placeholder="Your Message..."
                className="rounded-xl border border-white bg-transparent p-4 text-sm outline-0 placeholder:text-neutral-8 focus:border-secondary-5 active:border-secondary-5 lg:text-lg"
              ></textarea>
              {errorMessage && (
                <span className="absolute -bottom-6 left-1/2 -translate-x-1/2 whitespace-nowrap text-2xs font-semibold capitalize text-feedback-failure-7 sm:-bottom-8 sm:text-sm">
                  {errorMessage}
                </span>
              )}
              {successMessage && (
                <span className="absolute -bottom-6 left-1/2 -translate-x-1/2 whitespace-nowrap text-2xs font-semibold capitalize text-feedback-success-6 sm:-bottom-8 sm:text-sm">
                  {successMessage}
                </span>
              )}
            </div>
            <button className="white-to-gr mt-2 rounded-full border-0 py-4 lg:mt-6" type="submit">
              <span className="text-gr text-base font-bold sm:text-lg"> Submit</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;

const Input = ({
  label,
  type = "text",
  isRequired = true,
  placeholder,
  onChange,
  span = false,
  name,
  value,
  minLength = 2,
}) => {
  return (
    <div className="flex flex-col gap-1">
      <label className="text-2xs uppercase text-[#FEFEFE] sm:text-xs lg:text-sm">{label}</label>
      <div className="relative w-full">
        <input
          type={type}
          name={name}
          value={value}
          required={isRequired}
          placeholder={placeholder}
          minLength={minLength}
          className={`w-full rounded-xl border
         border-white bg-transparent p-4  text-xs outline-0 placeholder:text-neutral-8 focus:border-secondary-5 active:border-secondary-5 sm:text-sm lg:text-lg 
         ${span && "pl-8"}
         `}
          onChange={onChange}
        />
        {span && (
          <span className="absolute left-4 top-1/2 -translate-y-1/2 text-sm text-white lg:text-lg">
            @
          </span>
        )}
      </div>
    </div>
  );
};

const Select = ({
  label,
  type = "text",
  isRequired = true,
  placeholder,
  onChange,
  span = false,
  name,
  value,
  minLength = 2,
  options = [],
}) => {
  return (
    <div className="flex flex-col gap-1">
      <label className="text-2xs uppercase text-[#FEFEFE] sm:text-xs lg:text-sm">{label}</label>
      <div className="relative w-full">
        <select
          type={type}
          name={name}
          value={value}
          required={isRequired}
          placeholder={placeholder}
          minLength={minLength}
          className={`w-full rounded-xl border border-white
         bg-transparent p-4 text-xs   outline-0 placeholder:text-neutral-8 focus:border-secondary-5 active:border-secondary-5 sm:text-sm lg:text-lg 
         ${span && "pl-8"}
         `}
          onChange={onChange}
        >
          <option value="" disabled>
            {placeholder}
          </option>
          {options.map((item, index) => {
            return (
              <option
                className={`!p-2 text-neutral-1 ${index === 0 && "!border-b pb-2"}`}
                key={index}
                value={item.name}
              >
                {item.name}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};
