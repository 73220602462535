import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { useAppContext } from "./context/appContext";

const ProtectedRoute = ({ children }) => {
  const { user } = useAppContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      navigate("/waitlist");
    }
  }, []);
  return children;
};

export default ProtectedRoute;
