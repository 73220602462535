import img1 from "assets/onlyfans/section-1-1.webp";

const Section1 = () => {
  return (
    <div className="  mx-auto flex flex-col items-center gap-10 pt-10 md:pt-16 lg:pt-24 xl:gap-20   2xl:pt-20">
      <div className="max-screen px-4  ">
        <h1
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="500"
          className="title-text-shadow archivo mb-6 text-start text-5xl font-semibold  !leading-none
          text-neutral-1  lg:mb-10 lg:text-6xl lg:font-bold  xl:text-7xl 2xl:text-8xl 3xl:text-[115px] "
        >
          Create Fun <span className="text-gr-animation">Interactive </span>{" "}
          <span className="text-5xl lg:text-7xl  xl:text-8xl 2xl:text-[110px] 3xl:text-[140px]">
            {" "}
            <span className="text-gr-animation"> Livestream</span> Videos
          </span>
        </h1>
        <h2
          data-aos="fade-in"
          data-aos-duration="2000"
          data-aos-delay="1500"
          className="mb-6 text-start text-xl  font-bold text-neutral-2 lg:text-3xl   xl:mb-8 3xl:text-4xl  "
        >
          Engage Your Audience in Real-Time with Livestream Votes
        </h2>
        <p
          data-aos="fade-in"
          data-aos-duration="2000"
          data-aos-delay="1500"
          className="text-start text-base font-medium  text-neutral-4 lg:text-xl 3xl:text-3xl "
        >
          Create up to 4 vote buttons that lets your audience interact with you in real-time
          livestreams. Have fun with your live audience with your entertaining livestream games and
          shows in real-time.
        </p>
      </div>

      <div className="w-full">
        <img
          src={img1}
          className="w-full object-cover"
          alt=""
          data-aos="zoom-out"
          data-aos-duration="1500"
          data-aos-delay="1000"
        />
      </div>
    </div>
  );
};

export default Section1;
