import useIsDesktop from "app/hooks/useIsDesktop";
import img1 from "assets/brand/section-1-1.jpg";
import img2 from "assets/brand/section-1-2.jpg";

const DriveYouBusiness = ({ scrollToGetInTouch }) => {
  const { isDesktopScreen } = useIsDesktop();
  return (
    <div
      className=" mx-auto flex flex-col gap-10 gap-y-20 py-10 px-4 lg:gap-20 lg:py-20  lg:px-8
      xl:max-w-[1150px] xl:gap-20 2xl:max-w-[1400px] 2xl:py-32 3xl:max-w-screen-2xl"
    >
      <div className="flex flex-col gap-8 lg:flex-row 2xl:gap-10 3xl:gap-16">
        <div className="flex flex-col gap-4 xl:gap-6 2xl:gap-8 [&>*]:w-fit ">
          <div className="flex flex-col gap-4 xl:gap-6 [&>*]:w-fit">
            <div
              data-aos="fade-down"
              data-aos-duration="1000"
              data-aos-delay="1000"
              className="rounded-full bg-neutral-11"
            >
              <p className="text-gr    py-2 px-5 text-center text-sm tracking-[2.5px] xl:text-lg 3xl:text-xl">
                Beladed's Technology
              </p>
            </div>
            <h2
              data-aos="fade-down"
              data-aos-duration="1500"
              data-aos-delay="1000"
              className="text-3xl font-bold xl:text-4xl 2xl:text-5xl 3xl:text-6xl"
            >
              Drive Your Business Growth and Engagement
            </h2>
          </div>
          <p
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="1000"
            className="text-sm text-neutral-4 xl:text-base 2xl:text-lg 3xl:text-xl"
          >
            Beladed's technology offers innovative features such as multi-channel broadcasting,
            interactive livestreams, and customizable ads to help businesses drive growth and
            engagement.
          </p>
          <button
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="1000"
            onClick={scrollToGetInTouch}
            className="btn-gr btn rounded-full lg:py-3 lg:px-6 xl:py-4 xl:px-10"
          >
            Get In Touch
          </button>
        </div>
        <div className="relative mx-auto flex  w-[90%] flex-shrink-0 justify-center lg:w-1/2">
          <img
            src={img1}
            data-aos="fade-right"
            data-aos-duration="1500"
            data-aos-delay={isDesktopScreen ? "2500" : "1500"}
            alt="Strategize and Innovate with Beladed  "
            className="aspect-square h-fit w-[100%] max-w-[600px] object-cover lg:w-[90%] xl:w-[80%]"
          />
          <div className="gradient-1 absolute inset-0 top-4 left-4 -z-10 mx-auto aspect-square max-w-[600px]  translate-x-2 translate-y-2  lg:w-[90%] xl:w-[80%] ">
            <p
              data-aos="fade-left"
              data-aos-duration="1500"
              data-aos-delay={isDesktopScreen ? "2500" : "1500"}
              className=" vertical-text absolute -right-6 bottom-2 text-sm uppercase tracking-[5px] lg:text-base"
            >
              Growth
            </p>
          </div>
        </div>
      </div>
      <div className="relative  flex flex-col gap-8 lg:left-4 lg:flex-row-reverse 2xl:gap-10 3xl:gap-16">
        <div className="flex flex-col gap-4 xl:gap-6 2xl:gap-8 [&>*]:w-fit">
          <div className="flex  flex-col gap-4 xl:gap-6 [&>*]:w-fit">
            <div
              data-aos="fade-down"
              data-aos-duration="1000"
              data-aos-delay="1000"
              className="rounded-full bg-neutral-11"
            >
              <p className="text-gr    py-2 px-5 text-center text-sm tracking-[2.5px] xl:text-lg 3xl:text-xl">
                Branded Community Building
              </p>
            </div>
            <h2
              data-aos="fade-down"
              data-aos-duration="1500"
              data-aos-delay="1000"
              className="text-3xl font-bold xl:text-4xl 2xl:text-5xl 3xl:text-6xl"
            >
              Exclusive Rewards and Unique Content for Your Audience
            </h2>
          </div>
          <p
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="1000"
            className=" text-sm text-neutral-4 xl:text-base  2xl:text-lg 3xl:text-xl"
          >
            Beladed specializes in creating branded communities that foster lasting connections with
            your customers. Our solutions offer exclusive rewards and unique content tailored to
            your audience's interests, helping you enhance your reputation, boost loyalty, and
            increase engagement.
          </p>
          <button
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="1000"
            onClick={scrollToGetInTouch}
            className="btn-gr btn rounded-full lg:py-3 lg:px-6 xl:py-4 xl:px-10"
          >
            Get In Touch
          </button>
        </div>
        <div className="relative mx-auto flex    w-[90%] flex-shrink-0 justify-center lg:w-1/2 lg:justify-start">
          <img
            src={img2}
            data-aos="fade-left"
            data-aos-duration="1500"
            data-aos-delay={isDesktopScreen ? "2500" : "1500"}
            alt="Strategize and Innovate with Beladed"
            className="aspect-square w-[100%] max-w-[600px] object-cover lg:h-fit lg:w-[90%] xl:w-[80%]"
          />
          <div className="gradient-1 absolute inset-0 top-4 -right-0 -z-10 mx-auto aspect-square max-w-[600px] -translate-x-4 object-cover lg:-right-4 lg:top-4 lg:mx-0 lg:w-[90%] lg:-translate-x-5 xl:w-[80%] ">
            <p
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-delay={isDesktopScreen ? "2500" : "1500"}
              className="vertical-text absolute -left-6 bottom-2 text-sm uppercase tracking-[5px] lg:text-base"
            >
              Exclusive Rewards
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriveYouBusiness;
