import img from "assets/advisor/section-2.jpg";

const Section2 = ({ scrollToGetInTouch }) => {
  return (
    <div
      style={{ "--image-url": `url(${img})` }}
      className={`relative flex  h-[500px] max-h-[900px] items-start 
     justify-start bg-[image:var(--image-url)] bg-cover  bg-no-repeat lg:h-[max(100vh,700px)]`}
    >
      <div
        className="ml-4 flex w-[90%] flex-col gap-6 bg-[rgba(102,51,204,0.85)] p-6 text-white
      sm:ml-[5%] sm:p-8 md:max-w-xl lg:ml-[10%] lg:p-10 xl:max-w-3xl 2xl:max-w-3xl 2xl:gap-8 2xl:p-12"
      >
        <h2
          data-aos="fade-down"
          data-aos-duration="1000"
          data-aos-delay="1000"
          className="text-3xl font-bold sm:text-4xl xl:text-5xl 2xl:text-6xl 3xl:text-7xl"
        >
          Join Beladed Advisors Today
        </h2>
        <p
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="1500"
          className=" text-sm xl:text-base  2xl:text-lg 3xl:text-xl"
        >
          Join Beladed Advisors to innovate and make a lasting impact in the digital landscape.
          Share your expertise and strengthen your brand's presence in various industries, including
          eSports, entertainment, cryptocurrency, and more. Join a dynamic community of industry
          leaders who value collaboration and make a difference.
        </p>
        <button
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="1500"
          onClick={scrollToGetInTouch}
          className="btn white-to-gr w-fit rounded-full border-0 px-6 py-2 2xl:px-10 2xl:py-3 3xl:px-20 3xl:py-4"
        >
          <span className="text-gr text-sm font-semibold  lg:text-base 2xl:text-lg 3xl:text-xl">
            Let’s Talk
          </span>
        </button>
      </div>
    </div>
  );
};

export default Section2;
