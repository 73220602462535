import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Avatar from "app/components/CustomComponents/Avatar";
import SimpleInputForm from "app/components/Inputs/SimpleInputForm";
import SimpleSelectForm from "app/components/Inputs/SimpleSelectForm";
import CashModal from "app/components/Modals/CashModal";
import MessagingModal from "app/components/Modals/Messaging";
import ModelGallerySingle from "app/components/Modals/ModelGallerySingle";
import PaypalModal from "app/components/Modals/PaypalModal";
import VenmoModal from "app/components/Modals/VenmoModal";
import VideoModal from "app/components/Modals/VideoModal";
import CircularLoading from "app/components/Small/Loading";
import UserDropdown from "app/components/Structure/UserDropdown";
import useCountdown from "app/hooks/useCountdown";
import useHandleAppModals from "app/hooks/useHandleAppModals";
import buyCongrats from "assets/BuyStep3-congrats.svg";
import checkMark from "assets/Checkmark-purple.svg";
import checkMarkWhite from "assets/Checkmark-white.svg";
import VoteToken from "assets/VoteToken.svg";
import card from "assets/card.png";
import cash from "assets/cash.svg";
import logo from "assets/logo-d.svg";
import paypal from "assets/paypal.svg";
import play from "assets/play.svg";
import twodots from "assets/twodots.svg";
import venmo from "assets/venmo.svg";
import axios from "axios";
import { mockData } from "data/mock-data";
import { useEffect, useRef, useState } from "react";
import { CountryRegionData } from "react-country-region-selector";
import { useQuery } from "react-query";
import { Link, unstable_HistoryRouter, useNavigate } from "react-router-dom";

function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}
export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const clearValue = clearNumber(value);
  let nextValue;

  nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(
    8,
    12,
  )} ${clearValue.slice(12, 16)}`;

  return nextValue.trim();
}
export function formatCVC(value) {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  return clearValue.slice(0, maxLength);
}

function getMonthsInNumbers() {
  return Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, "0"));
}
function getNextTenYears() {
  var currentYear = new Date().getFullYear();
  return Array.from({ length: 10 }, (_, i) => (currentYear + i).toString());
}
export const BuyTokens = () => {
  const [chosenOffer, setChosenOffer] = useState("");
  const [pickedMethod, setPickedMethod] = useState("creditCard");
  const [chosenPayment, setChosenPayment] = useState("card");
  const [billingInfoValues, setBillingInfoValues] = useState({
    full_name: "",
    zip: "",
    billing_address: "",
    city: "",
    country: "",
  });
  const [cardInfoValues, setCardInfoValues] = useState({
    holder: "",
    number: "",
    expiry: { year: "", month: "" },
    cvc: "",
    isDefault: false,
  });
  const [paypalValues, setPaypalValues] = useState({ email: "" });
  const [cashValues, setCashValues] = useState({ username: "", phone: "" });
  const [venmoValues, setVenmoValues] = useState({ username: "", phone: "" });
  const [country, setCountry] = useState("");
  const handlePick = (e) => {
    setPickedMethod(e.target.name);
  };
  const handleChange = (e) => {
    setBillingInfoValues({
      ...billingInfoValues,
      [e.target.name]: e.target.value,
    });
  };
  const handleCardInfoValues = (e) => {
    if (e.target.name === "number") {
      e.target.value = formatCreditCardNumber(e.target.value);
    }
    if (e.target.name === "cvc") {
      e.target.value = formatCVC(e.target.value);
    }

    setCardInfoValues({ ...cardInfoValues, [e.target.name]: e.target.value });
  };
  const handlePaypalValues = (e) => {};
  const handleCashValues = (e) => {
    setCashValues({ ...cashValues, [e.target.name]: e.target.value });
  };
  const handleVenmoValues = (e) => {
    setVenmoValues({ ...venmoValues, [e.target.name]: e.target.value });
  };
  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    setBillingInfoValues({ ...billingInfoValues, country: e.target.value[0] });
  };
  // useEffect(() => {
  //   setBillingInfoValues({ ...billingInfoValues, region: "" });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [country]);

  const [modalOpen, setModalOpen] = useState(false);
  const handleModal = () => {
    setModalOpen(!modalOpen);
  };
  const url =
    "https://player.vimeo.com/video/667528642?h=01bb3a9b98&autoplay=1&title=0&byline=0&portrait=0";
  const { values: timer } = useCountdown(1000 * 60 * 5);

  const handleMethod = () => {
    switch (chosenPayment) {
      case "card":
        return (
          <Card
            handleChange={handleChange}
            handleCardInfoValues={handleCardInfoValues}
            handleCountryChange={handleCountryChange}
            country={country}
            cardInfoValues={cardInfoValues}
            setCardInfoValues={setCardInfoValues}
          />
        );
      case "paypal":
        return (
          <Paypal
            handleChange={handleChange}
            handlePaypalValues={handlePaypalValues}
            handleCountryChange={handleCountryChange}
            country={country}
          />
        );
      case "cash app":
        return (
          <Cash
            handleChange={handleChange}
            handleCashValues={handleCashValues}
            handleCountryChange={handleCountryChange}
            country={country}
          />
        );
      case "venmo":
        return (
          <Venmo
            handleChange={handleChange}
            handleVenmoValues={handleVenmoValues}
            handleCountryChange={handleCountryChange}
            country={country}
          />
        );
      default:
        break;
    }
  };
  const [cashModal, setCashModal] = useState(false);
  const handleCashModal = () => {
    setCashModal(!cashModal);
  };
  const [venmoModal, setVenmoModal] = useState(false);
  const handleVenmoModal = () => {
    setVenmoModal(!venmoModal);
  };
  const [paypalModal, setPaypalModal] = useState(false);
  const handlePaypalModal = () => {
    setPaypalModal(!paypalModal);
  };
  const handlePurchase = (e) => {
    e.preventDefault();
    switch (chosenPayment) {
      case "card":
        console.log("cardInfoValues:", cardInfoValues);
        break;
      case "paypal":
        handlePaypalModal();
        console.log("paypalValues:", paypalValues);
        break;
      case "cash app":
        handleCashModal();
        console.log("cashValues:", cashValues);
        break;
      case "venmo":
        handleVenmoModal();
        console.log("venmoValues:", venmoValues);
        break;
      default:
        break;
    }
    console.log("billingInfoValues:", billingInfoValues);
    console.log("chosenOffer:", chosenOffer.title);
    console.log("chosenPayment:", chosenPayment);
  };
  const offerRef = useRef();
  const clickScroll = () => {
    offerRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const [step, setStep] = useState(1);
  return (
    <main className=" container-fluid mx-auto  min-h-screen max-w-screen-2xl px-0   py-6 text-neutral-1 sm:py-12 lg:px-8">
      <VideoModal state={modalOpen} toggle={handleModal} title={chosenOffer.title} url={url} />
      <CashModal state={cashModal} toggle={handleCashModal} setStep={setStep} />
      <VenmoModal state={venmoModal} toggle={handleVenmoModal} setStep={setStep} />
      <PaypalModal state={paypalModal} toggle={handlePaypalModal} setStep={setStep} />
      <div className="mb-8 flex   flex-wrap items-center justify-between gap-4 px-4">
        <Link to="/waitlist/dashboard">
          <img src={logo} alt="logo" className=" w-44 md:w-auto" />
        </Link>
        <UserDropdown />
      </div>
      {step === 1 ? (
        <BuyTokensStep1 setStep={setStep} setChosenOffer={setChosenOffer} />
      ) : step === 2 ? (
        <div className="row mx-auto  h-full min-h-screen  gap-y-8 px-2 text-white sm:px-4">
          <div className="col-12  ">
            <div className="flex  flex-col gap-8">
              <div className="mb-8 flex flex-col items-center justify-between gap-4 sm:flex-row">
                <div className="flex flex-col gap-2 xl:gap-4">
                  <div className="flex items-center justify-center gap-2 md:justify-start xl:gap-4">
                    <h2 className="text-xl font-semibold text-neutral-1 lg:text-3xl">
                      {" "}
                      Buy Tokens
                    </h2>
                    <img src={VoteToken} alt="vote token" />
                  </div>
                  <p className="text-2xs text-neutral-6 lg:text-xs">
                    Choose your payment method below
                  </p>
                </div>
                <div className="flex flex-col items-center gap-2 font-medium text-neutral-1">
                  <span className="">Offer Expires In-</span>
                  <CountDown timer={timer} />
                </div>
              </div>
            </div>
            <div className="flex  max-w-screen-lg flex-col gap-8">
              <div ref={offerRef}>
                {chosenOffer && (
                  <div className="flex w-full flex-col gap-4">
                    <h4 className="font-medium text-neutral-1">Token Details</h4>
                    <div className="flex w-full   flex-col  items-center justify-between gap-6 rounded border border-neutral-10 p-6 sm:w-fit sm:flex-row sm:gap-10 xl:gap-20 xl:p-6 ">
                      <div className="flex w-full flex-col    items-start  gap-4 sm:flex-row   sm:items-center md:justify-between lg:w-auto">
                        <div className="flex flex-col  gap-2 border-b pb-4  pr-3 sm:border-b-0   sm:border-r  sm:pr-5 lg:border-b-0 lg:pb-0">
                          <h4 className="text-lg font-semibold text-primary-6 lg:text-2xl">
                            {chosenOffer.title}
                          </h4>
                          <div className="flex flex-wrap items-center gap-1">
                            <span className={`text-sm font-medium text-black xl:text-base   `}>
                              ${chosenOffer.price}/
                            </span>
                            <span className={`text-xs text-neutral-6 xl:text-sm  `}>
                              %{chosenOffer.discount} Discount
                            </span>
                          </div>
                        </div>
                        <div>
                          {" "}
                          <ul className="flex flex-col gap-2 lg:gap-4 ">
                            {chosenOffer.options.map((item, index) => {
                              return (
                                <li className="flex items-center gap-2" key={index}>
                                  <span className="text-primary-5">
                                    <CheckCircleOutlineIcon />
                                  </span>
                                  <span className="text-xs text-neutral-1">{item}</span>{" "}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                      {chosenOffer.title === "Boss" && (
                        <button
                          className="flex w-full flex-col  items-center gap-2 rounded-lg bg-primary-10 px-6 py-3 transition-all duration-300 hover:bg-primary-9 sm:w-auto lg:w-auto xl:px-10 xl:py-5"
                          onClick={handleModal}
                        >
                          <img src={play} alt="play" />
                          <span className="font-semibold  text-primary-4">
                            {chosenOffer.title} Benefits
                          </span>
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <form onSubmit={handlePurchase} className="flex flex-col gap-8">
                <div className="flex items-center gap-1 rounded ">
                  {methodOptions.map((item, index) => {
                    return (
                      <button
                        key={index}
                        name={item.name}
                        onClick={() => setChosenPayment(item.name)}
                        className={`flex h-16 w-20 flex-col items-center justify-center gap-1 border border-neutral-9 bg-neutral-10 text-xs font-medium capitalize text-neutral-1 ${
                          item.name === chosenPayment && "!bg-primary-5 !text-white"
                        } `}
                      >
                        <img src={item.image} alt={item.name} className="w-5" />
                        {item.name}
                      </button>
                    );
                  })}
                </div>
                {handleMethod()}
                <div>
                  <p className="text-sm text-neutral-1 lg:text-base">
                    Upon completing your purchase, you’ll receive an email with your order
                    confirmation. Tokens purchased are linked to the email address{" "}
                    <span className="text-link">(john_doe@gmail.com)</span>. Use the same email to
                    create a Beladed account when we launch Beladed Live.
                  </p>
                </div>
                <div className="flex flex-col gap-6 ">
                  <p className="text-sm text-neutral-5 lg:text-base">
                    By clicking 'Purchase tokens' you agree to our{" "}
                    <Link to="/about/terms" className="text-link">
                      Payment Terms.
                    </Link>
                  </p>
                  <button
                    type="submit"
                    // onClick={(e) => handlePurchase(e)}
                    className="btn btn-main btn-large w-fit"
                    disabled={!chosenOffer}
                  >
                    Continue
                  </button>
                  <button
                    onClick={() => setStep(1)}
                    className="btn btn-large btn-second-grey flex w-fit items-center gap-2 text-neutral-1"
                  >
                    <span>
                      <ArrowBackIcon />
                    </span>
                    Go Back
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className="max-screen flex-c h-full flex-col gap-8 py-8">
          <div className="flex-c flex-col gap-4 ">
            <img src={buyCongrats} alt="congrats" />
            <div className="flex flex-col items-center gap-4">
              <p className="text-center text-3xl font-semibold">
                {" "}
                Congratulation! Your order is complete 🚀
              </p>
              <p className="dark:text-neutral-8 text-center text-base text-neutral-4">
                you’ll receive an email with your order confirmation. Tokens purchased are linked to
                the email address <span className="text-link">(john_doe@gmail.com)</span>. Use the
                same email to create a Beladed account when we launch Beladed Live.
              </p>
            </div>
          </div>

          <div className="flex items-center justify-center gap-2">
            <Link to="/waitlist/dashboard" className="btn btn-main ">
              Back To Home
            </Link>
          </div>
        </div>
      )}
    </main>
  );
};

export default BuyTokens;
const CountDown = ({ timer: values }) => {
  const [s, setS] = useState(values.seconds.toString());
  const [m, setM] = useState(values.minutes.toString());
  useEffect(() => {
    setS(values.seconds.toString());
    setM(values.minutes.toString());
  }, [values]);
  return (
    <div className="countdown flex items-center justify-center gap-3">
      <span className="flex-c rounded-lg border border-neutral-9 bg-white py-1 px-2 text-2xl font-bold text-neutral-4 lg:py-3 lg:px-4 lg:text-4xl">
        {m[0]}
      </span>

      <span className="flex-c rounded-lg border border-neutral-9 bg-white py-1 px-2 text-2xl font-bold text-neutral-4 lg:py-3 lg:px-4 lg:text-4xl">
        {m[1]}
      </span>

      <img src={twodots} alt="" className="h-4 lg:h-10" />

      <span className="flex-c rounded-lg border border-neutral-9 bg-white py-1 px-2 text-2xl font-bold text-neutral-4 lg:py-3 lg:px-4 lg:text-4xl">
        {s[0]}
      </span>

      <span className="flex-c rounded-lg border border-neutral-9 bg-white py-1 px-2 text-2xl font-bold text-neutral-4 lg:py-3 lg:px-4 lg:text-4xl">
        {s[1]}
      </span>
    </div>
  );
};

const GeneralPaymentInputs = ({ handleCountryChange, handleChange, country }) => {
  return (
    <div className="flex flex-1 flex-col gap-4">
      <h3 className="font-medium text-neutral-1">Billing Info</h3>
      <div className="row   gap-y-6">
        <div className=" col-12 col-sm-6 col-xl-12">
          <SimpleInputForm
            placeholder={"Full Name"}
            name="full_name"
            onChange={handleChange}
            label={"Full Name"}
          />
        </div>
        <div className=" col-12 col-sm-6 col-xl-12">
          <SimpleInputForm
            placeholder={"Billing Address"}
            name="billing_address"
            onChange={handleChange}
            label={"Billing Address"}
          />
        </div>
        <div className="flex gap-6">
          <div className="w-full">
            <SimpleInputForm
              placeholder={"City"}
              name="city"
              onChange={handleChange}
              label={"City"}
            />
          </div>
          <div className="w-full">
            <SimpleInputForm
              placeholder={"Zip Code"}
              name="zip"
              onChange={handleChange}
              label={"Zip Code"}
              type="number"
            />
          </div>
        </div>
        <div className="col-12 col-sm-6 col-xl-12">
          <FormControl className="w-full">
            <InputLabel id="select-category">Country</InputLabel>
            <Select
              sx={{ width: "100%" }}
              id="country"
              label="Country"
              value={country}
              select
              required={true}
              onChange={(e) => handleCountryChange(e)}
            >
              {CountryRegionData.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option[0]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  );
};

const Card = ({
  handleChange,
  handleCardInfoValues,
  handleCountryChange,
  country,
  cardInfoValues,
  setCardInfoValues,
}) => {
  return (
    <div className="flex w-full flex-col flex-wrap gap-8 xl:flex-row">
      <GeneralPaymentInputs
        handleCountryChange={handleCountryChange}
        handleChange={handleChange}
        country={country}
      />
      <div className="flex  flex-1 flex-col gap-4">
        <h3 className="font-medium text-neutral-1">Credit Card Info</h3>
        <div className="row  gap-y-6">
          <div className=" col-12 col-sm-6 col-xl-12">
            <SimpleInputForm
              placeholder={"Card Holder’s Name"}
              name="holder"
              onChange={handleCardInfoValues}
              label={"Card Holder’s Name"}
            />
          </div>
          <div className=" col-12 col-sm-6 col-xl-12">
            <SimpleInputForm
              placeholder={"Card Number"}
              name="number"
              onChange={handleCardInfoValues}
              label={"Card Number"}
            />
          </div>

          <div className="col-12 col-sm-6">
            <SimpleSelectForm
              value={cardInfoValues.expiry.month}
              onChange={(e) =>
                setCardInfoValues({
                  ...cardInfoValues,
                  expiry: {
                    ...cardInfoValues.expiry,
                    month: e.target.value,
                  },
                })
              }
              options={getMonthsInNumbers()}
              label="Exp. Month"
            />
          </div>
          <div className="col-12 col-sm-6 ">
            <SimpleSelectForm
              value={cardInfoValues.expiry.year}
              onChange={(e) =>
                setCardInfoValues({
                  ...cardInfoValues,
                  expiry: {
                    ...cardInfoValues.expiry,
                    year: e.target.value,
                  },
                })
              }
              options={getNextTenYears()}
              label="Exp. Year"
            />
          </div>

          <div className="col-12 col-sm-6 col-xl-12">
            <SimpleInputForm
              placeholder={"CVC Number"}
              name="cvc"
              onChange={handleCardInfoValues}
              label={"CVC Number"}
              type="tel"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
const Paypal = ({ handleChange, handlePaypalValues, handleCountryChange, country }) => {
  return (
    <div className="flex w-full flex-col flex-wrap gap-8 xl:flex-row">
      <GeneralPaymentInputs
        handleCountryChange={handleCountryChange}
        handleChange={handleChange}
        country={country}
      />

      <div className="flex  flex-1 flex-col gap-4">
        <h3 className="font-medium text-neutral-1">Paypal Info</h3>
        <div className="row ">
          <div className=" col-12 col-sm-6 col-xl-12">
            <SimpleInputForm
              placeholder={"Enter Your Paypal Email"}
              name="email"
              onChange={handlePaypalValues}
              label={"PayPal Email Address"}
              type="email"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
const Cash = ({ handleChange, handleCashValues, handleCountryChange, country }) => {
  return (
    <div className="flex w-full flex-col flex-wrap gap-8 xl:flex-row">
      <GeneralPaymentInputs
        handleCountryChange={handleCountryChange}
        handleChange={handleChange}
        country={country}
      />

      <div className="flex  flex-1 flex-col gap-4">
        <h3 className="font-medium text-neutral-1">Cash App Info</h3>
        <div className="row  gap-y-6">
          <div className="col-12 col-sm-6 col-xl-12">
            <SimpleInputForm
              placeholder={"Enter Your Phone Number"}
              name="phone"
              onChange={handleCashValues}
              label={"Phone Number"}
              type="tel"
            />
          </div>
          <div className="col-12 col-sm-6 col-xl-12">
            <SimpleInputForm
              placeholder={"username"}
              name="username"
              onChange={handleCashValues}
              label={"Cash App Handle"}
              hasIcon={true}
              icon={<AttachMoneyIcon sx={{ fontSize: "1rem", margin: "0" }} />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
const Venmo = ({ handleChange, handleVenmoValues, handleCountryChange, country }) => {
  return (
    <div className="flex w-full flex-col flex-wrap gap-8 xl:flex-row">
      <GeneralPaymentInputs
        handleCountryChange={handleCountryChange}
        handleChange={handleChange}
        country={country}
      />

      <div className="flex  flex-1 flex-col gap-4">
        <h3 className="font-medium text-neutral-1">Venmo Info</h3>
        <div className="row gap-y-6">
          <div className="col-12 col-sm-6 col-xl-12">
            <SimpleInputForm
              placeholder={"Enter Your Phone Number"}
              name="phone"
              onChange={handleVenmoValues}
              label={"Phone Number"}
              type="tel"
            />
          </div>
          <div className="col-12 col-sm-6 col-xl-12">
            <SimpleInputForm
              placeholder={"Venmo Handle"}
              name="username"
              onChange={handleVenmoValues}
              label={"Venmo Handle"}
              hasIcon={true}
              icon={<AlternateEmailIcon sx={{ fontSize: "1rem", margin: "0" }} />}
            />
          </div>
          <form className="hidden" action="https://bitpay.com/checkout" method="post">
            <input type="hidden" name="action" defaultValue="checkout" />
            <input type="hidden" name="posData" defaultValue="" />
            <input type="hidden" name="notificationType" defaultValue="json" />
            <input
              type="hidden"
              name="data"
              defaultValue="lQbz0lKBE2+SS18wyo+2tD074FOG5qRfn3+x1l5X04vgIoZyRuC2eZSdEy/gzFyCT/6exG/S5Xh7s+jcb9dUHAAcwcS1X52YMgSdgcwidPvy9bUOj7gX6ndsZJBjXNoYA/iF1ZhM9wVmUrxsGyMJt0VfnMApyU0yrmST1HLM8ivqBwb1mLf+/cVc6219z/zr2CQXCkGqyOaRGUAIgIa3a+y/pt6l6C29Y/Bcse1EvXjevUyPeEnmHFhUDkM1U0MczYBX2xt/tqgNBkqpj2FvJ92GQJ275IZ5tsYrT9B5WYX6qzoLweTcTKt+FRvq28PHLrwHTnzL85gaC5WHDMg3BGndPuJaZAME9f0f2xkj2y5ebGAESuBO1MuLQMLp/EHS"
            />
            <input
              type="image"
              src="https://bitpay.com/cdn/en_US/bp-btn-pay-currencies.svg"
              name="submit"
              style={{ width: 210 }}
              alt="BitPay, the easy way to pay with bitcoins."
            />
          </form>
        </div>
      </div>
    </div>
  );
};
const methodOptions = [
  { name: "card", image: card },
  { name: "paypal", image: paypal },
  { name: "cash app", image: cash },
  { name: "venmo", image: venmo },
];

const bitPay = [
  {
    package: "tiny",
    value:
      "lQbz0lKBE2+SS18wyo+2tD074FOG5qRfn3+x1l5X04vgIoZyRuC2eZSdEy/gzFyCdc5GQw0kkGjVFBewdxTe8rp+VLtbsfu0gYtOpv71U+Bkyo7y5dB7jRl1BdN9LBJuL4C2k9xZrenniHeFjdLwTnrAexYHLD6/eSATQ4Bfip3ELt1npSaUo9MsfbIz47iIOkYD6KslMV0UOzqk0q/Smy7uivGFy6JP/gCqhqIyfDVNXf/lQPhpy9yZEgTPypL/rPxzhynItoWXnWGXuK8KeqOIgtHwUofyHyr0LxXwiGpKdHoKYIQE2Gpc2YhyEhsJzFUN0zZjY3qvm7lcO8729Aba3moSqEJNQSBL+jp8ptzKW/SBbd/SuIIRahxWXysl",
  },
  {
    package: "small",
    value:
      "lQbz0lKBE2+SS18wyo+2tD074FOG5qRfn3+x1l5X04vgIoZyRuC2eZSdEy/gzFyCDBxTfEKwj7ruLK6G77ECjGdX7rY28OH8vAANDsSmfNXeCQExE8uwm4ZD/u+fnR4eLRQ76ugNV/NWBTITelUQ8CX/rhUV7lV58NgcBYR/lISlVpzBt39PE5JAZb1RiOtFmbrRpKndywT7d4aGSVvC/RHfDKDPbiofQ3yqZdvLVkwgmENRI7WUlK6rZ7zVroVyU+YNoDNdMClE0wB+/ztr+2+ClE6Unk1nMeGxUutm8d6sZB50p+IuyguY6P9yyTmKEcZw0ZieQ5LwMD4HtMw/Z5p7DYIXch+XTmoDHl3wZj4HrqLVXBDmQ6z4SzSOhiUR",
  },
  {
    package: "basic",
    value:
      "lQbz0lKBE2+SS18wyo+2tD074FOG5qRfn3+x1l5X04vgIoZyRuC2eZSdEy/gzFyChkfOeix5mLdbz9H8qY5p4B5LiXl0wx+kpTq25LlxAl5oZmfmkF7HVjtCbIIegll0bl369YOa3DTW1KRb6kOus0hIiKAX3yTRe01aCbiC6oaGkTkCaRQAf0RQoNpdBsj8mYPgcv29QUyeYraP5gJ8C5g0RvVA44Kbnwfv2sXC908DkEbvThgGwqQe8R/Fi7Edrmj+C2LahIQAAPd8O51bE0YSp+6fOazUfWloD2UkgAJVqgasgtSab/fKRCZs4KrrpiiW6IlSKjJOv5HdykxmbFAiJb58tTj3f2nCm/rbrctH2y7yLoDASh5Fwd/3e5is",
  },
  {
    package: "large",
    value:
      "lQbz0lKBE2+SS18wyo+2tD074FOG5qRfn3+x1l5X04vgIoZyRuC2eZSdEy/gzFyCLuH4CqS077UcTIWZ+PrXyzDGHlEeRizUibwhrGaLNUi/W4oC4znxLQj1KVpDxVIzfvXvBB6kari2xcBUmGKEeW6+HH276m4g+GMhE6Ci0j/w+INskaUzhI2qIQd4d6SKclewu3VWW//MLe91LzCx8PNbopByNX96dg2j9vDnnKaBr3uTSXRw1ujJ1eaav7urngoixKYBAauyq4qApLQ5agV8TP5ZwLEk/wDwG01PgXhw8YK3FKDOV6IU5nDgGC4mJxEWW9i+Y/34R6Eeopdqjb4dJsspA5oXJ2oXqav32h3Rsg5RgoiI0gIip3uuXQYK",
  },
  {
    package: "huge",
    value:
      "lQbz0lKBE2+SS18wyo+2tD074FOG5qRfn3+x1l5X04vgIoZyRuC2eZSdEy/gzFyCjPprdkGLXPT5E1Tto2qiLE6N0VylrLYYMscab/LbVsjQ1JHY3tE+PUxDRkefD5cxGLFZgdKisy7ckNzYl3UduiW/NPKyncMvf/sxB9uomlMKXi8yME6R613pa/W2kR43DsZWVo6q8YM187MC0UrfJT+r1By384FkBPP8fSrF+3pI5anmXyTuS74oYnAeBcKXN28dL2LoqO3pzcfZtEwYFWyEF5iP4CMxawPou5hdPifdM4jliHCIfj6PXZSTglWVsifqiSWXe70t/mvIDHa7UkO+pXBdx8+gIn/XHmhz1tTa5/fvVNO62ibLk8crs0ey",
  },
  {
    package: "leader",
    value:
      "lQbz0lKBE2+SS18wyo+2tD074FOG5qRfn3+x1l5X04vgIoZyRuC2eZSdEy/gzFyCjPprdkGLXPT5E1Tto2qiLE6N0VylrLYYMscab/LbVsjQ1JHY3tE+PUxDRkefD5cxGLFZgdKisy7ckNzYl3UduiW/NPKyncMvf/sxB9uomlMKXi8yME6R613pa/W2kR43DsZWVo6q8YM187MC0UrfJT+r1By384FkBPP8fSrF+3pI5anmXyTuS74oYnAeBcKXN28dL2LoqO3pzcfZtEwYFWyEF5iP4CMxawPou5hdPifdM4jliHCIfj6PXZSTglWVsifqiSWXe70t/mvIDHa7UkO+pXBdx8+gIn/XHmhz1tTa5/fvVNO62ibLk8crs0ey",
  },
  {
    package: "captain",
    value:
      "lQbz0lKBE2+SS18wyo+2tD074FOG5qRfn3+x1l5X04vgIoZyRuC2eZSdEy/gzFyCjPprdkGLXPT5E1Tto2qiLE6N0VylrLYYMscab/LbVsjQ1JHY3tE+PUxDRkefD5cxGLFZgdKisy7ckNzYl3UduiW/NPKyncMvf/sxB9uomlMKXi8yME6R613pa/W2kR43DsZWVo6q8YM187MC0UrfJT+r1By384FkBPP8fSrF+3pI5anmXyTuS74oYnAeBcKXN28dL2LoqO3pzcfZtEwYFWyEF5iP4CMxawPou5hdPifdM4jliHCIfj6PXZSTglWVsifqiSWXe70t/mvIDHa7UkO+pXBdx8+gIn/XHmhz1tTa5/fvVNO62ibLk8crs0ey",
  },
  {
    package: "boss",
    value:
      "lQbz0lKBE2+SS18wyo+2tD074FOG5qRfn3+x1l5X04vgIoZyRuC2eZSdEy/gzFyCjPprdkGLXPT5E1Tto2qiLE6N0VylrLYYMscab/LbVsjQ1JHY3tE+PUxDRkefD5cxGLFZgdKisy7ckNzYl3UduiW/NPKyncMvf/sxB9uomlMKXi8yME6R613pa/W2kR43DsZWVo6q8YM187MC0UrfJT+r1By384FkBPP8fSrF+3pI5anmXyTuS74oYnAeBcKXN28dL2LoqO3pzcfZtEwYFWyEF5iP4CMxawPou5hdPifdM4jliHCIfj6PXZSTglWVsifqiSWXe70t/mvIDHa7UkO+pXBdx8+gIn/XHmhz1tTa5/fvVNO62ibLk8crs0ey",
  },
];

const BuyTokensStep1 = ({ toggle, setChosenOffer, setStep }) => {
  const navigate = useNavigate();
  const handleGoBack = (params) => {
    navigate(-1);
  };

  return (
    <div className="flex h-full flex-col gap-8 px-4">
      <Models />
      <div className="dark:border-neutral-3 flex items-center justify-between border-neutral-9 md:border-b md:pb-7 ">
        <div className="flex w-full flex-col items-center gap-4 lg:items-start">
          <p className="text-center text-neutral-4">Ready for an unforgettable experience? </p>
          <div className="flex items-center justify-center gap-4 lg:items-start">
            <h2 className="text-center text-2xl font-semibold md:text-3xl">Buy Tokens Now!</h2>
            <img src={VoteToken} alt="vote token" />
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-wrap gap-2 gap-y-6 pb-8 sm:flex-row  md:gap-x-4">
        {offers.map((item, index) => {
          return (
            <OfferBox key={index} {...item} setChosenOffer={setChosenOffer} setStep={setStep} />
          );
        })}
      </div>
      <div>
        <button
          onClick={() => handleGoBack()}
          className="btn btn-large btn-second-grey flex hidden w-fit items-center gap-2 text-neutral-1"
        >
          <span>
            <ArrowBackIcon />
          </span>
          Go Back
        </button>
      </div>
    </div>
  );
};

const offers = [
  {
    title: "Tiny",
    price: 9.99,
    discount: 5,
    description: "Includes $0.39 per token pricing",
    isBestOffer: false,
    options: ["25 Vote Token", "Save $14.97"],
    endpoint: "Tiny",
  },
  {
    title: "Small",
    price: 24.99,
    discount: 5,
    description: "Includes $0.39 per token pricing",
    isBestOffer: false,
    options: ["100 Vote Tokens", "4x More Votes", "Save $14.97 per token"],
    endpoint: "Tiny",
  },

  {
    title: "Basic",
    price: 44.99,
    discount: 5,
    description: "Includes $0.17 per token pricing",
    isBestOffer: true,
    options: ["250 Vote Token", "10x More Votes", "Save $54.91"],
    endpoint: "Tiny",
  },
  {
    title: "Large",
    price: 99.99,
    discount: 5,
    description: "Includes $0.16 per token pricing",
    isBestOffer: false,
    options: ["600 Vote Token", "24x More Votes", "Save $139.76"],
    endpoint: "Tiny",
  },
  {
    title: "Huge",
    price: 199.99,
    discount: 5,
    description: "Includes $0.13 per token pricing",
    isBestOffer: false,
    options: ["1500 Vote Token", "60x More Votes", "Save $399.40"],
    endpoint: "Tiny",
  },
  {
    title: "Leader",
    price: 499.99,
    discount: 5,
    description: "Includes $0.11 per token pricing",
    isBestOffer: false,
    options: ["4500 Vote Token", "180x More Votes", "Save $1298.21"],
    endpoint: "Tiny",
  },
  {
    title: "Captain",
    price: 999.99,
    discount: 5,
    description: "Includes $0.09 per token pricing",
    isBestOffer: false,
    options: ["10000 Vote Token", "400x More Votes", "Save $2996.01"],
    endpoint: "Tiny",
  },
  {
    title: "Boss",
    price: 1999.99,
    discount: 5,
    description: "Includes $0.07 per token pricing",
    isBestOffer: false,
    options: ["25000 Vote Token", "1000x More Votes", "Save $7996.01"],
    endpoint: "Tiny",
  },
];
const OfferBox = ({
  title,
  price,
  discount,
  description,
  isBestOffer,
  options,
  endpoint,
  setChosenOffer,
  setStep,
}) => {
  return (
    <div
      className={`relative flex flex-[45%] flex-col justify-between gap-4 rounded-lg border border-neutral-10 p-6 md:flex-[31%]  lg:gap-6 xl:flex-[22%]
    
    ${isBestOffer && "gradient-animations !text-white "}
    `}
    >
      <div className="dark:border-neutral-3 flex flex-col gap-2 border-b border-neutral-10 pb-5">
        <h3 className={`text-2xl font-semibold text-primary-6  ${isBestOffer && " !text-white"}`}>
          {title}
        </h3>{" "}
        <div className="mb-4 flex gap-1">
          <span className={`font-medium text-black   ${isBestOffer && " !text-white"}`}>
            ${price}/
          </span>
          <span className={`text-sm text-neutral-6  ${isBestOffer && " !text-neutral-9"}`}>
            %{discount} Discount
          </span>
        </div>
        <p className={`text-xs text-neutral-4     ${isBestOffer && " !text-neutral-9"}`}>
          {description}
        </p>
      </div>
      <div>
        {" "}
        <ul className=" flex flex-col gap-6">
          {options.map((item, index) => {
            return (
              <li className="flex items-center gap-2" key={index}>
                <img
                  src={isBestOffer ? checkMarkWhite : checkMark}
                  alt=""
                  className="aspect-square w-6"
                />
                <span className="text-xs">{item}</span>{" "}
              </li>
            );
          })}
        </ul>
      </div>

      <div className="w-full">
        <button
          onClick={() => {
            setChosenOffer(offers.find((item) => item.title === title));
            setStep(2);
          }}
          className={`btn btn-main flex-c w-full border-0   ${
            isBestOffer && " bg-white text-neutral-2 hover:bg-secondary-10"
          }`}
        >
          Buy Tokens
        </button>
      </div>
      {isBestOffer && (
        <div
          className="absolute left-1/2 top-0 -translate-x-1/2 -translate-y-1/2  rounded  p-[1.5px] text-xs"
          style={{
            background: "linear-gradient(99.09deg, #6633CC 10.36%, #FF44B8 98.61%)",
          }}
        >
          <div className="dark:bg-neutral-1 whitespace-nowrap bg-white px-4 py-1">
            {" "}
            <span style={{ ...gradientText }}>BEST OFFER </span>
          </div>
        </div>
      )}
    </div>
  );
};
const gradientText = {
  background: "linear-gradient(99.09deg, #6633CC 10.36%, #FF44B8 98.61%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  backgroundClip: "text",
  textFillColor: "transparent",
};

const Models = () => {
  const fetchModels = async () => {
    // const { data } = await axios.get(`http://localhost:5000/models`);
    const data = mockData["models"];
    return data;
  };
  const { isLoading, isError, data, error } = useQuery("Models", fetchModels, {
    refetchOnWindowFocus: false,
  });
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const containerRef = useRef(null);

  const handleMouseOver = () => {
    containerRef.current.style.animationPlayState = "paused";
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    e.preventDefault();
    if (!isDragging) return;
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    containerRef.current.scrollLeft = scrollLeft - walk;
  };
  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartX(e.touches[0].pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  const handleTouchMove = (e) => {
    e.preventDefault();
    if (!isDragging) return;
    const x = e.touches[0].pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    containerRef.current.scrollLeft = scrollLeft - walk;
  };
  const {
    openModal,
    handleDynamicToggle,
    handleStaticToggle,
    handleDoubleStaticToggle,
    handleKeyClose,
  } = useHandleAppModals();
  if (isLoading) {
    return (
      <div className="flex-c h-[200px] w-full ">
        <CircularLoading />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex-c h-[200px] w-full  text-lg font-medium">Error: {error.message}</div>
    );
  }
  return (
    <div
      onKeyDown={() => handleKeyClose()}
      className="row dark:border-neutral-3 gap-6 border-b  border-neutral-10"
    >
      <ModelGallerySingle
        state={openModal.gallerySingle}
        toggle={handleDynamicToggle}
        staticToggle={handleStaticToggle}
        doubleStaticToggle={handleDoubleStaticToggle}
      />
      <MessagingModal
        state={openModal.messaging}
        toggle={handleDynamicToggle}
        staticToggle={handleStaticToggle}
        doubleStaticToggle={handleDoubleStaticToggle}
      />
      <div className="flex items-center justify-between ">
        <h2 className="text-xl font-semibold">Models Online</h2>
        <Link to="/search/models/online" className="btn-link-color hidden">
          View All
        </Link>
      </div>
      <div
        className="custom-scrollbar animate-infinite-hr-parent cursor-grab"
        onMouseOver={handleMouseOver}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        ref={containerRef}
      >
        <div className="  animate-infinite-hr custom-scrollbar flex justify-between  gap-4 pb-4 md:gap-8 ">
          {data.slice(0, 100).map((item, index) => {
            const { image, username } = item;
            return (
              <Avatar
                key={index}
                username={username}
                image={image}
                showName={true}
                indicator={"status"}
                dynamicWidth={true}
                staticToggle={handleStaticToggle}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
