import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <div className="gr-dark flex flex-col gap-10 py-10 px-2 text-white lg:py-16 xl:gap-20 xl:px-8 ">
      <div className="mx-auto flex max-w-[1400px] flex-col gap-10 ">
        <div className=" ">
          <h1 className="  archivo mb-6 px-4 text-center text-5xl font-bold !leading-none lg:mb-6 lg:text-start lg:text-6xl xl:text-7xl">
            Get Started
          </h1>
          <div className=" flex-c">
            <Link to="/waitlist/models" className="btn-gr py-4 px-8">
              JOIN THE WAITLIST
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
