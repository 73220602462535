import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DirectionsIcon from "@mui/icons-material/Directions";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import { Button } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { useAppContext } from "app/context/appContext";
import useHandleAppModals from "app/hooks/useHandleAppModals";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import EditGallery from "../Modals/EditGallery";
import EditMessageSettings from "../Modals/EditMessageSettings";
import EditProfile from "../Modals/EditProfile";
import MessagingModal from "../Modals/Messaging";
import ModelGallery from "../Modals/ModelGallery";
import SelectModel from "../Modals/SelectModel";

const Dropdown = ({ open, close, anchorState, staticToggle }) => {
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const { user, logoutUser } = useAppContext();
  const navigate = useNavigate();
  const handleLogout = () => {
    logoutUser();
    navigate("/");
  };
  return (
    <React.Fragment>
      {user.type === "model" ? (
        <Menu
          anchorEl={anchorState}
          id="account-menu"
          open={open}
          onClose={close}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              boxShadow: "0px 12px 24px 0px #0000000D",
              mt: 1.5,
              px: 4,
              py: 2,
              minWidth: "250px",

              borderRadius: "8px",
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
            },
          }}
          transformOrigin={{ horizontal: "center", vertical: "top" }}
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          className="!border !border-neutral-3 "
        >
          <div className="flex max-w-[300px] flex-col gap-2">
            <Link
              to="/waitlist/dashboard"
              className="dark:bg-neutral-2 flex  items-center gap-2 rounded-lg bg-neutral-11 p-2 transition-all duration-500 hover:bg-neutral-10"
            >
              <img
                src={user.image}
                alt="img"
                className="aspect-square w-8 rounded-full object-cover"
              />
              <span className="dark:text-neutral-11 text-sm  font-medium text-neutral-2">
                {user.first_name} {user.last_name}
              </span>
            </Link>
            <MenuItem
              sx={{ color: "#9E9E9E" }}
              className=" !rounded-md !p-0 !pl-1 !text-lg !font-medium"
            >
              <button
                onClick={() => staticToggle("messaging")}
                className="flex w-full justify-start py-[6px] text-primary-4"
              >
                Reply To Messages
              </button>
            </MenuItem>

            <Divider />
            <MenuItem
              sx={{ color: "#9E9E9E" }}
              className=" !rounded-md !p-0 !pl-1 !text-lg !font-medium "
            >
              <button
                onClick={() => staticToggle("editProfile")}
                className="flex w-full justify-start py-[6px] text-neutral-6"
              >
                Edit Profile Details
              </button>
            </MenuItem>
            <MenuItem
              sx={{ color: "#9E9E9E" }}
              className=" !rounded-md !p-0 !pl-1 !text-lg !font-medium "
            >
              <button
                onClick={() => staticToggle("editMessageSettings")}
                className="flex w-full justify-start py-[6px] text-neutral-6"
              >
                Edit Message Settings
              </button>
            </MenuItem>
            <MenuItem
              sx={{ color: "#9E9E9E" }}
              className=" !rounded-md !p-0 !pl-1 !text-lg !font-medium "
            >
              <button
                onClick={() => staticToggle("editGallery")}
                className="flex w-full justify-start  py-[6px] text-neutral-6"
              >
                Edit Image Gallery
              </button>
            </MenuItem>
            <MenuItem
              sx={{ color: "#9E9E9E" }}
              className=" !rounded-md !p-0 !pl-1 !text-lg !font-medium "
            >
              <Link to={`/waitlist/payout-details`} className="w-full py-[6px] text-neutral-6">
                Edit Payout Details
              </Link>
            </MenuItem>
            <Divider />
            <MenuItem
              sx={{ color: "#9E9E9E" }}
              className=" !rounded-md !p-0 !pl-1 !text-lg !font-medium "
            >
              <Link to={`/waitlist/referral`} className="w-full py-[6px] text-neutral-6">
                Refer and Earn Money
              </Link>
            </MenuItem>
            <MenuItem
              sx={{ color: "#9E9E9E" }}
              className=" !rounded-md !p-0 !pl-1 !text-lg !font-medium "
            >
              <Link to={`/waitlist/win-25k`} className="w-full py-[6px] text-neutral-6">
                Enter To Win $25K
              </Link>
            </MenuItem>
            <Divider />

            <MenuItem className="!rounded-md !p-0 !pl-1  ">
              <button
                onClick={() => handleLogout()}
                className="  flex w-full justify-start py-[6px] font-semibold text-neutral-6"
              >
                Log Out
              </button>
            </MenuItem>
          </div>
        </Menu>
      ) : (
        <Menu
          anchorEl={anchorState}
          id="account-menu"
          open={open}
          onClose={close}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              boxShadow: "0px 12px 24px 0px #0000000D",
              mt: 1.5,
              px: 4,
              py: 2,
              minWidth: "250px",

              borderRadius: "8px",
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
            },
          }}
          transformOrigin={{ horizontal: "center", vertical: "top" }}
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          className="!border !border-neutral-3 "
        >
          <div className="flex max-w-[300px] flex-col gap-2">
            <Link
              to="/waitlist/dashboard"
              className="dark:bg-neutral-2 flex  items-center gap-2 rounded-lg bg-neutral-11 p-2 transition-all duration-500 hover:bg-neutral-10"
            >
              <img
                src={user.image}
                alt="img"
                className="aspect-square w-8 rounded-full object-cover"
              />
              <span className="dark:text-neutral-11 text-sm  font-medium text-neutral-2">
                {user.first_name} {user.last_name}
              </span>
            </Link>
            <MenuItem
              sx={{ color: "#9E9E9E" }}
              className=" !rounded-md !p-0 !pl-1 !text-lg !font-medium"
            >
              <button
                onClick={() => staticToggle("selectModel")}
                className="flex w-full justify-start py-[6px] text-primary-4"
              >
                Message Models
              </button>
            </MenuItem>
            <MenuItem
              sx={{ color: "#9E9E9E" }}
              className=" !rounded-md !p-0 !pl-1 !text-lg !font-medium"
            >
              <Link
                to={`/waitlist/users/tokens`}
                className="flex w-full justify-start py-[6px] text-secondary-4"
              >
                Purchase Tokens
              </Link>
            </MenuItem>
            <Divider />
            <MenuItem
              sx={{ color: "#9E9E9E" }}
              className=" !rounded-md !p-0 !pl-1 !text-lg !font-medium "
            >
              <button
                onClick={() => staticToggle("editProfile")}
                className="flex w-full justify-start py-[6px] text-neutral-6"
              >
                Edit Profile Details
              </button>
            </MenuItem>
            <MenuItem
              sx={{ color: "#9E9E9E" }}
              className=" !rounded-md !p-0 !pl-1 !text-lg !font-medium "
            >
              <Link to={`/waitlist/payout-details`} className="w-full py-[6px] text-neutral-6">
                Edit Payout Details
              </Link>
            </MenuItem>
            <Divider />
            <MenuItem
              sx={{ color: "#9E9E9E" }}
              className=" !rounded-md !p-0 !pl-1 !text-lg !font-medium "
            >
              <Link to={`/waitlist/referral`} className="w-full py-[6px] text-neutral-6">
                Refer and Earn Money
              </Link>
            </MenuItem>
            <MenuItem
              sx={{ color: "#9E9E9E" }}
              className=" !rounded-md !p-0 !pl-1 !text-lg !font-medium "
            >
              <Link to={`/waitlist/win-25k`} className="w-full py-[6px] text-neutral-6">
                Enter To Win $25K
              </Link>
            </MenuItem>
            <Divider />

            <MenuItem className="!rounded-md !p-0 !pl-1  ">
              <button
                onClick={() => handleLogout()}
                className="  flex w-full justify-start py-[6px] font-semibold text-neutral-6"
              >
                Log Out
              </button>
            </MenuItem>
          </div>
        </Menu>
      )}
    </React.Fragment>
  );
};
const UserDropdown = () => {
  const { user } = useAppContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const openDropDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };
  const [selectedModal, setSelectedModal] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [searchModal, setSearchModal] = useState(false);
  const handleSearchModal = () => {
    handleStaticToggle("searchModel");
  };
  const {
    openModal,
    setOpenModal,
    handleDynamicToggle,
    handleStaticToggle,
    handleDoubleStaticToggle,

    handleKeyClose,
  } = useHandleAppModals();

  return (
    <div onKeyDown={handleKeyClose}>
      <SelectModel
        state={openModal.selectModel}
        toggle={handleDynamicToggle}
        staticToggle={handleStaticToggle}
        doubleStaticToggle={handleDoubleStaticToggle}
        setSelectedModal={setSelectedModal}
        setSelectedModel={setSelectedModel}
        selectedModel={selectedModel}
        selectedModal={selectedModal}
        handleSearchModal={handleSearchModal}
      />
      <ModelGallery
        state={openModal.gallery}
        toggle={handleDynamicToggle}
        staticToggle={handleStaticToggle}
        doubleStaticToggle={handleDoubleStaticToggle}
      />
      <MessagingModal
        state={openModal.messaging}
        toggle={handleDynamicToggle}
        staticToggle={handleStaticToggle}
        doubleStaticToggle={handleDoubleStaticToggle}
      />
      <EditProfile
        state={openModal.editProfile}
        toggle={handleDynamicToggle}
        staticToggle={handleStaticToggle}
        doubleStaticToggle={handleDoubleStaticToggle}
      />
      <EditGallery
        state={openModal.editGallery}
        toggle={handleDynamicToggle}
        staticToggle={handleStaticToggle}
        doubleStaticToggle={handleDoubleStaticToggle}
      />
      <EditMessageSettings
        state={openModal.editMessageSettings}
        toggle={handleDynamicToggle}
        staticToggle={handleStaticToggle}
        doubleStaticToggle={handleDoubleStaticToggle}
      />
      <button className="flex items-center gap-0" onClick={handleClick}>
        <Tooltip title="Account settings">
          <img src={user.image} className="aspect-square w-8 rounded-full object-cover" alt="" />
        </Tooltip>

        <ArrowDropDownIcon sx={{ color: "black" }} />
      </button>
      <Dropdown
        anchorState={anchorEl}
        open={openDropDown}
        close={handleCloseDropdown}
        staticToggle={handleStaticToggle}
      />
    </div>
  );
};

export default UserDropdown;
