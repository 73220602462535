import waves from 'assets/waves-2.svg';
import { useEffect, useState } from 'react';

import '../style.css';

const initialState = {
  name: '',
  email: '',
  phone: '',
  message: '',
};

const Contact = () => {
  const [values, setValues] = useState(initialState);
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!values.name || !values.email || !values.phone || !values.country) {
      setErrorMessage('please enter all required fields.');
    } else {
      console.log(values);
      setSuccessMessage('Your message has been sent!');
      setValues(initialState);
    }
  };
  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage('');
      }, 2000);
    }
  }, [errorMessage]);
  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage('');
      }, 2000);
    }
  }, [successMessage]);
  return (
    <div className="relative flex flex-col gap-10 py-10 px-2 text-white lg:py-16 xl:gap-20 xl:px-8 ">
      <div
        className="abs-c flex-c absolute h-full w-full bg-cover "
        style={{
          backgroundImage: `url(${waves})`,
          backgroundSize: '100% 100%',
        }}
      ></div>
      <div className="mx-auto flex max-w-[1400px] flex-col gap-10 ">
        <div
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="500"
          className="gradient-dark mx-auto flex w-full max-w-[1050px] flex-col gap-6 rounded-lg px-6 py-8 lg:gap-10 lg:px-20 lg:py-10"
        >
          <h2 className="  text-center text-4xl font-bold  leading-tight md:text-6xl lg:text-start lg:text-6xl ">
            Get In Touch
          </h2>
          <p className="text-sm lg:text-lg xl:text-xl">
            Please complete this short form and our team will reach out to you via email.
          </p>
          <form onSubmit={handleSubmit} className="flex flex-col gap-4 sm:gap-6">
            <Input
              label={'full name*'}
              placeholder="Enter your Full Name"
              onChange={handleChange}
              name="name"
              minLength={4}
              value={values.name}
            />

            <div className="flex w-full flex-col gap-6 sm:flex-row sm:gap-4 [&>*]:flex-1">
              <Input
                label={'Email Address*'}
                placeholder="Enter your Email Address"
                type="email"
                onChange={handleChange}
                name="email"
                value={values.email}
              />
              <Input
                label={'phone number*'}
                placeholder="Enter your Phone Number"
                type="tel"
                onChange={handleChange}
                name="phone"
                minLength={8}
                value={values.phone}
              />
            </div>

            <div className="relative flex flex-col gap-1">
              <label className="text-xs uppercase text-[#FEFEFE] lg:text-sm">Message</label>
              <textarea
                onChange={handleChange}
                name="message"
                rows="4"
                value={values.message}
                placeholder="Your Message..."
                className="rounded-xl border border-white bg-transparent p-4
                 text-sm  outline-0 placeholder:text-neutral-8 focus:border-secondary-5 active:border-secondary-5 lg:text-lg"
              ></textarea>
              {errorMessage && (
                <span className="absolute -bottom-6 left-1/2 -translate-x-1/2 whitespace-nowrap text-2xs font-semibold capitalize text-feedback-failure-7 sm:-bottom-8 sm:text-sm">
                  {errorMessage}
                </span>
              )}
              {successMessage && (
                <span className="absolute -bottom-6 left-1/2 -translate-x-1/2 whitespace-nowrap text-2xs font-semibold capitalize text-feedback-success-6 sm:-bottom-8 sm:text-sm">
                  {successMessage}
                </span>
              )}
            </div>
            <button className=" white-to-gr mt-2 rounded-full border-0 py-4 lg:mt-6 " type="submit">
              <span className="text-gr text-base font-bold sm:text-lg"> Submit</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;

const Input = ({
  label,
  type = 'text',
  isRequired = true,
  placeholder,
  onChange,
  span = false,
  name,
  value,
  minLength = 2,
}) => {
  return (
    <div className="flex flex-col gap-1">
      <label className="text-2xs uppercase text-[#FEFEFE] sm:text-xs lg:text-sm">{label}</label>
      <div className="relative w-full">
        <input
          type={type}
          name={name}
          value={value}
          required={isRequired}
          placeholder={placeholder}
          minLength={minLength}
          className={`w-full rounded-xl border
         border-white bg-transparent p-4  text-xs outline-0 placeholder:text-neutral-8 focus:border-secondary-5 active:border-secondary-5 sm:text-sm lg:text-lg 
         ${span && 'pl-8'}
         `}
          onChange={onChange}
        />
        {span && (
          <span className="absolute left-4 top-1/2 -translate-y-1/2 text-sm text-white lg:text-lg">
            @
          </span>
        )}
      </div>
    </div>
  );
};
