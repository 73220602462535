import img1 from "assets/tiktok/section-3-1.webp";
import img2 from "assets/tiktok/section-3-2.jpg";

const Section3 = () => {
  return (
    <div className=" max-screen relative z-20 mx-auto flex flex-col items-center gap-10 py-10 px-4 md:py-16 lg:py-24 lg:px-8 xl:gap-20  2xl:py-32 ">
      <div className="   ">
        <h1
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="500"
          className="hero-title archivo mb-4  text-start text-5xl font-bold leading-tight text-neutral-1 lg:mb-8 lg:text-6xl  xl:text-7xl 2xl:text-8xl 3xl:text-[150px] "
        >
          Fun Interactive <span className="text-gr">Features</span>
        </h1>
        <div className="flex flex-col gap-4 lg:gap-8">
          <h2
            data-aos="fade-in"
            data-aos-duration="2000"
            data-aos-delay="1500"
            className="text-start text-lg font-bold  text-neutral-2 lg:text-3xl 3xl:text-4xl     "
          >
            Get paid monthly subscriptions from your private channels
          </h2>
          <p
            data-aos="fade-in"
            data-aos-duration="2000"
            data-aos-delay="1500"
            className="text-start text-sm font-medium  text-neutral-4 lg:text-xl 3xl:text-3xl "
          >
            Use interactive features, such as custom buttons and timers to better engage with you
            audience in new and exciting ways. With unlimited possibilities you can foster a
            stronger sense of community and engagement with your audience.
          </p>
        </div>
      </div>

      <div className=" grid w-full grid-cols-8 grid-rows-6 rounded-xl  sm:px-6 ">
        <div
          style={{ gridArea: " 1 / 2 / 9 / 9" }}
          className="self-center"
          data-aos="fade-right"
          data-aos-duration="1500"
          data-aos-delay="1000"
        >
          <img src={img1} alt="Target Verticals" className="rounded-lg object-cover  " />
        </div>
        <div
          style={{ gridArea: "1 / 1/ 9 / 3" }}
          className="flex justify-end self-center"
          data-aos="fade-left"
          data-aos-duration="2000"
          data-aos-delay="1500"
        >
          <img src={img2} alt="Target Verticals" className="rounded-lg" />
        </div>
      </div>
    </div>
  );
};

export default Section3;
