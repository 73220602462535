import React, { useEffect, useRef, useState } from "react";

import img1 from "assets/invest/section-5-1.png";
import img2 from "assets/invest/section-5-2.png";
import img1a from "assets/invest/section-5-1-a.jpg";
import img1b from "assets/invest/section-5-1-b.jpg";
import img1c from "assets/invest/section-5-1-c.png";
import img1d from "assets/invest/section-5-1-d.png";
const ThePlatform = () => {
  return (
    <div className="h-gradient text-white pb-10 lg:pb-32 flex flex-col gap-10 xl:gap-20 px-4 xl:px-8 ">
      <div className="max-w-[1400px] mx-auto flex flex-col gap-10 lg:gap-0 ">
        <div className="flex flex-col gap-22 relative z-10">
          <div className="xl:w-[90%] z-10 pt-16 lg:pt-32">
            <h1
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-delay="600"
              className="  text-5xl lg:text-7xl 3xl:text-[150px] text-start font-bold leading-tight  mb-6 lg:mb-6">
              The Platform
            </h1>
            <p
              data-aos="fade-in"
              data-aos-duration="2000"
              data-aos-delay="1500"
              className=" text-base font-medium  lg:text-xl 3xl:text-4xl ">
              Beladed revolutionizes livestreaming with games, shows, and
              interactive features. It solves social media and video streaming
              challenges with generative AI, branded team tournaments, NFT
              rewards, and interactive livestream voting. The world's first
              truly interactive livestream platform.
            </p>
          </div>

          <div className=" w-full grid grid-cols-[repeat(10, 1fr)] grid-rows-[repeat(6, 1fr)] py-6  rounded-xl">
            <div
              style={{ gridArea: "1 / 5 / 7 / 11" }}
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-delay="1500">
              <img
                src={img1a}
                alt="Target Verticals"
                className="rounded-lg h-full object-cover object-left lg:h-auto"
              />
            </div>
            <div
              style={{ gridArea: "2 / 2 / 6 / 7" }}
              className="flex justify-end"
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="2000">
              <img
                src={img1b}
                alt="Target Verticals"
                className="rounded-lg border-4 lg:border-[12px] border-[#ffffff33] w-full"
              />
            </div>
            <div
              style={{ gridArea: "3 / 1 / 5 / 3" }}
              className="flex justify-end"
              data-aos="flip-right"
              data-aos-duration="1500"
              data-aos-delay="2500">
              <img src={img1c} alt="Target Verticals" className="rounded-xl" />
            </div>
            <div
              style={{ gridArea: "4 / 2 / 7 / 4" }}
              className="flex justify-end"
              data-aos="flip-left"
              data-aos-duration="1500"
              data-aos-delay="2500">
              <img src={img1d} alt="Target Verticals" className="rounded-xl" />
            </div>
          </div>
        </div>
        <div className="xl:w-[90%] z-10 pt-16 lg:pt-32">
          <h1
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="500"
            className="  text-5xl lg:text-7xl 3xl:text-[150px] text-start font-bold leading-tight  mb-6 lg:mb-6">
            The Difference
          </h1>
          <p
            data-aos="fade-in"
            data-aos-duration="2000"
            data-aos-delay="1500"
            className=" text-base font-medium  lg:text-xl 3xl:text-4xl ">
            Beladed offers a fully interactive entertainment experience by
            allowing streamers to create custom buttons with AI technology for
            viewers to click during the stream. This creates an engaging and
            interactive vote-based experience. Additionally, the platform's
            token-based economy allows users to participate in priority
            messaging, announcements, suggestions, and live stream voting.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ThePlatform;
