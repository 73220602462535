import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import CloseIcon from "@mui/icons-material/Close";
import TodayIcon from "@mui/icons-material/Today";
import { IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import axios from "axios";
import { mockData } from "data/mock-data.js";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";

import NumberInput from "../CustomComponents/NumberInput";
import SimpleInputForm from "../Inputs/SimpleInputForm";
import SimpleSelectForm from "../Inputs/SimpleSelectForm";
import CircularLoading from "../Small/Loading";
import UserBoxSelect from "../Small/UserBoxSelect";
import Modal from "../Structure/Modal";

const EditMessageSettings = ({
  state,
  toggle,
  staticToggle,
  doubleStaticToggle,
  setSelectedModal,
  selectedModal,
  handleSearchModal,
}) => {
  const [tokensAmount, setTokensAmount] = useState({
    priority1: "",
    priority2: "",
    priority3: "",
  });

  return (
    <Modal state={state} close={toggle}>
      <div className="relative mx-auto my-auto flex w-[95vw] flex-col   gap-6 rounded-lg  bg-white px-6 py-4 sm:w-[380px]  sm:p-6  ">
        <div className="flex w-full items-start justify-between">
          <div className="flex w-full flex-col gap-3 md:gap-6">
            <div className="flex items-center justify-between ">
              <h2 className="text-xl font-semibold text-neutral-1 md:text-2xl">Message Settings</h2>
              <div>
                <IconButton
                  aria-label="close"
                  name="editMessageSettings"
                  onClick={(e) => toggle(e)}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <h4 className="font-semibold text-neutral-3">Message Priority</h4>
          <p className="text-sm tracking-normal text-neutral-3">
            Enter the token amount for each message priority. <br /> Messages are sorted by
            Priority. <br />
            Priority 1 is highest.
          </p>
        </div>
        <div className="flex w-full flex-col gap-4">
          <div className="flex w-full flex-col gap-2">
            <label className="text-base font-medium text-neutral-3">Priority 1</label>
            <NumberInput
              name={"priority1"}
              tokensAmount={tokensAmount}
              setTokensAmount={setTokensAmount}
              minAmount={0}
              width={48}
              classes={"!w-full "}
              placeholder={"200"}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label className="text-base font-medium text-neutral-3">Priority 2</label>
            <NumberInput
              name={"priority2"}
              tokensAmount={tokensAmount}
              setTokensAmount={setTokensAmount}
              minAmount={0}
              width={32}
              classes={"!w-full "}
              placeholder={"150"}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label className="text-base font-medium text-neutral-3">Priority 3</label>
            <NumberInput
              name={"priority3"}
              tokensAmount={tokensAmount}
              setTokensAmount={setTokensAmount}
              minAmount={0}
              width={32}
              classes={"!w-full md:w-32"}
              placeholder={"100"}
            />
          </div>
          <div className="mt-3 flex items-center gap-4 self-end justify-self-end">
            <button
              className="btn btn-tertiary-grey "
              onClick={(e) => toggle(e)}
              name="editMessageSettings"
            >
              Close
            </button>
            <button name="editMessageSettings" onClick={(e) => toggle(e)} className="btn btn-main ">
              Update
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default EditMessageSettings;
