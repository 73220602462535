import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import logo from 'assets/logo-d.svg';
import { Link, useNavigate } from 'react-router-dom';

import { cookies } from './data';

const Terms = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <main className="container-fluid mx-auto max-w-screen-2xl py-8 px-4 lg:px-8 xl:px-10 [&_p]:text-neutral-5">
      <div className="mb-8 flex justify-center">
        <Link to="/waitlist/dashboard">
          {' '}
          <img src={logo} alt="logo" className="w-[180px] sm:w-auto" />
        </Link>
      </div>
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-4">
          <h1 className="text-3xl font-bold md:text-4xl">Cookies Policy </h1>
          <p className=" text-xs">
            Last Update: <span>25/05/2022</span>
          </p>
          <p>
            This Privacy & Cookies Policy (the "Policy") dated May 11, 2022 replaces all previous
            policies. Please take the time to review before you continue to use our products and
            services.
          </p>
          <p>
            This Policy applies to any information you provide to us, including through this
            website, our mobile apps, communications by email and through social media, by telephone
            and in person. It also covers any information we receive from third parties.
          </p>
          <p>
            By using our products and services, including browsing our websites, registering or
            logging in, you agree we may use your information as outlined in this Policy. If you do
            not wish to have your information used in line with this Policy, you must not use our
            products and services and not otherwise provide us with your information.
          </p>
        </div>
        {cookies.map((item, index) => {
          return (
            <div key={index} className="flex flex-col gap-4">
              <h2 className="text-xl font-medium md:text-2xl">
                {index + 1}. {item.title}
              </h2>
              <div
                className="text-sm md:text-base [&_a]:text-secondary-5
               [&>div]:flex [&>div]:flex-col [&>div]:gap-4  [&_h4]:text-lg  [&_h4]:font-semibold [&_h4]:text-neutral-4 [&_h5]:font-medium"
              >
                {item.content}
              </div>
            </div>
          );
        })}
      </div>
      <div className="my-8">
        <button
          onClick={() => handleGoBack()}
          className="btn btn-large btn-second-grey flex w-fit items-center gap-2 text-neutral-1 "
        >
          <span>
            <ArrowBackIcon />
          </span>
          Go Back
        </button>
      </div>
    </main>
  );
};

export default Terms;

const lorem =
  'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.';
