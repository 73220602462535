import Modal from "../Structure/Modal";
import PermMediaOutlinedIcon from "@mui/icons-material/PermMediaOutlined";

import useHandleUpload from "app/hooks/useHandleUpload";
import { useEffect, useRef, useState } from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const UploadPhotoModal = ({ state, toggle, staticToggle }) => {
  const {
    uploadedImage,
    uploadPhoto,

    progress,
  } = useHandleUpload();
  const upload = useRef();
  useEffect(() => {
    if (uploadedImage) {
      staticToggle("uploadPhoto");
    }
  }, [uploadedImage]);
  return (
    <Modal state={state} close={toggle}>
      <div className="relative mx-auto my-auto flex w-[95vw] sm:w-[600px]   flex-col gap-6  rounded-lg bg-white    lg:w-[780px]">
        <div className="absolute top-4 right-4 z-10">
          <IconButton
            aria-label="close"
            name="uploadPhoto"
            onClick={(e) => toggle(e)}>
            <CloseIcon />
          </IconButton>
        </div>
        <label
          className={`bg-[#FAFAFA] dark:bg-neutral-2  rounded-lg grid place-content-center aspect-[2/1]  relative    ${
            progress > 0 ? "cursor-not-allowed" : "cursor-pointer"
          }  `}>
          <div className="flex flex-col items-center  gap-2">
            <PermMediaOutlinedIcon
              color="primary"
              sx={{ fontSize: "max(72px , 5vw)" }}
            />
            <p className="text-xl font-medium">
              Drag and drop an Image, or
              <a role="button" className="text-secondary-5">
                {" "}
                Browse
              </a>
            </p>
            <p className="text-neutral-5 dark:text-neutral-9   text-sm">
              1600x1200 or higher recommended. Max 10MB each
            </p>
            <p className="text-neutral-5 dark:text-neutral-9   text-sm">
              High resolution images (png, jpg, gif)
            </p>
          </div>
          <input
            type="file"
            onChange={(e) => uploadPhoto(e)}
            className="hidden"
            accept=".jpg, .jpeg, .png"
            disabled={progress > 0}
            ref={upload}
          />
          {uploadedImage && (
            <img
              src={uploadedImage}
              alt="file"
              className={`absolute w-full h-full rounded-lg object-cover  
                `}
            />
          )}
        </label>
      </div>
    </Modal>
  );
};
export default UploadPhotoModal;
