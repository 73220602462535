import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import logo from "assets/logo-d.svg";
import congrats from "assets/congrats.svg";

import useCountdown from "app/hooks/useCountdown";
import twodots from "assets/twodots.svg";

import ScrollingPictures from "app/components/Big/ScrollingPictures";
// import { confetti } from "https://cdn.jsdelivr.net/npm/tsparticles-confetti/+esm";
import { confetti } from "tsparticles-confetti";
import LeavingModalUser from "app/components/Modals/LeavingModalUser";
import SelectModel from "app/components/Modals/SelectModel";
import useHandleAppModals from "app/hooks/useHandleAppModals";
import ModelGallery from "app/components/Modals/ModelGallery";
import MessagingModal from "app/components/Modals/Messaging";
const initialModalStates = {
  announce: false,
  suggest: false,
  message: false,
  draft: false,
  findTeam: false,
  draftSuccess: false,
  findModal: false,
  gallery: false,
};
export const PostJoin = () => {
  const { values: timer } = useCountdown(1000 * 60 * 5);
  const colors = ["#FF69bf", "#8a00c2", "#ffff00", "#1260cc"];
  const end = Date.now() + 2 * 1000;
  const run = () => {
    (function frame() {
      confetti({
        particleCount: 10,
        angle: -90,
        spread: 200,
        origin: { y: 0, x: 1 },
        colors: colors,
      });

      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    })();
  };

  useEffect(() => {
    run();
  }, []);
  const [leavingModal, setLeavingModal] = useState(false);
  const popstate = () => {
    window.history.pushState(null, null, document.URL);
    setLeavingModal(true);
  };
  const navigate = useNavigate();

  const allow = localStorage.getItem("allowGoBack");

  useEffect(() => {
    if (allow === "0") {
      window.history.pushState(null, null, document.URL);
      window.addEventListener("popstate", popstate);
    } else {
      window.removeEventListener("popstate", popstate);
    }
  }, []);
  const handleBeforeUnload = (e) => {
    e.preventDefault();
    setLeavingModal(true);
    e.returnValue = "";
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleStay = () => {
    window.removeEventListener("popstate", popstate);
    setLeavingModal(false);
  };

  const handleLeave = () => {
    setLeavingModal(false);
    window.removeEventListener("beforeunload", handleBeforeUnload);
    navigate("/waitlist/users");
  };
  const {
    openModal,
    setOpenModal,
    handleDynamicToggle,
    handleStaticToggle,
    handleDoubleStaticToggle,
  } = useHandleAppModals();
  const handleKeyClose = (e) => {
    if (e.key === "Escape") {
      setOpenModal(initialModalStates);
      setSearchModal(false);
    }
  };
  const resetAllModals = () => {
    setOpenModal(initialModalStates);
    setSelectedModal("");
    setSelectedModel("");
  };
  const [selectedModal, setSelectedModal] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [searchModal, setSearchModal] = useState(false);
  const handleSearchModal = () => {
    handleStaticToggle("searchModel");
  };

  return (
    <main
      className=" container-fluid px-8    min-h-screen "
      onKeyDown={() => handleKeyClose()}>
      <LeavingModalUser
        state={leavingModal}
        toggle={handleStay}
        leave={handleLeave}
        dynamicToggle={handleStaticToggle}
      />
      <SelectModel
        state={openModal.selectModel}
        toggle={handleDynamicToggle}
        staticToggle={handleStaticToggle}
        doubleStaticToggle={handleDoubleStaticToggle}
        resetAllModals={resetAllModals}
        setSelectedModal={setSelectedModal}
        setSelectedModel={setSelectedModel}
        selectedModel={selectedModel}
        selectedModal={selectedModal}
        handleSearchModal={handleSearchModal}
      />
      <ModelGallery
        state={openModal.gallery}
        toggle={handleDynamicToggle}
        staticToggle={handleStaticToggle}
        doubleStaticToggle={handleDoubleStaticToggle}
        resetAllModals={resetAllModals}
      />
      <MessagingModal
        state={openModal.messaging}
        toggle={handleDynamicToggle}
        staticToggle={handleStaticToggle}
        doubleStaticToggle={handleDoubleStaticToggle}
        resetAllModals={resetAllModals}
      />
      <div className="row  mx-auto text-white  h-full min-h-screen  ">
        <div className="hidden lg:flex col-lg-6 flex-c z-10 h-[max(100vh,800px)] xl:h-[max(100vh,700px)] 2xl:h-[max(100vh,900px)]">
          <div className="h-full">
            <ScrollingPictures />
          </div>
        </div>
        <div className="col-12 col-lg-6 flex-c min-h-screen  ">
          <div className="flex flex-col gap-8 my-10">
            <div className="mb-4 flex-c">
              <Link to="/waitlist/dashboard">
                <img src={logo} alt="logo" className=" w-44 md:w-auto" />
              </Link>
            </div>
            <div className="flex flex-col items-center gap-4 w-full">
              <img
                src={congrats}
                alt="congrats"
                className="w-36 animate__animated animate__tada animate__delay-1s animate__repeat-2 "
                id="cone"
              />
              <h1 className="text-4xl font-semibold text-black">
                Congratulations!
              </h1>
              <p className="text-neutral-4 text-center">
                Your name has been added to the waiting list.
              </p>
            </div>
            <div className="flex flex-col gap-4 items-center">
              <h2 className="text-xl font-semibold text-black">
                Token Pre-sale
              </h2>
              <p className="text-neutral-4 text-center">
                {" "}
                You can pre order token at 25% discount in the next 5 minutes{" "}
              </p>
              <CountDown timer={timer} />
            </div>
            <div className=" flex-c w-full animate__animated  animate__fadeIn animate__slow animate__delay-2s">
              <Link
                className="btn btn-main btn-large text-base w-full flex-c"
                to={"/waitlist/users/tokens"}>
                Purchase Tokens
              </Link>
            </div>
            <div className=" flex items-center gap-4 w-full animate__animated animate__slow  animate__fadeIn animate__delay-3s flex-wrap">
              <Link
                className="btn btn-second-grey btn-large text-base flex-1 flex-c"
                to={"/waitlist/win-25k"}>
                Win $25K
              </Link>
              <button
                onClick={(e) => handleDynamicToggle(e)}
                name="selectModel"
                className="btn btn-second-grey btn-large text-base flex-1 whitespace-nowrap flex-c">
                Message Models Directly
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default PostJoin;
const CountDown = ({ timer: values }) => {
  const [s, setS] = useState(values.seconds.toString());
  const [m, setM] = useState(values.minutes.toString());
  useEffect(() => {
    setS(values.seconds.toString());
    setM(values.minutes.toString());
  }, [values]);
  return (
    <div className="countdown flex items-center justify-center gap-3">
      <span className="flex-c rounded-lg bg-white border border-neutral-9 py-2 px-3 text-2xl text-neutral-4 lg:py-3 lg:px-4 lg:text-4xl font-bold">
        {m[0]}
      </span>

      <span className="flex-c rounded-lg bg-white border border-neutral-9 py-2 px-3 text-2xl text-neutral-4 lg:py-3 lg:px-4 lg:text-4xl font-bold">
        {m[1]}
      </span>

      <img src={twodots} alt="" className="h-4 lg:h-10" />

      <span className="flex-c rounded-lg bg-white border border-neutral-9 py-2 px-3 text-2xl text-neutral-4 lg:py-3 lg:px-4 lg:text-4xl font-bold">
        {s[0]}
      </span>

      <span className="flex-c rounded-lg bg-white border border-neutral-9 py-2 px-3 text-2xl text-neutral-4 lg:py-3 lg:px-4 lg:text-4xl font-bold">
        {s[1]}
      </span>
    </div>
  );
};
