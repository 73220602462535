import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import video from "assets/advisor/apple_video_3.mp4";
import logo from "assets/logo.svg";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import "../style.css";

const Hero = ({ scrollToGetInTouch }) => {
  const [isVideoPaused, setIsVideoPaused] = useState(true);
  const [videoDuration, setVideoDuration] = useState(0);
  const videoRef = useRef();
  useEffect(() => {
    videoRef?.current.paused ? setIsVideoPaused(true) : setIsVideoPaused(false);
  }, [videoRef.current?.paused]);
  const handlePlayVideo = () => {
    videoRef.current.volume = 0.5;

    isVideoPaused ? videoRef.current.play() : videoRef.current.pause();
  };
  useEffect(() => {
    const handlePlay = () => {
      setIsVideoPaused(false);
      setVideoDuration(videoRef.current.currentTime);
    };

    const handlePause = () => {
      setIsVideoPaused(true);
      setVideoDuration(videoRef.current.currentTime);
    };

    const currentVideoRef = videoRef.current;
    currentVideoRef.addEventListener("play", handlePlay);
    currentVideoRef.addEventListener("pause", handlePause);

    return () => {
      currentVideoRef.removeEventListener("play", handlePlay);
      currentVideoRef.removeEventListener("pause", handlePause);
    };
  }, []);
  return (
    <main className="flex-c relative z-0 min-h-[max(100vh,650px)] w-screen overflow-hidden ">
      <div className="-z-1 video-container absolute inset-0 h-full w-full">
        <video
          ref={videoRef}
          src={video}
          playsInline
          className=" h-full w-full object-cover object-center"
        ></video>
      </div>
      <div className="absolute inset-0 h-full w-full bg-primary-1 opacity-70"></div>
      <header className="absolute top-0 z-10 mx-auto flex w-full max-w-screen-xl items-center justify-between gap-4 px-4 py-4 md:px-8 lg:py-6 2xl:py-12">
        <Link to="/advisor">
          <img src={logo} alt="Beladed" className="w-32 lg:w-44" />
        </Link>
        <button onClick={scrollToGetInTouch} className="btn white-to-gr rounded-full border-0">
          <span className="text-gr text-sm font-bold lg:text-base xl:text-lg 3xl:text-xl">
            Contact Us
          </span>
        </button>
      </header>
      <div className="hero-container relative mx-auto flex max-w-screen-xl flex-col items-center gap-6 px-4 py-10  sm:gap-10 md:px-8 2xl:gap-6 2xl:py-14 3xl:py-20">
        <div className="rounded-full bg-[rgba(255,255,255,0.1)]  py-2 px-5">
          <p className=" text-center text-xs tracking-[2.5px] text-white sm:text-sm xl:text-lg 3xl:text-xl">
            Elevate Brands with Beladed Advisors
          </p>
        </div>
        <h1
          className="hero-title text-center text-5xl  font-extrabold !leading-tight text-white md:text-6xl  lg:text-7xl xl:text-8xl 3xl:text-9xl"
          data-aos="fade-down"
          data-aos-duration="1000"
          data-aos-delay="1000"
        >
          Join the Elite Circle
        </h1>
        <p
          className="mx-auto text-center text-base font-medium !leading-normal !tracking-[-1.2px] text-white sm:text-base md:text-lg lg:w-[95%] xl:text-2xl 3xl:text-3xl"
          data-aos="fade-in"
          data-aos-duration="2000"
          data-aos-delay="1000"
        >
          Beladed Advisors invites you to join our prestigious advisory board, comprised of thought
          leaders and innovators from a diverse array of industries.
        </p>
        <div
          data-aos="fade-in"
          data-aos-duration="2000"
          data-aos-delay="1000"
          className="flex flex-wrap items-center justify-center gap-4 px-4 lg:gap-6"
        >
          <button
            onClick={scrollToGetInTouch}
            className="btn white-to-gr flex-1 whitespace-nowrap rounded-full border-0 px-12 py-6 3xl:px-20 3xl:py-9 "
          >
            <span className="text-gr text-base font-semibold lg:text-lg xl:text-xl 3xl:text-2xl">
              Get In Touch
            </span>
          </button>

          <button
            onClick={handlePlayVideo}
            className="btn transparent-to-white flex-c flex-1 gap-2 whitespace-nowrap
           rounded-full border bg-transparent px-12 py-6 text-base font-semibold
            text-white hover:bg-white hover:text-primary-5 lg:text-lg xl:text-xl  3xl:border-[3px] 3xl:px-20 3xl:py-9"
          >
            {isVideoPaused ? (
              <>
                <span>
                  <PlayCircleFilledWhiteIcon
                    sx={{
                      fontSize: "2rem",
                    }}
                  />
                </span>
                <span className="text-base font-semibold lg:text-lg xl:text-xl 3xl:text-2xl">
                  {videoDuration === 0 ? "Watch Video" : " Resume"}
                </span>
              </>
            ) : (
              <>
                <span>
                  <PauseCircleIcon
                    sx={{
                      fontSize: "2rem",
                    }}
                  />
                </span>
                <span className="text-base font-semibold lg:text-lg xl:text-xl 3xl:text-2xl">
                  Pause
                </span>
              </>
            )}
          </button>
        </div>
      </div>
    </main>
  );
};

export default Hero;
