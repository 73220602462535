import Cryptocurrency from "assets/brand/Cryptocurrency.svg";
import ai from "assets/brand/ai.svg";
import eco from "assets/brand/eco.svg";
import metaverse from "assets/brand/metaverse.svg";
import logo from "assets/logo_sm.svg";

const TheConvergence = () => {
  return (
    <div className="container-fluid gradient-dark text-white">
      <div className=" mx-auto flex flex-col gap-10 py-10  sm:px-10 md:py-16 lg:py-24 xl:max-w-[1150px]  xl:px-8 2xl:max-w-[1400px] 2xl:py-32 3xl:max-w-screen-2xl">
        <div className="flex flex-col items-center justify-center gap-4 lg:gap-6">
          <h1
            data-aos="fade-down"
            data-aos-duration="1000"
            data-aos-delay="1000"
            className="text-center text-4xl font-bold sm:text-5xl xl:text-6xl 2xl:text-7xl 3xl:text-8xl"
          >
            The Convergence of Virtual, Digital, and Intelligent Worlds
          </h1>
        </div>
        <div className="row relative z-0 items-stretch     gap-y-6 lg:gap-y-10">
          {advantages.map((item, index) => {
            return (
              <div
                key={index}
                data-aos={index % 2 === 0 ? "fade-right" : "fade-left"}
                data-aos-duration="1500"
                data-aos-delay="1000"
                className={` relative z-10 flex-[1_0_100%]  md:flex-[1_0_50%]    `}
              >
                <SingleAdvantage {...item} />
              </div>
            );
          })}
          <div className=" abs-c h-full w-auto">
            <img
              src={logo}
              alt="beladed"
              className="-z-10 h-full  opacity-10 hue-rotate-[140deg] invert-[1]  "
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TheConvergence;

const advantages = [
  {
    title: "AI-Powered Campaign Optimization",
    text: "Harness the power of AI to optimize your brand's advertising campaigns on Beladed. Gain valuable insights into audience sentiment, performance metrics, and engagement trends. Use this data to make informed decisions that improve your campaigns, increasing their effectiveness and your brand's overall impact.",
    icon: ai,
  },
  {
    title: "Leveraging NFTs for Business Growth",
    text: "Stay ahead of the curve by utilizing NFTs to amplify your brand's reach and value. Offer unique, digital collectibles tied to your brand, such as artwork, merchandise, or exclusive content. As users trade and collect these NFTs, your brand gains increased exposure and enhanced value, boosting your reputation and appeal.",
    icon: Cryptocurrency,
  },
  {
    title: "Metaverse Integration for Business Growth",
    text: "Future-proof your brand by entering the metaverse with Beladed. With our advanced AR and VR capabilities, you'll gain access to immersive, interactive experiences that captivate audiences. Engage users through social VR, virtual events, and digital goods to strengthen your brand's presence across multiple digital dimensions.",
    icon: metaverse,
  },
  {
    title: "Crypto-Friendly Ecosystem",
    text: "Leverage the thriving cryptocurrency ecosystem within Beladed to offer unique incentives and rewards to your audience. Utilize our platform's native tokens or create your own branded cryptocurrency to facilitate in-platform transactions, sponsorships, and rewards. Embrace the future of digital currency and enhance your brand's appeal to tech-savvy users.",
    icon: eco,
  },
];

const SingleAdvantage = ({ title, text, icon, solo = false }) => {
  return (
    <div className="relative flex h-full flex-col items-center gap-4 bg-[rgba(255,255,255,0.1)] p-4  sm:items-start sm:gap-6 sm:p-6 lg:p-10">
      <div className="flex w-full flex-row items-center justify-between gap-1 sm:gap-3 lg:gap-6">
        <h3 className="text-base font-semibold sm:text-lg lg:text-2xl xl:text-3xl  2xl:text-4xl 3xl:text-5xl">
          {title}
        </h3>
        <div className="gradient-dark-opacity flex-c h-16 w-16 flex-shrink-0 rounded-xl sm:h-20  sm:w-20 xl:h-28 xl:w-28">
          <img src={icon} alt={title} />
        </div>
      </div>
      <div
        className={`text-xs sm:text-sm lg:text-base 2xl:text-lg  [&_p]:font-medium [&_span]:font-normal [&_span]:text-neutral-4 `}
      >
        {text}
      </div>
    </div>
  );
};
