import React from "react";
import logo from "assets/logo.svg";
import cover from "assets/home-cover.jpg";
import { Link, useNavigate } from "react-router-dom";
import { useAppContext } from "app/context/appContext";
const user = {
  type: "user",
  first_name: "Mohammed",
  last_name: "Banani",
  image:
    "https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1365&q=80",
};
const model = {
  type: "model",
  first_name: "Selena",
  last_name: "Gomez",
  image: "https://i.scdn.co/image/ab6761670000ecd4faf63cac20a454f861478fc0",
};
const NotFound = () => {
  return (
    <main className="relative flex-c min-h-screen  overflow-hidden px-2">
      <div className="absolute inset-0 min-h-screen w-screen z-0">
        <img
          src={cover}
          alt="cover"
          className="h-full w-full object-cover cover-animation"
        />
        <div className="gr-dark h-full w-full absolute inset-0 opacity-[85%]"></div>
      </div>
      <div className="relative z-10 flex flex-col gap-10 items-center text-center">
        <div className="flex flex-col gap-6 items-center">
          <img src={logo} alt="Beladed" className="w-64" />
          <h1 className="text-2xl text-white font-semibold">
            Sorry, we couldn’t find that page.
          </h1>
          <p className="text-lg text-white ">
            Head over to the{" "}
            <Link
              className="text-secondary-5 font-medium"
              to="/waitlist/dashboard">
              homepage
            </Link>
            , or check out some other pages.
          </p>
        </div>
        <div className="flex flex-col gap-8 items-center">
          <div className="flex items-center justify-center gap-6 flex-wrap">
            <Link to="/waitlist/win-25k" className="btn btn-main btn-large">
              Win 25K
            </Link>
            <Link to="/waitlist/referral" className="btn btn-main btn-large">
              Referral
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
};

export default NotFound;
