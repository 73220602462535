import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { createTheme, styled, ThemeProvider } from "@mui/material";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#FF1FAA",
    },
    secondary: {
      main: "#8055D5",
    },
  },
});
const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#FF1FAA",
    },
    secondary: {
      main: "#8055D5",
    },
  },
});
const SimpleSelectForm = ({
  value,
  onChange,
  options,
  label,
  showLabel = true,
  required = true,
  name,
  theme = "light",
}) => {
  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <FormControl
        className="w-full"
        sx={{
          ".MuiFormLabel-root": {
            fontSize: "14px !important",
          },

          ".MuiFormLabel-colorPrimary": {},
          ".MuiMenu-list": {
            backgroundColor: "black",
          },
          "MuiFormLabel-root": {
            color: `${theme === "dark" && "white !important"}`,
          },
          ".MuiInputLabel-root": {
            color: `${theme === "dark" && "white !important"}`,
          },
        }}>
        {showLabel && (
          <InputLabel
            id="select-category"
            className="!text-neutral-4"
            sx={{
              "MuiFormLabel-root": {
                color: `${theme === "dark" && "white !important"}`,
              },
              ".MuiInputLabel-root": {
                color: `${theme === "dark" && "white !important"}`,
              },
            }}>
            {label}
          </InputLabel>
        )}
        <Select
          sx={{
            width: "100%",

            fontSize: "14px",
            ".MuiSvgIcon-root": {},
            ".MuiPaper-root": {
              backgroundColor: "black",
            },
            ".MuiList-root": {
              backgroundColor: "black",
            },
            "MuiFormLabel-root": {
              color: `${theme === "dark" && "white !important"}`,
            },
            ".MuiInputLabel-root": {
              color: `${theme === "dark" && "white !important"}`,
            },
          }}
          id={label}
          label={label}
          value={value}
          required={required}
          onChange={(e) => onChange(e)}
          name={name}
          className="capitalize">
          {options.map((item, index) => {
            return (
              <MenuItem key={index} value={item} className="capitalize">
                {item}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
};
export default SimpleSelectForm;
