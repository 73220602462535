import logo from 'assets/logo.svg';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer
      className=" relative z-10 flex  items-center justify-center overflow-hidden bg-cover bg-no-repeat   p-4 
      text-white  xl:gap-20 "
    >
      <div className=" flex flex-col items-center justify-center gap-8">
        <div className="">
          <img src={logo} alt="Beladed" />
        </div>

        <div className="flex-c flex-col gap-4">
          <div>
            <p className="text-xs font-semibold  sm:text-base ">
              © 2023 Beladed Inc. All rights reserved.
            </p>
          </div>
          <div className="flex w-full   justify-center gap-3 text-2xs font-semibold text-secondary-5  sm:gap-6 sm:text-sm lg:gap-8 lg:text-base">
            <Link to="/about/terms" className="hover:text-secondary-4">
              Terms of Use
            </Link>
            <Link to="/about/privacy" className="hover:text-secondary-4">
              Privacy Policy
            </Link>
            <Link to="/about/california-privacy-policy" className="hover:text-secondary-4">
              California Consumer
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
