import Cryptocurrency from "assets/advisor/Cryptocurrency.svg";
import GameTech from "assets/advisor/Game-Tech.svg";
import Game from "assets/advisor/Game.svg";
import Shows from "assets/advisor/Shows.svg";
import adv from "assets/advisor/adv.svg";
import ai from "assets/advisor/ai.svg";
import ar from "assets/advisor/ar.svg";
import fashion from "assets/advisor/fashion.svg";
import fitness from "assets/advisor/fitness.svg";
import marketing from "assets/advisor/marketing.svg";
import media from "assets/advisor/media.svg";
import socialMedia from "assets/advisor/social-media.svg";
import logo from "assets/logo_sm.svg";

const Section3 = () => {
  return (
    <div className=" mx-auto flex flex-col gap-10 py-10 px-4 sm:px-10 md:py-16 lg:py-24 xl:max-w-[1150px] xl:gap-20 xl:px-8 2xl:max-w-[1400px] 2xl:py-32 3xl:max-w-screen-2xl">
      <div className="flex flex-col items-center justify-center gap-4 lg:gap-6">
        <div
          data-aos="fade-down"
          data-aos-duration="1000"
          data-aos-delay="1000"
          className="w-fit rounded-full bg-neutral-11"
        >
          <p className="text-gr    py-2 px-5 text-center text-sm tracking-[2.5px] xl:text-lg 3xl:text-xl">
            Write something for me
          </p>
        </div>
        <h1
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="1000"
          className="text-center text-3xl font-bold sm:text-4xl xl:text-5xl 2xl:text-6xl 3xl:text-7xl"
        >
          Advantage By Industry
        </h1>
      </div>
      <div className="lg:gap-x--8 relative z-0 flex  flex-wrap gap-y-6 gap-x-4 sm:gap-6 lg:gap-y-10 2xl:gap-8">
        {advantages.map((item, index) => {
          return (
            <div
              key={index}
              data-aos="zoom-in-left"
              data-aos-duration="1000"
              data-aos-delay={index <= 7 ? 700 + 200 * index : 200 + 200 * index}
              className=" relative z-10 sm:flex-[1_0_45%] md:flex-[1_0_30%] lg:flex-[1_0_22%]"
            >
              <SingleAdvantage {...item} />
            </div>
          );
        })}
        <div className=" abs-c w-[min(600px,100vw)] 2xl:w-[min(900px,100vw)]">
          <img src={logo} alt="beladed" className="-z-10 w-full opacity-5 grayscale" />
        </div>
      </div>
    </div>
  );
};

export default Section3;

const advantages = [
  {
    title: "eSports",
    text: (
      <p>
        Advise on tournament formats, game selection, and sponsorship{" "}
        <span>opportunities for the platform's gaming content.</span>
      </p>
    ),
    icon: Game,
  },
  {
    title: "Entertainment",
    text: (
      <p>
        Provide insights on audience preferences and trends,{" "}
        <span>advise on content selection, and assist with talent acquisition.</span>{" "}
      </p>
    ),
    icon: Shows,
  },
  {
    title: "Fashion",
    text: (
      <p>
        Collaborate on product placement and sponsorship{" "}
        <span>opportunities within the platform's content.</span>{" "}
      </p>
    ),
    icon: fashion,
  },
  {
    title: "Fitness",
    text: (
      <p>
        Advise on fitness content development and engagement{" "}
        <span>strategies to build a community of fitness enthusiasts on the platform.</span>{" "}
      </p>
    ),
    icon: fitness,
  },
  {
    title: "Marketing",
    text: (
      <p>
        Advise on brand partnerships, influencer marketing strategies,{" "}
        <span>and user acquisition tactics to drive growth.</span>{" "}
      </p>
    ),
    icon: marketing,
  },
  {
    title: "Advertising",
    text: (
      <p>
        Provide insights on ad placement, content relevance, and user engagement{" "}
        <span>to optimize ad performance and revenue.</span>{" "}
      </p>
    ),
    icon: adv,
  },
  {
    title: "Cryptocurrency",
    text: (
      <p>
        Advise on cryptocurrency integration within the platform,{" "}
        <span> including payment processing and reward structures.</span>{" "}
      </p>
    ),
    icon: Cryptocurrency,
  },
  {
    title: "Game Tech",
    text: (
      <p>
        Provide insights on game development and user engagement strategies{" "}
        <span>to create an engaging and immersive gaming experience.</span>{" "}
      </p>
    ),
    icon: GameTech,
  },
  {
    title: "Augmented Reality (AR)",
    text: (
      <p>
        Advise on AR content development and integration within the platform,{" "}
        <span> including interactive experiences and brand partnerships.</span>{" "}
      </p>
    ),
    icon: ar,
  },
  {
    title: "Artificial Intelligence (AI)",
    text: (
      <p>
        Collaborate on AI-powered user engagement strategies,{" "}
        <span>content personalization, and chatbot integration within the platform.</span>{" "}
      </p>
    ),
    icon: ai,
  },
  {
    title: "Social Media",
    text: (
      <p>
        Provide insights on social media engagement and user acquisition strategies,{" "}
        <span> and collaborate on cross-promotion opportunities.</span>{" "}
      </p>
    ),
    icon: socialMedia,
  },
  {
    title: "Media",
    text: (
      <p>
        Collaborate on content creation and distribution strategies,{" "}
        <span>and advise on emerging trends in media consumption and user engagement.</span>{" "}
      </p>
    ),
    icon: media,
  },
];

const SingleAdvantage = ({ title, text, icon }) => {
  return (
    <div className="flex flex-row items-center gap-4 sm:!flex-col  sm:items-start sm:gap-6">
      <div className="gradient-dark-2 flex-c h-16 w-16 flex-shrink-0 rounded-xl sm:h-20  sm:w-20">
        <img src={icon} alt={title} />
      </div>
      <div className="flex flex-col gap-1 sm:gap-3">
        <h3 className="text-base font-semibold sm:text-lg lg:text-xl 2xl:text-2xl">{title}</h3>
        <div className="text-xs sm:text-sm lg:text-base 2xl:text-lg  [&_p]:font-medium [&_span]:font-normal [&_span]:text-neutral-4">
          {text}
        </div>
      </div>
    </div>
  );
};
