import Modal from "../../components/Structure/Modal";
import CloseIcon from "@mui/icons-material/Close";
import ReactPlayer from "react-player";
const VideoModal = ({ state, toggle, title, url }) => {
  return (
    <Modal state={state} close={toggle}>
      <div className="relative flex  w-[800px]  flex-col gap-4 rounded-lg bg-white px-3 py-4  md:py-8 md:px-8">
        <div className="flex items-start justify-between  ">
          <div className="flex w-full flex-col gap-4">
            <div className="flex items-center justify-between">
              <h2 className="text-base font-semibold capitalize md:text-2xl text-neutral-1 ">
                {title} Benefits
              </h2>
              <button
                className=" text-neutral-1 "
                onClick={(e) => toggle(e)}
                name="video">
                {" "}
                <CloseIcon />
              </button>
            </div>
          </div>
          <div></div>
        </div>
        <div className="w-full h-full aspect-video">
          <ReactPlayer
            url={url}
            playsinline
            muted={false}
            width="100%"
            height="100%"
            controls={true}
            style={{
              zIndex: "-1",
              borderRadius: "8px",
            }}
            autoPlay={false}
          />
        </div>
      </div>
    </Modal>
  );
};
export default VideoModal;
