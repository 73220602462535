import CircularLoading from "app/components/CustomComponents/Loading";
import SelectWithRadio from "app/components/CustomComponents/SelectWithRadio";
import UserBox from "app/components/CustomComponents/UserBox";
import axios from "axios";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";

import { mockData } from "../../../data/mock-data";

const options = {
  sortBy: [
    { value: "Recently Joined", label: "Recently Joined" },
    { value: "Currently Online", label: "Currently Online" },
    { value: "Now Streaming", label: "Now Streaming" },
    { value: "Most Active", label: "Most Active" },
  ],
};

const initialState = {
  sortBy: false,
};
export const ModelsSearch = () => {
  const [configs, setConfigs] = useState({
    sortBy: "Currently Online",
  });
  const handleChange = (e) => {
    setConfigs({ ...configs, [e.target.name]: e.target.value });
  };
  const [areOpen, setAreOpen] = useState({
    sortBy: false,
  });
  const handleClose = () => {
    setAreOpen(initialState);
  };
  useEffect(() => {
    handleClose();
  }, [configs]);

  const navigate = useNavigate();

  return (
    <div className="container-fluid mb-32 mt-16 flex flex-col gap-4 p-4 sm:p-6">
      <div className="">
        <div className="flex flex-col gap-4">
          <div className="dark:border-neutral-4 flex flex-wrap items-end justify-between  gap-4 border-b border-neutral-9 pb-6">
            <div className="flex flex-col gap-4">
              <h2 className="text-3xl font-semibold">Streamers</h2>
              <div className="flex gap-6 font-medium">
                <p className="dark:text-primary-6 text-primary-5">Kartina</p>
                <p>90 Results</p>
              </div>
            </div>

            <div className="flex items-center gap-6">
              <div className="flex w-[250px] flex-col gap-2">
                <label className="text-sm font-medium">Sort By</label>
                <SelectWithRadio
                  options={options.sortBy}
                  areOpen={areOpen}
                  setAreOpen={setAreOpen}
                  configs={configs}
                  handleClose={handleClose}
                  handleChange={handleChange}
                  name="sortBy"
                  label={"Sort By"}
                  classNames="bg-white border border-neutral-9"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Models />
    </div>
  );
};
const Models = () => {
  const fetchUsers = async () => {
    // const res = await fetch(
    //   `https://randomuser.me/api/?gender=male&r?page=${pageParam}&results=50&inc=name,picture`,
    // );
    const res = mockData["models"];

    return res;
  };

  const { isLoading, isError, data, error, isFetching } = useQuery("streamerSearch", fetchUsers, {
    refetchOnWindowFocus: false,
  });
  const { ref, inView } = useInView();
  const [currentPage, setCurrentPage] = useState(1);

  const handleLoadMore = () => {
    setCurrentPage(currentPage + 1);
  };
  useEffect(() => {
    if (inView) {
      handleLoadMore();
    }
  }, [inView]);
  if (isLoading) {
    return (
      <div className="flex-c h-[200px] w-full ">
        <CircularLoading />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex-c h-[200px] w-full  text-lg font-medium">Error: {error.message}</div>
    );
  }
  return (
    <div className="col-12  ">
      <div>
        {data.length === 0 ? (
          <div className="flex flex-col items-center gap-6 pt-8 pb-6">
            <p className="text-xl font-medium"> No results matched your search</p>
            <Link to="/create-livestream" className="btn btn-main hidden">
              Go to Dashboard
            </Link>
          </div>
        ) : (
          <div>
            <div
              className="grid grid-cols-[repeat(auto-fit,minmax(150px,1fr))] flex-wrap 
            justify-between gap-4 md:grid-cols-[repeat(auto-fit,minmax(200px,1fr))]  "
            >
              {data.slice(0, currentPage * 100).map((item, index) => {
                const { name, image, username, followers_count } = item;
                return (
                  <UserBox
                    key={index}
                    dynamicWidth={false}
                    link={`/${username}`}
                    name={name}
                    username={username}
                    followers_count={followers_count}
                    image={image}
                    size={14}
                    buttons={["follow", "draft"]}
                  />
                );
              })}
            </div>
            <div className="flex-c">
              <button
                ref={ref}
                onClick={handleLoadMore}
                className="btn dark:btn-second-dark btn-second"
                disabled={currentPage * 100 >= data?.length}
              >
                {isFetching
                  ? "Loading more..."
                  : currentPage * 100 <= data?.length
                  ? "Load More"
                  : "Nothing more to load"}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModelsSearch;
