import { InputAdornment, ThemeProvider, createTheme, styled } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#FF1FAA",
    },
    secondary: {
      main: "#8055D5",
    },
  },
});
const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#FF1FAA",
    },
    secondary: {
      main: "#8055D5",
    },
  },
});
const SimpleInputForm = ({
  placeholder,
  onChange,
  label,
  name,
  value,
  type = "text",
  theme = "light",
  hasPattern = false,
  pattern = "",
  hasIcon = false,
  icon,
  required = true,
}) => {
  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <FormControl
        variant="outlined"
        className="w-full "
        onChange={(e) => onChange(e)}
        sx={{
          ".MuiInputLabel-root": {
            fontSize: "14px !important",
          },
          ".MuiFormLabel-colorPrimary": {},
          ".MuiInputBase-root": {
            fontSize: "14px",
          },
        }}
      >
        <InputLabel htmlFor="Nickname" className=" !text-3xs">
          {label}
        </InputLabel>
        <OutlinedInput
          type={type}
          label={label}
          value={value}
          placeholder={placeholder}
          name={name}
          autoComplete={"off"}
          required={required}
          startAdornment={
            hasIcon && (
              <InputAdornment position="start" sx={{ margin: 0 }}>
                {icon}
              </InputAdornment>
            )
          }
          inputProps={
            hasPattern
              ? { style: { fontSize: "14px" }, pattern: pattern }
              : { style: { fontSize: "14px" } }
          }
          sx={{
            ".MuiOutlinedInput-root:hover ": {},
            ".MuiOutlinedInput-notchedOutline:hover": {},
            ".MuiInputLabel-root": {
              fontSize: "14px !important",
            },
          }}
        />
      </FormControl>
    </ThemeProvider>
  );
};
export default SimpleInputForm;
