import { useEffect, useRef, useState } from "react";

const useIsDesktop = () => {
  const [isDesktopScreen, setIsDesktopScreen] = useState(false);
  const refWidth = useRef(window.innerWidth);
  useEffect(() => {
    const width = refWidth.current;
    setIsDesktopScreen(width > 1024);
    const handleResize = () => {
      const width = window.innerWidth;
      setIsDesktopScreen(width > 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { isDesktopScreen };
};

export default useIsDesktop;
