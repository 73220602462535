import { ThemeProvider, createTheme } from "@mui/material";
import "animate.css";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";

import ProtectedRoute from "./ProtectedRoute";
import ScrollToTop from "./hooks/ScrollToTop.jsx";
import { About, California, Cookies, Privacy, Terms } from "./pages/About/Imports";
import { AboutPage } from "./pages/About/Loadable";
import { Advisor } from "./pages/Advisor/Imports";
import { AdvisorPage } from "./pages/Advisor/Loadable";
import { Brands } from "./pages/Brands/Imports";
import { BrandsPage } from "./pages/Brands/Loadable";
import { Confirm } from "./pages/Confirm/Imports";
import { ConfirmPage } from "./pages/Confirm/Loadable";
import { Dashboard } from "./pages/Dashboard/Imports";
import { DashboardPage } from "./pages/Dashboard/Loadable";
import { Home } from "./pages/Home/Imports";
import { HomePage } from "./pages/Home/Loadable";
import { Invest } from "./pages/Invest/Imports";
import { InvestPage } from "./pages/Invest/Loadable";
import { ConfirmModels, JoinModels } from "./pages/Models/Imports";
import { NotFound } from "./pages/Not-found/Imports";
import { Partners } from "./pages/Partners/Imports";
import { PartnersPage } from "./pages/Partners/Loadable";
import { PayoutDetails } from "./pages/Payout-details/Imports";
import { PayoutDetailsPage } from "./pages/Payout-details/Loadable";
import { Referral } from "./pages/Referral/Imports";
import { ReferralPage } from "./pages/Referral/Loadable";
import { Root } from "./pages/Root/Imports";
import { RootPage } from "./pages/Root/Loadable";
import { Search } from "./pages/Search/Imports";
import { SearchPage } from "./pages/Search/Loadable";
import { BuyTokens, Join, PostJoin } from "./pages/Users/Imports";
import { UserPage } from "./pages/Users/Loadable";
import { WIN25K } from "./pages/Win-25K/Imports";
import { WIN25KPage } from "./pages/Win-25K/Loadable";
import { AgencyModels } from "./pages/agency-models/Imports";
import { AgencyModelsPage } from "./pages/agency-models/Loadable";
import { InstagramModels } from "./pages/instagram-models/Imports";
import { InstagramModelsPage } from "./pages/instagram-models/Loadable";
import { OnlyFansModels } from "./pages/onlyfans-models/Imports";
import { OnlyFansModelsPage } from "./pages/onlyfans-models/Loadable";
import { TiktokModels } from "./pages/tiktok-models/Imports";
import { TiktokModelsPage } from "./pages/tiktok-models/Loadable";
import { TwitchModels } from "./pages/twitch-models/Imports";
import { TwitchModelsPage } from "./pages/twitch-models/Loadable";
import { YoutubeModels } from "./pages/youtube-models/Imports";
import { YoutubeModelsPage } from "./pages/youtube-models/Loadable";

const muiLightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#FF1FAA",
    },
    secondary: {
      main: "#8055D5",
    },
    progress: {
      main: "#8055D5",
      light: "#F7F6F9",
    },
    text: {
      primary: "#000000",
      secondary: "#000000",
      dark: "red",
      light: "green",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
      "2xl": 1536,
    },
  },
  typography: {
    fontFamily: ["Inter", "-apple-system", "sans-serif"].join(","),
  },
  components: {
    MuiIcon: {
      styleOverrides: {
        root: {
          // Match 24px = 3 * 2 + 1.125 * 16
          boxSizing: "content-box",
          padding: 3,
          fontSize: "1.125rem",
          color: "#000000",
          backgroundColor: "#000000",
        },
      },
    },
  },
});
function App() {
  useEffect(() => {
    document.documentElement.classList.add("light");
    localStorage.setItem("allowGoBack", 0);
  }, []);

  return (
    <ThemeProvider theme={muiLightTheme}>
      <BrowserRouter>
        <Helmet titleTemplate="Beladed Pre-Sale" defaultTitle="Beladed Pre-Sale">
          <meta name="description" content="Beladed Pre-Sale" />
        </Helmet>
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <RootPage />
              </ProtectedRoute>
            }
          >
            <Route index element={<Root />} />
          </Route>

          <Route path="/about" element={<WIN25KPage />}>
            <Route index element={<About />} />
            <Route path="terms" element={<Terms />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="cookies" element={<Cookies />} />
            <Route path="california-privacy-policy" element={<California />} />
          </Route>
          <Route path="/waitlist" element={<HomePage />}>
            <Route index element={<Home />} />
          </Route>
          <Route path="/invest" element={<InvestPage />}>
            <Route index element={<Invest />} />
          </Route>
          <Route path="/advisors" element={<AdvisorPage />}>
            <Route index element={<Advisor />} />
          </Route>
          <Route path="/partners" element={<PartnersPage />}>
            <Route index element={<Partners />} />
          </Route>
          <Route path="/brands" element={<BrandsPage />}>
            <Route index element={<Brands />} />
          </Route>
          <Route path="/agency-models" element={<AgencyModelsPage />}>
            <Route index element={<AgencyModels />} />
          </Route>
          <Route path="/onlyfans-models" element={<OnlyFansModelsPage />}>
            <Route index element={<OnlyFansModels />} />
          </Route>
          <Route path="/twitch-models" element={<TwitchModelsPage />}>
            <Route index element={<TwitchModels />} />
          </Route>
          <Route path="/instagram-models" element={<InstagramModelsPage />}>
            <Route index element={<InstagramModels />} />
          </Route>
          <Route path="/tiktok-models" element={<TiktokModelsPage />}>
            <Route index element={<TiktokModels />} />
          </Route>
          <Route path="/youtube-models" element={<YoutubeModelsPage />}>
            <Route index element={<YoutubeModels />} />
          </Route>
          <Route
            path="/waitlist/referral"
            element={
              <ProtectedRoute>
                <ReferralPage />
              </ProtectedRoute>
            }
          >
            <Route index element={<Referral />} />
          </Route>
          <Route
            path="/waitlist/payout-details"
            element={
              <ProtectedRoute>
                <PayoutDetailsPage />
              </ProtectedRoute>
            }
          >
            <Route index element={<PayoutDetails />} />
          </Route>
          <Route
            path="/waitlist/win-25k"
            element={
              <ProtectedRoute>
                <WIN25KPage />
              </ProtectedRoute>
            }
          >
            <Route index element={<WIN25K />} />
          </Route>
          <Route
            path="/waitlist/dashboard"
            element={
              <ProtectedRoute>
                <DashboardPage />
              </ProtectedRoute>
            }
          >
            <Route index element={<Dashboard />} />
          </Route>

          <Route
            path="/waitlist/already-joined"
            element={
              <ProtectedRoute>
                <ConfirmPage />
              </ProtectedRoute>
            }
          >
            <Route index element={<Confirm />} />
          </Route>

          <Route
            path="/waitlist/users"
            element={
              <ProtectedRoute>
                <UserPage />
              </ProtectedRoute>
            }
          >
            <Route index element={<Join />} /> <Route path="tokens" element={<BuyTokens />} />
            <Route path="congrats" element={<PostJoin />} />
          </Route>

          <Route>
            <Route index element={<Join />} /> <Route path="tokens" element={<BuyTokens />} />
            <Route path="congrats" element={<PostJoin />} />
          </Route>
          <Route
            path="/waitlist/models"
            element={
              <ProtectedRoute>
                <UserPage />
              </ProtectedRoute>
            }
          >
            <Route index element={<JoinModels />} />{" "}
          </Route>
          <Route path="/waitlist/search/streamers" element={<SearchPage />}>
            {" "}
            <Route index element={<Search />} />
          </Route>
          <Route path="*" element={<NotFound />}></Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
