import { useEffect, useMemo, useState } from "react";

const useStaticCountdown = (targetDate) => {
  const [countDown, setCountDown] = useState(targetDate);
  const [values, setValues] = useState(getReturnValues(countDown));
  const handleReset = () => {
    setCountDown(targetDate);
  };
  useEffect(() => {
    setCountDown(targetDate);
  }, [targetDate]);

  useEffect(() => {
    setValues(getReturnValues(countDown));
  }, [countDown]);
  return { values, handleReset };
};

const getReturnValues = (countDown) => {
  let minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  let seconds = Math.floor((countDown % (1000 * 60)) / 1000);
  seconds = seconds < 10 ? "0" + seconds : seconds;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  if (countDown === 0) {
    seconds = "00";
    minutes = "00";
  }
  return { minutes, seconds };
};
export default useStaticCountdown;
