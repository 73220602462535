import img1a from "assets/invest/section-4-1-a.jpg";
import img1b from "assets/invest/section-4-1-b.jpg";
import img1c from "assets/invest/section-4-1-c.png";

const ImmersiveExperience = () => {
  return (
    <div className=" max-screen relative z-20 mx-auto flex flex-col items-center gap-10 py-10 px-4 md:py-16 lg:py-24 lg:px-8 xl:gap-20  2xl:py-32 ">
      <div className="   ">
        <h1
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="500"
          className="hero-title archivo mb-4  text-start text-5xl font-bold leading-tight text-neutral-1 lg:mb-8 lg:text-6xl  xl:text-7xl 2xl:text-8xl 3xl:text-[150px] "
        >
          Immersive <span className="text-gr">Experience</span>
        </h1>
        <div className="flex flex-col gap-4 lg:gap-8">
          <h2
            data-aos="fade-in"
            data-aos-duration="2000"
            data-aos-delay="1500"
            className="text-start text-lg font-bold  text-neutral-2 lg:text-3xl 3xl:text-4xl     "
          >
            Enthrall fans, transcend expectations, and redefine entertainment.
          </h2>
          <p
            data-aos="fade-in"
            data-aos-duration="2000"
            data-aos-delay="1500"
            className="text-start text-sm font-medium  text-neutral-4 lg:text-xl 3xl:text-3xl "
          >
            Provide immersive, unforgettable experiences for your viewers, setting you apart from
            the competition and elevating your brand.
          </p>
        </div>
      </div>

      <div className=" grid w-full grid-cols-8 grid-rows-8 rounded-xl py-6 sm:px-6 lg:py-12">
        <div
          style={{ gridArea: " 1 / 1 / 9 / 8" }}
          className="self-center"
          data-aos="fade-right"
          data-aos-duration="1500"
          data-aos-delay="1000"
        >
          <img src={img1a} alt="Target Verticals" className="rounded-lg object-cover  " />
        </div>
        <div
          style={{ gridArea: "1 / 6 / 9 / 9" }}
          className="flex justify-end self-center"
          data-aos="fade-left"
          data-aos-duration="2000"
          data-aos-delay="1500"
        >
          <img src={img1b} alt="Target Verticals" className="rounded-lg" />
        </div>
        <div
          style={{ gridArea: "5 / 4 / 7 / 7" }}
          className="flex justify-end"
          data-aos="flip-right"
          data-aos-duration="1500"
          data-aos-delay="1500"
        >
          <img src={img1c} alt="Target Verticals" className="rounded-lg" />
        </div>
      </div>
    </div>
  );
};

export default ImmersiveExperience;
