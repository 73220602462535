import img1a from "assets/agency/section-2-1-a.jpg";
import img1b from "assets/agency/section-2-1-b.jpg";
import img1c from "assets/agency/section-2-1-c.jpg";
import img2 from "assets/agency/section-2-2.webp";

const TargetIndustries = () => {
  return (
    <div className="gr-dark flex flex-col gap-10 pt-10 md:pt-16 lg:gap-20 lg:pt-24 2xl:pt-32 ">
      <div className="max-screen mx-auto flex flex-col  gap-6 px-4 lg:px-8 xl:px-12">
        <div>
          <h1
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="500"
            className="archivo mb-6 w-fit  text-start text-4xl font-bold !leading-none text-[#DDD2F2] md:text-6xl lg:mb-10 lg:text-6xl  xl:text-7xl 2xl:text-8xl 3xl:text-[130px]"
          >
            Boost Your <span className="text-gr">Income</span>
          </h1>
          <div className="lg:flex-8 flex flex-col gap-4">
            <h2
              data-aos="fade-in"
              data-aos-duration="2000"
              data-aos-delay="1500"
              className="text-start text-lg font-bold  text-neutral-10 lg:text-3xl 3xl:text-4xl     "
            >
              Diversify earnings, maximize income, and secure success
            </h2>
            <p
              data-aos="fade-in"
              data-aos-duration="2000"
              data-aos-delay="1500"
              className="text-start text-sm font-normal  text-neutral-10 lg:text-xl 3xl:text-3xl "
            >
              Monetize your content in multiple ways, from custom buttons to post-live video ads,
              ensuring a steady income stream and financial stability as a model.
            </p>
          </div>
        </div>
        <div className="flex flex-col py-6 lg:py-12 ">
          {" "}
          <div className=" grid w-full grid-cols-12 grid-rows-7  rounded-xl px-6">
            <div
              style={{ gridArea: " 1 / 2 / 9 / 12" }}
              className="self-center"
              data-aos="zoom-in"
              data-aos-duration="1500"
              data-aos-delay="1000"
            >
              <img
                src={img1a}
                alt="Target Verticals"
                className="w-full rounded-lg object-cover md:h-full"
              />
            </div>
            <div
              style={{ gridArea: "2 / 10 / 4 / 13" }}
              className="flex justify-end"
              data-aos="flip-left"
              data-aos-duration="2000"
              data-aos-delay="1500"
            >
              <img src={img1b} alt="Target Verticals" className="rounded-lg" />
            </div>
            <div
              style={{ gridArea: "5 / 1 / 7 / 6" }}
              className="flex justify-end"
              data-aos="flip-right"
              data-aos-duration="1500"
              data-aos-delay="1500"
            >
              <img src={img1c} alt="Target Verticals" className="rounded-lg" />
            </div>
          </div>
          <small className="mt-2 text-3xs tracking-tighter text-neutral-10  lg:text-sm">
            The above is an example of what earnings could look like. Actual earnings may vary.
          </small>
        </div>

        <div className=" relative mx-auto flex max-w-screen-2xl flex-col items-end gap-4 lg:flex-row  ">
          <div className="flex flex-col gap-4   px-4 pb-6 pt-10  text-white lg:w-1/2 lg:gap-10 lg:px-0  lg:pb-20  2xl:pb-40">
            <h1
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="500"
              className="archivo text-start  text-4xl font-bold !leading-none text-[#DDD2F2] md:text-6xl lg:text-6xl 2xl:text-7xl 3xl:text-[130px]  "
            >
              Unleash <span className="text-gr">Creative</span> Freedom
            </h1>
            <div>
              <p
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="1000"
                className="text-start text-sm font-normal  text-neutral-10 lg:text-xl 3xl:text-3xl "
              >
                Be bold, express yourself, and redefine your image. Break free from conventional
                boundaries, experiment with innovative content ideas, and redefine your personal
                brand in the ever-evolving world of modeling.
              </p>
            </div>
          </div>

          <div
            className="h-full px-4 xl:px-0 3xl:px-8"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="1500"
          >
            <img src={img2} alt="streaming" className="h-full max-h-[900px] w-full" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TargetIndustries;
