import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const About = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/about/terms");
  }, []);
  return <div></div>;
};

export default About;
