import { useAppContext } from "app/context/appContext";
import { useEffect, useRef, useState } from "react";

const ScrollingPictures = () => {
  const slidersRef = useRef([]);
  const intervalRef = useRef(null);
  const [isMouseIn, setIsMouseIn] = useState(false);
  const [numColumns, setNumColumns] = useState(getNumColumns());
  const { user } = useAppContext();

  useEffect(() => {
    slidersRef.current = slidersRef.current.slice(0, numColumns);
  }, [numColumns]);
  //   useEffect(() => {
  //     if (!isMouseIn) {
  //       const interval = setInterval(() => {
  //         const sliders = slidersRef.current;
  //         const numSliders = sliders.length;

  //         const currentScrolls = sliders.map((slider) => slider.scrollTop);
  //         const scrollHeights = sliders.map((slider) => slider.scrollHeight);
  //         const clientHeights = sliders.map((slider) => slider.clientHeight);

  //         for (let i = 0; i < numSliders; i++) {
  //           const slider = sliders[i];
  //           const currentScroll = currentScrolls[i];
  //           const scrollHeight = scrollHeights[i];
  //           const clientHeight = clientHeights[i];

  //           if (i % 2 === 0) {
  //             if (currentScroll + clientHeight >= scrollHeight) {
  //               slider.scrollTop = 0;
  //             } else {
  //               slider.scrollTop += 1;
  //             }
  //           } else {
  //             if (currentScroll <= 0) {
  //               slider.scrollTop = scrollHeight - clientHeight;
  //             } else {
  //               slider.scrollTop -= 0.01;
  //             }
  //           }
  //         }
  //       }, 20);
  //       intervalRef.current = interval;
  //       return () => clearInterval(interval);
  //     }
  //   }, [isMouseIn]);
  const handleMouseEnter = () => {
    clearInterval(intervalRef.current);
    setIsMouseIn(true);
  };

  const handleMouseLeave = () => {
    setIsMouseIn(false);
  };
  useEffect(() => {
    function handleResize() {
      setNumColumns(getNumColumns());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const divs = [
    1, 1, 1, 0, 1, 1, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1,
    1, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 0, 1, 0,
    1, 0, 1, 1, 0, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 0, 1, 0, 1, 0, 1, 1, 0,
    1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 1, 0, 1, 1,
    1, 1, 0, 1, 1, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 1, 0, 1, 11, 1, 1, 0, 1, 1, 1,
    0, 1, 0, 1, 0, 1, 1, 0, 1, 1, 0, 1, 1,
  ];

  return (
    <>
      <div className="flex gap-5 scroll-smooth overflow-hidden h-full    animate-parent px-4">
        {[...Array(numColumns)].map((_, index) => {
          return (
            <div
              key={index}
              className={` flex flex-col gap-5 animate animate-${index}  `}
              ref={(item) => (slidersRef.current[index] = item)}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}>
              {divs.map((item, index2) => {
                if (item === 0) {
                  if (user.type === "user") {
                    return (
                      <div
                        className={`px-3 h-32 w-32  flex-shrink-0 flex flex-col items-center justify-center gap-3 rounded-xl`}
                        key={index2}
                        style={{
                          backgroundColor: randomColors[Math.floor(index)],
                        }}>
                        <div className="text-center">
                          {" "}
                          <p className="font-semibold text-sm">
                            {randomTexts[Math.floor(index)].title}
                          </p>
                        </div>
                        <div className="flex flex-col text-xs items-center">
                          <span className="font-semibold">
                            {randomTexts[Math.floor(index)].price}
                          </span>
                          <span>Tokens</span>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        className={`px-3 h-32 w-32  flex-shrink-0 flex flex-col items-center justify-center gap-3 rounded-xl`}
                        key={index2}
                        style={{
                          backgroundColor: randomColors[Math.floor(index)],
                        }}>
                        <div className="text-center">
                          <span className="text-xs font-semibold">
                            Earn Money
                          </span>
                          <p className="text-xs">
                            {randomTextsModels[Math.floor(index)].text}
                          </p>
                        </div>
                        <div className="flex flex-col text-sm text-center items-center">
                          <span className="font-semibold">
                            {randomTextsModels[Math.floor(index)].title}
                          </span>
                        </div>
                      </div>
                    );
                  }
                } else {
                  return (
                    <div
                      key={index2}
                      className={`w-32 h-32 rounded-xl aspect-square flex-shrink-0 bg-cover bg-center bg-no-repeat image-${
                        index2 + 1 + numColumns * index
                      }  `}></div>
                  );
                }
              })}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ScrollingPictures;
const getNumColumns = () => {
  const windowWidth = window.innerWidth;
  const squareWidth = 128 * 2.2;
  const gap = 20;
  const totalWidth = squareWidth + gap;
  return windowWidth >= 2250
    ? Math.floor(2250 / totalWidth)
    : Math.floor(windowWidth / totalWidth);
};

const randomTexts = [
  { title: "Send Priority Message", price: "100" },
  { title: "Request A Birthday Shout out", price: "250" },
  { title: "Give Models A Tip", price: "100" },
  { title: "Send A Live stream Sticker", price: "25" },
  { title: "Draft Models To a Team", price: "100" },
  { title: "Vote On Model Live stream", price: "50" },
  { title: "Suggest Live stream Activity", price: "100" },
];
const randomTextsModels = [
  { title: "Send A Live stream Sticker", text: "For Giving A" },
  { title: "Give Tips", text: "When Users" },
  { title: "Priority Messages", text: "For Replying To" },
  { title: "Birthday Shout", text: "For Giving A" },
  { title: "Vote On Your Live-streams", text: "When Users" },
  { title: "Draft You To A Team", text: "When Users" },
  { title: "Suggest Live stream Activities", text: "When Users" },
];
const randomColors = [
  "#6633CC",
  "#5229A3",
  "#E5008E",
  "#FF1FAA",
  "#6633CC",
  "#5229A3",
  "#E5008E",
  "#FF1FAA",
  "#6633CC",
  "#5229A3",
  "#E5008E",
  "#FF1FAA",
];
