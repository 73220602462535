import logo from "assets/logo_sm.svg";
import Cryptocurrency from "assets/partner/Cryptocurrency.svg";
import ai from "assets/partner/ai.svg";
import metaverse from "assets/partner/metaverse.svg";
import waves from "assets/waves-2.svg";

const TheConvergence = () => {
  return (
    <div className="container-fluid gradient-dark text-white">
      <div className=" mx-auto flex flex-col gap-10 py-10 px-4 sm:px-10 md:py-16 lg:py-24 xl:max-w-[1150px]  xl:px-8 2xl:max-w-[1400px] 2xl:py-32 3xl:max-w-screen-2xl">
        <div className="flex flex-col items-center justify-center gap-4 lg:gap-6">
          <h1
            data-aos="fade-down"
            data-aos-duration="1000"
            data-aos-delay="1000"
            className="text-center text-4xl font-bold sm:text-5xl xl:text-6xl 2xl:text-7xl 3xl:text-8xl"
          >
            The Convergence of Virtual, Digital, and Intelligent Worlds
          </h1>
        </div>{" "}
        <div className="row relative z-0 items-stretch     gap-y-6 lg:gap-y-10">
          {advantages.map((item, index) => {
            return (
              <div
                key={index}
                data-aos={index === 0 ? "fade-right" : index === 1 ? "fade-left" : "fade-up"}
                data-aos-duration="1500"
                data-aos-delay="1000"
                className={` relative z-10  ${
                  index === 2 ? "flex-1 " : "flex-1 md:flex-[1_0_50%]"
                }  `}
              >
                <SingleAdvantage {...item} solo={index === 2} />
              </div>
            );
          })}
          <div className=" abs-c h-full w-auto">
            <img
              src={logo}
              alt="beladed"
              className="-z-10 h-full  opacity-10 hue-rotate-[140deg] invert-[1]  "
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TheConvergence;

const advantages = [
  {
    title: "AI-Powered Optimization Strategies",
    text: "Future-proof your brand and enter the metaverse with Beladed. Immerse your followers in captivating AR and VR experiences, and engage with them on a whole new level. The Beladed Partners program offers the tools you need to conquer the digital frontier and leave a lasting impact.",
    icon: ai,
  },
  {
    title: "Tap into Digital Currency",
    text: "Embrace the future of digital currency by joining the Beladed Partners program. Utilize our platform's native tokens or create your own branded cryptocurrency to facilitate transactions, sponsorships, and rewards. Enhance your appeal to tech-savvy users and stay ahead of the curve in the ever-evolving digital landscape.",
    icon: Cryptocurrency,
  },
  {
    title: "Metaverse Expansion Opportunity",
    text: "Future-proof your brand and enter the metaverse with Beladed. Immerse your followers in captivating AR and VR experiences, and engage with them on a whole new level. The Beladed Partners program offers the tools you need to conquer the digital frontier and leave a lasting impact.",
    icon: metaverse,
  },
];

const SingleAdvantage = ({ title, text, icon, solo = false }) => {
  return (
    <div className="relative flex h-full flex-row items-center gap-4 bg-[rgba(255,255,255,0.1)] p-4  sm:!flex-col sm:items-start sm:gap-6 sm:p-6 lg:p-10">
      <div className="flex w-full flex-row items-center justify-between gap-1 sm:gap-3 lg:gap-6">
        <h3 className="text-base font-semibold sm:text-lg lg:text-2xl xl:text-3xl  2xl:text-4xl 3xl:text-5xl">
          {title}
        </h3>
        <div className="gradient-dark-opacity flex-c h-16 w-16 flex-shrink-0 rounded-xl sm:h-20  sm:w-20 xl:h-28 xl:w-28">
          <img src={icon} alt={title} />
        </div>
      </div>
      <div
        className={`text-xs sm:text-sm lg:text-base 2xl:text-lg  [&_p]:font-medium [&_span]:font-normal [&_span]:text-neutral-4 ${
          solo && "max-w-3xl"
        }`}
      >
        {text}
      </div>
      {solo && (
        <div
          className="abs-c flex-c absolute -z-10 h-full w-full bg-cover "
          style={{
            backgroundImage: `url(${waves})`,
            backgroundSize: "",
          }}
        ></div>
      )}
    </div>
  );
};
