import paypal from "assets/paypal.svg";
import { useNavigate } from "react-router-dom";

import Modal from "../Structure/Modal";

const PaypalModal = ({
  state,
  toggle,

  name,
  setStep,
}) => {
  const handleClose = (e) => {
    toggle(e);
  };
 
  const handlePaypal = () => {
    const paypalURL = "https://www.paypal.com";
    window.open(paypalURL, "_blank");
    setTimeout(() => {
      setStep(3);
      handleClose();
    }, 500);
  };

  return (
    <Modal state={state} close={toggle}>
      <div className="flex w-[min(90vw,500px)] flex-col  items-center justify-center gap-4 bg-white py-10 px-8">
        <div className="flex items-center gap-2">
          <img src={paypal} alt="Cash App" />
          <span className="font-bold text-neutral-1">Paypal</span>
        </div>
        <div>
          <button
            onClick={() => handlePaypal()}
            className="btn-pill flex items-center gap-2 bg-neutral-1 py-4 px-8 text-white"
          >
            <img src={paypal} alt="" />
            Continue to Paypal
          </button>
        </div>
        <p className="text-center text-neutral-5">
          We will confirm your payment and email you a receipt.{" "}
        </p>
        <div className="mt-2  flex justify-center px-3 ">
          <button name={name} onClick={(e) => handleClose(e)} className="btn btn-main ">
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default PaypalModal;
