import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import axios from "axios";
import { mockData } from "data/mock-data.js";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";

import CircularLoading from "../Small/Loading";
import UserBoxSelect from "../Small/UserBoxSelect";
import Modal from "../Structure/Modal";

const SelectModel = ({
  state,
  toggle,
  staticToggle,
  doubleStaticToggle,
  setSelectedModal,
  selectedModal,
  handleSearchModal,
}) => {
  const fetchModels = async () => {
    // const { data } = await axios.get(`http://localhost:5000/models`);
    const data = mockData["models"];
    return data;
  };
  const { isLoading, isError, data, error } = useQuery("ProfileFollowing", fetchModels, {
    refetchOnWindowFocus: false,
  });

  const [selectedModel, setSelectedModel] = useState("");
  const handleSelectModel = (id) => {
    setSelectedModel(id);
  };
  const checkIfSelected = (id) => {
    const isSelected = data.indexOf(id);

    return id === selectedModel.id ? true : false;
  };
  const handleSelect = () => {
    doubleStaticToggle("selectModel", "gallery");
  };

  if (isLoading) {
    return (
      <div className="flex-c h-[200px] w-full ">
        <CircularLoading />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex-c h-[200px] w-full  text-lg font-medium">Error: {error.message}</div>
    );
  }
  return (
    <Modal state={state} close={toggle}>
      <div className="relative mx-auto my-auto flex w-[95vw] flex-col   gap-6 rounded-lg  bg-white px-3 py-4 sm:w-[600px]  md:px-8 md:py-8 lg:w-[780px]">
        <div className="flex  w-full items-start justify-between">
          <div className="flex w-full  flex-col gap-3 md:gap-6">
            <div className="flex items-center justify-between ">
              <h2 className="text-xl font-semibold text-neutral-1 md:text-2xl">Search Model</h2>
              <div>
                <IconButton aria-label="close" name="selectModel" onClick={(e) => toggle(e)}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            <div className="flex max-w-[300px] items-center rounded-lg   border border-neutral-9 bg-white px-3   py-2 ">
              <FormControl className="flex flex-row gap-2" sx={{ height: "100%", border: "none" }}>
                <div className="flex-c text-neutral-5   ">
                  {" "}
                  <SearchIcon />
                </div>
                <div className="text-neutral-5   ">
                  <InputBase
                    sx={{ flex: 1, fontSize: 16 }}
                    placeholder="Search..."
                    inputProps={{ "aria-label": "Search" }}
                  />
                </div>
              </FormControl>
            </div>
          </div>
          <div></div>
        </div>
        <div className="flex  flex-col gap-6">
          <div
            className="custom-scrollbar grid max-h-[300px] grid-cols-[repeat(auto-fit,minmax(160px,1fr))] gap-2 
           overflow-y-auto pr-2  pb-4 md:max-h-[300px]  md:gap-4 "
          >
            {data.slice(0, 20).map((item, index) => {
              const { id, name, image, username, followers_count, picture } = item;
              return (
                <UserBoxSelect
                  onClick={() =>
                    setSelectedModel({
                      id,
                      name: name.first,
                      image,
                    })
                  }
                  key={index}
                  isSelected={checkIfSelected(id)}
                  link={`/${username}`}
                  classes="w-full"
                  username={username}
                  name={name}
                  image={image}
                  dynamicWidth={false}
                  followers_count={followers_count}
                  buttons={["follow", "draft"]}
                  size={14}
                  toggle={toggle}
                />
              );
            })}
          </div>
        </div>

        <div className="mt-3 flex  items-center gap-4 self-end justify-self-end">
          <button className="btn btn-tertiary-grey " onClick={(e) => toggle(e)} name="selectModel">
            Close
          </button>
          <button
            className="btn btn-main "
            onClick={() => handleSelect()}
            disabled={!selectedModel}
          >
            Select
          </button>
        </div>
      </div>
    </Modal>
  );
};
export default SelectModel;
